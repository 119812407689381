import React from 'react';
import '../../../css/utils/buttons/MenuButton.css';

const MenuButton = (props) => {
    const {text, disabled, click} = props;
    return(
        <button disabled={disabled} onClick={click} className='MenuButton'>{text}</button>
    )
}

export default MenuButton;