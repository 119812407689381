import React from 'react';
import '../../../../css/dashboard/containers/white-container/WhiteContainer.css';

const WhiteContainer = (props) => {
    const {children} = props;
    return(
        <div className='WhiteContainer'>
            {children}
        </div>
    );
}

export default WhiteContainer;