import React from 'react';
import { Link } from 'react-router-dom';
import '../../../../css/dashboard/projects/single-project/SingleProject.css';

const SingleProject = (props) => {
    const { client, packageName, isAsign, id, status } = props;
    let result;    

    if(status !== 'Por iniciar') {
        result = (
            <Link to={`/rh-admin/dashboard/proyectos/${id}/etapas`} className='SingleProject-link'>
                <div className='SingleProject'>
                    <div className='SingleProject-wrapper'><p className='SingleProject-title'>{client}</p></div>
                    <div className='SingleProject-wrapper'><p className='SingleProject-title'>{packageName}</p></div>
                    <div className='SingleProject-wrapper'><p className='SingleProject-title'>{isAsign}</p></div>
                </div>
            </Link>
        )
    } else {
        result = (
            <Link to={`/rh-admin/dashboard/proyecto-detalles/${id}`} className='SingleProject-link'>
                <div className='SingleProject'>
                    <div className='SingleProject-wrapper'><p className='SingleProject-title'>{client}</p></div>
                    <div className='SingleProject-wrapper'><p className='SingleProject-title'>{packageName}</p></div>
                    <div className='SingleProject-wrapper'><p className='SingleProject-title'>{isAsign}</p></div>
                </div>
            </Link>
        )
    }

    return result;
}

export default SingleProject;