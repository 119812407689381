import React from 'react';
import uuid from 'uuid/v4';
import '../../../css/utils/list/List.css';

const List = (props) => {
    const {list} = props;

    const options = list.split(', ');

    const items = options.map(option => (
        <li key={uuid()} className='List-item'>-{option}</li>
    ));

    return(
        <ul className='List'>
           {items} 
        </ul>
    )
}

export default List;