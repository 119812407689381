import React from 'react';
import uuid from 'uuid/v4';
import DashInput from '../brief-dash-components/DashInput';
import DashTextarea from '../brief-dash-components/DashTextarea';
import '../../../../css/dashboard/brief-dash/forms/GeneralBriefDash.css';

const Online = (props) => {
    const { info, redes } = props;

    const answers = info.map(data => {
        if(data.isText) {
            return (
                <div key={uuid()} style={{marginBottom: '26px'}}>
                    <DashTextarea label={data.label} value={data.answer} />
                </div>
            )
        } else {
            return (
                <div key={uuid()} style={{marginTop: '26px'}}>
                    <DashInput label={data.label} value={data.answer} />
                </div>
            )
        }
    })

    const redesList = redes.map(red => (
        <div key={uuid()} style={{display: 'grid', gridTemplateColumns: '286px auto', gridTemplateRows: 'repeat(1, 1fr)'}} className='Online-social'>
            <div><p style={{color: '#000', fontSize: '15px', fontWeight: '500', margin: 0}}>{red.social}</p></div>
            <div><p style={{color: '#000', fontSize: '15px', fontWeight: '500', opacity: 0.5, margin: 0}}>{red.answer}</p></div>
        </div>
    ));
     
    return(
        <div>
            {redesList}
            {answers}
        </div>
    )
}

export default Online;