import React from 'react';
import DashboardTitle from '../../titles/DashboardTitle';
import DashboardSubtitle from '../../titles/DashboardSubtitle';
import AddAgencyForm from './form/AddAgencyForm';
import '../../../../css/dashboard/agencies/add-agency/AddAgency.css';

const AddAgency = (props) => {
    return (
        <section>
            <DashboardTitle title='Nueva agencia' />
            <div className='AddAgency-subtitle-wrapper'>
                <DashboardSubtitle title='Sobre tu marca/empresa' />
            </div>
            <AddAgencyForm />
        </section>
    )
}

export default AddAgency;