import React, { useState, useEffect } from 'react';
import { database } from '../../../../firebase/firebase';
import '../../../../css/dashboard/pages-details/PagesDetails.css';

const PagesAgenciesDetails = (props) => {
  const { count, collection, getTotalDocs, id, child } = props;
  const [totalDocuments, setTotalDocuments] = useState('');

  useEffect(() => {
    let totalRecords = [];
    database
      .ref('briefs')
      .orderByChild('agency')
      .equalTo(id)
      .once('value')
      .then((snapshot) => {
        snapshot.forEach((childSnap) => {
          totalRecords.push(childSnap.key);
        });
        setTotalDocuments(totalRecords.length);
        getTotalDocs(totalRecords.length);
      });
  }, []);

  return (
    <p className='PagesDetails'>
      Mostrando {count} de {totalDocuments} resultados
    </p>
  );
};

export default PagesAgenciesDetails;
