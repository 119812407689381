import React from 'react';
import AgencyTitle from './titles/AgencyTitle';
import AgencyParagraph from './paragraph/AgencyParagraph';
import AgencyButton from './buttons/AgencyButton';
import '../../css/home-agency/HeaderAgency.css';

const HeaderAgency = () => (
  <header className="Header-agency">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="Header-agency-container">
            <div className="Header-agency-info">
              <AgencyTitle title="ÚNETE A LA JUNGLA RHINO" />
              <div className="Header-agency-paragraph-wrapper">
                <AgencyParagraph
                  text="¡Estás a unos pasos de entrar a la jungle!"
                  color="#fff"
                  fontWeight={500}
                />
                <AgencyParagraph
                  text="¿Listo para aumentar la rentabilidad de tu agencia?"
                  color="#fff"
                  fontWeight={500}
                />
              </div>
            </div>
            <div className="Header-agency-btn-container">
              <AgencyButton text="¡A LA CARGA!" click={() => window.open('https://api.whatsapp.com/send?phone=5215570910612&text=Quiero%20poner%20%22a%20la%20carga%22%20mi%20agencia%20y%20hacerla%20m%C3%A1s%20rentable', '_blank')} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
);

export default HeaderAgency;
