import PropTypes from 'prop-types';
import React from 'react';
import '../../../css/dashboard/dashboard-package/DashboardPackage.css';
import { Edit2, Trash2 } from 'react-feather';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

const DashboardPackage = ({
  packageName, id, deletePackage, history,
}) => (
  <>
    <div className="DashboardPackage">
      <p className="DashboardPackage-title">
        {`Plan Rhino ${packageName}`}
      </p>
      <Button variant="outline" onClick={() => history.push(`/rh-admin/dashboard/edit-package/${id}`)}>
        <Edit2 size="20" />
      </Button>
      <Button variant="outline-danger" onClick={() => deletePackage(id)}>
        <Trash2 size="20" />
      </Button>
    </div>
    <div className="DashboardPackage-line">&nbsp;</div>
  </>
);

export default withRouter(DashboardPackage);

DashboardPackage.propTypes = {
  deletePackage: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  packageName: PropTypes.string.isRequired,
};
