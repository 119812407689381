import React from 'react';
import { Link } from 'react-router-dom';
import AddIcon from '../../../icons/add.svg';
import '../../../css/utils/links/AddLink.css';

const AddLink = (props) => {
  const { text, path } = props;
  return (
    <Link className='AddLink' to={path}>
      <img src={AddIcon} alt='Agregar' style={{ marginRight: 8 }} /> {text}
    </Link>
  );
};

export default AddLink;
