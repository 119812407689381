import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import { database } from '../../../../firebase/firebase';
import AgencyButton from '../../buttons/AgencyButton';
import DashboardSubtitle from '../../titles/DashboardSubtitle';
import DashboardTitle from '../../titles/DashboardTitle';
import '../../../../css/dashboard/agencies/adit-agency/EditAgency.css';

const EditSchema = yup.object().shape({
  name: yup.string().required(),
  agency: yup.string().required(),
  email: yup.string().email('Correo invalido').required(),
});

const EditProfile = (props) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    database
      .ref(`agencies/${props.match.params.id}`)
      .once('value')
      .then((snapshot) => {
        setData({ id: snapshot.key, ...snapshot.val() });
      });
  }, [props.match.params.id]);

  return (
    <section>
      <DashboardTitle title="Editar agencia" />
      <div className="AddAgency-subtitle-wrapper">
        <DashboardSubtitle title="Sobre tu marca/empresa" />
      </div>
      {data !== null && (
        <Formik
          initialValues={{
            name: `${data.userName}`,
            agency: `${data.agency}`,
            email: `${data.userEmail}`,
            specialty: '',
          }}
          validationSchema={EditSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            /* if(values.specialty === '') {
                    database.ref(`agencies/${data.id}`).update({
                        userName: values.name.trim(),
                        agency: values.agency.trim()
                    }).then(() => {
                        database.ref(`users/${data.mainAgencyId}/profile`).update({
                            userName: values.name.trim(),
                            agency: values.agency.trim()
                        }).then(() => {
                            props.history.push(`/rh-admin/dashboard/visualizar-agencia/${data.id}`)
                        })
                    })
                } */
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            isValid,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="AddAgencyInputWrapper">
                <label className="AddAgencyFormLabel">Nombre</label>
                <input
                  type="text"
                  placeholder="Nombre completo"
                  className="AddAgencyFormInput"
                  value={values.name}
                  name="name"
                  onChange={handleChange}
                />
              </div>
              <div className="AddAgencyInputWrapper">
                <label className="AddAgencyFormLabel">Agencia</label>
                <input
                  type="text"
                  placeholder="Agencia"
                  className="AddAgencyFormInput"
                  value={values.agency}
                  name="agency"
                  onChange={handleChange}
                />
              </div>
              <div className="AddAgencyInputWrapper">
                <label className="AddAgencyFormLabel">Correo electrónico</label>
                <input
                  type="text"
                  placeholder="Correo electrónico"
                  className="AddAgencyFormInput"
                  value={values.email}
                  name="email"
                  onChange={handleChange}
                  style={{
                    border:
                      errors.email !== undefined && touched.email
                        ? '1px solid #ff0000'
                        : '',
                    boxShadow:
                      errors.email !== undefined && touched.email
                        ? '0 0 0 0.2rem rgba(255, 0, 0, 0.25)'
                        : '',
                  }}
                />
              </div>
              {(errors.email && touched.email)
              || (errors.password && touched.password)
              || (errors.confirmPass && touched.confirmPass) ? (
                <div className="error-message">
                  <p
                    style={{
                      color: '#ff0000',
                      textAlign: 'center',
                      fontSize: 16,
                    }}
                  >
                    Correo y/o contraseña inválidos
                  </p>
                </div>
                ) : null}
              <p className="AddAgencyRadioTitle">Especialidad</p>
              <div className="AddAgencyInputWrapper AddAgencyRadioWrapper">
                <div className="AddAgencyOptionsRadio">
                  <input
                    id="especialidad1"
                    type="radio"
                    name="specialty"
                    value="Especialidad 1"
                    className="AddAgencyOptionInput"
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="especialidad1"
                    className="AddAgencyOptionLabel"
                  >
                    <span>
                      <div className="selected-icon-span">&nbsp;</div>
                    </span>
                    Especialidad 1
                  </label>
                </div>
                <div className="AddAgencyOptionsRadio">
                  <input
                    id="especialidad2"
                    type="radio"
                    name="specialty"
                    value="Especialidad 2"
                    className="AddAgencyOptionInput"
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="especialidad2"
                    className="AddAgencyOptionLabel"
                  >
                    <span>
                      <div className="selected-icon-span">&nbsp;</div>
                    </span>
                    Especialidad 2
                  </label>
                </div>
                <div className="AddAgencyOptionsRadio">
                  <input
                    id="especialidad3"
                    type="radio"
                    name="specialty"
                    value="Especialidad 3"
                    className="AddAgencyOptionInput"
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="especialidad3"
                    className="AddAgencyOptionLabel"
                  >
                    <span>
                      <div className="selected-icon-span">&nbsp;</div>
                    </span>
                    Especialidad 3
                  </label>
                </div>
                <div className="AddAgencyOptionsRadio">
                  <input
                    id="especialidad4"
                    type="radio"
                    name="specialty"
                    value="Especialidad 4"
                    className="AddAgencyOptionInput"
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="especialidad4"
                    className="AddAgencyOptionLabel"
                  >
                    <span>
                      <div className="selected-icon-span">&nbsp;</div>
                    </span>
                    Especialidad 4
                  </label>
                </div>
                <div className="AddAgencyOptionsRadio">
                  <input
                    id="especialidad5"
                    type="radio"
                    name="specialty"
                    value="Especialidad 5"
                    className="AddAgencyOptionInput"
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="especialidad5"
                    className="AddAgencyOptionLabel"
                  >
                    <span>
                      <div className="selected-icon-span">&nbsp;</div>
                    </span>
                    Especialidad 5
                  </label>
                </div>
                <div className="AddAgencyOptionsRadio">
                  <input
                    id="especialidad6"
                    type="radio"
                    name="specialty"
                    value="Especialidad 6"
                    className="AddAgencyOptionInput"
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="especialidad6"
                    className="AddAgencyOptionLabel"
                  >
                    <span>
                      <div className="selected-icon-span">&nbsp;</div>
                    </span>
                    Especialidad 6
                  </label>
                </div>
              </div>
              <div className="EditAgency-btns-wrapper">
                <div>
                  <Link
                    to={`/rh-admin/dashboard/visualizar-agencia/${data.id}`}
                    className="EditAgency-cancel"
                  >
                    Cancelar
                  </Link>
                </div>
                <div className="EditAgencyFormButton">
                  <AgencyButton
                    text="Aceptar"
                    disabled={!isValid || isSubmitting}
                    isValid={isValid}
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      )}
    </section>
  );
};

export default withRouter(EditProfile);
