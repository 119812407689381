import React, { Component } from 'react';
import uuid from 'uuid/v4';
import { database, firebase } from '../../../firebase/firebase';
import Navbar from '../../navbar/Navbar';
import NavbarBg from '../../navbar/NavbarBg';
import PackageUser from './PackageUser';
import Stages from './Stages';
import Footer from '../../footer/Footer';
import SignUpModal from '../../modal/SignUpModal';
import '../../../css/packageUserInterface/PackageInterface.css';

class PackageInterface extends Component {
  constructor(props) {
    super(props);
    this.state = {
      package: {},
      stages: [],
      payments: [],
      isLoaded: false,
      isPaymentLoaded: false,
      show: false,
      userId: null,
      innerWidth: null,
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.setWidth = this.setWidth.bind(this);
  }

  setWidth() {
    this.setState({ innerWidth: window.innerWidth });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const user = firebase.auth().currentUser;

    if (user != null) {
      this.setState({ userId: user.uid });
    }

    // With of device
    window.addEventListener('resize', this.setWidth, false);
    this.setState({ innerWidth: window.innerWidth });

    const stages = [];
    const payments = [];
    database
      .ref(`packages/${this.props.match.params.id}`)
      .once('value')
      .then((snapshot) => {
        this.setState({
          package: { id: snapshot.key, ...snapshot.val() },
          isLoaded: true,
        });
      })
      .catch((e) => {
        console.log(`Could not fetch data: ${e}`);
      });

    database
      .ref('stages')
      .orderByChild('packageId')
      .equalTo(`${this.props.match.params.id}`)
      .once('value')
      .then((snapshot) => {
        snapshot.forEach((childSnapshot) => {
          stages[childSnapshot.val().indexOf] = {
            id: childSnapshot.key,
            ...childSnapshot.val(),
          };
        });
        this.setState({ stages: [...stages] });
      })
      .catch((e) => {
        console.log(`Error fetching data: ${e}`);
      });

    database
      .ref('payments')
      .orderByChild('packageId')
      .equalTo(`${this.props.match.params.id}`)
      .once('value')
      .then((snapshot) => {
        snapshot.forEach((snap) => {
          payments[snap.val().indexOf] = {
            ...snap.val(),
          };
        });
        this.setState({ payments: [...payments], isPaymentLoaded: true });
      })
      .catch((e) => {
        console.log('Could not fetch data');
      });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setWidth, false);
  }

  /* Modal */
  handleOpenModal() {
    this.setState({ show: true });
  }

  handleCloseModal() {
    this.setState({ show: false });
  }

  render() {
    let p;
    if (this.state.isLoaded) {
      p = this.state.package.packageDetails.split('\n').map((item) => (
        <p key={uuid()} className="PackageInterface-paragraph">
          {item}
        </p>
      ));
    }
    return (
      <div>
        <Navbar />
        <NavbarBg />
        <section className="PackageInterface">
          <div className="container PackageInterface-main-container">
            <div className="row PackageInterface-row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                <div className="PackageInterface-about">
                  <h1 className="PackageInerface-main-header">
                    <span>Paquete Rhino</span>
                    <span>{this.state.package.packageName}</span>
                  </h1>
                  {this.state.isPaymentLoaded && (
                    <div className="PackageUser-container">
                      <PackageUser
                        id={this.state.package.id}
                        price={this.state.payments[0].amount}
                        img={this.state.package.imgPath}
                        name={this.state.package.packageName}
                        payments={this.state.payments}
                        stages={this.state.stages}
                        userId={this.state.userId}
                        handleOpen={this.handleOpenModal}
                        innerWidth={this.state.innerWidth}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                <div className="PackageInterface-container">
                  <div className="PackageInterface-details">
                    <h3 className="PackageInterface-header">Acerca de</h3>
                    {p}
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-end adjust-moblile">
              <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                <div className="PackageInterface-stages">
                  <div>
                    <h3 style={{ color: '#58595c', fontWeight: 500 }}>
                      Etapas que incluye
                    </h3>
                  </div>
                  <Stages stages={this.state.stages} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        <SignUpModal
          show={this.state.show}
          handleClose={this.handleCloseModal}
        />
      </div>
    );
  }
}

export default PackageInterface;
