import * as firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyBmPri-IG_OI3YCE8v-9WhXqCPuOJfJqw4",
    authDomain: "rhino-dev-14800.firebaseapp.com",
    databaseURL: "https://rhino-dev-14800.firebaseio.com",
    projectId: "rhino-dev-14800",
    storageBucket: "rhino-dev-14800.appspot.com",
    messagingSenderId: "275662589634",
    appId: "1:275662589634:web:bb1f4fb752fc284a0b355b"
};

firebase.initializeApp(firebaseConfig);

const database = firebase.database();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

export {firebase, googleAuthProvider, database };