import React, { Component, createRef } from 'react';
import { TweenMax, TimelineMax, Power3 } from 'gsap';
import { typing } from '../../helpers/utils';
import '../../css/homepage/Header.css';
import Rhino from '../../img/homepage/rhino.png';
import Relleno from '../../img/homepage/relleno.png';
import Background from '../../img/homepage/background_.png';
import Blanco from '../../img/homepage/blanco.png';
import RhinoRes from '../../img/homepage/rhinoRes.png';
import Montana from '../../img/homepage/montana.png';

class Header extends Component {
  constructor(props) {
    super(props);
    // Ref to DOM Nodes
    this.parallax = createRef();
    this.rhinos = null;
    this.bgOrange = null;
    this.bgWhite = null;
    this.paragraph = null;
    this.header = null;
    this.title = null;
    this.titleMovilOne = null;
    this.titleMovilTwo = null;
    this.animHeader = null;
    this.animTitle = new TimelineMax();
    // DOM NODES PARALLAX MOBILE
    this.rhinoMovil = null;
    this.bgOrangeMovil = null;
    this.bgWhiteMovil = null;
  }

  componentDidMount() {
    const layersParallax = [this.rhinos, this.bgOrange, this.bgWhite];

    window.addEventListener('scroll', () => {
      for (let i = 0; i < layersParallax.length; i++) {
        layersParallax[i].style.transform = `translateY(-${window.pageYOffset * i / layersParallax.length}px)`;
      }
    });

    // Triggers the animation only when the secction is in the viewport by using Intersection Observer
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && entries[0].intersectionRatio >= 0.5) {
        this.animHeader = TweenMax.to(this.header, 0, { css: { visibility: 'visible' } });
        typing(this.animTitle, this.title, '100%');
        typing(this.animTitle, this.titleMovilOne, '100%');
        typing(this.animTitle, this.titleMovilTwo, '100%');
        setTimeout(() => {
          TweenMax.to(this.paragraph, 0.8, { opacity: 1, y: 0, ease: Power3.easeOut });
        }, 900);
      }
    }, options);

    observer.observe(this.header);
  }

  render() {
    return (
      <header ref={(header) => this.header = header} className="Header">
        <div className="container">
          <div className="Header-container">
            <div className="Header-title-container Header-title-desktop">
              <h1 ref={(el) => this.title = el} className="Header-title line">PON A LA CARGA TU NEGOCIO</h1>
            </div>
            <div className="Header-title-container Header-title-movil">
              <h1 ref={(el) => this.titleMovilOne = el} className="Header-title line">PON A LA CARGA</h1>
              <h1 ref={(el) => this.titleMovilTwo = el} className="Header-title line">TU NEGOCIO</h1>
            </div>
            <div className="Header-text-container" ref={(div) => this.paragraph = div}>
              <p className="Header-paragraph">Las mejores agencias de habla hispana ahora a tu alcance </p>
              <h3 className="Header-third-title"><strong>Proyectos con experiencia y a tiempo, mejores resultados. </strong></h3>
              <div className="Header-play-button">
                <div>&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
        <div className="Header-img-container" ref={this.parallax}>
          <div ref={(el) => this.rhinos = el} className="Header-img-rhino">
            <img src={Rhino} alt="Rhino" />
          </div>
          <div ref={(el) => this.bgOrange = el} className="Header-img-color">
            <img src={Relleno} alt="Relleno" />
          </div>
          <div ref={(el) => this.bgWhite = el} className="Header-img-bg">
            <img src={Background} alt="Background" />
          </div>
        </div>
        <div className="Header-img-container-movil">
          <div ref={(el) => this.rhinos = el} className="Header-img-rhino">
            <img src={RhinoRes} alt="Rhino" />
          </div>
          <div ref={(el) => this.bgOrangeMovil = el} className="Header-img-color">
            <img src={Montana} alt="Relleno" />
          </div>
          <div ref={(el) => this.bgWhiteMovil = el} className="Header-img-bg">
            <img src={Blanco} alt="Background" />
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
