import React from 'react';
import { Link } from 'react-router-dom';
import Cuerno from '../../img/footer/cuerno.png';
import '../../css/footer/FooterAgency.css';

const FooterAgency = (props) => (
  <section className="FooterAgency">
    <div className="container">
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-1">
          <div className="Footer-cuerno">
            <img src={Cuerno} alt="Cuerno" />
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-3 pd0">
          <div className="Footer-container">
            <ul className="Footer-list">
              <li className="Footer-item-title">
                <strong>Rhino</strong>
              </li>
              <li className="Footer-item">
                <Link to="/agencias" className="Footer-link">
                  Sobre nosotros
                                </Link>
              </li>
              <li className="Footer-item">
                <Link to="/agencias" className="Footer-link">
                  Preguntas frecuentes
                                </Link>
              </li>
              <li className="Footer-item">
                <Link to="/agencias" className="Footer-link">
                  Contacto
                                </Link>
              </li>
              <li className="Footer-item">
                <Link to="/agencias" className="Footer-link">
                  Sostenibilidad
                                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-2 pd0">
          <div className="Footer-container">
            <ul className="Footer-list">
              <li className="Footer-item-title">
                <strong>A la carga</strong>
              </li>
              <li className="Footer-item">
                <Link to="/agencias" className="Footer-link">
                  Precios
                                </Link>
              </li>
              <li className="Footer-item">
                <Link to="/agencias" className="Footer-link">
                  Agencias
                                </Link>
              </li>
              <li className="Footer-item">
                <Link to="/agencias" className="Footer-link">
                  Inspirate
                                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-3 pd0">
          <div className="Footer-container">
            <ul className="Footer-list">
              <li className="Footer-item-title">
                <strong>Elige to proyecto</strong>
              </li>
              <li className="Footer-item">
                <Link to="/agencias" className="Footer-link">
                  Lista de servicios estella
                                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-2 pd0">
          <div className="Footer-container">
            <ul className="Footer-list">
              <li className="Footer-item-title">
                <strong>Consultas</strong>
              </li>
              <li className="Footer-item">
                <Link to="/agencias" className="Footer-link">
                  Blog
                                </Link>
              </li>
              <li className="Footer-item">
                <Link to="/agencias" className="Footer-link">
                  Mis proyectos
                                </Link>
              </li>
              <li className="Footer-item">
                <Link to="/agencias" className="Footer-link">
                  Rhino amigo
                                </Link>
              </li>
              <li className="Footer-item">
                <Link to="/agencias" className="Footer-link">
                  Ayuda
                                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-1 pd0">
          <div className="Footer-cuerno-movil">
            <img src={Cuerno} alt="Cuerno" />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default FooterAgency;
