import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { firebase } from '../../../firebase/firebase';
import AgencyProfileInfo from './AgencyProfileInfo';
import NavbarAgency from '../../navbar/NavbarAgency';
import NavbarBgAgency from '../../navbar/NavbarBgAgency';
import FooterAgency from '../../footer/FooterAgency';
import '../../../css/profile/users/UserProfile.css';

const AgencyProfile = (props) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    const user = firebase.auth().currentUser;

    if (user != null) {
      setName(user.displayName);
      setEmail(user.email);
    }
  }, [name, email]);

  return (
    <React.Fragment>
      <NavbarAgency />
      <NavbarBgAgency />
      <section className='UserProfile'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='UserProfile-container'>
                <div className='UserProfile-title-wrapper'>
                  <h3 className='UserProfile-title'>Mi Perfil</h3>
                </div>
                <div className='UserProfile-info-link-edit-mobile-wrapper'>
                  <Link
                    className='UserProfile-info-link-edit-mobile'
                    to={`/agencias/perfil/${props.match.params.userId}/editar`}
                  >
                    Editar
                  </Link>
                </div>
                <div className='UserProfile-info'>
                  <div className='UserProfile-info-title-container'>
                    <p className='UserProfile-info-title'>Datos</p>
                    <Link
                      className='UserProfile-info-link-edit'
                      to={`/agencias/perfil/${props.match.params.userId}/editar`}
                    >
                      Editar
                    </Link>
                  </div>
                  <div className='UserProfile-info-container'>
                    <div>
                      <AgencyProfileInfo field='Nombre' data={name} />
                    </div>
                    <div className='UserProfile-wrapper-desk'>
                      <AgencyProfileInfo
                        field='Correo electrónico'
                        data={email}
                      />
                    </div>
                    <div className='UserProfile-wrapper-mobile'>
                      <AgencyProfileInfo field='Correo' data={email} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterAgency />
    </React.Fragment>
  );
};

export default AgencyProfile;
