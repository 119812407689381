import React from 'react';
import '../../css/utils/titles/title-h5/TitleH5.css';

const TitleH2 = (props) => {
  return (
    <h6 style={{ color: '#58595c' }} className='TitleH5'>
      {props.title}
    </h6>
  );
};

export default TitleH2;
