import React from 'react';
import CreateDeliverable from '../../../components/forms/admin/create-deliverable/CreateDeliverable';
import '../../../css/dashboard/deliverables/DeliverableDashboard.css';

const DeliverableDashboard = (props) => {
  const {
    num,
    deliverableName,
    value,
    deliverableDuration,
    valueDuration,
    deliverableDesc,
    valueDesc,
    handleChange,
    errorDeliverableName,
    errorDeliverableDesc,
    errorDeliverableDuration,
    onDeletePressed,
  } = props;
  return (
    <div>
      <CreateDeliverable
        num={num}
        deliverableName={deliverableName}
        valueName={value}
        deliverableDuration={deliverableDuration}
        valueDuration={valueDuration}
        deliverableDesc={deliverableDesc}
        valueDesc={valueDesc}
        handleChange={handleChange}
        errorDeliverableName={errorDeliverableName}
        errorDeliverableDesc={errorDeliverableDesc}
        errorDeliverableDuration={errorDeliverableDuration}
        onDeletePressed={onDeletePressed}
      />
    </div>
  );
};

export default DeliverableDashboard;
