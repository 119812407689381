import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import SignUp from '../login/user/SignUp';
import Login from '../login/user/Login';
import Exit from '../../img/navbar/exit.png';
import '../../css/modal/SignUpModal.css';

class SignUpModal extends Component {
  constructor(props) {
    super(props);
    this.state = { isUser: false };
    this.handleSesion = this.handleSesion.bind(this);
    this.handleRegistro = this.handleRegistro.bind(this);
  }

  handleSesion() {
    this.setState({ isUser: !this.state.isUser });
  }

  handleRegistro() {
    this.setState({ isUser: !this.state.isUser });
  }

  render() {
    return (
      <Modal centered show={this.props.show} onHide={this.props.handleClose}>
        <div className='SignUp-btn-container'>
          <div>
            <button onClick={this.props.handleClose} className='SignUp-btn'>
              <img src={Exit} alt='cerrar' />
            </button>
          </div>
        </div>
        <Modal.Body>
          <div className='SignUp-body'>
            {this.state.isUser ? (
              <Login
                handleRegistro={this.handleRegistro}
                handleClose={this.props.handleClose}
              />
            ) : (
              <SignUp
                handleSesion={this.handleSesion}
                handleClose={this.props.handleClose}
              />
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default SignUpModal;
