import React, { useEffect, useRef, useState } from 'react';
import { TweenMax } from 'gsap';
import BlueTitle from './titles/BlueTitle';
import '../../css/home-agency/PerksAgency.css';

const PerksAgency = (props) => {
  const sliderOne = useRef(null);
  const sliderTwo = useRef(null);
  const sliderThree = useRef(null);

  const [sliders, setSliders] = useState([]);

  useEffect(() => {
    const sliders = [sliderOne.current, sliderTwo.current, sliderThree.current];
    setSliders([...sliders]);
    sliders.forEach((slide) => {
      if (slide.classList.contains('isActive')) {
        TweenMax.to(slide, 0.3, { opacity: 1, scale: 1.15 });
      }
    });
  }, []);

  const getHeight = (el) => el.offsetHeight;

  const addActiveClassToNextElm = () => {
    let position;
    for (let i = 0; i < sliders.length; i++) {
      if (sliders[i].classList.contains('isActive')) {
        sliders[i].classList.remove('isActive');
        const height = getHeight(sliders[i]);
        TweenMax.to(sliders[i], 0.3, {
          y: height, opacity: 0.2, scale: 0.9, zIndex: 5,
        });
        position = i;
      }
    }

    if (position === 0) {
      sliders[position + (sliders.length - 1)].classList.add('isActive');
      const height = getHeight(sliders[position + (sliders.length - 1)]);
      TweenMax.to(sliders[position], 0.3, {
        y: height * 2, opacity: 0.2, scale: 0.9, zIndex: 5,
      });
      TweenMax.to(sliders[position + (sliders.length - 1)], 0.3, {
        y: -height, opacity: 1, scale: 1.15, zIndex: 10,
      });
      TweenMax.to(sliders[position + 1], 0.3, {
        y: -height, opacity: 0.2, scale: 0.9, zIndex: 5,
      });
    }

    if (position === 1) {
      sliders[position - 1].classList.add('isActive');
      const height = getHeight(sliders[position]);
      TweenMax.to(sliders[position - 1], 0.3, {
        y: height,
        opacity: 1,
        scale: 1.15,
        zIndex: 10,
      });
      TweenMax.to(sliders[position + 1], 0.3, {
        y: -(height * 2), opacity: 0.2, scale: 0.9, zIndex: 5,
      });
    }

    if (position === 2) {
      sliders[position - 1].classList.add('isActive');
      TweenMax.to(sliders[position - 1], 0.3, {
        y: 0,
        opacity: 1,
        scale: 1.15,
        zIndex: 10,
      });
      TweenMax.to(sliders[position - position], 0.3, {
        y: 0,
        opacity: 0.2,
        scale: 0.9,
        zIndex: 5,
      });
      TweenMax.to(sliders[position], 0.3, {
        y: 0,
        opacity: 0.2,
        scale: 0.9,
        zIndex: 5,
      });
    }
  };

  const addActiveClassToNextElmReverse = () => {
    let position;
    for (let i = 0; i < sliders.length; i++) {
      if (sliders[i].classList.contains('isActive')) {
        sliders[i].classList.remove('isActive');
        const height = getHeight(sliders[i]);
        TweenMax.to(sliders[i], 0.3, {
          y: -height, opacity: 0.2, scale: 0.9, zIndex: 5,
        });
        position = i;
      }
    }

    if (position === 0) {
      sliders[position + 1].classList.add('isActive');
      // const height = getHeight(sliders[position + (sliders.length - 1)]);
      TweenMax.to(sliders[position], 0.3, {
        y: 0, opacity: 0.2, scale: 0.9, zIndex: 5,
      });
      TweenMax.to(sliders[position + (sliders.length - 1)], 0.3, {
        y: 0, opacity: 0.2, scale: 0.9, zIndex: 5,
      });
      TweenMax.to(sliders[position + 1], 0.3, {
        y: 0, opacity: 1, scale: 1.15, zIndex: 10,
      });
    }

    if (position === 1) {
      sliders[position + 1].classList.add('isActive');
      const height = getHeight(sliders[position]);
      TweenMax.to(sliders[position + 1], 0.3, {
        y: -height,
        opacity: 1,
        scale: 1.15,
        zIndex: 10,
      });
      TweenMax.to(sliders[position - position], 0.3, {
        y: (height * 2), opacity: 0.2, scale: 0.9, zIndex: 5,
      });
    }
    if (position === 2) {
      const height = getHeight(sliders[position]);
      sliders[position - position].classList.add('isActive');
      TweenMax.to(sliders[position - 1], 0.3, {
        y: height,
        opacity: 0.2,
        scale: 0.9,
        zIndex: 5,
      });
      TweenMax.to(sliders[position - position], 0.3, {
        y: height,
        opacity: 1,
        scale: 1.15,
        zIndex: 10,
      });
      TweenMax.to(sliders[position], 0.3, {
        y: -(height * 2),
        opacity: 0.2,
        scale: 0.9,
        zIndex: 5,
      });
    }
  };

  return (
    <section className="PerksAgency">
      <div className="container">
        <div className="PerksAgency-title-wrapper">
          <BlueTitle text="¿Qué puede ofrecer Rhino para las agencias?" />
        </div>
        <div className="Perks-slider-container">
          <div className="Perks-slider-up-arrow" onClick={addActiveClassToNextElmReverse}>&nbsp;</div>
          <div className="Perks-slider-wrapper" ref={sliderOne}>
            <div className="Perks-slider-img">&nbsp;</div>
            <div className="Perks-slider-text-wrapper">
              <p className="Perks-slider-text">
                Mayor prospección de clientes a unos cuantos clics de distancia
              </p>
            </div>
          </div>
          <div className="Perks-slider-wrapper isActive" ref={sliderTwo}>
            <div className="Perks-slider-img">&nbsp;</div>
            <div className="Perks-slider-text-wrapper">
              <p className="Perks-slider-text">
                Lleva a tu agencia a todo el mercado internacional de habla hispana.
              </p>
            </div>
          </div>
          <div className="Perks-slider-wrapper" ref={sliderThree}>
            <div className="Perks-slider-img">&nbsp;</div>
            <div className="Perks-slider-text-wrapper">
              <p className="Perks-slider-text">
                Optimiza el trato con clientes (chat y gestor de proyectos incluido)
              </p>
            </div>
          </div>
          <div
            className="Perks-slider-down-arrow"
            onClick={addActiveClassToNextElm}
          >
            &nbsp;
          </div>
        </div>
      </div>
      <div className="PerksAgency-island">&nbsp;</div>
    </section>
  );
};

export default PerksAgency;
