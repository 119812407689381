import React from 'react';
import Input from '../../../utils/forms/input/Input';
import Textarea from '../../../utils/forms/textarea/Textarea';
import { validateFields } from '../../../../helpers/utils';
import ButtonOnClick from '../../../utils/buttons/ButtonOnClick';
import Checked from '../../../../img/navbar/checked.png';

const StepFour = (props) => {
  const {
    next,
    back,
    handleChange,
    facebookURL,
    twitterURL,
    instagramURL,
    youtubeURL,
    indeedURL,
    anotherURL,
    googleKeywords,
    isOnline,
    errorGoogle,
    handleChangeCheckbox,
  } = props;

  const fieldsValues = [googleKeywords];

  return (
    <div>
      <form>
        <div className='form-group'>
          <p className='online-paragraph'>
            ¿En qué plataformas digitales podemos encontrar a tu marca?
          </p>
          <div className='Brief-form-container online-links'>
            <div className='Brief-form-container-online'>
              <Input
                type='text'
                label='Facebook'
                placeholder='https://facebook.com/lorem.ipsumd/olor/sitamet…'
                value={facebookURL}
                name='facebookURL'
                handleChange={handleChange}
              />
            </div>
          </div>
          <div className='Brief-form-container online-links'>
            <div className='Brief-form-container-online'>
              <Input
                type='text'
                label='Twitter'
                placeholder='https://twitter.com/lorem.ipsumd/olor/sitamet…'
                value={twitterURL}
                name='twitterURL'
                handleChange={handleChange}
              />
            </div>
          </div>
          <div className='Brief-form-container online-links'>
            <div className='Brief-form-container-online'>
              <Input
                type='text'
                label='Instagram'
                placeholder='https://instagram.com/lorem.ipsumd/olor/sitamet…'
                value={instagramURL}
                name='instagramURL'
                handleChange={handleChange}
              />
            </div>
          </div>
          <div className='Brief-form-container online-links'>
            <div className='Brief-form-container-online'>
              <Input
                type='text'
                label='Youtube'
                placeholder='https://youtube.com/lorem.ipsumd/olor/sitamet…'
                value={youtubeURL}
                name='youtubeURL'
                handleChange={handleChange}
              />
            </div>
          </div>
          <div className='Brief-form-container online-links'>
            <div className='Brief-form-container-online'>
              <Input
                type='text'
                label='Indeed'
                placeholder='https://indeed.com/lorem.ipsumd/olor/sitamet…'
                value={indeedURL}
                name='indeedURL'
                handleChange={handleChange}
              />
            </div>
          </div>
          <div className='Brief-form-container online-links'>
            <div className='Brief-form-container-online'>
              <Input
                type='text'
                label='Otra'
                placeholder='https://linkedin.com/lorem.ipsumd/olor/sitamet…'
                value={anotherURL}
                name='anotherURL'
                handleChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className='form-group'>
          <input
            type='checkbox'
            className='form-check-input'
            id='isOnline'
            name='isOnline'
            onChange={handleChangeCheckbox}
            checked={isOnline}
            style={{ display: 'none' }}
          />
          <label className='form-check-label' htmlFor='isOnline'>
            <span className='check-container'>
              {isOnline && <img src={Checked} alt='Checked' />}
            </span>
            No tengo presencia online
          </label>
        </div>
        <p className='online-small-text'>
          Indica las cuentas de redes sociales que existen
        </p>
        <div className='Brief-form-container'>
          <Textarea
            type='text'
            label='¿Qué palabras usarías para encontrar a tu marca en Google?'
            placeholder='Lorem ipsum dolor sit amet'
            value={googleKeywords}
            name='googleKeywords'
            handleChange={handleChange}
            customError={errorGoogle}
            message='Keywords (Palabras Clave)'
          />
        </div>
      </form>
      <div className='Step-button-container two-btns'>
        <ButtonOnClick text='ATRÁS' click={back} />
        <ButtonOnClick
          text='CONTINUAR'
          click={next}
          isDisabled={validateFields(fieldsValues)}
        />
      </div>
    </div>
  );
};

export default StepFour;
