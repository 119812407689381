import React from 'react';
import { Dropdown } from 'react-bootstrap';
import DownArrow from '../../../icons/down-arrow-orange.svg';
import UpArrow from '../../../icons/up-arrow-orange.svg';
import uuid from 'uuid/v4';
import '../../../css/utils/dropdownFilter/DropdownFilter.css';

const DropdownFilter = (props) => {
  const options = [
    'Todo',
    'Por iniciar',
    'En proceso',
    'Finalizado',
    'Pendiente',
  ];
  const listOptions = options.map((option) => (
    <div className='DropdownFilter-container' key={uuid()}>
      <label
        htmlFor={option.replace(/ /g, '')}
        className='DropdownFilter-label'
      >
        <input
          type='radio'
          id={option.replace(/ /g, '')}
          className='DropdownFilter-radio'
          name='filter'
          value={option}
          checked={props.selectedOption === option}
          onChange={props.handleChange}
        />
        <span className='radio-icon'>
          <div className='radio-icon-dot'>&nbsp;</div>
        </span>
        {option}
      </label>
    </div>
  ));
  return (
    <div className='DropdownFilter'>
      <Dropdown>
        <div onClick={props.handleClick}>
          <Dropdown.Toggle>
            <strong>Ordenar</strong>
            {props.isClicked ? (
              <img src={UpArrow} alt='Flecha' style={{ marginLeft: 9 }} />
            ) : (
              <img src={DownArrow} alt='Flecha' style={{ marginLeft: 9 }} />
            )}
          </Dropdown.Toggle>
        </div>
        <Dropdown.Menu className='DropdownFilter-menu'>
          {listOptions}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default DropdownFilter;
