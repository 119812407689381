import React from 'react';
import { Link } from 'react-router-dom';
import '../../../css/categories/userInterface/PackageDetails.css';

const PackageDetails = (props) => {
  const category = props.categories.filter(
    (category) => category.id === props.categoryId,
  );
  return (
    <div className="PackageDetails">
      <div className="PackageDetails-container">
        <div
          className="PackageDetails-img"
          style={{ backgroundImage: `url(${props.img})` }}
        >
          &nbsp;
        </div>
        <div className="PackageDetails-info-container">
          <div className="PackageDetails-info">
            <p className="PackageDetails-info-main-paragraph">Paquete Rhino</p>
            <h5>{props.name}</h5>
            <p className="PackageDetails-info-paragraph">
              Categoria:
              {' '}
              {category[0].name}
            </p>
            <div className="PackageDetails-link-container">
              <Link
                to={`/categorias/paquete/${props.id}`}
                className="PackageDetails-link"
              >
                ¡A la carga!
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageDetails;
