import React from 'react';
import Input from '../../../utils/forms/input/Input';
import Textarea from '../../../utils/forms/textarea/Textarea';
import Select from '../../../utils/forms/select/Select';
import { validateFields } from '../../../../helpers/utils';
import ButtonOnClick from '../../../utils/buttons/ButtonOnClick';
import '../../../../css/brief/BriefForms.css';

const StepSeven = (props) => {
  const {
    next,
    back,
    handleChange,
    knowUs,
    inspireBrand,
    frequency,
    employees,
    errorInspireBrand,
    errorKnowUs,
  } = props;

  const fieldsValues = [knowUs, inspireBrand];

  return (
    <div>
      <form>
        <div className='Brief-form-container'>
          <Input
            type='text'
            label='¿Cómo conociste Rhino.lat?'
            placeholder='Textiles John Doe'
            value={knowUs}
            name='knowUs'
            handleChange={handleChange}
            customError={errorKnowUs}
            message='Ejemplo: Redes Sociales, un amigo, otra empresa, buscadores, etc.'
          />
        </div>
        <div className='Brief-form-container'>
          <Textarea
            type='text'
            label='¿Qué marca te inspira y sirve como referencia y por qué?'
            placeholder='Lorem ipsum dolor sit amet'
            value={inspireBrand}
            name='inspireBrand'
            handleChange={handleChange}
            customError={errorInspireBrand}
          />
        </div>
        <div className='Brief-form-container'>
          <Select
            label='¿Con cuánta frecuencia, tú o tu negocio, tienen necesidades de diseño?'
            value={frequency}
            name='frequency'
            handleChange={handleChange}
            options={[
              'Nada frecuente',
              'Un poco frecuente',
              'Frecuente',
              'Muy frecuente',
            ]}
          />
        </div>
        <div className='Brief-form-container'>
          <Select
            label='¿Cuántos empleados tiene tu empresa incluido tú mismo?'
            value={employees}
            name='employees'
            handleChange={handleChange}
            options={['Menos de 15', 'Entre 15 y 50', 'Más de 50']}
          />
        </div>
      </form>
      <div className='Step-button-container two-btns'>
        <ButtonOnClick text='ATRÁS' click={back} />
        <ButtonOnClick
          text='CONTINUAR'
          click={next}
          isDisabled={validateFields(fieldsValues)}
        />
      </div>
    </div>
  );
};

export default StepSeven;
