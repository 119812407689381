import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({isAuthenticated, typeOfUser, component: Component, ...rest}) => {
  return (
    <Route {...rest} component={(props) => (
      (isAuthenticated === true && (typeOfUser === 'usuario' || typeOfUser === 'admin')) ? (
        <Component {...props} />
      ) : (
        <Redirect to='/'/>
      )
      )}
    />
  )
};

export default PrivateRoute;
