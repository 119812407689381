import React from 'react';
import '../../../../css/dashboard/brief-dash/brief-dash-components/DashTextarea.css';

const DashTextarea = (props) => {
    const { label, value } = props;
    return (
        <div className='DashTextarea-wrapper'>
            <label className='DashboardLabel'>{label}</label>
            <textarea className='Dashboard-textarea' type='text' value={value} disabled={true}></textarea> 
        </div>
    )
}

export default DashTextarea;