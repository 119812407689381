import React from 'react';
import Input from '../../../utils/forms/input/Input';
import Textarea from '../../../utils/forms/textarea/Textarea';
import Select from '../../../utils/forms/select/Select';
import { validateFields } from '../../../../helpers/utils';
import ButtonOnClick from '../../../utils/buttons/ButtonOnClick';
import '../../../../css/brief/BriefForms.css';

const StepFive = (props) => {
  const {
    next,
    back,
    handleChange,
    sectorSituation,
    projectName,
    language,
    target,
    mainObjective,
    errorSector,
    errorProjectName,
    errorTarget,
    errorMainObjective,
  } = props;

  const fieldsValues = [sectorSituation, projectName, target, mainObjective];

  return (
    <div>
      <form>
        <div className='Brief-form-container'>
          <Select
            label='¿En qué idioma trabajaremos?'
            value={language}
            name='language'
            handleChange={handleChange}
            options={['Español', 'Inglés']}
          />
        </div>
        <div className='Brief-form-container'>
          <Textarea
            type='text'
            label='Situación de la industria en la que está tu marca:'
            placeholder='Situación del sector'
            value={sectorSituation}
            name='sectorSituation'
            handleChange={handleChange}
            customError={errorSector}
            message='Indica si hay alguna tendencia, crisis o algo que quieras destacar'
          />
        </div>
        <div className='Brief-form-container'>
          <Input
            type='text'
            label='¿Cómo llamarás a este proyecto?'
            placeholder='Textiles John Doe'
            value={projectName}
            name='projectName'
            handleChange={handleChange}
            customError={errorProjectName}
          />
        </div>
        <div className='Brief-form-container'>
          <Textarea
            type='text'
            label='¿Cuáles son las características del mercado objetivo?'
            placeholder='Personas de clase media alta, de entre 20 a 30 años…'
            value={target}
            name='target'
            handleChange={handleChange}
            customError={errorTarget}
            message='Por ejemplo, edad, sexo, localización, educación, intereses, estilo de vida, valores, etc.'
          />
        </div>
        <div className='Brief-form-container'>
          <Textarea
            type='text'
            label='Objetivo del proyecto y alcances'
            placeholder='Aumentar las ventas…'
            value={mainObjective}
            name='mainObjective'
            handleChange={handleChange}
            customError={errorMainObjective}
            message='¿Qué quieres obtener de este proyecto? ej: más seguidores, más interacciones, 
                        más ventas, etc.'
          />
        </div>
      </form>
      <div className='Step-button-container two-btns'>
        <ButtonOnClick text='ATRÁS' click={back} />
        <ButtonOnClick
          text='CONTINUAR'
          click={next}
          isDisabled={validateFields(fieldsValues)}
        />
      </div>
    </div>
  );
};

export default StepFive;
