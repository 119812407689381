import React, { useEffect } from 'react';
import HeaderAgency from './HeaderAgency';
import PerksAgency from './PerksAgency';
import HowItWorks from './HowItWorks';
import FAQS from './FAQS';
import Rhino from './Rhino';
import NavbarAgency from '../navbar/NavbarAgency';
import FooterAgency from '../footer/FooterAgency';

const HomeAgency = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <NavbarAgency />
      <HeaderAgency />
      <PerksAgency />
      <HowItWorks />
      <FAQS/>
      <Rhino />
      <FooterAgency />
    </div>
  );
};

export default HomeAgency;
