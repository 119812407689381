import React, { Component, createRef } from 'react';
import { Link } from 'react-router-dom';
import { TimelineLite, Elastic, Power3 } from 'gsap';
import { typing } from '../../helpers/utils';
import '../../css/homepage/Message.css';
import { TimelineMax } from 'gsap/gsap-core';

class Message extends Component {
  constructor(props) {
    super(props);
    //Ref of DOMNodes
    this.sectionRef = createRef();
    this.title = null;
    this.titleMovil = null;
    this.rhinoVideo = null;
    this.paragraph = null;
    this.contact = null;
    this.anim = new TimelineLite();
    this.animTitle = new TimelineMax();
    this.animTitleMovil = new TimelineMax();
  }

  componentDidMount() {
    //Triggers the animation when the section is in the viewport by using Intersection Observer
    const options = {
      root: null,
      rootMagin: '0px',
      threshold: 0.3,
    };

    const observer = new IntersectionObserver((entries) => {
      if (
        entries[0].isIntersecting &&
        entries[0].intersectionRatio >= options.threshold
      ) {
        this.title.style.visibility = 'visible';
        typing(this.animTitle, this.title, '100%');
        typing(this.animTitleMovil, this.titleMovil, '100%');
        this.anim
          .to(
            this.rhinoVideo,
            2,
            { scale: 1, ease: Elastic.easeOut.config(0.85, 0.5) },
            '-=1'
          )
          .to(
            this.paragraph,
            1,
            { opacity: 1, y: 0, ease: Power3.easeOut },
            '-=2'
          )
          .staggerTo(
            this.contact.children,
            1,
            { opacity: 1, ease: Power3.easeOut },
            0.1,
            '-=1'
          );
      }
    }, options);

    observer.observe(this.sectionRef.current);
  }

  render() {
    return (
      <section ref={this.sectionRef} className='Message'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='Message-title-container-movil'>
                <h2 ref={(el) => (this.titleMovil = el)}>
                  <strong>MENSAJE DEL CEO</strong>
                </h2>
              </div>
            </div>
            <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
              <div
                ref={(el) => (this.rhinoVideo = el)}
                className='Message-container'
              >
                <div className='Message-container-bg'>&nbsp;</div>
                <div className='Message-play-button'>
                  <div>&nbsp;</div>
                </div>
              </div>
            </div>
            <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
              <div className='Message-text'>
                <div className='Message-title-container'>
                  <h2 ref={(el) => (this.title = el)}>
                    <strong>MENSAJE DEL CEO</strong>
                  </h2>
                </div>
                <div
                  ref={(el) => (this.paragraph = el)}
                  className='Message-paragraph-container'
                >
                  <p className='Message-paragraph'>
                    En esta jungla hay otros que necesitan de nuestra ayuda.
                  </p>
                  <p className='Message-paragraph'>
                    Por cada uno de los proyectos que decidas llevar a cabo en
                    Rhino, un porcentaje (indicado en cada proyecto que
                    comienzas en Rhino) de las ganancias serán destinadas a
                    organizaciones dedicadas a la conservación de especies,
                    especialmente comenzando con los rinocerontes. Te
                    agradecemos que elijas esta plataforma que a parte de estar
                    comprometida con sus clientes y las agencias que la
                    conforman, también pone por delante a este mundo que tanto
                    nos necesita. ¡Gracias por ayudarnos a ponernos a la carga
                    por un planeta mejor!
                  </p>
                  <p className='Message-ceo'>
                    <strong>- CEO de Rhino</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div ref={(el) => (this.contact = el)} className='Message-info'>
                <h3>
                  <strong>Pon a la carga tu negocio</strong>
                </h3>
                <div className='Message-btn'>
                  <Link
                    to='/'
                    className='Message-btn-blue hvr-shutter-out-horizontal'
                  >
                    ¡A la carga!
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Message;
