import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRouteAgency = ({
  isAuthenticated,
  typeOfUser,
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      component={(props) =>
        isAuthenticated === true && typeOfUser === 'agencia' ? (
          <Component {...props} />
        ) : (
          <Redirect to='/agencias' />
        )
      }
    />
  );
};

export default PrivateRouteAgency;
