import React, { Component, createRef } from 'react';
import Slider from 'react-slick';
import { TimelineMax, Linear } from 'gsap';
import uuid from 'uuid/v4';
import { typingTestimonios } from '../../helpers/utils';
import '../../css/homepage/Testimonals.css';
import Lago from '../../img/testimonials/lago__.gif';

class Testimonials extends Component {
  constructor(props) {
    super(props);
    this.sectionRef = createRef();
    this.title = null;
    this.bgTestimonials = null;
    this.animText = new TimelineMax();
    this.bgAnimation = new TimelineMax();
  }

  componentDidMount() {
    const options = {
      root: null,
      rootMagin: '0px',
      threshold: 0.25,
    };

    const observer = new IntersectionObserver((entries) => {
      if (
        entries[0].isIntersecting
        && entries[0].intersectionRatio >= options.threshold
      ) {
        this.title.style.visibility = 'visible';
        typingTestimonios(this.animText, this.title, '100%');
        this.bgAnimation.to(this.bgTestimonials, 1.5, {
          opacity: 1,
          ease: Linear.easeInOut,
        });
      }
    }, options);

    observer.observe(this.sectionRef.current);
  }

  render() {
    const quotes = [
      {
        quote:
          'Fue una experiencia asombrosa. Fue muy fácil y rápido trabajar para mis colaboradores junto a la agencia; sobre todo en la fase de definición de la nueva estrategia digital del negocio',
        client: 'Jacobo Levi',
      },
      {
        quote:
          'Estoy muy contento con el logo que he recibido y el trabajo final por la agencia definida por Rhino, además de brindarme un excelente seguimiento en cada paso del proyecto para obtener el diseño final. Lo usaré nuevamente para mi próximo proyecto',
        client: 'Leonor Pedroza',
      },
      {
        quote:
          'Muy satisfecho con el servicio, pudimos obtener resultados digitales muy rápido y el nivel de comunicación con la agencia fue fantástico. Recomiendo satisfecho el servicio de Rhino',
        client: 'J. Manuel Garcia',
      },
      {
        quote:
          'Siendo una marca pequeña necesitamos de una agencia integral para hacer crecer nuestras ventas, y Rhino nos ayuda a gestionar todas las etapas de manera ordenada y a tiempo',
        client: 'María Valeria Marín',
      },
    ];

    const settings = {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    const testimonials = quotes.map((quote) => (
      <div key={uuid()} className="Testimonials-comment">
        <h3>
          <span className="quoteIcon">"</span>
          {quote.quote}
        </h3>
        <p>
          <strong>
            -
            {quote.client}
          </strong>
        </p>
      </div>
    ));

    return (
      <section ref={this.sectionRef} className="Testimonals">
        <div
          ref={(el) => (this.bgTestimonials = el)}
          className="Testimonials-bg"
        >
          &nbsp;
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="Testimonials-title">
                <h2 ref={(el) => (this.title = el)}>
                  <strong>TESTIMONIOS</strong>
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="Testimonials-container">
                <div className="Testimonials-slides">
                  <Slider {...settings}>{testimonials}</Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Testionials-animation">
          <img src={Lago} alt={Lago} />
        </div>
      </section>
    );
  }
}

export default Testimonials;
