import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {firebase} from '../../../firebase/firebase';
import { Formik } from 'formik';
import * as yup from 'yup';
import Checked from '../../../img/navbar/checked.png';
import '../../../css/forms/FormInicioSesion.css';

const Schema = yup.object().shape({
    email: yup.string().email().required('Campo obligatorio'),
    password: yup.string().min(6).required('Campo obligatorio')
})

const FormInicioAdmin = (props) => {
    return (
        <div className='FormRegistrase-form'>
        <Formik
                initialValues={{ email: '', password: '', remember: false }}
                validationSchema={Schema}
                onSubmit= {(values, { resetForm, setErrors, setSubmitting }) => {
                    firebase
                      .auth()
                      .signInWithEmailAndPassword(values.email, values.password)
                      .then((user) => {
                        props.history.push('/rh-admin/dashboard/proyectos')
                      })
                      .catch((error) => {
                        console.log('En catch')
                        let errorCode = error.code;
                        if (errorCode === 'auth/wrong-password') {
                          setErrors({ password: 'Contraseña invalida' });
                          setSubmitting(false);
                        } else {
                          setErrors({ email: 'Correo invalido' });
                          setSubmitting(false);
                        }
                      });
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    isValid
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className='form-group FormRegistrarse-wrapper'>
                        <input
                            type='email'
                            className='form-control FormRegistrarseInput'
                            onChange={handleChange}
                            placeholder='Correo electrónico'
                            name='email'
                            value={values.email}
                            style={{
                            border:
                                errors.email !== undefined && touched.email
                                ? '1px solid #ff0000'
                                : '',
                            boxShadow:
                                errors.email !== undefined && touched.email
                                ? '0 0 0 0.2rem rgba(255, 0, 0, 0.25)'
                                : '',
                            }}
                        />
                        </div>
                        <div className='form-group FormRegistrarse-wrapper'>
                        <input
                            type='password'
                            className='form-control FormRegistrarseInput'
                            onChange={handleChange}
                            placeholder='Contraseña'
                            name='password'
                            value={values.password}
                            style={{
                            border:
                                errors.password !== undefined && touched.password
                                ? '1px solid #ff0000'
                                : '',
                            boxShadow:
                                errors.password !== undefined && touched.password
                                ? '0 0 0 0.2rem rgba(255, 0, 0, 0.25)'
                                : '',
                            }}
                        />
                        </div>
                        <div className='error-message'>
                        {(errors.password && touched.password) ||
                        (errors.email && touched.email) ? (
                            <p style={{ color: '#ff0000', fontSize: 16, textAlign: 'center' }}>
                                El correo electrónico o la contraseña que ingresaste son incorrectos. Revísalos e inténtalo de nuevo.
                            </p>
                        ) : null}
                        </div>
                        <div className='FormInicioSesion-details'>
                        <div className='form-group aligning-items FormInicioSesion-recordarme-container'>
                            <input
                            id='terms'
                            type='checkbox'
                            style={{ display: 'none' }}
                            className='check-input-form'
                            name='remember'
                            checked={values.remember}
                            onChange={handleChange}
                            />
                            <label htmlFor='terms' className='FormInicioSesion-recordarme'>
                            <span className='FormRegistrarse-check' style={{ width: '16px' }}>
                                {values.remember && <img src={Checked} alt='checked' />}
                            </span>
                            Recordarme.
                            </label>
                        </div>
                        <div className='FormInicioSesion-link-container'>
                            <Link className='FormInicioSesion-restablecer' to='/password_reset'>
                            Olvidé mi contraseña
                            </Link>
                        </div>
                        </div>
                        <div className='FormInicioSesion-btn-container' style={{width: 320}}>
                        <button
                            disabled={isSubmitting || !isValid}
                            style={{
                            backgroundColor: isValid && '#f47600',
                            color: isValid && '#fff',
                            }}
                            type='submit'
                        >
                            INICIAR
                        </button>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    );

}
 
export default withRouter(FormInicioAdmin);