import React from 'react';
import '../../../css/profile/users/ProfileIfo.css';

const ProfileInfo = (props) => {
    const {field, data} = props;
    return (
        <div className='ProfileInfo'>
            <div className='ProfileInfo-container'>
                <div className='ProfileInfo-paragraph-container-1'>
                    <p className='ProfileInfo-paragraph'>{field}</p>
                </div>
                <div className='ProfileInfo-paragraph-container-2'>
                    <p className='ProfileInfo-paragraph'>{data}</p>
                </div>
            </div>
        </div>
    )
}

export default ProfileInfo;