import React, { Component } from 'react';
import Navbar from '../navbar/Navbar';
import Header from './Header';
import Jungle from './Jungle';
import Steps from './Steps';
import Benefits from './Benefits';
import Testimonials from './Testimonials';
import Message from './Message';
import Footer from '../footer/Footer';

class HomePage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <Navbar />
        <Header />
        <Jungle />
        <Steps />
        <Benefits />
        <Testimonials />
        <Message />
        <Footer />
      </div>
    );
  }
}

export default HomePage;
