import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import DashboardTitle from '../../titles/DashboardTitle';
import '../../../../css/dashboard/users/user-dash-details/UserDashDetails.css';
import { database } from '../../../../firebase/firebase';

const UserDashDetails = (props) => {
  const [user, setUser] = useState({});
  const [projects, setProjects] = useState([]);
  let list = [];

  useEffect(() => {
    const projects = [];
    database
      .ref(`users/${props.match.params.id}/profile`)
      .once('value')
      .then((snapshot) => {
        setUser({ id: snapshot.key, ...snapshot.val() });
        database
          .ref('briefs')
          .orderByChild('userId')
          .equalTo(props.match.params.id)
          .once('value')
          .then((snap) => {
            if (snap) {
              snap.forEach((innerSnap) => {
                projects.push({
                  id: innerSnap.key,
                });
              });

              setProjects([...projects]);
            }
          });
      });
  }, [props.match.params.id]);

  if (projects.length > 0) {
    list = projects.map((project) => (
      <div className="UserDashDetails" key={project.id}>
        <div><p className="UserDashDetails-title">Datos de registo</p></div>
        <div><Link to={`/rh-admin/dashboard/brief/${project.id}`} className="UserDashDetails-link">Ver</Link></div>
      </div>
    ));
  }

  return (
    <section>
      <DashboardTitle title={user.userName} />
      <div className="UserDashDetails-wrapper">
        <div className="UserDashDetails">
          <div><p className="UserDashDetails-title">Nombre</p></div>
          <div><p className="UserDashDetails-title">{user.userName}</p></div>
        </div>
        <div className="UserDashDetails">
          <div><p className="UserDashDetails-title">Correo</p></div>
          <div><p className="UserDashDetails-title">{user.userEmail}</p></div>
        </div>
        {projects.length > 0 && list}
      </div>
    </section>
  );
};

export default withRouter(UserDashDetails);
