import React from 'react';
import { getDate } from '../../../helpers/utils';
import List from '../../utils/list/List';
import DeleteFileModal from  '../../modal/DeleteFileModal';
import '../../../css/projects/projectsAgency/EntregableOptions.css';

const EntregableOptios = (props) => {
  return (
    <div className='EntregableOptions'>
      <div className='option'>
        <div className='optionWidth'>
          <p className='EntregableOptions-subtitle'>Descripción</p>
        </div>
        <div className='EntregableOptions-details-container'>
          <div className='optionGap'>{props.desc}</div>
          {props.options !== undefined && (
            <div className='EntregableOptions-list'>
              <List list={props.options} />
            </div>
          )}
        </div>
        <p className='EntregableOptions-subtitle-mobile'><span>Descripción:</span></p>
        <p className='optionGap-mobile'>{props.desc}</p>
      </div>
      <div className='option'>
        <div className='optionWidth'>
          <p className='EntregableOptions-subtitle'>Estatus</p>
        </div>
        <div className='optionGap'>
          {props.status}
        </div>
        <p className='EntregableOptions-subtitle-mobile'><span>Estatus:</span> {props.status}</p>
      </div>
      {props.deliveryDate !== null && (
        <div className='option'>
          <div className='optionWidth'>
            <p className='EntregableOptions-subtitle'>Fecha de entrega</p>
          </div>
          <div className='optionGap'>{getDate(props.deliveryDate)}</div>
          <p className='EntregableOptions-subtitle-mobile'><span>Fecha de entrega:</span> {getDate(props.deliveryDate)}</p>
        </div>
      )}
      {props.fileName !== null ? (
        <div className='option'>
          <div className='optionWidth'>
            <p className='EntregableOptions-subtitle'>Archivo final</p>
          </div>
          <div className='optionGap'>
            <a
              href={`${props.file}`}
              target='_blank'
              rel='noopener noreferrer'
              className='file-link'
            >
              {props.fileName}
            </a>
          </div>
          {props.delete && props.status !== 'Finalizado' && <button className='Delete-file-btn Delete-file-btn-desktop' onClick={props.handleShow}>Eliminar</button>}
          {props.delete && props.status !== 'Finalizado' && <button className='Delete-file-btn Delete-file-btn-mobile' onClick={props.handleShow}><img src={props.iconDelete} alt='Eliminar'/></button>}
          <p className='EntregableOptions-subtitle-mobile'>
            <span>Archivo final: </span>
            <a
              href={`${props.file}`}
              target='_blank'
              rel='noopener noreferrer'
              className='file-link-mobile'
              >
              {props.fileName}
            </a>
            {props.icon && <a
              href={`${props.file}`}
              target='_blank'
              rel='noopener noreferrer'
              className='file-link-mobile'
              >
              <img src={props.icon} alt='descargar' className='download-icon'/>
            </a>}
          </p>
        </div>
      ) : (
        <div className='option'>
          <div className='optionWidth'>
            <p className='EntregableOptions-subtitle'>Archivo final</p>
          </div>
          <div className='optionGap'>Sin entregar</div>
          <p className='EntregableOptions-subtitle-mobile'><span>Archivo final:</span> Sin entregar</p>
        </div>
      )}
      <DeleteFileModal 
        show={props.show}
        handleClose={props.handleClose}
        delete={props.delete}
      />
    </div>
  );
};

export default EntregableOptios;
