import React from 'react';
import '../../../../css/utils/forms/textarea/Textarea.css';

const Textarea = (props) => {
  const {
    name,
    label,
    type,
    placeholder,
    value,
    handleChange,
    isTouched,
    error,
    message,
    customError,
  } = props;
  return (
    <div className='Textarea-container'>
      {label && <label className='Textarea-label'>{label}</label>}
      <textarea
        className='Textarea'
        type={type}
        name={name}
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
        style={{
          border:
            (error !== undefined && isTouched) ||
            (customError && customError !== '')
              ? '1px solid #ff0000'
              : '',
          boxShadow:
            (error !== undefined && isTouched) ||
            (customError && customError !== '')
              ? '0 0 0 0.2rem rgba(255, 0, 0, 0.25)'
              : '',
        }}
      ></textarea>
      {error !== undefined && isTouched && (
        <small className='Textarea-error'>{error}</small>
      )}
      {customError !== '' && (
        <small className='Input-error'>{customError}</small>
      )}
      {message && <small className='Textarea-message'>{message}</small>}
    </div>
  );
};

export default Textarea;
