import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import ButtonOnClick from '../utils/buttons/ButtonOnClick';
import TransferDetails from './TransferDetails';
import { database, firebase } from '../../firebase/firebase';
import ResetModal from '../modal/ResetModal';
import '../../css/payment-details/Transfer.css';

const Transfer = (props) => {
  const {
    amount,
    id,
    packageId,
    userId,
    paymentId,
    userDetails,
    paymentNum,
    packageName,
  } = props;

  const [paymentDetails, setPaymentDetails] = useState({});
  const [show, setShow] = useState(false);

  useEffect(() => {
    const unsubscribe = database
      .ref(`usersPayments/${userId}/${packageId}/${paymentId}`)
      .on('value', (snapshot) => {
        setPaymentDetails({ ...snapshot.val() });
      });

    return () =>
      database
        .ref(`usersPayments/${userId}/${packageId}/${paymentId}`)
        .off('value', unsubscribe);
  }, []);

  const handleClose = () => {
    setShow(false);
    props.history.push(`/proyectos/${id}/pagos`);
  };

  const confirmTransfer = () => {
    database
      .ref('paymentsMade')
      .push({
        ...paymentDetails,
        date: firebase.database.ServerValue.TIMESTAMP,
        userId: userId,
        userName: userDetails.userName,
        userEmail: userDetails.userEmail,
        paidWith: 'Transferencia',
        packageName,
        paymentNum,
        briefId: id,
        paymentId,
        isPending: true,
      })
      .then(() => {
        database
          .ref(`usersPayments/${userId}/${packageId}/${paymentId}`)
          .update({
            date: firebase.database.ServerValue.TIMESTAMP,
            isPending: true,
          })
          .then(() => {
            setShow(true);
          });
      });
  };

  return (
    <React.Fragment>
      <TransferDetails />
      <div className='Transfer-btn'>
        <ButtonOnClick
          text='CONTINUAR'
          click={confirmTransfer}
          isDisabled={paymentDetails.isPending}
        />
      </div>
      <ResetModal
        message={
          <>
            <span className='Transfer-modal-text'>
              Tu comprobante de pago será revisado dentro{' '}
            </span>
            <span className='Transfer-modal-text'>
              de los siguientes 2 días hábiles para que{' '}
            </span>
            <span>pueda comenzar tu proyecto.</span>
          </>
        }
        handleClose={handleClose}
        show={show}
      />
    </React.Fragment>
  );
};

export default withRouter(Transfer);
