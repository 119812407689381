/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import DashboardTitle from '../titles/DashboardTitle';
import { database } from '../../../firebase/firebase';
import SingleUser from './single-user/SingleUser';
import PrevNextButtons from '../buttons/PrevNextButtons';
import ShowDocs from '../show-docs/ShowDocs';
import '../../../css/dashboard/users/UserDashboardList.css';

const UserDashboardList = () => {
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(1);
  const [index, setIndex] = useState(null);
  const [totalDocuments, setTotalDocuments] = useState(null);
  const [seen, setSeen] = useState(null);
  const [quotient, setQuotient] = useState(0);
  const [remainder, setRemainder] = useState(0);
  const [selectedOption, setSelectedOption] = useState(15);
  const [usersList, setUsersList] = useState([]);

  const tempArray = [];

  useEffect(() => {
    setCount(1);
    setUsers([]);
    setUsersList([]);
    setRemainder(null);
    setQuotient(null);
    setIndex(null);
    setTotalDocuments(0);
    setSeen(0);

    const users = [];
    database
      .ref('users')
      .once('value')
      .then((snapshot) => {
        snapshot.forEach((childSnap) => {
          if (childSnap.val().profile.typeOfUser === 'usuario') {
            users.push({
              id: childSnap.key,
              ...childSnap.val().profile,
            });
          }
        });
        setUsers([...users]);
        setTotalDocuments(users.length);

        if (users.length >= selectedOption) {
          setRemainder(users.length % selectedOption);
          setQuotient(Math.floor(users.length / selectedOption) - 1);
          setIndex(selectedOption);
          if (users.length > selectedOption) {
            setSeen(selectedOption);
          } else {
            setSeen(users.length);
          }
          setUsersList([]);
        } else {
          setIndex(users.length);
          setSeen(users.length);
          setUsersList([]);
        }
      });
  }, [selectedOption]);

  const prevPage = () => {
    const newArr = [];
    const lastItem = index;

    for (
      let i = lastItem - selectedOption - 1;
      i
      >= lastItem
      - selectedOption
      - 1
      - (selectedOption - 1);
      i -= 1
    ) {
      newArr.push(
        <SingleUser
          key={users[i].id}
          userName={users[i].userName}
          email={users[i].userEmail}
          id={users[i].id}
        />,
      );
    }
    if (index % selectedOption === 0) {
      setIndex(index - newArr.length);
      setSeen(seen - newArr.length);
    } else {
      setIndex(index - remainder);
      setSeen(seen - remainder);
    }

    setUsersList([...newArr]);
    setCount(count - 1);
    setQuotient(quotient + 1);
  };

  const nextPage = () => {
    const newArr = [];
    const lastItem = index;
    if (quotient === 0 && remainder > 0) {
      for (let i = lastItem; i < index + remainder; i += 1) {
        newArr.push(
          <SingleUser
            key={users[i].id}
            userName={users[i].userName}
            email={users[i].userEmail}
            id={users[i].id}
          />,
        );
      }
    } else if (quotient > 0) {
      for (let i = lastItem; i < index + selectedOption; i += 1) {
        newArr.push(
          <SingleUser
            key={users[i].id}
            userName={users[i].userName}
            email={users[i].userEmail}
            id={users[i].id}
          />,
        );
      }
    }

    setIndex(index + newArr.length);
    setCount(count + 1);
    setSeen(seen + newArr.length);
    setQuotient(quotient - 1);
    setUsersList([...newArr]);
  };

  const handleChangeSelect = (e) => {
    setSelectedOption(e.target.value);
  };

  if (users.length > 0 && users.length >= selectedOption) {
    for (let i = 0; i < selectedOption; i += 1) {
      tempArray.push(
        <SingleUser
          key={users[i].id}
          userName={users[i].userName}
          email={users[i].userEmail}
          id={users[i].id}
        />,
      );
    }
  } else if (users.length > selectedOption) {
    for (let i = 0; i <= 4; i += 1) {
      tempArray.push(
        <SingleUser
          key={users[i].id}
          userName={users[i].userName}
          email={users[i].userEmail}
          id={users[i].id}
        />,
      );
    }
  } else if (users.length < selectedOption) {
    for (let i = 0; i < users.length; i += 1) {
      tempArray.push(
        <SingleUser
          key={users[i].id}
          userName={users[i].userName}
          email={users[i].userEmail}
          id={users[i].id}
        />,
      );
    }
  }

  return (
    <section>
      <DashboardTitle title="Usuarios" />
      <div className="UserDashboardList-docs-wrapper">
        <ShowDocs value={selectedOption} handleChange={handleChangeSelect} />
      </div>
      <div className="UserDashboardList-wrapper">
        <div>
          <p className="UserDashboardList-title">Nombre</p>
        </div>
        <div>
          <p className="UserDashboardList-title">Correo</p>
        </div>
      </div>
      <div>{usersList.length !== 0 ? usersList : tempArray}</div>
      <div className="AgencyProfile-pagination">
        <p className="PagesDetails">
          Mostrando
          {' '}
          {seen}
          {' '}
          de
          {' '}
          {totalDocuments}
          {' '}
          resultados
        </p>
        <PrevNextButtons
          disabledPrev={count === 1}
          disabledNext={index === users.length || users.length <= 5}
          prevFunc={prevPage}
          nextFunc={nextPage}
          limit={selectedOption + 1}
        />
      </div>
    </section>
  );
};

export default UserDashboardList;
