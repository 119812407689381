import React, {useState} from 'react';
import {Accordion, Card} from 'react-bootstrap';
import PackagesList from '../../categories/userInterface/PackagesList';
import DownArrow from '../../../icons/down-arrow-grey.svg';
import UpArrow from '../../../icons/up-arrow-grey.svg';
import '../../../css/utils/Collapsable/CollapsableItems.css';

const CollapsableItems = (props) => {
    const [isOpened, setIsOpen] = useState(false);
    let items = [];
    props.options.forEach(option => {
        if(option.categoryId === props.id) {
            items.push(option);
        }
    });

    const handleOpen = () => setIsOpen(!isOpened);

    const options = items.map(item => (
        <PackagesList key={item.id} id={item.id} name={item.packageName}/>
    ));
    
    return(
        <Card className='CollapsableItems-card'>
            <Accordion.Toggle 
                as={Card.Header} eventKey={props.evtKey} 
                className='CollapsableItems-header'
                onClick={handleOpen}
            >
               <div className='CollapsableItems-paragraph-container'>
                <p className='CollapsableItems-paragraph'>{props.item} </p> 
                <img src={!isOpened ? DownArrow : UpArrow} alt='Flecha'/>
               </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={props.evtKey}>
                <Card.Body  className='CollapsableItems-body'>
                    <ul className='CollapsableItems-list'>
                        {options}
                    </ul>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
}

export default CollapsableItems;