import React from 'react';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik'
import * as yup from 'yup';
import AgencyButton from '../../../buttons/AgencyButton';
import { firebase, database } from '../../../../../firebase/firebase';
import '../../../../../css/dashboard/agencies/add-agency/form/AddAgencyForm.css';

const registerSchema = yup.object().shape({
    name: yup.string().required(),
    agency: yup.string().required(),
    email: yup.string().email('Correo invalido').required(),
    pass: yup.string().min(6, 'Minimo 6 caracteres').required(),
    confirmPass: yup.string().oneOf([yup.ref('pass'), null] , 'Contraseña erronea').required()

})

const AddAgencyForm = (props) => {
    return (
        <Formik
            initialValues={{ name: '', agency: '', email: '', pass: '', confirmPass: '', specialty: '' }}
            validationSchema={registerSchema}
            onSubmit={(values, { setSubmitting, setErrors }) => {
                firebase
                    .auth()
                    .createUserWithEmailAndPassword(values.email, values.pass)
                    .then((result) => {
                        //Sets profileName
                        result.user.updateProfile({
                            displayName: values.name.trim()
                        });

                        //Create a copy in agencies collection
                        database
                        .ref('agencies')
                        .push({
                            userName: values.name.trim(),
                            userEmail: values.email.trim(),
                            agency: values.agency.trim(),
                            typeOfUser: 'agencia',
                            status: 'Activo',
                            specialty: values.specialty.trim(),
                            mainAgencyId: firebase.auth().currentUser.uid
                        });

                        //Sets data in users collection
                        database
                        .ref(`users/${firebase.auth().currentUser.uid}/profile`)
                        .set({
                            userName: values.name.trim(),
                            userEmail: values.email.trim(),
                            agency: values.agency.trim(),
                            typeOfUser: 'agencia',
                            status: 'Activo',
                            specialty: values.specialty.trim()
                        })
                        .then(() => {
                            props.history.push('/rh-admin/dashboard/agencias');
                            setSubmitting(false);
                        });
                    })
                    .catch((error) => {
                        console.log(`Error: ${error.code}`);
                        console.log(`Error: ${error}`);
                        if (error.code === 'auth/weak-password') {
                        setErrors({
                            pass: 'La contrasela debe ser de al menos 6 caracteres',
                        });
                        } else if (error.code === 'auth/invalid-email') {
                        setErrors({ email: 'Correo invalido' });
                        } else if (error.code === 'auth/email-already-in-use') {
                        setErrors({ email: 'Correo existente' });
                        }
                    });

            }}
        >
        {({values, errors, touched, handleChange, handleSubmit, isSubmitting, isValid}) => (
            <form  onSubmit={handleSubmit}>
                <div className='AddAgencyInputWrapper'>
                    <label className='AddAgencyFormLabel'>Nombre</label>
                    <input 
                        type='text'
                        placeholder='Nombre completo'
                        className='AddAgencyFormInput'
                        value={values.name}
                        name='name'
                        onChange={handleChange}
                    />
                </div>
                <div className='AddAgencyInputWrapper'>
                    <label className='AddAgencyFormLabel'>Agencia</label>
                    <input 
                        type='text'
                        placeholder='Agencia'
                        className='AddAgencyFormInput'
                        value={values.agency}
                        name='agency'
                        onChange={handleChange}
                    />
                </div>
                <div className='AddAgencyInputWrapper'>
                    <label className='AddAgencyFormLabel'>Correo electrónico</label>
                    <input 
                        type='text'
                        placeholder='Correo electrónico'
                        className='AddAgencyFormInput'
                        value={values.email}
                        name='email'
                        onChange={handleChange}
                        style={{
                            border:
                              errors.email !== undefined && touched.email
                                ? '1px solid #ff0000'
                                : '',
                            boxShadow:
                              errors.email !== undefined && touched.email
                                ? '0 0 0 0.2rem rgba(255, 0, 0, 0.25)'
                                : '',
                          }}
                    />
                </div>
                <div className='AddAgencyInputWrapper'>
                    <label className='AddAgencyFormLabel'>Contraseña</label>
                    <input 
                        type='password'
                        placeholder='Contraseña'
                        className='AddAgencyFormInput'
                        value={values.pass}
                        name='pass'
                        onChange={handleChange}
                        style={{
                            border:
                              errors.pass !== undefined && touched.pass
                                ? '1px solid #ff0000'
                                : '',
                            boxShadow:
                              errors.pass !== undefined && touched.pass
                                ? '0 0 0 0.2rem rgba(255, 0, 0, 0.25)'
                                : '',
                        }}
                    />
                </div>
                <div className='AddAgencyInputWrapper'>
                    <label className='AddAgencyFormLabel'>Confirmar contraseña</label>
                    <input 
                        type='password'
                        placeholder='Confirmar contraseña'
                        className='AddAgencyFormInput'
                        value={values.confirmPass}
                        name='confirmPass'
                        onChange={handleChange}
                        style={{
                            border:
                              errors.confirmPass !== undefined && touched.confirmPass
                                ? '1px solid #ff0000'
                                : '',
                            boxShadow:
                              errors.confirmPass !== undefined && touched.confirmPass
                                ? '0 0 0 0.2rem rgba(255, 0, 0, 0.25)'
                                : '',
                          }}
                    />
                </div>
                {(errors.email && touched.email) ||
                    (errors.password && touched.password) ||
                    (errors.confirmPass && touched.confirmPass) ? (
                      <div className='error-message'>
                        <p style={{ color: '#ff0000', textAlign: 'center', fontSize: 16 }}>
                          Correo y/o contraseña inválidos
                        </p>
                      </div>
                 ) : null}
                <p className='AddAgencyRadioTitle'>Especialidad</p>
                <div className='AddAgencyInputWrapper AddAgencyRadioWrapper'>
                    <div className='AddAgencyOptionsRadio'>
                        <input 
                            id='especialidad1' 
                            type='radio' 
                            name='specialty' 
                            value='especialidad 1' 
                            className='AddAgencyOptionInput'
                            onChange={handleChange}
                        />
                        <label htmlFor='especialidad1' className='AddAgencyOptionLabel'>
                            <span><div className='selected-icon-span'>&nbsp;</div></span> 
                            Especialidad 1
                        </label>
                    </div>
                    <div className='AddAgencyOptionsRadio'>
                        <input 
                            id='especialidad2' 
                            type='radio' 
                            name='specialty' 
                            value='especialidad 2' 
                            className='AddAgencyOptionInput'
                            onChange={handleChange}
                        />
                        <label htmlFor='especialidad2' className='AddAgencyOptionLabel'>
                            <span><div className='selected-icon-span'>&nbsp;</div></span> 
                            Especialidad 2
                        </label>
                    </div>
                    <div className='AddAgencyOptionsRadio'>
                        <input 
                            id='especialidad3' 
                            type='radio' 
                            name='specialty' 
                            value='especialidad 3' 
                            className='AddAgencyOptionInput'
                            onChange={handleChange}
                        />
                        <label htmlFor='especialidad3' className='AddAgencyOptionLabel'>
                            <span><div className='selected-icon-span'>&nbsp;</div></span> 
                            Especialidad 3
                        </label>
                    </div>
                    <div className='AddAgencyOptionsRadio'>
                        <input 
                            id='especialidad4' 
                            type='radio' 
                            name='specialty' 
                            value='especialidad 4' 
                            className='AddAgencyOptionInput'
                            onChange={handleChange}
                        />
                        <label htmlFor='especialidad4' className='AddAgencyOptionLabel'>
                            <span><div className='selected-icon-span'>&nbsp;</div></span> 
                            Especialidad 4
                        </label>
                    </div>
                    <div className='AddAgencyOptionsRadio'>
                        <input 
                            id='especialidad5' 
                            type='radio' 
                            name='specialty' 
                            value='especialidad 5' 
                            className='AddAgencyOptionInput'
                            onChange={handleChange}
                        />
                        <label htmlFor='especialidad5' className='AddAgencyOptionLabel'>
                            <span><div className='selected-icon-span'>&nbsp;</div></span> 
                            Especialidad 5
                        </label>
                    </div>
                    <div className='AddAgencyOptionsRadio'>
                        <input 
                            id='especialidad6' 
                            type='radio' 
                            name='specialty' 
                            value='especialidad 6' 
                            className='AddAgencyOptionInput'
                            onChange={handleChange}
                        />
                        <label htmlFor='especialidad6' className='AddAgencyOptionLabel'>
                            <span><div className='selected-icon-span'>&nbsp;</div></span> 
                            Especialidad 6
                        </label>
                    </div>
                </div>
                <div className='AddAgencyFormButton'>
                    <AgencyButton text='REGISTRAR AGENCIA' disabled={!isValid || isSubmitting} isValid={isValid}/>
                </div>
            </form>
        )}
        </Formik>
    )
}

export default withRouter(AddAgencyForm);