import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Pasto from '../../img/footer/pasto.gif';
import PastoEstatico from '../../img/footer/grass_img.svg';
import ResponsiveGrass from '../../img/footer/responsive-grass.svg';
import Cuerno from '../../img/footer/cuerno.png';
import '../../css/footer/Footer.css';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = { innerWidth: null };
    this.setWidth = this.setWidth.bind(this);
  }

  setWidth() {
    this.setState({ innerWidth: window.innerWidth });
  }

  componentDidMount() {
    window.addEventListener('resize', this.setWidth, false);
    this.setState({ innerWidth: window.innerWidth });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setWidth, false);
  }

  render() {
    const path = this.props.location.pathname.slice(1);
    return (
      <section className='Footer'>
        {path !== '' ? (
          this.state.innerWidth > 414 ? (
            <div className='Footer-pasto-estatico'>
              <img
                src={PastoEstatico}
                alt='Pasto'
                className='Footer-pasto-estatico-img'
              />
            </div>
          ) : (
            <div className='Footer-pasto-estatico'>
              <img
                src={ResponsiveGrass}
                alt='Pasto'
                className='Footer-pasto-estatico-img'
              />
            </div>
          )
        ) : (
          <div className='Footer-animation'>
            <img src={Pasto} alt='Pasto' />
          </div>
        )}
        <div className='container pd0'>
          <div className='row justify-content-end mg-row'>
            <div className='col-12 col-sm-12 col-md-12 col-lg-1'>
              <div className='Footer-cuerno'>
                <img src={Cuerno} alt='Cuerno' />
              </div>
            </div>
            <div className='col-12 col-sm-12 col-md-12 col-lg-3 pd0'>
              <div className='Footer-container'>
                <ul className='Footer-list'>
                  <li className='Footer-item-title'>
                    <strong>Rhino</strong>
                  </li>
                  <li className='Footer-item'>
                    <Link to='/' className='Footer-link'>
                      Sobre nosotros
                    </Link>
                  </li>
                  <li className='Footer-item'>
                    <Link to='/' className='Footer-link'>
                      Preguntas frecuentes
                    </Link>
                  </li>
                  <li className='Footer-item'>
                    <Link to='/' className='Footer-link'>
                      Contacto
                    </Link>
                  </li>
                  <li className='Footer-item'>
                    <Link to='/' className='Footer-link'>
                      Sostenibilidad
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-12 col-sm-12 col-md-12 col-lg-2 pd0'>
              <div className='Footer-container'>
                <ul className='Footer-list'>
                  <li className='Footer-item-title'>
                    <strong>A la carga</strong>
                  </li>
                  <li className='Footer-item'>
                    <Link to='/' className='Footer-link'>
                      Precios
                    </Link>
                  </li>
                  <li className='Footer-item'>
                    <Link to='/agencias' className='Footer-link'>
                      Agencias
                    </Link>
                  </li>
                  <li className='Footer-item'>
                    <Link to='/' className='Footer-link'>
                      Inspirate
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-12 col-sm-12 col-md-12 col-lg-3 pd0'>
              <div className='Footer-container'>
                <ul className='Footer-list'>
                  <li className='Footer-item-title'>
                    <strong>Elige to proyecto</strong>
                  </li>
                  <li className='Footer-item'>
                    <Link to='/' className='Footer-link'>
                      Lista de servicios estella
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-12 col-sm-12 col-md-12 col-lg-2 pd0'>
              <div className='Footer-container'>
                <ul className='Footer-list'>
                  <li className='Footer-item-title'>
                    <strong>Consultas</strong>
                  </li>
                  <li className='Footer-item'>
                    <Link to='/' className='Footer-link'>
                      Blog
                    </Link>
                  </li>
                  <li className='Footer-item'>
                    <Link to='/' className='Footer-link'>
                      Mis proyectos
                    </Link>
                  </li>
                  <li className='Footer-item'>
                    <Link to='/' className='Footer-link'>
                      Rhino amigo
                    </Link>
                  </li>
                  <li className='Footer-item'>
                    <Link to='/' className='Footer-link'>
                      Ayuda
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-12 col-sm-12 col-md-12 col-lg-1 pd0'>
              <div className='Footer-cuerno-movil'>
                <img src={Cuerno} alt='Cuerno' />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(Footer);
