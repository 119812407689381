import React, { Component } from 'react';
import uuid from 'uuid';
import { database } from '../../../firebase/firebase';
import Collapsable from '../../utils/collapsable/Collapsable';
import Header from './Header';
import PackagesPerCategory from './PackagesPerCategory';
import NavbarBg from '../../navbar/NavbarBg';
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';
import '../../../css/categories/userInterface/Categories.css';

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      packages: [],
      isCategoriesLoaded: false,
      isPackegesLoaded: false,
      counter: 6,
      category: '',
      description: '',
    };
    this.counter = this.counter.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const categories = [];
    const packages = [];
    database
      .ref('categories')
      .once('value')
      .then((snapshot) => {
        snapshot.forEach((childSnapshot) => {
          categories.push({
            id: childSnapshot.key,
            name: childSnapshot.val().categoryName,
            description: childSnapshot.val().description,
            disabled: childSnapshot.val().disabled,
          });

          this.setState({
            categories: [...categories],
            isCategoriesLoaded: true,
          });
        });

        this.setCategoryInfo(categories);
      });

    database
      .ref('packages')
      .once('value')
      .then((snapshot) => {
        snapshot.forEach((childSnapshot) => {
          packages.push({
            id: childSnapshot.key,
            ...childSnapshot.val(),
          });
        });
        this.setState({ packages: [...packages], isPackegesLoaded: true });
      });
  }

  setCategoryInfo(categories) {
    let category = 'Categorías';
    let description = 'Sobre las categorías';

    const { id } = this.props.match.params;
    if (id) {
      console.log(id);
      const currentCat = categories.find((c) => {
        console.log(c);
        return c.id === id;
      });
      category = currentCat.name;
      description = currentCat.description;
    }

    this.setState({
      category, description,
    });
  }

  counter() {
    this.setState((prevState) => ({
      counter: prevState.counter + 3,
    }));
  }

  render() {
    let categories;

    if (this.props.match.params.id) {
      if (this.state.isPackegesLoaded) {
        categories = (
          <PackagesPerCategory
            id={this.props.match.params.id}
            categories={this.state.categories}
          />
        );
      }
    } else {
      categories = this.state.categories.map((category) => !category.disabled && (
        <PackagesPerCategory
          id={category.id}
          categories={this.state.categories}
          key={uuid()}
        />
      ));
    }

    return (
      <section>
        <Navbar />
        <div
          style={{ position: 'absolute', width: '100%', zIndex: 5 }}
          className="Categories-nav-bg"
        >
          <NavbarBg />
        </div>
        <div className="Categories">
          <Header category={this.state.category} description={this.state.description} />
          <div className="row" style={{ margin: 0 }}>
            <div
              className="col-12 col-sm-12 col-md-3 col-lg-3 Cotegories-list-movil"
              style={{ padding: 0 }}
            >
              <div className="Categories-list-container">
                <div className="Categories-list-bg">&nbsp;</div>
                <div className="Categories-container">
                  <h5 className="Categories-title">
                    <strong>Categorias</strong>
                  </h5>
                </div>
                <Collapsable
                  items={this.state.categories}
                  options={this.state.packages}
                />
              </div>
            </div>
            <div
              className="col-12 col-sm-12 col-md-12 col-lg-9"
              style={{ padding: 0 }}
            >
              <div className="Categories-packages-container">{categories}</div>
            </div>
          </div>
          <Footer />
        </div>
      </section>
    );
  }
}

export default Categories;
