import React, { Component, createRef } from 'react';
import uuid from 'uuid/v4';
import { TimelineLite, Linear } from 'gsap';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { database, firebase } from '../../../firebase/firebase';
import { numberWithCommas, getDate, formValid } from '../../../helpers/utils';
import StepOne from './stepsForm/StepOne';
import StepTwo from './stepsForm/StepTwo';
import StepThree from './stepsForm/StepThree';
import StepFour from './stepsForm/StepFour';
import StepFive from './stepsForm/StepFive';
import StepSix from './stepsForm/StepSix';
import StepSeven from './stepsForm/StepSeven';
import StepEight from './stepsForm/StepEight';
import Title from '../../utils/Title';
import ProgressBar from '../../utils/progress/ProgressBar';
import Steps from '../../utils/progress/StepProgress';
import TitleH5 from '../../utils/TitleH5';
import Navbar from '../../navbar/Navbar';
import NavbarBg from '../../navbar/NavbarBg';
import ButtonOnClick from '../../utils/buttons/ButtonOnClick';
import PaymentMethod from '../../paymentsForms/PaymentMethod';
import BriefTransfer from './BriefTransfer';

import ResetModal from '../../modal/ResetModal';
import '../../../css/brief/Brief.css';
import 'firebase/functions';

const functions = firebase.functions();

const stripePromise = loadStripe(
  'pk_live_51H8gh3D2mszNuPF4C1beaGYwJrMM7u37L0cUozKZyG5pMHBCUtN6KfVQwUUa7j8p0ic5cu9ROCGfCqtg5DrVpghQ00yzmfozRa',
);

class BriefFormMultiSteps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      progress: 0,
      userId: firebase.auth().currentUser.uid,
      hasPackage: false,
      package: {},
      stages: [],
      payments: [],
      balls: [],
      lines: [],
      clientSecret: '',
      isTransferred: false,
      userDetails: {},
      firstPayment: {},
      isProcessing: false,
      disabled: true,
      error: null,
      show: false,
      // Valores para el formulario
      // Primer Formulario
      companyName: '',
      companyInfo: '',
      slogan: '',
      strengths: '',
      weaknesses: '',
      // Segundo Formulario
      companySector: '',
      clientsPOV: '',
      solutions: '',
      different: '',
      // Tercer Formulario
      competitors: '',
      directCompetitor: '',
      // Cuarto Formulario
      facebookURL: '',
      twitterURL: '',
      instagramURL: '',
      youtubeURL: '',
      indeedURL: '',
      anotherURL: '',
      isOnline: false,
      googleKeywords: '',
      // Quinto Formulario
      sectorSituation: '',
      projectName: '',
      language: 'Español',
      target: '',
      mainObjective: '',
      // Sexto Formulario
      projectObjectives: '',
      hasWorkedWAgency: 'No',
      agencyWorked: '',
      // Septimo Formulario
      knowUs: '',
      inspireBrand: '',
      frequency: 'Frecuente',
      employees: 'Menos de 15',
      // Octavo Formulario Pago con tarjeta
      cardName: '',
      isRemembered: false,
      formErrors: {
        // Primer Formulario
        companyName: '',
        companyInfo: '',
        slogan: '',
        strengths: '',
        weaknesses: '',
        // Segundo Formulario
        companySector: '',
        clientsPOV: '',
        solutions: '',
        different: '',
        // Tercer Formulario
        competitors: '',
        directCompetitor: '',
        // Cuarto Formulario
        googleKeywords: '',
        // Quinto Formulario
        sectorSituation: '',
        projectName: '',
        target: '',
        mainObjective: '',
        // Sexto Formulario
        projectObjectives: '',
        // Septimo Formulario
        knowUs: '',
        inspireBrand: '',
      },
    };
    // To call a funtion
    this.refCard = new createRef();
    // Balls and horn Refs
    this.horn = new createRef();
    this.sOne = new createRef();
    this.sTwo = new createRef();
    this.sThree = new createRef();
    this.sFour = new createRef();
    this.sFive = new createRef();
    this.sSix = new createRef();
    this.sSeven = new createRef();
    this.sEight = new createRef();
    // Lines Ref
    this.lineOne = new createRef();
    this.lineTwo = new createRef();
    this.lineThree = new createRef();
    this.lineFour = new createRef();
    this.lineFive = new createRef();
    this.lineSix = new createRef();
    this.lineSeven = new createRef();
    // Twees Gsap
    this.tl = new TimelineLite();
    this.t2 = new TimelineLite();
    // Funtions
    this.nextStep = this.nextStep.bind(this);
    this.prevStep = this.prevStep.bind(this);
    this.hornAnimationForward = this.hornAnimationForward.bind(this);
    this.hornAnimationBackward = this.hornAnimationBackward.bind(this);
    this.deletePackage = this.deletePackage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.payCard = this.payCard.bind(this);
    this.payTransfer = this.payTransfer.bind(this);
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.handleChangeCheck = this.handleChangeCheck.bind(this);
    this.handleChangeStripe = this.handleChangeStripe.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const stages = [];
    const payments = [];

    const user = firebase.auth().currentUser;

    if (user != null) {
      this.setState({
        userDetails: { userName: user.displayName, userEmail: user.email },
      });
    }

    if (this.props.location.state !== undefined) {
      database
        .ref(`packages/${this.props.location.state.packageId}`)
        .once('value')
        .then((snap) => {
          this.setState({
            package: { id: snap.key, ...snap.val() },
            hasPackage: true,
          });
        })
        .catch((e) => {
          console.log(`Could not fetch data ${e}`);
        });
      database
        .ref('stages')
        .orderByChild('packageId')
        .equalTo(this.props.location.state.packageId)
        .once('value')
        .then((snap) => {
          snap.forEach((childSnap) => {
            database
              .ref('deliverables')
              .orderByChild('stageId')
              .equalTo(childSnap.key)
              .once('value')
              .then((snapshot) => {
                const deliverables = [];
                snapshot.forEach((snapChaild) => {
                  deliverables.push({
                    deliverableName: snapChaild.val().deliverableName,
                    deliverableDesc: snapChaild.val().deliverableDesc,
                    deliverableDuration: snapChaild.val().deliverableDuration,
                  });
                  stages[childSnap.val().indexOf].deliverables = [
                    ...deliverables,
                  ];
                });
              });
            stages[childSnap.val().indexOf] = {
              id: childSnap.key,
              stageName: childSnap.val().stageName,
            };
            this.setState({ stages: [...stages] });
          });
        })
        .catch((e) => {
          console.log(`Could not fetch data ${e}`);
        });
      database
        .ref('payments')
        .orderByChild('packageId')
        .equalTo(this.props.location.state.packageId)
        .once('value')
        .then((snap) => {
          snap.forEach((childSnap) => {
            payments[childSnap.val().indexOf] = {
              ...childSnap.val(),
            };
          });
          this.setState({ payments: [...payments] });
          window
            .fetch('https://devapi.rhino.lat/create-payment-intent', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ amount: payments[0].amount * 100 }),
            })
            .then((res) => res.json())
            .then((data) => {
              this.setState({ clientSecret: data.clientSecret });
            });
        })
        .catch((e) => {
          console.log(`Could not fetch data ${e}`);
        });
    } else {
      this.setState({ hasPackage: false });
    }

    // Animation
    const steps = [
      this.sOne.current,
      this.sTwo.current,
      this.sThree.current,
      this.sFour.current,
      this.sFive.current,
      this.sSix.current,
      this.sSeven.current,
      this.sEight.current,
    ];

    const lines = [
      this.lineOne.current,
      this.lineTwo.current,
      this.lineThree.current,
      this.lineFour.current,
      this.lineFive.current,
      this.lineSix.current,
      this.lineSeven.current,
    ];

    this.setState({ balls: [...steps], lines: [...lines] });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.progress !== this.state.progress) {
      for (let i = 0; i < this.state.balls.length; i++) {
        if (i === this.state.step - 1) {
          this.t2.to(this.state.balls[i], 0.6, {
            opacity: 0,
            ease: Linear.easeInOut,
          });
        } else if (i < this.state.step - 1) {
          const currentBall = this.state.balls[i];
          currentBall.style.opacity = 1;
        } else {
          const currentBall = this.state.balls[i];
          currentBall.style.opacity = 0.2;
        }
      }
      for (let i = 0; i < this.state.lines.length; i++) {
        if (i === this.state.step - 2) {
          this.t2.to(
            this.state.lines[i],
            0.4,
            {
              width: '100%',
              ease: Linear.easeInOut,
            },
            '-=.4',
          );
        } else if (i < this.state.step - 2) {
          const currentLine = this.state.lines[i];
          currentLine.style.width = '100%';
        } else {
          this.t2.to(
            this.state.lines[i],
            0.4,
            {
              width: 0,
              ease: Linear.easeInOut,
            },
            '-=.4',
          );
        }
      }
    }
  }

  handleClose() {
    this.setState({ show: false });
    this.props.history.push('/proyectos');
  }

  payCard() {
    this.setState({ isTransferred: false });
  }

  payTransfer() {
    this.setState({ isTransferred: true });
  }

  // Allows the creation of packages, stages, delaverables and payments
  generateDuplicates(id) {
    const paymentsIds = [];

    for (let i = 0; i < this.state.payments.length; i++) {
      paymentsIds.push(uuid());
    }

    const newPackageId = database
      .ref(`usersPackages/${firebase.auth().currentUser.uid}`)
      .push({
        mainPackageId: id,
        ...this.state.package,
      });

    this.state.stages.forEach((stage) => {
      database
        .ref(
          `usersStages/${firebase.auth().currentUser.uid}/${newPackageId.key}`,
        )
        .push({
          packageId: newPackageId.key,
          stageName: stage.stageName,
        })
        .then((newKey) => {
          // Remplazar id de cada etapa en pagos
          for (let i = 0; i < this.state.payments.length; i++) {
            const stageIds = [];
            for (const [key, value] of Object.entries(this.state.payments[i])) {
              if (key !== 'packageId' && key !== 'amount' && key !== 'indexOf') stageIds.push(value);
            }
            for (let j = 0; j < stageIds.length; j++) {
              if (stage.id === stageIds[j]) {
                if (i === 0) {
                  if (!this.state.isTransferred) {
                    database
                      .ref(
                        `usersPayments/${firebase.auth().currentUser.uid}/${
                          newPackageId.key
                        }/${paymentsIds[i]}`,
                      )
                      .update({
                        packageId: newPackageId.key,
                        amount: this.state.payments[i].amount,
                        indexOf: i,
                        [`stageId${j}`]: newKey.key,
                        isPaid: true,
                        isPending: false,
                        date: firebase.database.ServerValue.TIMESTAMP,
                      });
                    // Saves info from first payment
                    this.setState({
                      firstPayment: {
                        packageId: newPackageId.key,
                        amount: this.state.payments[i].amount,
                        indexOf: i,
                        [`stageId${j}`]: newKey.key,
                        isPaid: true,
                        isPending: false,
                        date: firebase.database.ServerValue.TIMESTAMP,
                        paymentId: paymentsIds[i],
                      },
                    });
                  } else {
                    database
                      .ref(
                        `usersPayments/${firebase.auth().currentUser.uid}/${
                          newPackageId.key
                        }/${paymentsIds[i]}`,
                      )
                      .update({
                        packageId: newPackageId.key,
                        amount: this.state.payments[i].amount,
                        indexOf: i,
                        [`stageId${j}`]: newKey.key,
                        isPaid: false,
                        isPending: true,
                        date: firebase.database.ServerValue.TIMESTAMP,
                      });
                    // Saves info from first payment
                    this.setState({
                      firstPayment: {
                        packageId: newPackageId.key,
                        amount: this.state.payments[i].amount,
                        indexOf: i,
                        [`stageId${j}`]: newKey.key,
                        isPaid: false,
                        isPending: true,
                        date: firebase.database.ServerValue.TIMESTAMP,
                        paymentId: paymentsIds[i],
                      },
                    });
                  }
                  // Generates copy of deliverables
                  stage.deliverables.forEach((deliverable) => {
                    database
                      .ref(
                        `usersDeliverables/${firebase.auth().currentUser.uid}/${
                          newPackageId.key
                        }/${newKey.key}/${paymentsIds[i]}`,
                      )
                      .push({
                        stageId: newKey.key,
                        ...deliverable,
                        status: 'Por iniciar',
                        payment: paymentsIds[i],
                      });
                  });
                } else {
                  database
                    .ref(
                      `usersPayments/${firebase.auth().currentUser.uid}/${
                        newPackageId.key
                      }/${paymentsIds[i]}`,
                    )
                    .update({
                      packageId: newPackageId.key,
                      amount: this.state.payments[i].amount,
                      indexOf: i,
                      [`stageId${j}`]: newKey.key,
                      isPaid: false,
                      date: firebase.database.ServerValue.TIMESTAMP,
                    });
                  // Generates copy of deliverables
                  stage.deliverables.forEach((deliverable) => {
                    database
                      .ref(
                        `usersDeliverables/${firebase.auth().currentUser.uid}/${
                          newPackageId.key
                        }/${newKey.key}/${paymentsIds[i]}`,
                      )
                      .push({
                        stageId: newKey.key,
                        ...deliverable,
                        status: 'Por iniciar',
                        paymentId: paymentsIds[i],
                      });
                  });
                }
              }
            }
          }
        });
    });
    return newPackageId.key;
  }

  handleChange(e) {
    e.preventDefault();
    const { name, value } = e.target;
    const { formErrors } = this.state;

    switch (name) {
      case 'companyName':
        formErrors.companyName = value === '' ? 'Campo obligatorio' : '';
        formErrors.companyName = value.length === 0 ? 'Campo obligatorio' : '';
        break;
      case 'companyInfo':
        formErrors.companyInfo = value === '' ? 'Campo obligatorio' : '';
        formErrors.companyInfo = value.length === 0 ? 'Campo obligatorio' : '';
        break;
      case 'slogan':
        formErrors.slogan = value === '' ? 'Campo obligatorio' : '';
        formErrors.slogan = value.length === 0 ? 'Campo obligatorio' : '';
        break;
      case 'strengths':
        formErrors.strengths = value === '' ? 'Campo obligatorio' : '';
        formErrors.strengths = value.length === 0 ? 'Campo obligatorio' : '';
        break;
      case 'weaknesses':
        formErrors.weaknesses = value === '' ? 'Campo obligatorio' : '';
        formErrors.weaknesses = value.length === 0 ? 'Campo obligatorio' : '';
        break;
      case 'companySector':
        formErrors.companySector = value === '' ? 'Campo obligatorio' : '';
        break;
      case 'clientsPOV':
        formErrors.clientsPOV = value === '' ? 'Campo obligatorio' : '';
        break;
      case 'solutions':
        formErrors.solutions = value === '' ? 'Campo obligatorio' : '';
        break;
      case 'different':
        formErrors.different = value === '' ? 'Campo obligatorio' : '';
        break;
      case 'competitors':
        formErrors.competitors = value === '' ? 'Campo obligatorio' : '';
        break;
      case 'directCompetitor':
        formErrors.directCompetitor = value === '' ? 'Campo obligatorio' : '';
        break;
      case 'googleKeywords':
        formErrors.googleKeywords = value === '' ? 'Campo obligatorio' : '';
        break;
      case 'sectorSituation':
        formErrors.sectorSituation = value === '' ? 'Campo obligatorio' : '';
        break;
      case 'projectName':
        formErrors.projectName = value === '' ? 'Campo obligatorio' : '';
        break;
      case 'target':
        formErrors.target = value === '' ? 'Campo obligatorio' : '';
        break;
      case 'mainObjective':
        formErrors.mainObjective = value === '' ? 'Campo obligatorio' : '';
        break;
      case 'projectObjectives':
        formErrors.projectObjectives = value === '' ? 'Campo obligatorio' : '';
        break;
      case 'knowUs':
        formErrors.knowUs = value === '' ? 'Campo obligatorio' : '';
        break;
      case 'inspireBrand':
        formErrors.inspireBrand = value === '' ? 'Campo obligatorio' : '';
        break;
      default:
        break;
    }

    this.setState({ formErrors, [name]: value });
  }

  async handleChangeStripe(e) {
    this.setState({ disabled: e.empty });
    this.setState({ error: e.error ? e.error.message : '' });
  }

  // Validate
  validate() {
    const { formErrors } = this.state;

    if (this.state.companyName === '') {
      formErrors.companyName = 'Campo obligatorio';
    }
    if (this.state.companyInfo === '') {
      formErrors.companyInfo = 'Campo obligatorio';
    }
    if (this.state.slogan === '') {
      formErrors.slogan = 'Campo obligatorio';
    }
    if (this.state.strengths === '') {
      formErrors.strengths = 'Campo obligatorio';
    }
    if (this.state.weaknesses === '') {
      formErrors.weaknesses = 'Campo obligatorio';
    }

    if (this.state.companySector === '') {
      formErrors.companySector = 'Campo obligatorio';
    }
    if (this.state.clientsPOV === '') {
      formErrors.clientsPOV = 'Campo obligatorio';
    }
    if (this.state.solutions === '') {
      formErrors.solutions = 'Campo obligatorio';
    }
    if (this.state.different === '') {
      formErrors.different = 'Campo obligatorio';
    }

    if (this.state.competitors === '') {
      formErrors.competitors = 'Campo obligatorio';
    }
    if (this.state.directCompetitor === '') {
      formErrors.directCompetitor = 'Campo obligatorio';
    }
    if (this.state.googleKeywords === '') {
      formErrors.googleKeywords = 'Campo obligatorio';
    }

    if (this.state.sectorSituation === '') {
      formErrors.sectorSituation = 'Campo obligatorio';
    }
    if (this.state.projectName === '') {
      formErrors.projectName = 'Campo obligatorio';
    }
    if (this.state.target === '') {
      formErrors.target = 'Campo obligatorio';
    }
    if (this.state.mainObjective === '') {
      formErrors.mainObjective = 'Campo obligatorio';
    }

    if (this.state.projectObjectives === '') {
      formErrors.projectObjectives = 'Campo obligatorio';
    }
    if (this.state.knowUs === '') {
      formErrors.knowUs = 'Campo obligatorio';
    }
    if (this.state.inspireBrand === '') {
      formErrors.inspireBrand = 'Campo obligatorio';
    }

    this.setState((prevState) => ({
      formErrors: { ...prevState.formErrors, ...formErrors },
    }));
  }

  handleChangeCheckbox(e) {
    this.setState({ isOnline: !this.state.isOnline });
  }

  handleChangeCheck(e) {
    this.setState({ isRemembered: !this.state.isRemembered });
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.validate();
    if (formValid(this.state.formErrors)) {
      this.setState({ isProcessing: true });
      if (!this.state.isTransferred) {
        const payload = await this.refCard.current.checkoutStripe(
          this.state.clientSecret,
          this.state.cardName,
        );

        if (payload.paymentIntent) {
          const result = this.generateDuplicates(
            this.props.location.state.packageId,
          );
          if (result) {
            database
              .ref('briefs')
              .push({
                // User Id
                userId: firebase.auth().currentUser.uid,
                // Package associated to this brief
                packageId: result,
                // General information of company
                companyName: this.state.companyName.trim(),
                companyInfo: this.state.companyInfo.trim(),
                slogan: this.state.slogan.trim(),
                strengths: this.state.strengths.trim(),
                weaknesses: this.state.weaknesses.trim(),
                companySector: this.state.companySector.trim(),
                clientsPOV: this.state.clientsPOV.trim(),
                solutions: this.state.solutions.trim(),
                different: this.state.different.trim(),
                competitors: this.state.competitors.trim(),
                directCompetitor: this.state.directCompetitor.trim(),
                facebookURL: this.state.facebookURL.trim(),
                twitterURL: this.state.twitterURL.trim(),
                instagramURL: this.state.instagramURL.trim(),
                youtubeURL: this.state.youtubeURL.trim(),
                indeedURL: this.state.indeedURL.trim(),
                anotherURL: this.state.anotherURL.trim(),
                googleKeywords: this.state.googleKeywords.trim(),
                sectorSituation: this.state.sectorSituation.trim(),
                language: this.state.language,
                target: this.state.target.trim(),
                mainObjective: this.state.mainObjective.trim(),
                projectName: this.state.projectName.trim(),
                projectObjectives: this.state.projectObjectives.trim(),
                agencyWorked: this.state.agencyWorked.trim(),
                hasWorkedWAgency: this.state.hasWorkedWAgency.trim(),
                knowUs: this.state.knowUs.trim(),
                inspireBrand: this.state.inspireBrand.trim(),
                frequency: this.state.frequency,
                employees: this.state.employees,
                isOnline: this.state.isOnline,
                // Date of purchased
                purchaseDate: firebase.database.ServerValue.TIMESTAMP,
                // Status and Agency
                status: 'Por iniciar',
                agency: 'Sin asignar',
              })
              .then((briefId) => {
                database
                  .ref('paymentsMade')
                  .push({
                    ...this.state.firstPayment,
                    isPaid: true,
                    date: firebase.database.ServerValue.TIMESTAMP,
                    capture: payload.paymentIntent.capture_method,
                    type: payload.paymentIntent.payment_method_types[0],
                    currency: payload.paymentIntent.currency,
                    status: payload.paymentIntent.status,
                    userId: firebase.auth().currentUser.uid,
                    userName: this.state.userDetails.userName,
                    userEmail: this.state.userDetails.userEmail,
                    paidWith: 'Pago con tarjeta',
                    packageName: this.state.package.packageName,
                    paymentNum: 'Pago 1',
                    briefId: briefId.key,
                    paymentId: this.state.firstPayment.paymentId,
                    isPending: false,
                  })
                  .then(() => {
                    this.setState({ isProcessing: false });
                    this.setState({ show: true });
                  });
                // Sen email to user
                const sendEmail = functions.httpsCallable('sendPaidEmail');
                sendEmail({
                  email: this.state.userDetails.userEmail,
                  idPurchase: this.state.firstPayment.paymentId,
                  clientName: this.state.userDetails.userName,
                });
              })
              .catch((err) => {
                console.log(`Error saving data in database ${err}`);
              });
          }
        } else {
          this.setState({ error: payload.message });
          this.setState({ isProcessing: false });
        }
      } else {
        const result = this.generateDuplicates(
          this.props.location.state.packageId,
        );

        if (result) {
          database
            .ref('briefs')
            .push({
              // User Id
              userId: firebase.auth().currentUser.uid,
              // Package associated to this brief
              packageId: result,
              // General information of company
              companyName: this.state.companyName.trim(),
              companyInfo: this.state.companyInfo.trim(),
              slogan: this.state.slogan.trim(),
              strengths: this.state.strengths.trim(),
              weaknesses: this.state.weaknesses.trim(),
              companySector: this.state.companySector.trim(),
              clientsPOV: this.state.clientsPOV.trim(),
              solutions: this.state.solutions.trim(),
              different: this.state.different.trim(),
              competitors: this.state.competitors.trim(),
              directCompetitor: this.state.directCompetitor.trim(),
              facebookURL: this.state.facebookURL.trim(),
              twitterURL: this.state.twitterURL.trim(),
              instagramURL: this.state.instagramURL.trim(),
              youtubeURL: this.state.youtubeURL.trim(),
              indeedURL: this.state.indeedURL.trim(),
              anotherURL: this.state.anotherURL.trim(),
              googleKeywords: this.state.googleKeywords.trim(),
              sectorSituation: this.state.sectorSituation.trim(),
              language: this.state.language,
              target: this.state.target.trim(),
              mainObjective: this.state.mainObjective.trim(),
              projectName: this.state.projectName.trim(),
              projectObjectives: this.state.projectObjectives.trim(),
              agencyWorked: this.state.agencyWorked.trim(),
              hasWorkedWAgency: this.state.hasWorkedWAgency.trim(),
              knowUs: this.state.knowUs.trim(),
              inspireBrand: this.state.inspireBrand.trim(),
              frequency: this.state.frequency,
              employees: this.state.employees,
              isOnline: this.state.isOnline,
              // Date of purchased
              purchaseDate: firebase.database.ServerValue.TIMESTAMP,
              // Status and Agency
              status: 'Por iniciar',
              agency: 'Sin asignar',
            })
            .then((briefId) => {
              database
                .ref('paymentsMade')
                .push({
                  ...this.state.firstPayment,
                  date: firebase.database.ServerValue.TIMESTAMP,
                  userId: firebase.auth().currentUser.uid,
                  userName: this.state.userDetails.userName,
                  userEmail: this.state.userDetails.userEmail,
                  paidWith: 'Transferencia',
                  packageName: this.state.package.packageName,
                  paymentNum: 'Pago 1',
                  briefId: briefId.key,
                  paymentId: this.state.firstPayment.paymentId,
                  isPending: true,
                })
                .then(() => {
                  this.setState({ isProcessing: false });
                  this.setState({ show: true });
                });
            })
            .catch((err) => {
              console.log(`Error saving data in database ${err}`);
            });
        } else {
          this.setState({ isProcessing: false });
        }
      }
    }
  }

  hornAnimationForward() {
    this.tl.to(this.horn.current, 0.7, {
      x: `${this.state.progress + 159}px`,
      ease: Linear.easeInOut,
    });
    this.setState((prevState) => ({
      progress: prevState.progress + 159,
    }));
  }

  hornAnimationBackward() {
    this.tl.to(this.horn.current, 0.7, {
      x: `${this.state.progress - 159}px`,
      ease: Linear.easeInOut,
    });
    this.setState((prevState) => ({
      progress: prevState.progress - 159,
    }));
  }

  nextStep() {
    window.scrollTo(0, 0);
    this.setState((prevState) => ({
      step: prevState.step + 1,
    }));
    this.hornAnimationForward();
  }

  prevStep() {
    window.scrollTo(0, 0);
    this.setState((prevState) => ({
      step: prevState.step - 1,
    }));
    this.hornAnimationBackward();
  }

  deletePackage() {
    this.setState({ hasPackage: false });
  }

  render() {
    let results;
    const description = 'Para darte el mejor servicio necesitamos más información de tu marca y/o proyecto. Prepárate un café y ayúdanos a contestar las siguientes preguntas:';
    if (this.state.step === 1) {
      results = (
        <div>
          <Navbar />
          <NavbarBg />
          <section className="Brief-section">
            <div className="container BriefForm">
              <div className="row noMargin">
                <div className="col-12">
                  <div className="BriefForm-details">
                    <Title title="Brief" />
                    <p className="BriefForm-details-paragraph">{description}</p>
                  </div>
                </div>
                <div className="col-12">
                  <div>
                    <ProgressBar
                      horn={this.horn}
                      sOne={this.sOne}
                      sTwo={this.sTwo}
                      sThree={this.sThree}
                      sFour={this.sFour}
                      sFive={this.sFive}
                      sSix={this.sSix}
                      sSeven={this.sSeven}
                      lineOne={this.lineOne}
                      lineTwo={this.lineTwo}
                      lineThree={this.lineThree}
                      lineFour={this.lineFour}
                      lineFive={this.lineFive}
                      lineSix={this.lineSix}
                      lineSeven={this.lineSeven}
                      sEight={this.sEight}
                    />
                    <Steps step={this.state.step} />
                  </div>
                </div>
                <div className="col-12">
                  <div className="Brief-main-title">
                    <TitleH5 title="Sobre tu marca/empresa" />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-9 centerForm">
                  <div>
                    <StepOne
                      companyName={this.state.companyName}
                      companyInfo={this.state.companyInfo}
                      slogan={this.state.slogan}
                      strengths={this.state.strengths}
                      weaknesses={this.state.weaknesses}
                      errorCompanyName={this.state.formErrors.companyName}
                      errorCompanyInfo={this.state.formErrors.companyInfo}
                      errorSlogan={this.state.formErrors.slogan}
                      errorStrengths={this.state.formErrors.strengths}
                      errorWeaknesses={this.state.formErrors.weaknesses}
                      next={this.nextStep}
                      handleChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      );
    } else if (this.state.step === 2) {
      results = (
        <div>
          <Navbar />
          <NavbarBg />
          <section className="Brief-section">
            <div className="container BriefForm">
              <div className="row noMargin">
                <div className="col-12">
                  <div className="BriefForm-details">
                    <Title title="Brief" />
                  </div>
                </div>
                <div className="col-12">
                  <div>
                    <ProgressBar horn={this.horn} />
                    <Steps step={this.state.step} />
                  </div>
                </div>
                <div className="col-12">
                  <div className="Brief-main-title">
                    <TitleH5 title="Percepción de la marca" />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-9 centerForm">
                  <div>
                    <StepTwo
                      companySector={this.state.companySector}
                      clientsPOV={this.state.clientsPOV}
                      solutions={this.state.solutions}
                      different={this.state.different}
                      errorClientsPOV={this.state.formErrors.clientsPOV}
                      errorCompanySector={this.state.formErrors.companySector}
                      errorSolutions={this.state.formErrors.solutions}
                      errorDifferent={this.state.formErrors.different}
                      next={this.nextStep}
                      back={this.prevStep}
                      handleChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      );
    } else if (this.state.step === 3) {
      results = (
        <div>
          <Navbar />
          <NavbarBg />
          <section className="Brief-section">
            <div className="container BriefForm">
              <div className="row noMargin">
                <div className="col-12">
                  <div className="BriefForm-details">
                    <Title title="Brief" />
                  </div>
                </div>
                <div className="col-12">
                  <div>
                    <ProgressBar horn={this.horn} />
                    <Steps step={this.state.step} />
                  </div>
                </div>
                <div className="col-12">
                  <div className="Brief-main-title">
                    <TitleH5 title="Competencia" />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-9 centerForm">
                  <div>
                    <StepThree
                      competitors={this.state.competitors}
                      directCompetitor={this.state.directCompetitor}
                      errorCompetitors={this.state.formErrors.competitors}
                      errorDirectCompetitor={
                        this.state.formErrors.directCompetitor
                      }
                      next={this.nextStep}
                      back={this.prevStep}
                      handleChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      );
    } else if (this.state.step === 4) {
      results = (
        <div>
          <Navbar />
          <NavbarBg />
          <section className="Brief-section">
            <div className="container BriefForm">
              <div className="row noMargin">
                <div className="col-12">
                  <div className="BriefForm-details">
                    <Title title="Brief" />
                  </div>
                </div>
                <div className="col-12">
                  <div>
                    <ProgressBar horn={this.horn} />
                    <Steps step={this.state.step} />
                  </div>
                </div>
                <div className="col-12">
                  <div className="Brief-main-title">
                    <TitleH5 title="Presencia Online" />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-9 centerForm">
                  <div>
                    <StepFour
                      facebookURL={this.state.facebookURL}
                      twitterURL={this.state.twitterURL}
                      instagramURL={this.state.instagramURL}
                      youtubeURL={this.state.youtubeURL}
                      indeedURL={this.state.indeedURL}
                      anotherURL={this.state.anotherURL}
                      isOnline={this.state.isOnline}
                      googleKeywords={this.state.googleKeywords}
                      errorGoogle={this.state.formErrors.googleKeywords}
                      next={this.nextStep}
                      back={this.prevStep}
                      handleChange={this.handleChange}
                      handleChangeCheckbox={this.handleChangeCheckbox}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      );
    } else if (this.state.step === 5) {
      results = (
        <div>
          <Navbar />
          <NavbarBg />
          <section className="Brief-section">
            <div className="container BriefForm">
              <div className="row noMargin">
                <div className="col-12">
                  <div className="BriefForm-details">
                    <Title title="Brief" />
                  </div>
                </div>
                <div className="col-12">
                  <div>
                    <ProgressBar horn={this.horn} />
                    <Steps step={this.state.step} />
                  </div>
                </div>
                <div className="col-12">
                  <div className="Brief-main-title">
                    <TitleH5 title="Sobre el proyecto" />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-9 centerForm">
                  <div>
                    <StepFive
                      sectorSituation={this.state.sectorSituation}
                      projectName={this.state.projectName}
                      language={this.state.language}
                      target={this.state.target}
                      mainObjective={this.state.mainObjective}
                      errorSector={this.state.formErrors.sectorSituation}
                      errorProjectName={this.state.formErrors.projectName}
                      errorTarget={this.state.formErrors.target}
                      errorMainObjective={this.state.formErrors.mainObjective}
                      next={this.nextStep}
                      back={this.prevStep}
                      handleChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      );
    } else if (this.state.step === 6) {
      results = (
        <div>
          <Navbar />
          <NavbarBg />
          <section className="Brief-section">
            <div className="container BriefForm">
              <div className="row noMargin">
                <div className="col-12">
                  <div className="BriefForm-details">
                    <Title title="Brief" />
                  </div>
                </div>
                <div className="col-12">
                  <div>
                    <ProgressBar horn={this.horn} />
                    <Steps step={this.state.step} />
                  </div>
                </div>
                <div className="col-12">
                  <div className="Brief-main-title">
                    <TitleH5 title="Números" />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-9 centerForm">
                  <div>
                    <StepSix
                      projectObjectives={this.state.projectObjectives}
                      hasWorkedWAgency={this.state.hasWorkedWAgency}
                      agencyWorked={this.state.agencyWorked}
                      errorProjectObj={this.state.formErrors.projectObjectives}
                      next={this.nextStep}
                      back={this.prevStep}
                      handleChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      );
    } else if (this.state.step === 7) {
      results = (
        <div>
          <Navbar />
          <NavbarBg />
          <section className="Brief-section">
            <div className="container BriefForm">
              <div className="row noMargin">
                <div className="col-12">
                  <div className="BriefForm-details">
                    <Title title="Brief" />
                  </div>
                </div>
                <div className="col-12">
                  <div>
                    <ProgressBar horn={this.horn} />
                    <Steps step={this.state.step} />
                  </div>
                </div>
                <div className="col-12">
                  <div className="Brief-main-title">
                    <TitleH5 title="Último sorbo" />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-9 centerForm">
                  <div>
                    <StepSeven
                      knowUs={this.state.knowUs}
                      inspireBrand={this.state.inspireBrand}
                      frequency={this.state.frequency}
                      employees={this.state.employees}
                      errorInspireBrand={this.state.formErrors.inspireBrand}
                      errorKnowUs={this.state.formErrors.knowUs}
                      next={this.nextStep}
                      back={this.prevStep}
                      handleChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      );
    } else if (this.state.step === 8) {
      results = (
        <div>
          <Navbar />
          <NavbarBg />
          <section className="Brief-section">
            <div className="container BriefForm">
              <div className="row noMargin">
                <div className="col-12">
                  <div className="BriefForm-details">
                    <Title title="Pago" />
                  </div>
                </div>
                <div className="col-12">
                  <div>
                    <ProgressBar horn={this.horn} />
                    <Steps step={this.state.step} />
                  </div>
                </div>
                <div className="col-12">
                  <div className="Brief-product-details-mobile">
                    {this.state.hasPackage ? (
                      <>
                        <p className="Brief-product-movil-title">
                          Tus artículos
                        </p>
                        <p className="Brief-product-movil-package">
                          Paquete Rhino
                          {' '}
                          {this.state.package.packageName}
                        </p>
                        <p className="Brief-product-movil-price">
                          $
                          {numberWithCommas(this.state.payments[0].amount)}
                          .00
                          MXN
                        </p>
                        <button
                          className="PackageDetails-delete-button-movil"
                          onClick={this.deletePackage}
                        >
                          ELIMINAR
                        </button>
                        <p className="Brief-product-total-movil">
                          Total: $
                          {numberWithCommas(this.state.payments[0].amount)}
                          .00
                          MXN
                        </p>
                      </>
                    ) : (
                      <div>
                        <p className="No-package-found">
                          No has seleccionado ningún paquete
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="col-12"
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                  {this.state.hasPackage ? (
                    <h3
                      style={{ color: '#58595c' }}
                      className="Total-price-header"
                    >
                      <span>Total</span>
                      <span>
                        $
                        {numberWithCommas(this.state.payments[0].amount)}
                        .00
                        MXN
                      </span>
                    </h3>
                  ) : (
                    <h3
                      style={{ color: '#58595c' }}
                      className="Total-price-header"
                    >
                      <span>Total</span>
                      <span>0.00 MXN</span>
                    </h3>
                  )}
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-max-1">
                  {!this.state.isTransferred ? (
                    <div>
                      <Elements stripe={stripePromise}>
                        <StepEight
                          ref={this.refCard}
                          cardName={this.state.cardName}
                          errorCardName={this.state.formErrors.cardName}
                          isRemembered={this.state.isRemembered}
                          next={this.nextStep}
                          handleChange={this.handleChange}
                          handleSubmit={this.handleSubmit}
                          hasPackage={this.state.hasPackage}
                          isTransferred={this.state.isTransferred}
                          payCard={this.payCard}
                          payTransfer={this.payTransfer}
                          isProcessing={this.state.isProcessing}
                          handleChangeCheck={this.handleChangeCheck}
                          error={this.state.error}
                          handleChangeStripe={this.handleChangeStripe}
                          disabled={this.state.disabled}
                        />
                      </Elements>
                    </div>
                  ) : (
                    <div className="Payment-container">
                      <div className="BriefForm-transfer-wrapper">
                        <PaymentMethod
                          isTransferred={this.state.isTransferred}
                          cardFunc={this.payCard}
                          transFunc={this.payTransfer}
                        />
                        <BriefTransfer />
                        <div className="BriefForm-transfer-btn-wrapper">
                          <ButtonOnClick
                            text={
                              this.state.isProcessing
                                ? 'PROCESANDO...'
                                : 'CONTINUAR'
                            }
                            click={this.handleSubmit}
                            isDisabled={
                              this.state.isProcessing || !this.state.hasPackage
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-max-2">
                  <div className="PackageDetails-purchase">
                    <div className="PackageDetails-purchase-title">
                      <h3 className="Total-price">Tus artículos</h3>
                    </div>
                    <div className="PackageDetails-purchase-details">
                      {this.state.hasPackage ? (
                        <>
                          <div className="PackageDetails-purchase-container">
                            <p className="PackageDetails-purchase-paragraph">
                              Paquete Rhino
                              {' '}
                              {this.state.package.packageName}
                            </p>
                            <p
                              className="PackageDetails-purchase-paragraph"
                              style={{ fontWeight: 500 }}
                            >
                              $
                              {numberWithCommas(this.state.payments[0].amount)}
                              .00 MXN
                            </p>
                            <button
                              className="PackageDetails-delete-button"
                              onClick={this.deletePackage}
                            >
                              ELIMINAR
                            </button>
                          </div>
                          <div className="price-package-paragraph-container">
                            <p className="price-package-paragraph">
                              $
                              {numberWithCommas(this.state.payments[0].amount)}
                              .00 MXN
                            </p>
                          </div>
                        </>
                      ) : (
                        <div className="PackageDetails-purchase-noPackage">
                          <p>No has seleccionado ningún paquete</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="back-btn-8">
                    <ButtonOnClick text="ATRÁS" click={this.prevStep} />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <ResetModal
            message={
              !this.state.isTransferred ? (
                <>
                  <span className="Transfer-modal-text">
                    Tu pago se realizó exitosamente.
                  </span>
                </>
              ) : (
                <>
                  <span className="Transfer-modal-text">
                    Tu comprobante de pago será revisado dentro
                    {' '}
                  </span>
                  <span className="Transfer-modal-text">
                    de los siguientes 2 días hábiles para que
                    {' '}
                  </span>
                  <span>pueda comenzar tu proyecto.</span>
                </>
              )
            }
            handleClose={this.handleClose}
            show={this.state.show}
          />
        </div>
      );
    }
    return results;
  }
}

export default BriefFormMultiSteps;
