import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Navbar from '../navbar/Navbar';
import NavbarBg from '../navbar/NavbarBg';
import Footer from '../footer/Footer';
import ArrowExit from '../../icons/exit_icon.svg';
import Checkout from './Checkout';
import { firebase } from '../../firebase/firebase';
import Transfer from './Transfer';
import PaymentMethod from './PaymentMethod';
import { numberWithCommas } from '../../helpers/utils';
import '../../css/payment-details/PaymentDetails.css';

const stripePromise = loadStripe(
  'pk_live_51H8gh3D2mszNuPF4C1beaGYwJrMM7u37L0cUozKZyG5pMHBCUtN6KfVQwUUa7j8p0ic5cu9ROCGfCqtg5DrVpghQ00yzmfozRa'
);

const PaymentDetails = (props) => {
  const [user, setUser] = useState({});
  const [isTransferred, setIsTransferred] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const userDetails = firebase.auth().currentUser;
    let name, email;

    if (userDetails !== null) {
      name = userDetails.displayName;
      email = userDetails.email;
      setUser({ userName: name, userEmail: email });
    }
  }, []);

  const transfer = () => {
    setIsTransferred(true);
  };

  const card = () => {
    setIsTransferred(false);
  };

  const goBack = () => {
    props.history.goBack();
  };

  const amount = parseInt(atob(props.match.params.amount));

  return (
    <>
      <Navbar />
      <NavbarBg />
      <section className='PaymentDetails-gestor'>
        <div className='PaymentDetails-gestor-container'>
          <div
            className='ProjectDetails-exit-icon-PaymentDetails'
            onClick={goBack}
            style={{ cursor: 'pointer' }}
          >
            <div className='ArrowExit-PaymentDetails'>
              <img src={ArrowExit} alt='icono' />
            </div>
            <p className='Exit-title-payment'>Salir</p>
          </div>
          <p className='PaymentDetails-mainTitle'>Mis proyectos</p>
          <div className='PaymentDetails-gestor-wrapper'>
            <div>
              <div className='PaymentDetails-gest-mainDetails'>
                <p className='PaymentDetails-gest-mainTotal'>Total</p>
                <p className='PaymentDetails-gest-mainPrice'>
                  ${numberWithCommas(amount)}.00 MXN
                </p>
              </div>

              <div className='PaymentDetails-gestor-form-wrapper'>
                <PaymentMethod
                  isTransferred={isTransferred}
                  cardFunc={card}
                  transFunc={transfer}
                />
                {!isTransferred ? (
                  <Elements stripe={stripePromise}>
                    <Checkout
                      amount={amount}
                      id={props.match.params.id}
                      packageId={props.match.params.packageId}
                      userId={props.match.params.userId}
                      paymentId={props.match.params.paymentId}
                      userDetails={user}
                      packageName={atob(props.match.params.packageName)}
                      paymentNum={atob(props.match.params.paymentNum)}
                    />
                  </Elements>
                ) : (
                  <Transfer
                    amount={amount}
                    id={props.match.params.id}
                    packageId={props.match.params.packageId}
                    userId={props.match.params.userId}
                    paymentId={props.match.params.paymentId}
                    userDetails={user}
                    packageName={atob(props.match.params.packageName)}
                    paymentNum={atob(props.match.params.paymentNum)}
                  />
                )}
              </div>
            </div>
            <div className='PaymentDetails-gestor-details-wrapper'>
              <p className='PaymentDetails-gestor-details-title'>
                Tus artículos
              </p>
              <p className='PaymentDetails-gestor-details-package'>
                Paquete Rhino {atob(props.match.params.packageName)} (
                {atob(props.match.params.paymentNum)})
              </p>
              <p className='PaymentDetails-gestor-details-price'>
                ${numberWithCommas(amount)}.00 MXN
              </p>
              <p className='PaymentDetails-gestor-details-total'>
                Total: ${numberWithCommas(amount)}.00 MXN
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default PaymentDetails;
