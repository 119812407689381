import React from 'react';
import AddIcon from '../../../icons/add.svg';
import '../../../css/utils/buttons/Add.css';

const Add = (props) => {
  const { text, click } = props;
  return (
    <button onClick={click} className='Add-button'>
      <img src={AddIcon} alt='+' style={{marginRight: 6}}/>{text}
    </button>
  );
};

export default Add;
