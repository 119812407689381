import React, { Component } from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import {
  firebase,
  googleAuthProvider,
  database,
} from '../../../firebase/firebase';
import FormRegistrarse from '../../forms/user/FormRegistrarse';
import Title from '../../utils/Title';
import Google from '../../../img/navbar/google-logo.png';
import GoogleWhite from '../../../img/navbar/google-white.png';
import '../../../css/login/users/SignUp.css';

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = { isRedirected: false };
    this.handleProviderSignUp = this.handleProviderSignUp.bind(this);
  }

  handleProviderSignUp() {
    firebase
      .auth()
      .signInWithPopup(googleAuthProvider)
      .then((result) => {
        const user = result.user;
        if (user !== null) {
          database
            .ref(`users/${user.uid}/profile`)
            .set({
              userName: user.displayName,
              userEmail: user.email,
              typeOfUser: 'usuario',
              provider: 'Google',
            })
            .then(() => {
              //sendgrid start
              fetch('https://devapi.rhino.lat/send-email', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  email: user.email,
                  subject: 'Bienvenido a la familia',
                  templateId: 'd-57ef848ed46643739ece6bf8eae5412d',
                  data: { name: user.displayName },
                }),
              });
              this.props.handleClose();
              window.location.reload(true);
            });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  render() {
    if (
      this.props.isAuthenticated === true &&
      this.props.typeOfUser === 'usuario'
    ) {
      return <Redirect to='/proyectos' />;
    }
    return (
      <section className='SignUp'>
        <div className='SignUp-container'>
          <Title title='Regístrate' />
          <div className='google-signup-container google-desk'>
            <button
              className='google-signup-btn'
              onClick={this.handleProviderSignUp}
            >
              <div className='google-icon-container'>
                <img src={Google} alt='Google' />
              </div>
              Continuar con Google
            </button>
          </div>
          <div className='google-signup-container google-movil'>
            <button className='google-signup-btn'>
              <div className='google-icon-container'>
                <img
                  src={GoogleWhite}
                  alt='Google'
                  style={{ width: '25px', height: '25px' }}
                />
              </div>
              Continuar con Google
            </button>
          </div>
          <div className='line-container'>&nbsp;</div>
          <FormRegistrarse handleClose={this.props.handleClose} />
          <div style={{ marginTop: 16 }} className='SignUp-paragraph'>
            <p style={{ textAlign: 'center', fontSize: 16, color: '#58595c' }}>
              ¿Ya tienes una cuenta?
              <button
                className='btn-con-cuenta'
                onClick={this.props.handleSesion}
              >
                Iniciar sesión
              </button>
              <Link to='/login' className='link-con-cuenta'>
                Iniciar sesión
              </Link>
            </p>
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(SignUp);
