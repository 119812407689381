import React from 'react';
import { Link } from 'react-router-dom';
import Payment from '../payments/Payment';
import Payments from '../payments/Payments';
import { numberWithCommas } from '../../../helpers/utils';
import '../../../css/packageUserInterface/PackageUser.css';

const PackageUser = (props) => {
  let totalPayments = '';

  switch (props.payments.length - 3) {
    case 2:
      totalPayments = 'Dos';
      break;
    case 3:
      totalPayments = 'Tres';
      break;
    case 4:
      totalPayments = 'Cuatro';
      break;
    case 5:
      totalPayments = 'Cinco';
      break;
    case 6:
      totalPayments = 'Seis';
      break;
    case 7:
      totalPayments = 'Siete';
      break;
    case 8:
      totalPayments = 'Ocho';
      break;
    case 9:
      totalPayments = 'Nueve';
      break;
    case 10:
      totalPayments = 'Diez';
      break;
    case 11:
      totalPayments = 'Once';
      break;
    case 12:
      totalPayments = 'Doce';
      break;

    default:
      totalPayments = null;
  }

  return (
    <div className="PackageUser">
      <div
        className="PackageUser-img"
        style={{ backgroundImage: `url(${props.img})` }}
      >
        &nbsp;
      </div>
      <div className="PackageUser-info-container">
        <h1 className="PackageUser-main-header">
          <span>Paquete Rhino</span>
          <span>{props.name}</span>
        </h1>
        <h4>Primer pago</h4>
        <h3 className="PackageUser-price">
          <strong>
            $
            {numberWithCommas(props.price)}
            .00 MXN
          </strong>
        </h3>
        <p>contrato mínimo 12 meses</p>
        <div>
          <p className="PackageUder-payments-details">Detalles de los pagos</p>
        </div>
        <div>
          {props.payments.length > 0 && (
            <>
              <Payment
                title="Primer pago"
                payment={props.payments[0]}
                stages={props.stages}
              />

              {
                props.payments[1]
                && (
                <Payment
                  title="Segundo pago"
                  payment={props.payments[1]}
                  stages={props.stages}
                />
                )
              }
              {
                props.payments[2]
                && (
                <Payment
                  title="Tercer pago"
                  payment={props.payments[2]}
                  stages={props.stages}
                />
                )
              }
              {
                totalPayments
                && (
                <Payments
                  title={`${totalPayments} pagos`}
                  payments={props.payments}
                  stages={props.stages}
                />
                )
              }
            </>
          )}
        </div>
        <div className="PackageUser-link-container">
          {props.userId !== null ? (
            <Link
              to={{ pathname: '/brief', state: { packageId: props.id } }}
              className="PackageUser-link"
            >
              OBTENER
            </Link>
          ) : props.innerWidth >= 1280 ? (
            <button onClick={props.handleOpen} className="PackageUser-bnt">
              OBTENER
            </button>
          ) : (
            <Link
              to={{ pathname: '/signup', state: { packageId: props.id } }}
              className="PackageUser-link"
            >
              OBTENER
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default PackageUser;
