import React from 'react';
import {Modal} from 'react-bootstrap';
import ButtonOnClick from '../utils/buttons/ButtonOnClick';
import '../../css/modal/StageModal.css';

const DeleteFileModal = (props) => {
    return(
        <Modal centered show={props.show} onHide={props.handleClose} className='StageModal'>
            <Modal.Body className='StageModal-body'>
                <div className='StageModal-paragraphs-wrapper'>
                    <p style={{color: '#58595c', textAlign: 'center', margin: 0}} className='StageModal-message'>
                        ¿Estás seguro de que quieres eliminar este archivo?
                    </p>
                </div>
                <div className='StageModal-btns-wrapper'>
                    <div className='StageModal-finished'><ButtonOnClick text='Aceptar' click={props.delete} /></div>
                    <div className='StageModal-cancel-wrapper'><button onClick={props.handleClose} className='StageModal-cancel'>Cancelar</button></div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default DeleteFileModal;