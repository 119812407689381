import React from 'react';
import Ball from './Ball';
import Line from './Line';
import '../../../css/utils/progress/StepProgress.css';

const Steps = (props) => {
    return (
        <div className='StepProgress'>
            {props.step !== 1 && <div className='Steps-line-container' style={{marginRight: '20px', marginLeft: '0'}}>
                <Line />
            </div>}
            <div>
                <Ball step={props.step} />
            </div>
            {props.step !== 8 && <div className='Steps-line-container'>
                <Line />
            </div>}
        </div>
    );
}

export default Steps;