import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { database, firebase } from './firebase/firebase';
import PrivateRoute from './privateRoute/PrivateRoute';
import PrivateRouteAgency from './privateRouteAgency/PrivateRouteAgency';
import PrivateRouteDash from './privateRouteDash/PrivateRouteDash';
import HomePage from './components/homepage/HomePage';
import Categories from './components/categories/userInterface/Categories';
import HomeAgency from './components/homeAgency/HomeAgency';
import PackageInterface from './components/packages/userInterface/PackageInterface';
import SignUpMobile from './components/login/user/SignUpMobile';
import Login from './components/login/user/Login';
import ResetPassword from './components/reset/ResetPassword';
import UserProfile from './components/profile/users/UserProfile';
import EditUserProfile from './components/profile/users/EditUserProfile';
import AgencyProfile from './components/profile/agency/AgencyProfile';
import EditAgencyProfile from './components/profile/agency/EditAgencyProfile';
import LoginAdmin from './components/login/admin/LoginAdmin';
import SignUpAdmin from './components/login/admin/SignUpAdmin';
import SignUpAgency from './components/login/agencies/SignUpAgency';
import LoginAgency from './components/login/agencies/LoginAgency';
import ProjectList from './components/projects/projectsUsers/ProjectList';
import EntregableList from './components/projects/projectsUsers/EntregableList';
import EntregableAgencyList from './components/projects/projectsAgency/EntregableAgencyList';
import ProjectDetails from './components/projects/projectsUsers/ProjectDetails';
import ProjectAgencyDetails from './components/projects/projectsAgency/ProjectAgencyDetails';
import ProjectsAgencyList from './components/projects/projectsAgency/ProjectsAgencyList';
import EntregableDetails from './components/projects/projectsAgency/EntregableDetails';
import RecurrentDeliverablesList from './components/projects/projectsUsers/RecurrentDeliverablesList';
import RecurrentDListAgency from './components/projects/projectsAgency/RecurrentDListAgency';
import EntregableDetailsUser from './components/projects/projectsUsers/EntregableDetailsUser';
import PaymentDetails from './components/paymentsForms/PaymentDetails';
import Dashboard from './components/dashboard/Dashboard';
import UserChat from './components/chat/userChat/UserChat';
import AgencyChat from './components/chat/agencyChat/AgencyChat';
import DashboardPackages from './components/dashboard/packages/DashboardPackageForm';
import DashboardPackageList from './components/dashboard/DashboardPackageList';
import BriefFormMultiSteps from './components/brief/userBriefInterface/BriefFormMultiSteps';
import './App.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      isAuthenticated: false,
      uid: '',
      typeOfUser: '',
      loading: true,
    };
  }

  componentDidMount() {
    let userProfile = {};
    this.authListener = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        database
          .ref(`users/${user.uid}/profile`)
          .once('value')
          .then((snapshot) => {
            userProfile = { ...snapshot.val() };
            this.setState({
              user: { ...user, ...userProfile },
              isAuthenticated: true,
              uid: user.uid,
              typeOfUser: userProfile.typeOfUser,
              loading: false,
            });
          })
          .catch((e) => {
            console.log(`Error fetching data: ${e}`);
          });
      } else {
        this.setState({
          user: null,
          isAuthenticated: false,
          uid: '',
          typeOfUser: '',
          loading: false,
        });
      }
    });
  }

  componentWillUnmount() {
    this.authListener();
  }

  render() {
    console.log(this.state);
    const { typeOfUser, loading, isAuthenticated } = this.state;
    if (loading) return null;
    return (
      <div className="App">
        <Switch>
          <Route
            exact
            path="/"
            render={(routeProps) => <HomePage {...routeProps} />}
          />
          <Route exact path="/agencias" component={HomeAgency} />
          <Route
            exact
            path="/categorias"
            render={(routeProps) => <Categories {...routeProps} />}
          />
          <Route
            exact
            path="/categorias/:id"
            render={(p) => (
              <Categories
                key={p.location.pathname + p.location.search}
                history={p.history}
                location={p.location}
                match={p.match}
              />
            )}
          />
          <Route
            exact
            path="/categorias/paquete/:id"
            render={(routeProps) => <PackageInterface {...routeProps} />}
          />
          <PrivateRoute
            exact
            path="/brief"
            isAuthenticated={isAuthenticated}
            typeOfUser={typeOfUser}
            component={BriefFormMultiSteps}
          />
          <Route exact path="/signup" render={() => <SignUpMobile />} />
          <Route
            exact
            path="/login"
            render={() => (
              <Login
                isAuthenticated={isAuthenticated}
                typeOfUser={typeOfUser}
              />
            )}
          />
          <Route exact path="/password_reset" component={ResetPassword} />
          <PrivateRoute
            exact
            path="/proyectos"
            isAuthenticated={isAuthenticated}
            typeOfUser={typeOfUser}
            component={ProjectList}
          />
          <PrivateRoute
            exact
            path="/perfil/:userId"
            isAuthenticated={isAuthenticated}
            typeOfUser={typeOfUser}
            component={UserProfile}
          />
          <PrivateRouteAgency
            exact
            path="/agencias/perfil/:userId"
            isAuthenticated={isAuthenticated}
            typeOfUser={typeOfUser}
            component={AgencyProfile}
          />
          <PrivateRoute
            exact
            path="/perfil/:userId/editar"
            isAuthenticated={isAuthenticated}
            typeOfUser={typeOfUser}
            component={EditUserProfile}
          />
          <PrivateRouteAgency
            exact
            path="/agencias/perfil/:userId/editar"
            isAuthenticated={isAuthenticated}
            typeOfUser={typeOfUser}
            component={EditAgencyProfile}
          />
          <Route
            exact
            path="/rh-admin/login"
            render={() => (
              <LoginAdmin
                isAuthenticated={isAuthenticated}
                typeOfUser={typeOfUser}
              />
            )}
          />
          <Route
            exact
            path="/rh-admin/signup"
            render={() => (
              <SignUpAdmin isAuthenticated={isAuthenticated} />
            )}
          />
          <PrivateRouteDash
            exact
            path="/rh-admin/dashboard/:sec"
            isAuthenticated={isAuthenticated}
            typeOfUser={typeOfUser}
            component={Dashboard}
          />
          <PrivateRouteDash
            exact
            path="/rh-admin/dashboard/:sec/:id"
            isAuthenticated={isAuthenticated}
            typeOfUser={typeOfUser}
            component={Dashboard}
          />
          <PrivateRouteDash
            exact
            path="/rh-admin/dashboard/proyectos/:id/:sec"
            isAuthenticated={isAuthenticated}
            typeOfUser={typeOfUser}
            component={Dashboard}
          />
          <PrivateRouteDash
            exact
            path="/rh-admin/dashboard/:sec/:id/:packageId/:userId/etapas/:stageId/:stageName/:stageNum/entregables"
            isAuthenticated={isAuthenticated}
            typeOfUser={typeOfUser}
            component={Dashboard}
          />
          <PrivateRouteDash
            exact
            path="/rh-admin/dashboard/:sec/:id/:packageId/:userId/etapas/:stageId/:payment/entregable/:deliverableId"
            isAuthenticated={isAuthenticated}
            typeOfUser={typeOfUser}
            component={Dashboard}
          />
          <PrivateRouteDash
            exact
            path="/rh-admin/dashboard/:sec/:id/:packageId/:userId/etapas/:stageId/:payment/entregable/:deliverableId/messages"
            isAuthenticated={isAuthenticated}
            typeOfUser={typeOfUser}
            component={Dashboard}
          />
          <Route
            exact
            path="/agencias/signup"
            render={() => (
              <SignUpAgency isAuthenticated={isAuthenticated} />
            )}
          />
          <Route
            exact
            path="/agencias/login"
            render={() => (
              <LoginAgency
                isAuthenticated={isAuthenticated}
                typeOfUser={typeOfUser}
              />
            )}
          />
          <PrivateRouteAgency
            exact
            path="/agencias/proyectos"
            isAuthenticated={isAuthenticated}
            typeOfUser={typeOfUser}
            component={ProjectsAgencyList}
          />
          <PrivateRoute
            exact
            path="/proyectos/:id/:sec"
            isAuthenticated={isAuthenticated}
            typeOfUser={typeOfUser}
            component={ProjectDetails}
          />
          <PrivateRouteAgency
            exact
            path="/agencias/proyectos/:id/:sec"
            isAuthenticated={isAuthenticated}
            typeOfUser={typeOfUser}
            component={ProjectAgencyDetails}
          />
          <PrivateRoute
            exact
            path="/proyectos/:id/:packageId/:userId/etapas/:stageId/:stageName/:stageNum/entregables"
            isAuthenticated={isAuthenticated}
            typeOfUser={typeOfUser}
            component={EntregableList}
          />
          <PrivateRoute
            exact
            path="/proyectos/:id/:packageId/:userId/etapas/:stageId/:stageNum/:stageName/entregables/:paymentId/recurrentes"
            isAuthenticated={isAuthenticated}
            typeOfUser={typeOfUser}
            component={RecurrentDeliverablesList}
          />
          <PrivateRouteAgency
            exact
            path="/agencias/proyectos/:id/:packageId/:userId/etapas/:stageId/:stageNum/:stageName/entregables/:paymentId/recurrentes"
            isAuthenticated={isAuthenticated}
            typeOfUser={typeOfUser}
            component={RecurrentDListAgency}
          />
          <PrivateRouteAgency
            exact
            path="/agencias/proyectos/:id/:packageId/:userId/etapas/:stageId/:stageName/:stageNum/entregables"
            isAuthenticated={isAuthenticated}
            typeOfUser={typeOfUser}
            component={EntregableAgencyList}
          />
          <PrivateRouteAgency
            exact
            path="/agencias/proyectos/:id/:packageId/:userId/etapas/:stageId/:payment/entregable/:deliverableId"
            isAuthenticated={isAuthenticated}
            typeOfUser={typeOfUser}
            component={EntregableDetails}
          />
          <PrivateRoute
            exact
            path="/proyectos/:id/:packageId/:userId/etapas/:stageId/:payment/entregable/:deliverableId"
            isAuthenticated={isAuthenticated}
            typeOfUser={typeOfUser}
            component={EntregableDetailsUser}
          />
          <PrivateRoute
            exact
            path="/proyectos/:id/:packageId/:userId/etapas/:stageId/:payment/entregable/:deliverableId/messages"
            isAuthenticated={isAuthenticated}
            typeOfUser={typeOfUser}
            component={UserChat}
          />
          <PrivateRouteAgency
            exact
            path="/agencias/proyectos/:id/:packageId/:userId/etapas/:stageId/:payment/entregable/:deliverableId/messages"
            isAuthenticated={isAuthenticated}
            typeOfUser={typeOfUser}
            component={AgencyChat}
          />
          <PrivateRoute
            exact
            path="/proyectos/:id/:sec/:packageId/:userId/:amount/:packageName/:paymentNum/:paymentId"
            isAuthenticated={isAuthenticated}
            typeOfUser={typeOfUser}
            component={PaymentDetails}
          />
          <PrivateRouteDash
            exact
            path="/rh-admin/crear/paquete"
            isAuthenticated={isAuthenticated}
            typeOfUser={typeOfUser}
            component={DashboardPackages}
          />
          <PrivateRouteDash
            exact
            path="/rh-admin/lista/paquetes"
            isAuthenticated={isAuthenticated}
            typeOfUser={typeOfUser}
            component={DashboardPackageList}
          />
        </Switch>
      </div>
    );
  }
}

export default App;
