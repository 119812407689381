import React from 'react';
import uuid from 'uuid/v4';
import '../../../../css/utils/forms/select/Select.css';

const Select = (props) => {
  const {
    name,
    label,
    value,
    handleChange,
    isTouched,
    error,
    message,
    options,
  } = props;

  const optionsList = options.map((option) => (
    <option key={uuid()} value={option}>
      {option}
    </option>
  ));

  return (
    <div className='Select-container'>
      {label && <label className='Input-label'>{label}</label>}
      <select
        className='Select'
        name={name}
        onChange={handleChange}
        value={value}
        style={{
          border: error !== undefined && isTouched ? '1px solid #ff0000' : '',
          boxShadow:
            error !== undefined && isTouched
              ? '0 0 0 0.2rem rgba(255, 0, 0, 0.25)'
              : '',
        }}
      >
        {optionsList}
      </select>
      {error !== undefined && isTouched && (
        <small className='Select-error'>{error}</small>
      )}
      {message && <small className='Select-message'>{message}</small>}
    </div>
  );
};

export default Select;
