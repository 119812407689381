import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { firebase } from '../../firebase/firebase';
import Input from '../utils/forms/input/Input';
import ButtonOnSubmit from '../utils/buttons/ButtonOnSubmit';
import ResetModal from '../modal/ResetModal';
import RhinoLogo from '../../img/reset/reset_logo.svg';
import '../../css/reset/ResetPassword.css';

const ResetPasswordSchema = yup.object().shape({
  email: yup.string().email('Correo invalido').required('Campo obligatorio'),
});

const ResetPassword = (props) => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <React.Fragment>
      <section className='ResetPassword'>
        <div className='ResetPassword-details'>
          <div className='ResetPassword-rinho-logo'>
            <img src={RhinoLogo} alt='Rhino' className='ResetPassword-img' />
          </div>
          <div>
            <h3 className='ResetPassword-title'>Restablecer contraseña</h3>
          </div>
          <div>
            <p className='ResetPassword-info'>
              Enviaremos un correo electrónico con instrucciones para
              restablecer tu contraseña.
            </p>
          </div>
          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={ResetPasswordSchema}
            onSubmit={(values, { resetForm, setSubmitting, setErrors }) => {
              const auth = firebase.auth();
              const emailAddress = values.email.trim();

              auth
                .sendPasswordResetEmail(emailAddress)
                .then(() => {
                  resetForm();
                  setSubmitting(false);
                  handleShow();
                })
                .catch((error) => {
                  let code = error.code;
                  if(code === 'auth/user-not-found') {
                    setErrors({email: 'Usuario inexistente'});
                  }
                });
            }}
          >
            {({ errors, touched, values, handleChange, isSubmitting }) => (
              <Form>
                <div className='ResetPassword-input'>
                  <Input
                    type='email'
                    placeholder='Correo electrónico'
                    value={values.email}
                    name='email'
                    handleChange={handleChange}
                    isTouched={touched.email}
                    error={errors.email}
                  />
                </div>
                <div className='ResetPassword-btn'>
                  <ButtonOnSubmit
                    text='Enviar email'
                    isDisabled={isSubmitting}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </section>
      <ResetModal
        message='Hemos enviado las instrucciones a tu correo para poder restablecer tu contraseña.'
        show={show}
        handleClose={handleClose}
      />
    </React.Fragment>
  );
};

export default ResetPassword;
