import React, { Component } from 'react';
import { database } from '../../../firebase/firebase';
import ArrowExit from '../../../icons/exit_icon.svg';
import AccordionMobile from '../project-components/project-accordion-mobile/AccordionMobile';
import CardMobile from '../project-components/project-accordion-mobile/CardMobile';
import NavbarBgAgency from '../../navbar/NavbarBgAgency';
import NavbarAgency from '../../navbar/NavbarAgency';
import FooterAgency from '../../footer/FooterAgency';
import '../../../css/projects/projectsUser/EntregableList.css';

class RecurrentDeliverablesList extends Component {
  constructor(props) {
    super(props);
    this.state = { deliverables: [], paymentDate: '', isLoaded: false, isPaymenteLoaded: false};
    this.back = this.back.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let deliverables = [];
    database.ref(`usersDeliverables/${this.props.match.params.userId}/${this.props.match.params.packageId}/${this.props.match.params.stageId}/${this.props.match.params.paymentId}`)
    .once('value')
    .then(snapshot => {
        snapshot.forEach(childSnap => {
            deliverables.push({
                id: childSnap.key,
                ...childSnap.val()
            })
        });
        this.setState({deliverables: [...deliverables], isLoaded: true})
    });
    database.ref(`usersPayments/${this.props.match.params.userId}/${this.props.match.params.packageId}/${this.props.match.params.paymentId}`)
    .once('value')
    .then(snapshot => this.setState({paymentDate: snapshot.val().date, isPaymenteLoaded: true}))
  }

  back() {
    this.props.history.goBack();
  }

  render() {
    let stageName = atob(this.props.match.params.stageName);
    let stageNum = atob(this.props.match.params.stageNum);
    let deliverables = [];
    /* let stageName = atob(this.props.match.params.stageName);
    let stageNum = atob(this.props.match.params.stageNum); */
    //Calculate the days
    /* let totalDays;
      if (deliverDate !== null) {
        const milliseconds = finished - deliverDate;
        if (Math.sign(Math.ceil(milliseconds / 86400000)) === 1) {
          totalDays = `+${Math.ceil(milliseconds / 86400000)}`;
        } else if (Math.sign(Math.ceil(milliseconds / 86400000)) === -1) {
          totalDays = `-${Math.ceil(milliseconds / 86400000)}`;
        }
      } else {
        totalDays = '-';
      } */
    if(this.state.isLoaded && this.state.isPaymenteLoaded) {
        let started = this.state.paymentDate;
        let index = 0;

        this.state.deliverables.forEach(deliverable => {
            let count = 0;
            let start = started;
            let finished;
            let totalDays;
            let totalMgs;

            while (count !== parseInt(deliverable.deliverableDuration)) {
            start += 86400000;
            let date = new Date(start);
            if (date.getDay() === 0 || date.getDay() === 6) {
                count--;
            }
            count++;
            }

            finished = start;

            
            if (deliverable.deliveryDate !== null) {
              const milliseconds = finished - deliverable.deliveryDate;
              if (Math.sign(Math.ceil(milliseconds / 86400000)) === 1) {
                totalDays = `+${Math.ceil(milliseconds / 86400000)}`;
              } else if (Math.sign(Math.ceil(milliseconds / 86400000)) === -1) {
                totalDays = `-${Math.ceil(milliseconds / 86400000)}`;
              }
            } else {
              totalDays = '-';
            }

            if(deliverable.userCountMgs) {
              totalMgs = deliverable.userCountMgs
            } else {
              totalMgs = 0;
            }

            deliverables.push(
            <CardMobile 
                key={deliverable.id} 
                name={deliverable.deliverableName} 
                index={index}
                options={[
                {title: 'Inicio', subtitle: started}, 
                {title: 'Termino', subtitle: finished},
                {title: 'Margen', subtitle: totalDays},
                {title: 'Mensajes', subtitle: `${totalMgs} mensajes`,
                mgsLink: `/agencias/proyectos/${this.props.match.params.id}/${this.props.match.params.packageId}/${this.props.match.params.userId}/etapas/${this.props.match.params.stageId}/${this.props.match.params.paymentId}/entregable/${deliverable.id}/messages`,
                mgs: totalMgs
                },
                ]}
                status={deliverable.status}
                linkTwo={
                `/agencias/proyectos/${this.props.match.params.id}/${this.props.match.params.packageId}/${this.props.match.params.userId}/etapas/${this.props.match.params.stageId}/${this.props.match.params.paymentId}/entregable/${deliverable.id}`
                }
                refUrl={`usersDeliverables/${this.props.match.params.userId}/${this.props.match.params.packageId}/${this.props.match.params.stageId}/${this.props.match.params.paymentId}/${deliverable.id}`}
                resetProp='userCountMgs'
            />
            );
            started = finished;
            index++;
        });
    }

    return (
      <React.Fragment>
        <NavbarAgency />
        <NavbarBgAgency />
        <section className='EntregablesList'>
          <div className='container'>
            <div className='row'>
              <div
                className='ProjectDetails-exit-icon'
                onClick={this.back}
                style={{ cursor: 'pointer' }}
              >
                <div className='ArrowExit'>
                  <img src={ArrowExit} alt='icono' />
                </div>
                <p>Salir</p>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='ProjectDetails-title'>
                  <h3>
                    {stageNum.split('_').join(' ')}
                    <span style={{ display: 'block' }}>
                        {stageName.split('_').join(' ')}
                    </span>
                  </h3>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='EntregableList-wrapper-mobile'>
                    <AccordionMobile
                        items={deliverables}
                    />
                </div>
              </div>
            </div>
          </div>
        </section>
        <FooterAgency />
      </React.Fragment>
    );
  }
}

export default RecurrentDeliverablesList;