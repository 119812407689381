import React from 'react';
import uuid from 'uuid/v4';
import ProjectItem from './ProjectItem';

const Project = (props) => {
    const items = props.items.map(item => (
        <ProjectItem key={uuid()}  item={item}/>
    ));
    return( 
        <tr>
            {items}
        </tr>
    );
}

export default Project;