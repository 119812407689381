import React, { Component } from 'react';
import Lottie from 'react-lottie';
import { NavLink, Link } from 'react-router-dom';
import ArrowExit from '../../../icons/exit_icon.svg';
import { database } from '../../../firebase/firebase';
import * as animationData from '../../../lottie/lottie_loading.json';
import ProjectStagesList from '../project-components/ProjectStagesList';
import BriefDetails from './BriefDetails';
import AgencyPayments from './AgencyPayments';
import NavbarBgAgency from '../../navbar/NavbarBgAgency';
import NavbarAgency from '../../navbar/NavbarAgency';
import FooterAgency from '../../footer/FooterAgency';
import '../../../css/projects/projectsUser/ProjectDetails.css';

class ProjectAgencyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { stages: [], brief: {}, isLoaded: false, isStarted: false };
    this.startProject = this.startProject.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let stages = [];
    let brief = {};
    database
      .ref(`briefs/${this.props.match.params.id}`)
      .once('value')
      .then((snap) => {
        database
          .ref(
            `usersPackages/${snap.val().userId}/${
              snap.val().packageId
            }`
          )
          .once('value')
          .then((childSnap) => {
            database
              .ref(
                `usersStages/${snap.val().userId}/${
                  snap.val().packageId
                }`
              )
              .once('value')
              .then((child) => {
                child.forEach((childChild) => {
                  stages.push({
                    id: childChild.key,
                    ...childChild.val(),
                  });
                  brief = {
                    id: snap.key,
                    ...snap.val(),
                    packageDetails: { ...childSnap.val() },
                  };
                });
                this.setState({
                  stages: [...stages],
                  brief: { ...brief },
                  isLoaded: true,
                });
              });
          });
      });
  }

  startProject() {
    database
      .ref(`briefs/${this.props.match.params.id}`).update({
        status: 'En proceso'
      }).then(() => {
        this.setState({isStarted: true});
      });
  }

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData.default,
    };

    let result;
    switch (this.props.match.params.sec) {
      case 'etapas':
        result = (
          <ProjectStagesList
            stages={this.state.stages}
            id={this.props.match.params.id}
            packageId={this.state.brief.packageId}
            userId={this.state.brief.userId}
            partUrl='/agencias/proyectos'
          />
        );
        break;
      case 'brief':
        result = (
          <BriefDetails brief={this.state.brief} id={this.props.match.params.id} />
        );
        break;
      case 'pagos':
        result = (
          <AgencyPayments brief={this.state.brief} id={this.props.match.params.id} />
        );
        break;
      default:
        result = <h3>Página no encontrada</h3>;
        break;
    }
    return (
      <div>
        <NavbarAgency />
        <NavbarBgAgency />
        <section className='ProjectDetails'>
          <div className='container'>
            <div className='row'>
              <Link to='/agencias/proyectos' className='ProjectDetails-exit-link'>
                <div className='ProjectDetails-exit-icon'>
                  <div className='ArrowExit'>
                    <img src={ArrowExit} alt='icono' />
                  </div>
                  <p>Salir</p>
                </div>
              </Link>
            </div>
            {this.state.isLoaded ? (
              <React.Fragment>
                <div className='row'>
                  <div className='col-12'>
                    <div className='ProjectDetails-title'>
                      <h3>
                        Paquete Rhino{' '}
                        <span>
                          {this.state.isLoaded &&
                            this.state.brief.packageDetails.packageName}
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <div className='ProjectDetails-options ProjectDetails-options-agency'>
                      <ul className='ProjectDetails-list'>
                        <li className='ProjectDetails-item'>
                          <NavLink
                            to={{
                              pathname: `/agencias/proyectos/${this.props.match.params.id}/etapas`,
                            }}
                            className='ProjectDetails-link'
                            activeClassName='ProjectDetails-active'
                          >
                            Etapas
                          </NavLink>
                        </li>
                        <li className='ProjectDetails-item'>
                          <NavLink
                            to={{
                              pathname: `/agencias/proyectos/${this.props.match.params.id}/brief`,
                            }}
                            className='ProjectDetails-link'
                            activeClassName='ProjectDetails-active'
                          >
                            Brief del proyecto
                          </NavLink>
                        </li>
                        <li className='ProjectDetails-item'>
                          <NavLink
                            to={{
                              pathname: `/agencias/proyectos/${this.props.match.params.id}/pagos`,
                            }}
                            className='ProjectDetails-link'
                            activeClassName='ProjectDetails-active'
                          >
                            Pagos
                          </NavLink>
                        </li>
                      </ul>
                      {!this.state.isStarted && this.state.brief.status === 'Por iniciar' && <div className='ProjectAgencyStart-btn-wrapper'>
                        <button className='ProjectAgencyStart-btn' onClick={this.startProject}>Iniciar</button>
                      </div>}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <div className='ProjectDetails-stages-wrapper'>
                      {result}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <div className='row'>
                <div className='col-12'>
                  <Lottie options={defaultOptions} height={400} width={320} />
                </div>
              </div>
            )}
          </div>
        </section>
        <FooterAgency />
      </div>
    );
  }
}

export default ProjectAgencyDetails;
