import React, { useState, useEffect } from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { Formik } from 'formik';
import * as yup from 'yup';
import { withRouter } from 'react-router-dom';
import { firebase, database } from '../../firebase/firebase';
import Input from '../utils/forms/input/Input';
import ResetModal from '../modal/ResetModal';
import '../../css/payment-details/PaymentDetails.css';
import 'firebase/functions';

const functions = firebase.functions();

//Validación
const payment = yup.object().shape({
  name: yup.string().required('Campo obligatorio'),
});

const Checkout = (props) => {
  const {
    amount,
    id,
    userId,
    packageId,
    paymentId,
    userDetails,
    packageName,
    paymentNum,
  } = props;

  const [paymentDetails, setPaymentDetails] = useState({});
  const [packageDetails, setPackageDetails] = useState([]);
  const [clientSecret, setClientSecret] = useState('');
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [errorCardExp, setErrorCardExp] = useState(null);
  const [disabledCardExp, setDisabledCardExp] = useState(true);
  const [errorCardCvc, setErrorCardCvc] = useState(null);
  const [disabledCardCvc, setDisabledCardCvc] = useState(true);
  const [processing, setProcessing] = useState('');
  const [succeeded, setSucceeded] = useState(false);
  const [show, setShow] = useState(false);

  //Elements from stripe
  const elements = useElements();
  const stripe = useStripe();

  useEffect(() => {
    database
      .ref(`usersPayments/${userId}/${packageId}/${paymentId}`)
      .once('value')
      .then((snapshot) => {
        setPaymentDetails({ ...snapshot.val() });
      });

    database
      .ref(`usersPackages/${userId}/${packageId}`)
      .once('value')
      .then((snapshot) => {
        setPackageDetails({ ...snapshot.val() });
      });
  }, []);

  useEffect(() => {
    window
      .fetch('https://devapi.rhino.lat/create-payment-intent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ amount: amount * 100 }),
      })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setClientSecret(data.clientSecret);
      });
  }, []);

  const handleClose = () => {
    setShow(false);
    props.history.push(`/proyectos/${id}/pagos`);
  };

  //styles for Stripe components
  const cardNumStyle = {
    style: {
      base: {
        color: '#58595c',
        fontFamily: 'Ubuntu, sans-serif',
        fontSize: '19px',
        '::placeholder': {
          color: '#C2C2C2',
        },
      },
      invalid: {
        color: 'red',
      },
    },
  };

  const cardExpStyle = {
    style: {
      base: {
        color: '#58595c',
        fontFamily: 'Ubuntu, sans-serif',
        fontSize: '19px',
        '::placeholder': {
          color: '#C2C2C2',
        },
      },
      invalid: {
        color: 'red',
      },
    },
  };

  const cardCvcStyle = {
    style: {
      base: {
        color: '#58595c',
        fontFamily: 'Ubuntu, sans-serif',
        fontSize: '19px',
        '::placeholder': {
          color: '#C2C2C2',
        },
      },
      invalid: {
        color: 'red',
        iconColor: '#fa755a',
      },
    },
  };

  const handleChangeCardNum = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  const handleChangeCardExp = async (event) => {
    setDisabledCardExp(event.empty);
    setErrorCardExp(event.error ? event.error.message : '');
  };

  const handleChangeCardCvc = async (event) => {
    setDisabledCardCvc(event.empty);
    setErrorCardCvc(event.error ? event.error.message : '');
  };

  return (
    <>
      <Formik
        initialValues={{ name: '' }}
        validationSchema={payment}
        onSubmit={async (values, { setSubmitting }) => {
          if (!stripe || !elements) return;

          const payload = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
              card: elements.getElement(CardNumberElement),
              billing_details: {
                name: values.name,
              },
            },
          });
          if (payload.error) {
            setError(`El pago falló ${payload.error.message}`);
            setProcessing(false);
          } else {
            console.log(payload);
            setError(null);
            setProcessing(false);
            setSucceeded(true);
            database
              .ref(`usersPayments/${userId}/${packageId}/${paymentId}`)
              .update({
                isPaid: true,
                date: firebase.database.ServerValue.TIMESTAMP,
                isPending: false,
              })
              .then(() => {
                database
                  .ref('paymentsMade')
                  .push({
                    ...paymentDetails,
                    isPaid: true,
                    date: firebase.database.ServerValue.TIMESTAMP,
                    capture: payload.paymentIntent.capture_method,
                    type: payload.paymentIntent.payment_method_types[0],
                    currency: payload.paymentIntent.currency,
                    status: payload.paymentIntent.status,
                    userId: userId,
                    userName: userDetails.userName,
                    userEmail: userDetails.userEmail,
                    paidWith: 'Pago con tarjeta',
                    packageName,
                    paymentNum,
                    briefId: id,
                    paymentId,
                    isPending: false,
                  })
                  .then(() => {
                    setShow(true);
                  });

                const sendEmail = functions.httpsCallable('sendPaidEmail');
                sendEmail({
                  email: userDetails.userEmail,
                  idPurchase: paymentId,
                  clientName: userDetails.userName,
                });
              });
          }
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className='Brief-form-container-card'>
              <Input
                type='text'
                label='Nombre en la tarjeta'
                placeholder='Joe Doe'
                name='name'
                value={values.name}
                handleChange={handleChange}
                isTouched={touched.name}
                error={errors.name}
              />
            </div>
            <div className='Payment-imgs'>
              <div className='Payment-img Payment-img-1'>&nbsp;</div>
              <div className='Payment-img Payment-img-2'>&nbsp;</div>
              <div className='Payment-img Payment-img-3'>&nbsp;</div>
            </div>
            <p className='date-card-paragraph'>Numero tarjeta</p>
            <div
              style={{
                backgroundColor: '#fff',
                border: 'solid 1px #000',
                borderRadius: '4px',
                padding: '10px 16px',
                height: '50px',
              }}
              className='Checkout-styles'
            >
              <CardNumberElement
                options={cardNumStyle}
                onChange={handleChangeCardNum}
              />
            </div>
            <p
              className='date-card-paragraph'
              style={{ marginBottom: '24px', marginTop: '24px' }}
            >
              Fecha de vencimiento
            </p>
            <div className='Brief-form-container-card-inner'>
              <div
                style={{
                  backgroundColor: '#fff',
                  border: 'solid 1px #000',
                  borderRadius: '4px',
                  padding: '10px 16px',
                  height: '50px',
                }}
                className='PaymentDetails-checkout-expiry Checkout-styles'
              >
                <CardExpiryElement
                  options={cardExpStyle}
                  onChange={handleChangeCardExp}
                />
              </div>
              <div
                style={{
                  backgroundColor: '#fff',
                  border: 'solid 1px #000',
                  borderRadius: '4px',
                  padding: '10px 16px',
                  height: '50px',
                }}
                className='PaymentDetails-checkout-cvc Checkout-styles'
              >
                <CardCvcElement
                  options={cardCvcStyle}
                  onChange={handleChangeCardCvc}
                />
              </div>
            </div>
            {error !== null && (
              <p className='PaymentDetails-checkout-message'>{error}</p>
            )}
            <div className='PaymentDetails-checkout-btn'>
              <button
                disabled={
                  isSubmitting || disabled || disabledCardExp || disabledCardCvc
                }
                style={{
                  backgroundColor:
                    isSubmitting ||
                    disabled ||
                    disabledCardExp ||
                    disabledCardCvc
                      ? 'transparent'
                      : '#f47600',
                  color:
                    isSubmitting ||
                    disabled ||
                    disabledCardExp ||
                    disabledCardCvc
                      ? '#f47600'
                      : '#fff',
                }}
              >
                {isSubmitting ? 'PROCESANDO' : 'REALIZAR PAGO'}
              </button>
            </div>
          </form>
        )}
      </Formik>
      <ResetModal
        message={
          <>
            <span className='Transfer-modal-text'>
              Tu pago se realizó exitosamente.
            </span>
          </>
        }
        handleClose={handleClose}
        show={show}
      />
    </>
  );
};

export default withRouter(Checkout);
