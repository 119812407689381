import React, { Component } from 'react';
import uuid from 'uuid/v4';
import { database } from '../../../../firebase/firebase';
import { formValid } from '../../../../helpers/utils';
import WhiteContainer from '../../containers/whit-container/WhiteContainer';
import DashboardAccordion from '../../dashboard-accordion/DashboardAccordion';
import DeliverableDashboard from '../../deliverables/DeliverableDashboard';
import '../../../../css/dashboard/stages/create-stage/CreateStage.css';

class CreateStage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deliverables: [],
      isOpened: false,
      formErrors: {},
      updateMode: false,
      deliverablesToDelete: [],
    };
    this.addDeliverable = this.addDeliverable.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.isAValidForm = this.isAValidForm.bind(this);
    this.validateDeliverables = this.validateDeliverables.bind(this);
    this.isOpened = this.isOpened.bind(this);
  }

  onDelete(deliverable, index) {
    const newDeliverables = this.state.deliverables;
    newDeliverables.splice(index, 1);
    const deliverablesToDelete = (deliverable.deliverableKey)
      ? [...this.state.deliverablesToDelete, deliverable.deliverableKey]
      : this.state.deliverablesToDelete;

    this.setState({
      deliverables: newDeliverables,
      deliverablesToDelete,
    });
  }

  initDeliverables(deliverableArray) {
    this.setState({ updateMode: true });
    if (!deliverableArray) {
      return;
    }
    Object.entries(deliverableArray).forEach(([key, values]) => {
      this.addDeliverable(key, values);
    });
  }

  addDeliverable(key = uuid().toString, values = { deliverableName: '', deliverableDesc: '', deliverableDuration: '' }) {
    const deliverableName = `deliverableName${key}`;
    const deliverableDuration = `deliverableDuration${key}`;
    const deliverableDesc = `deliverableDesc${key}`;
    this.setState((prevState) => ({
      deliverables: [
        ...prevState.deliverables,
        {
          deliverableName,
          deliverableDesc,
          deliverableDuration,
          deliverableKey: (values.deliverableName !== '') ? key : undefined,
        },
      ],
      [deliverableName]: values.deliverableName,
      [deliverableDesc]: values.deliverableDesc,
      [deliverableDuration]: values.deliverableDuration,
      formErrors: {
        [deliverableName]: '',
        [deliverableDesc]: '',
        [deliverableDuration]: '',
      },
    }));
  }

  handleChange(e) {
    const { name, value } = e.target;
    const { formErrors } = this.state;
    this.state.deliverables.forEach((deliverable) => {
      if (name === deliverable.deliverableName) {
        formErrors[deliverable.deliverableName] = value.length === 0 ? 'Campo obligatorio' : '';
      }
      if (name === deliverable.deliverableDesc) {
        formErrors[deliverable.deliverableDesc] = value.length === 0 ? 'Campo obligatorio' : '';
      }
      if (name === deliverable.deliverableDuration) {
        formErrors[deliverable.deliverableDuration] = value.length === 0 ? 'Campo obligatorio' : '';
      }
    });

    this.setState({ formErrors, [name]: value });
  }

  validateDeliverables() {
    const { formErrors } = this.state;

    this.state.deliverables.forEach((deliverable) => {
      if (this.state[deliverable.deliverableName] === '') {
        formErrors[deliverable.deliverableName] = 'Campo obligatorio';
      }

      if (this.state[deliverable.deliverableDesc] === '') {
        formErrors[deliverable.deliverableDesc] = 'Campo obligatorio';
      }

      if (this.state[deliverable.deliverableDuration] === '') {
        formErrors[deliverable.deliverableDuration] = 'Campo obligatorio';
      }
    });

    this.setState((prevState) => ({
      formErrors: { ...prevState.formErrors, ...formErrors },
    }));
  }

  isAValidForm() {
    return formValid(this.state.formErrors);
  }

  sendDataToDatabase(id) {
    if (this.state.deliverables.length > 0) {
      this.state.deliverables.forEach((deliverable) => {
        const deliverableValues = {
          deliverableName: this.state[deliverable.deliverableName].trim(),
          deliverableDesc: this.state[deliverable.deliverableDesc].trim(),
          deliverableDuration: this.state[deliverable.deliverableDuration].trim(),
        };
        if (deliverable.deliverableKey) {
          database.ref(`deliverables/${deliverable.deliverableKey}`)
            .update(deliverableValues);
        } else {
          database.ref('deliverables').push({
            stageId: id,
            ...deliverableValues,
          });
        }
      });
    }

    if (this.state.deliverablesToDelete.length > 0) {
      this.state.deliverablesToDelete.forEach((key) => {
        database.ref(`deliverables/${key}`).remove();
      });
    }
  }

  isOpened() {
    this.setState({ isOpened: !this.state.isOpened });
  }

  render() {
    const {
      stageName, stageValue, stageNum, handleChange, customError, onDelete,
    } = this.props;

    const deliverables = this.state.deliverables.map((deliverable, index) => (
      <DeliverableDashboard
        key={index}
        num={index + 1}
        deliverableName={deliverable.deliverableName}
        value={this.state[`${deliverable.deliverableName}`]}
        deliverableDuration={deliverable.deliverableDuration}
        valueDuration={this.state[`${deliverable.deliverableDuration}`]}
        deliverableDesc={deliverable.deliverableDesc}
        valueDesc={this.state[`${deliverable.deliverableDesc}`]}
        errorDeliverableName={this.state.formErrors[`${deliverable.deliverableName}`]}
        errorDeliverableDesc={this.state.formErrors[`${deliverable.deliverableDesc}`]}
        errorDeliverableDuration={this.state.formErrors[`${deliverable.deliverableDuration}`]}
        handleChange={this.handleChange}
        isOpened={this.state.isOpened}
        onDeletePressed={() => this.onDelete(deliverable, index)}
      />
    ));

    return (
      <div className="CreateStage">
        <WhiteContainer>
          <DashboardAccordion
            num={stageNum}
            name={stageName}
            value={stageValue}
            handleChange={handleChange}
            click={this.addDeliverable}
            data={deliverables}
            isOpened={this.state.isOpened}
            handleOpen={this.isOpened}
            label="Nombre"
            type="text"
            placeholder="Desarrollo de Marca"
            title="Etapa"
            innerTitle="Entregables"
            addText="Agregar entregable"
            customError={customError}
            onDeletePressed={onDelete}
          />
        </WhiteContainer>
      </div>
    );
  }
}

export default CreateStage;
