import React from 'react';
import { Link } from 'react-router-dom';
import '../../../../css/dashboard/users/single-user/SingleUser.css';

const SingleUser = ({ email, id, userName }) => (
  <Link to={`/rh-admin/dashboard/usuario-detalles/${id}`} className="SingleUser-link">
    <div className="SingleUser">
      <div><p className="SingleUser-title">{userName}</p></div>
      <div><p className="SingleUser-title">{email}</p></div>
    </div>
  </Link>
);

export default SingleUser;
