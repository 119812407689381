import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { firebase, database } from '../../../firebase/firebase';
import Project from '../project-components/Project';
import DropdownFilter from '../../utils/dropdownFilter/DropdownFilter';
import Navbar from '../../navbar/Navbar';
import NavbarBg from '../../navbar/NavbarBg';
import Footer from '../../footer/Footer';
import '../../../css/projects/projectsUser/ProjectList.css';

class ProjectList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      briefs: [],
      isLoaded: false,
      isClicked: false,
      isFinished: false,
      isDeliverablesLoaded: false,
      selectedOption: undefined,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let briefs = [];
    let items = [];

    database
      .ref('briefs')
      .orderByChild('userId')
      .equalTo(`${firebase.auth().currentUser.uid}`)
      .once('value')
      .then((snapshot) => {
        snapshot.forEach((childSnapshot) => {
          briefs.push({
            id: childSnapshot.key,
            ...childSnapshot.val(),
          });
        });
        this.setState({ briefs: [...briefs] });
        this.state.briefs.forEach((brief) => {
          let deliverables = [];
          let payments = [];
          database
            .ref(`usersDeliverables/${brief.userId}/${brief.packageId}`)
            .once('value')
            .then((snapshot) => {
              snapshot.forEach((childSnap) => {
                for (let [key1, value1] of Object.entries(childSnap.val())) {
                  for (let [key2, value2] of Object.entries(value1)) {
                    if (value2.mgs) {
                      let msg = [];
                      for (let [key3, value3] of Object.entries(value2.mgs)) {
                        msg.push(key3);
                      }
                      deliverables.push({
                        mgsCount: msg.length,
                        status: value2.status,
                        msg: value2.agencyCountMgs,
                      });
                    } else {
                      deliverables.push({
                        status: value2.status,
                        msg: value2.agencyCountMgs,
                      });
                    }
                  }
                }
              });
            });

          database
            .ref(`usersPayments/${brief.userId}/${brief.packageId}`)
            .orderByChild('isPending')
            .equalTo(true)
            .once('value')
            .then((paySnap) => {
              paySnap.forEach((pay) => {
                payments.push({
                  id: pay.key,
                });
              });
            });

          database
            .ref(`usersPackages/${brief.userId}/${brief.packageId}`)
            .once('value')
            .then((snapshot) => {
              items.push({
                id: snapshot.key,
                ...snapshot.val(),
                purchasedDate: brief.purchaseDate,
                status: payments.length > 0 ? 'Pendiente' : brief.status,
                briefId: brief.id,
                deliverables: [...deliverables],
              });
              this.setState({ items: [...items], isLoaded: true });
            });
        });
      })
      .catch((e) => {
        console.log(`Error fetching data: ${e}`);
      });
    this.setState({ isFinished: true });
  }

  handleChange(e) {
    this.setState({ selectedOption: e.target.value });
  }

  handleClick() {
    this.setState({ isClicked: !this.state.isClicked });
  }

  render() {
    let result;
    let projects = [];

    if (
      this.state.selectedOption !== undefined &&
      this.state.selectedOption !== 'Todo'
    ) {
      const selectedOption = this.state.items.filter(
        (item) => item.status === this.state.selectedOption
      );
      projects = selectedOption.map((option) => (
        <Project
          key={option.id}
          name={option.packageName}
          date={option.purchasedDate}
          status={option.status}
          id={option.id}
          briefId={option.briefId}
          pathUrl={`/proyectos/${option.briefId}/etapas`}
          deliverables={option.deliverables}
        />
      ));
    } else if (
      this.state.selectedOption !== undefined &&
      this.state.selectedOption === 'Todo'
    ) {
      projects = this.state.items.map((item) => (
        <Project
          key={item.id}
          name={item.packageName}
          date={item.purchasedDate}
          status={item.status}
          id={item.id}
          briefId={item.briefId}
          pathUrl={`/proyectos/${item.briefId}/etapas`}
          deliverables={item.deliverables}
        />
      ));
    } else {
      projects = this.state.items.map((item) => (
        <Project
          key={item.id}
          name={item.packageName}
          date={item.purchasedDate}
          status={item.status}
          id={item.id}
          briefId={item.briefId}
          pathUrl={`/proyectos/${item.briefId}/etapas`}
          deliverables={item.deliverables}
        />
      ));
    }

    if (this.state.isLoaded) {
      result = (
        <div>
          <Navbar />
          <NavbarBg />
          <section className='ProjectList'>
            <div className='ProjectList-container'>
              <div className='container ProjectListContainer'>
                <div className='row'>
                  <div className='col-12'>
                    <div className='ProjectList-header-container'>
                      <div className='ProjectList-title'>
                        <h3
                          style={{
                            color: '#58595c',
                            fontWeight: 500,
                            marginBottom: 0,
                          }}
                        >
                          Mis proyectos
                        </h3>
                      </div>
                      <div className='ProjectList-dropdown'>
                        <DropdownFilter
                          handleClick={this.handleClick}
                          isClicked={this.state.isClicked}
                          selectedOption={this.state.selectedOption}
                          handleChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>{projects}</div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      );
    } else {
      result = (
        <div>
          <Navbar />
          <NavbarBg />
          <section className='ProjectList'>
            <div className='ProjectList-container'>
              <div className='container'>
                <div className='row'>
                  <div className='col-12'>
                    <div className='ProjectList-title'>
                      <h3
                        style={{
                          color: '#58595c',
                          fontWeight: 500,
                          marginBottom: 0,
                        }}
                      >
                        Mis proyectos
                      </h3>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='ProjectList-paragraph-container'>
                      <p
                        className='ProjectList-paragraph'
                        style={{ color: '#f47600', marginBottom: 0 }}
                      >
                        Aún no tienes proyectos
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      );
    }

    return result;
  }
}

export default withRouter(ProjectList);
