import React, { Component } from 'react';
import '../../../css/categories/userInterface/Header.css';
import PackageName from './PackageName';

class Header extends Component {
  render() {
    return (
      <header className="Header-categories">
        <div className="Header-categories-bg-grey">&nbsp;</div>
        <div className="Header-categories-island">&nbsp;</div>
        <div className="Header-categories-details" style={{ width: '100%', height: '100%' }}>
          <div className="container" style={{ width: '100%', height: '100%' }}>
            <div className="row justify-content-end" style={{ width: '100%', height: '100%' }}>
              <div className="col-6 align-self-center">
                <PackageName package={this.props.category} details={this.props.description} />
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
