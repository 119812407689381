import React, { Component } from 'react';
import { database, firebase } from '../../../firebase/firebase';
import AgencyPayment from './AgencyPayment';
import '../../../css/projects/projectsUser/Payments.css';

class AgencyPayments extends Component {
  constructor(props) {
    super(props);
    this.state = { brief: {}, payments: [], isLoaded: false };
  }

  componentDidMount() {
    let payments = [];
    database
      .ref(`briefs/${this.props.id}`)
      .once('value')
      .then((snap) => {
        this.setState({ brief: { id: snap.key, ...snap.val() } });
        database
          .ref(
            `usersPayments/${snap.val().userId}/${
              snap.val().packageId
            }`
          )
          .once('value')
          .then((childSnap) => {
            childSnap.forEach((child) => {
              payments[child.val().indexOf] = {
                id: child.key,
                ...child.val(),
              };
            });
            this.setState({ payments: [...payments], isLoaded: true });
          });
      });
  }

  render() {
    let payments = [];
    let stagesIds = [];
    let result = null;

    if (this.state.isLoaded) {
      payments = this.state.payments.map((payment, index) => (
        <AgencyPayment
          key={payment.id}
          num={index + 1}
          date={payment.date}
          amount={payment.amount}
          isPaid={payment.isPaid}
          stagesIds={stagesIds}
          obj={payment}
          id={this.state.brief.id}
          userId={this.state.brief.userId}
          packageId={this.state.brief.packageId}
        />
      ));

      result = (
        <div
          className='Payments-project'
          style={{
            gridTemplateRows: `repeat(${this.state.payments.length}, 1fr)`,
          }}
        >
          {payments}
        </div>
      );
    }

    return result;
  }
}

export default AgencyPayments;
