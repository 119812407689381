const data = [
  {
    question: '¿Cuál es la diferencia entre Rhino.lat y los otros servicios?',
    answer: 'Rhino.lat te vincula con clientes que ya realizaron la compra, y tenemos alcance a nivel internacional 100% en tu idioma.',
  },
  {
    question: '¿Cuánto cuesta?',
    answer: 'Te cobramos el 25% de comisión sobre el proyecto',
  },
  {
    question: '¿Cuánto tiempo me tardo en obtener clientes?',
    answer: 'Desde el primer día que eres aceptado esta disponible en la plataforma para empezar a recibir propuestas',
  },
  {
    question: '¿Qué tan seguros son los proyectos?',
    answer: 'Te aseguramos recibir el pago a la entrega final de cada Etapa del proyecto.',
  },
  {
    question: '¿Cómo seleccionas a las mejores agencias?',
    answer: 'Tenemos un proceso de selección que se basa en 3 puntos: 1) Experiencia y especialización. 2) Infraestructura y equipo. 3) Capacidad destinada a Rhino.lat (eso tu lo decides).',
  },
];

export default data;
