import React from 'react';
import '../../../css/utils/buttons/ButtonOnClick.css';

const ButtonOnClick = (props) => {
  const { text, click, isDisabled } = props;
  return (
    <div className='Button-anim-container'>
      <button
        disabled={isDisabled}
        className='Button-anim Button-anim-hvr-shutter-out-horizontal'
        onClick={click}
      >
        {text}
      </button>
    </div>
  );
};

export default ButtonOnClick;
