import React, { Component } from 'react';
import Ball from './Ball';
import Line from './Line';
import Horn from './Horn';
import '../../../css/utils/progress/ProgressBar.css';

class ProgressBar extends Component {
    render() {
        return(
            <div className='ProgressBar-container'>
                <ul className='ProgressBar-list'>
                    <li className='ProgressBar-item'>Sobre <span>tu marca</span></li>
                    <li className='ProgressBar-item'>Percepción</li>
                    <li className='ProgressBar-item'>Competencia</li>
                    <li className='ProgressBar-item'>Presencia <span>Online</span></li>
                    <li className='ProgressBar-item'>Sobre <span>el proyecto</span></li>
                    <li className='ProgressBar-item'>Números</li>
                    <li className='ProgressBar-item'>Último sorbo</li>
                    <li className='ProgressBar-item'>Pago</li>
                </ul>
                <div className='ProgressBar'>&nbsp;</div>
                <Horn horn={this.props.horn}/>
                <div className='ProgressBar-ball-container'>
                    <Ball stepBall={this.props.sOne}/>
                    <Line line={this.props.lineOne}/>
                    <Ball stepBall={this.props.sTwo}/>
                    <Line line={this.props.lineTwo}/>
                    <Ball stepBall={this.props.sThree}/>
                    <Line line={this.props.lineThree}/>
                    <Ball stepBall={this.props.sFour}/>
                    <Line line={this.props.lineFour}/>
                    <Ball stepBall={this.props.sFive}/>
                    <Line line={this.props.lineFive}/>
                    <Ball stepBall={this.props.sSix}/>
                    <Line line={this.props.lineSix}/>
                    <Ball stepBall={this.props.sSeven}/>
                    <Line line={this.props.lineSeven}/>
                    <Ball stepBall={this.props.sEight}/>
                </div>
            </div>
        );
    }
}

export default ProgressBar;