import React, { Component } from 'react';
import Lottie from 'react-lottie';
import { NavLink, Link, withRouter } from 'react-router-dom';
import ArrowExit from '../../../icons/exit_icon.svg';
import { database } from '../../../firebase/firebase';
import * as animationData from '../../../lottie/lottie_loading.json';
import ProjectStagesList from '../project-components/ProjectStagesList';
import PaymentsDashboard from '../projectsAdmin/PaymentsDashboard';
import DashboardTitle from '../../dashboard/titles/DashboardTitle';
import '../../../css/projects/projectsUser/ProjectDetails.css';
import '../../../css/projects/projectsAdmin/ProjectDashboardDetails.css';

class ProjectDashboardDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { stages: [], brief: {}, isLoaded: false };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let stages = [];
    let brief = {};
    database
      .ref(`briefs/${this.props.match.params.id}`)
      .once('value')
      .then((snap) => {
        database
          .ref(
            `usersPackages/${snap.val().userId}/${
              snap.val().packageId
            }`
          )
          .once('value')
          .then((childSnap) => {
            database
              .ref(
                `usersStages/${snap.val().userId}/${
                  snap.val().packageId
                }`
              )
              .once('value')
              .then((child) => {
                child.forEach((childChild) => {
                  stages.push({
                    id: childChild.key,
                    ...childChild.val(),
                  });
                  brief = {
                    id: snap.key,
                    ...snap.val(),
                    packageDetails: { ...childSnap.val() },
                  };
                });
                this.setState({
                  stages: [...stages],
                  brief: { ...brief },
                  isLoaded: true,
                });
              });
          });
      });
  }

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData.default,
    };

    let result;
    switch (this.props.match.params.sec) {
      case 'etapas':
        result = (
          <ProjectStagesList
            stages={this.state.stages}
            id={this.props.match.params.id}
            packageId={this.state.brief.packageId}
            userId={this.state.brief.userId}
            partUrl='/rh-admin/dashboard/proyectos-detalles'
          />
        );
        break;
      case 'pagos':
        result = (
          <PaymentsDashboard brief={this.state.brief} id={this.props.match.params.id} />
        );
        break;
      default:
        result = <h3>Página no encontrada</h3>;
        break;
    }
    return (
        <section>
          <div className='container'>
            <div className='row'>
              <Link to='/rh-admin/dashboard/proyectos' className='ProjectDetails-exit-link'>
                <div className='ProjectDetails-exit-icon'>
                  <div className='ArrowExit'>
                    <img src={ArrowExit} alt='icono' />
                  </div>
                  <p>Salir</p>
                </div>
              </Link>
            </div>
            {this.state.isLoaded ? (
              <React.Fragment>
                <div className='row'>
                  <div className='col-12'>
                    <div className='ProjectDetails-title'>
                      {this.state.isLoaded && <DashboardTitle title={`Paquete Rhino ${this.state.brief.packageDetails.packageName}`}/>}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <div className='ProjectDetails-options-dash'>
                      <ul className='ProjectDetails-list-dash'>
                        <li className='ProjectDetails-item-dash'>
                          <NavLink
                            to={{
                              pathname: `/rh-admin/dashboard/proyectos/${this.props.match.params.id}/etapas`,
                            }}
                            className='ProjectDetails-link-dash'
                            activeClassName='ProjectDetails-active-dash'
                          >
                            Etapas
                          </NavLink>
                        </li>
                        <li className='ProjectDetails-item-dash'>
                          <NavLink
                            to={{
                              pathname: `/rh-admin/dashboard/proyectos/${this.props.match.params.id}/pagos`,
                            }}
                            className='ProjectDetails-link-dash'
                            activeClassName='ProjectDetails-active-dash'
                          >
                            Pagos
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <div className='ProjectDetails-stages-wrapper ProjectDetails-stages-wrapper-dash'>
                      {result}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <div className='row'>
                <div className='col-12'>
                  <Lottie options={defaultOptions} height={400} width={320} />
                </div>
              </div>
            )}
          </div>
        </section>
    );
  }
}

export default withRouter(ProjectDashboardDetails);
