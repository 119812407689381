import React, { useEffect, useState } from 'react';
import { database } from '../../firebase/firebase';
import WhiteContainer from './containers/whit-container/WhiteContainer';
import AddLink from '../utils/links/AddLink';
import DashboardPackage from './dashboard-package/DashboardPackage';
import '../../css/dashboard/DashboardPackageList.css';

const DashboardPackageList = () => {
  const [packages, setPackages] = useState([]);

  const deletePackage = (id) => {
    if (window.confirm('¿Deseas borrar este Plan?')) {
      database.ref(`packages/${id}`).remove().then(() => {
        setPackages(packages.filter((plan) => plan.id !== id));
      });
    }
  };

  useEffect(() => {
    const newPackages = [];
    database
      .ref('packages')
      .once('value')
      .then((snapshot) => {
        snapshot.forEach((snapChild) => {
          newPackages.push({
            id: snapChild.key,
            packageName: snapChild.val().packageName,
          });
        });
        setPackages([...newPackages]);
      });
  }, []);

  const packageList = packages.map((p) => (
    <DashboardPackage
      key={p.id}
      packageName={p.packageName}
      id={p.id}
      deletePackage={deletePackage}
    />
  ));

  return (
    <WhiteContainer>
      <div className="DashboardPackageList-Link-wrapper">
        <div>
          <AddLink text="Nuevo plan" path="/rh-admin/dashboard/create-package" />
        </div>
      </div>
      <div className="DashboardPackageList-linebreak">&nbsp;</div>
      <div className="DashboardPackageList-list">{packageList}</div>
    </WhiteContainer>
  );
};

export default DashboardPackageList;
