import React from 'react';
import { Accordion, Button, Card } from 'react-bootstrap';
import Input from '../../utils/forms/input/Input';
import Add from '../../utils/buttons/Add';
import OpenArrow from '../../../icons/arrow-open-package.svg';
import CloseArrow from '../../../icons/arrow-close-package.svg';
import '../../../css/dashboard/dashboard-accordion/DashboardAccordion.css';
import { Copy, Trash } from 'react-feather';

const DashboardAccordion = (props) => {
  const {
    name,
    value,
    num,
    handleChange,
    click,
    data,
    isOpened,
    handleOpen,
    type,
    label,
    placeholder,
    title,
    innerTitle,
    addText,
    customError,
    onDeletePressed,
    onCopy,
  } = props;
  return (
    <Accordion defaultActiveKey="0">
      <Card className="DashboardAccordion-card">
        <Accordion.Toggle
          as={Card.Header}
          eventKey="0"
          className="DashboardAccordion-header"
          onClick={handleOpen}
        >
          <div className="DashboardAccordionMain">
            <p className="DashboardAccordion-title">
              <img
                src={!isOpened ? OpenArrow : CloseArrow}
                alt=">"
                style={{ marginRight: 11 }}
              />
              {title}
              {' '}
              {num}
            </p>
            <div>
              {onCopy && (
              <Button
                variant="outline-success"
                onClick={onCopy}
              >
                <Copy size="15" />
                {' '}
                Duplicar
              </Button>
              )}
              {onDeletePressed && <Button variant="outline-danger" onClick={onDeletePressed}><Trash size="15" /></Button>}
            </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body className="DashboardAccordion-card-body">
            <div className="DashboardAccordion-input-wrapper">
              <Input
                type={type}
                label={label}
                placeholder={placeholder}
                name={name}
                value={value}
                handleChange={handleChange}
                customError={customError}
              />
            </div>
            <div className="DashboardAccordion-deliverables">
              <p className="DashboardAccordion-deliverables-title">
                {innerTitle}
              </p>
            </div>
            {data}
            <div>
              <Add text={addText} click={click} />
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default DashboardAccordion;
