import React, { Component } from 'react';
import Lottie from 'react-lottie';
import ArrowExit from '../../../icons/exit_icon.svg';
import { database } from '../../../firebase/firebase';
import * as animationData from '../../../lottie/lottie_loading.json';
import EntregableOptions from '../projectsAgency/EntregableOptions';
import NavbarBg from '../../navbar/NavbarBg';
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';
import StageModal from '../../modal/StageModal';
import ButtonOnClick from '../../utils/buttons/ButtonOnClick';
import DownloadFile from '../../../icons/download.svg';
import '../../../css/projects/projectsAgency/EntregableDetails.css';
import '../../../css/projects/projectsUser/EntregableDetailsUser.css';

class EntregableDetailsUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deliverable: {}, fileURL: '', show: false, isLoaded: false,
    };
    this.goBack = this.goBack.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.setStatus = this.setStatus.bind(this);
    this.finishStage = this.finishStage.bind(this);
  }

  componentDidMount() {
    database
      .ref(`usersDeliverables/${this.props.match.params.userId}/${this.props.match.params.packageId}/${this.props.match.params.stageId}/${this.props.match.params.payment}/${this.props.match.params.deliverableId}`)
      .on('value', (snapshot) => {
        this.setState({ deliverable: { id: snapshot.key, ...snapshot.val() }, isLoaded: true });
      });
  }

  componentWillUnmount() {
    database
      .ref(`usersDeliverables/${this.props.match.params.userId}/${this.props.match.params.packageId}/${this.props.match.params.stageId}/${this.props.match.params.payment}/${this.props.match.params.deliverableId}`)
      .off();
  }

  goBack() {
    this.props.history.goBack();
  }

  setStatus() {
    database.ref(`usersDeliverables/${this.props.match.params.userId}/${this.props.match.params.packageId}/${this.props.match.params.stageId}/${this.props.match.params.payment}/${this.props.match.params.deliverableId}`).update({
      status: 'En proceso',
    });
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleClose() {
    this.setState({ show: false });
  }

  finishStage() {
    database.ref(`usersDeliverables/${this.props.match.params.userId}/${this.props.match.params.packageId}/${this.props.match.params.stageId}/${this.props.match.params.payment}/${this.props.match.params.deliverableId}`).update({
      status: 'Finalizado',
    });
    this.setState({ show: false });
  }

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData.default,
    };
    let deliveryDate = null;
    let url = null;
    let fileName = null;

    if (
      this.state.deliverable.deliveryDate !== undefined && this.state.deliverable.deliveryDate !== ''
    ) {
      deliveryDate = this.state.deliverable.deliveryDate;
    } else {
      deliveryDate = null;
    }

    if (
      this.state.deliverable.pathUrl !== undefined && this.state.deliverable.pathUrl !== ''
    ) {
      url = this.state.deliverable.pathUrl;
    } else {
      url = null;
    }

    if (
      this.state.deliverable.fileName !== undefined && this.state.deliverable.fileName !== ''
    ) {
      fileName = this.state.deliverable.fileName;
    } else {
      fileName = null;
    }

    return (
      <div>
        <Navbar />
        <NavbarBg />
        <section className="EntregableDetails EntregableDetailsUser">
          <div className="container">
            <div className="row">
              <div
                className="ProjectDetails-exit-icon"
                onClick={this.goBack}
                style={{ cursor: 'pointer' }}
              >
                <div className="ArrowExit">
                  <img src={ArrowExit} alt="icono" />
                </div>
                <p>Salir</p>
              </div>
            </div>
            {this.state.isLoaded ? (
              <>
                <div className="row">
                  <div className="col-12">
                    <div className="ProjectDetails-title">
                      <h3>{this.state.deliverable.deliverableName}</h3>
                    </div>
                  </div>
                  <div className="col-12">
                    <div>
                      <EntregableOptions
                        desc={this.state.deliverable.deliverableDesc}
                        options={this.state.deliverable.deliverableList}
                        status={this.state.deliverable.status}
                        deliveryDate={deliveryDate}
                        file={url}
                        fileName={fileName}
                        icon={DownloadFile}
                      />
                    </div>
                  </div>
                  {this.state.isLoaded ? (
                    <div className="col-12">
                      {this.state.deliverable.status !== 'Por iniciar' ? (
                        this.state.deliverable.status !== 'Finalizado' ? (
                          <div className="EntregableDetailsUser-btn-container">
                            <ButtonOnClick
                              text="FINALIZAR"
                              click={this.handleShow}
                              isDisabled={fileName === null}
                            />
                          </div>
                        ) : null
                      ) : (
                        <div className="EntregableDetailsUser-btn-container">
                          <ButtonOnClick text="INICIAR" click={this.setStatus} />
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>
              </>
            ) : (
              <div className="row">
                <div className="col-12">
                  <Lottie options={defaultOptions} height={400} width={320} />
                </div>
              </div>
            )}
          </div>
          <StageModal
            show={this.state.show}
            handleClose={this.handleClose}
            finishStage={this.finishStage}
          />
        </section>
        <Footer />
      </div>
    );
  }
}

export default EntregableDetailsUser;
