import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import '../../css/navbar/DropDownAgency.css';

const DropDownAgency = (props) => {
  return (
    <Dropdown>
      <Dropdown.Toggle className='DropdownAgency' style={{ fontWeight: 600 }}>
        {props.userName}
      </Dropdown.Toggle>
      <Dropdown.Menu className='Dropdown-menu-agency'>
        <div className='Dropdown-list-agency'>
          <div className='Dropdown-item-container-agency'>
            <Link to='/agencias/proyectos' className='Dropdown-link-agency'>
              Mis proyectos
            </Link>
          </div>
          <div className='Dropdown-item-container-agency'>
            <Link
              to={{ pathname: `/agencias/perfil/${props.id}` }}
              className='Dropdown-link-agency'
            >
              Mi perfil
            </Link>
          </div>
          <div className='Dropdown-item-container-agency'>
            <button
              className='Dropdown-btn-agency'
              onClick={props.handleLogOut}
            >
              Cerrar Sesión
            </button>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropDownAgency;
