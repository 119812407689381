import React, { Component } from 'react';
import StageDetails from './StageDetails';
import '../../../css/projects/project-components/ProjectStagesList.css';

class ProjectStagesList extends Component {
  constructor(props) {
    super(props);
    this.state = { stages: [...this.props.stages] };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const stages = this.state.stages.map((stage, index) => (
      <StageDetails
        key={stage.id}
        stage={`Etapa ${index + 1}`}
        stageName={stage.stageName}
        id={this.props.id}
        stageId={stage.id}
        packageId={this.props.packageId}
        userId={this.props.userId}
        pathUrl={`${this.props.partUrl}/${this.props.id}/${this.props.packageId}/${this.props.userId}/etapas/${stage.id}`}
      />
    ));
    return (
      <div
        className='ProjectStagesList'
        style={{ gridTemplateRows: `repeat(${this.state.stages.length}, 1fr)` }}
      >
        {stages}
      </div>
    );
  }
}

export default ProjectStagesList;
