import React from 'react';
import Input from '../../../utils/forms/input/Input';
import Textarea from '../../../utils/forms/textarea/Textarea';
import Select from '../../../utils/forms/select/Select';
import { validateFields } from '../../../../helpers/utils';
import ButtonOnClick from '../../../utils/buttons/ButtonOnClick';
import '../../../../css/brief/BriefForms.css';

const StepSix = (props) => {
  const {
    next,
    back,
    handleChange,
    projectObjectives,
    hasWorkedWAgency,
    agencyWorked,
    errorProjectObj,
  } = props;

  const fieldsValues = [projectObjectives];

  return (
    <div>
      <form>
        <div className='Brief-form-container'>
          <Textarea
            type='text'
            label='¿Cuáles son los objetivos de negocio para este proyecto?'
            placeholder='Objetivos'
            value={projectObjectives}
            name='projectObjectives'
            handleChange={handleChange}
            customError={errorProjectObj}
            message='Ejemplo: Aumentar prospectos, porcentajes, ganancias, etc.'
          />
        </div>
        <div className='Brief-form-container'>
          <Select
            label='¿Anteriormente trabajaste con alguna agencia?'
            value={hasWorkedWAgency}
            name='hasWorkedWAgency'
            handleChange={handleChange}
            options={['Si', 'No']}
          />
        </div>
        {hasWorkedWAgency === 'Si' && (
          <div className='Brief-form-container'>
            <Input
              type='text'
              label='¿Cual?'
              placeholder='Textiles John Doe'
              value={agencyWorked}
              name='agencyWorked'
              handleChange={handleChange}
            />
          </div>
        )}
      </form>
      <div className='Step-button-container two-btns'>
        <ButtonOnClick text='ATRÁS' click={back} />
        <ButtonOnClick
          text='CONTINUAR'
          click={next}
          isDisabled={validateFields(fieldsValues)}
        />
      </div>
    </div>
  );
};

export default StepSix;
