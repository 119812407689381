import React, {useState, useEffect} from 'react';
import { database } from '../../../firebase/firebase';
import { withRouter } from 'react-router-dom';
import DashboardTitle from '../titles/DashboardTitle';
import DashboardSubtitle from '../titles/DashboardSubtitle';
import About from './forms/About';
import Perception from './forms/Perception'
import Competitor from './forms/Competitor';
import Online from './forms/Online';
import AboutProject from './forms/AboutProject';
import Numbers from './forms/Numbers';
import '../../../css/dashboard/brief-dash/BriefDashboard.css';

const BriefDashboard = (props) => {
    const [brief, setBrief] = useState({})
    const [num, setNum] = useState(1);
    let result = null;
    let buttons = [];

    useEffect(() => {
        database
        .ref(`briefs/${props.match.params.id}`)
        .once('value')
        .then(snapshot => {
            setBrief({id: snapshot.key, ...snapshot.val()});
        });
    }, [props.match.params.id])

    const changeNum = (num) => {
        setNum(num);
    }

    const prev = () => {
        setNum(num - 1);
    }

    const next = () => {
        setNum(num + 1);
        
    }

    if(num <= 5) {
        for(let i = 0; i < 5; i++) {
            buttons.push(
                <button 
                key={i} 
                className='BriefDashboard-btns' 
                onClick={() => changeNum(i+1)}
                style={{
                    backgroundColor: num === i + 1 ? '#f47600' : 'transparent',
                    color: num === i + 1 ? '#fff' : '#f47600'
                }}
                >
                    {i+1}
                </button>
            )
        }
    } else if( num <= 6) {
        for(let i = 1; i < 6; i++) {
            buttons.push(
                <button 
                key={i} 
                className='BriefDashboard-btns' 
                onClick={() => changeNum(i+1)}
                style={{
                    backgroundColor: num === i + 1 ? '#f47600' : 'transparent',
                    color: num === i + 1 ? '#fff' : '#f47600'
                }}
                >
                    {i+1}
                </button>
            )
        }
    } else {
        for(let i = 2; i < 7; i++) {
            buttons.push(
                <button 
                key={i} 
                className='BriefDashboard-btns' 
                onClick={() => changeNum(i+1)}
                style={{
                    backgroundColor: num === i + 1 ? '#f47600' : 'transparent',
                    color: num === i + 1 ? '#fff' : '#f47600'
                }}
                >
                    {i+1}
                </button>
            )
        }
    }

    if(num === 1) {
        result = (
            <section>
                <DashboardTitle title='Datos de registro' />
                <div style={{padding: '40px 0 26px 0'}}><DashboardSubtitle title='Sobre tu marca/empresa' /></div>
                <About 
                    info={[
                        {label: '¿Cómo se llama tu marca/empresa?', answer: brief.companyName},
                        {label: '¿Qué slogan o frase de venta tiene?', answer: brief.slogan},
                        {label: 'Describe a qué se dedica tu marca:', answer: brief.companyInfo},
                        {label: 'Haz una lista de las fortalezas de la marca:', answer: brief.strengths, isText: true},
                        {label: 'Haz una lista de las debilidades de la marca:', answer: brief.weaknesses, isText: true}
                    ]}
                />
                <div className='BriefDashboard-btn-wrapper'>
                    <button 
                    className='BriefDashboard-other-btns' 
                    style={{color: num === 1 ? '#000' : '#f47600', opacity: num === 1 ? 0.25 : 1}} 
                    disabled={num === 1}
                    >
                        Anterior
                    </button>
                    {buttons}
                    <button 
                    className='BriefDashboard-other-btns' 
                    style={{color: num === 7 ? '#000' : '#f47600', opacity: num === 7 ? 0.25 : 1}} 
                    disabled={num === 7} 
                    onClick={next}
                    >
                        Siguiente
                    </button>
                </div>
            </section>
        )
    } else if(num === 2){
        result = (
            <section>
                <DashboardTitle title='Datos de registro' />
                <div style={{padding: '40px 0 26px 0'}}><DashboardSubtitle title='Percepción de la marca' /></div>
                <Perception 
                    info={[
                        {label: '¿Cómo ven tus clientes a tu marca y como quieres que la vean?', answer: brief.clientsPOV, isText: true},
                        {label: 'A qué sector pertenece tu marca:', answer: brief.companySector},
                        {label: '¿Qué problemas solucionan los productos/servicios que ofrecen tu marca?', answer: brief.solutions},
                        {label: '¿Qué hace diferente a tu marca? ', answer: brief.different, isText: true}
                    ]}
                />
                <div className='BriefDashboard-btn-wrapper'>
                    <button 
                    className='BriefDashboard-other-btns' 
                    style={{color: num === 1 ? '#000' : '#f47600', opacity: num === 1 ? 0.25 : 1}} 
                    disabled={num === 1} onClick={prev}
                    >
                        Anterior
                    </button>
                    {buttons}
                    <button 
                    className='BriefDashboard-other-btns' 
                    style={{color: num === 7 ? '#000' : '#f47600', opacity: num === 7 ? 0.25 : 1}} 
                    disabled={num === 7} 
                    onClick={next}
                    >
                        Siguiente
                    </button>
                </div>
            </section>
        )
    }  else if(num === 3){
        result = (
            <section>
                <DashboardTitle title='Datos de registro' />
                <div style={{padding: '40px 0 26px 0'}}><DashboardSubtitle title='Competencia' /></div>
                <Competitor 
                    info={[
                        {label: '¿Tienes identificadas otras propuestas similares a la tuya?', answer: brief.competitors, isText: true},
                        {label: '¿Cuál es el nombre de tu competencia?', answer: brief.directCompetitor},
                    ]}
                />
                <div className='BriefDashboard-btn-wrapper'>
                    <button 
                    className='BriefDashboard-other-btns' 
                    style={{color: num === 1 ? '#000' : '#f47600', opacity: num === 1 ? 0.25 : 1}} 
                    disabled={num === 1} 
                    onClick={prev}
                    >
                        Anterior
                    </button>
                    {buttons}
                    <button 
                    className='BriefDashboard-other-btns' 
                    style={{color: num === 7 ? '#000' : '#f47600', opacity: num === 7 ? 0.25 : 1}} 
                    disabled={num === 7} 
                    onClick={next}
                    >
                        Siguiente
                    </button>
                </div>
            </section>
        )
    } else if(num === 4){
        result = (
            <section>
                <DashboardTitle title='Datos de registro' />
                <div style={{padding: '40px 0 26px 0'}}><DashboardSubtitle title='Presencia Online' /></div>
                <Online 
                    info={[
                        {label: '¿Qué palabras usarías para encontrar a tu marca en Google?', answer: brief.googleKeywords},
                    ]}
                    redes={[
                        {social: 'Facebook', answer: brief.facebookURL},
                        {social: 'Twitter', answer: brief.twitterURL},
                        {social: 'Instagram', answer: brief.instagramURL}
                    ]}
                />
                <div className='BriefDashboard-btn-wrapper'>
                    <button 
                    className='BriefDashboard-other-btns' 
                    style={{color: num === 1 ? '#000' : '#f47600', opacity: num === 1 ? 0.25 : 1}} 
                    disabled={num === 1} onClick={prev}
                    >
                        Anterior
                    </button>
                    {buttons}
                    <button 
                    className='BriefDashboard-other-btns' 
                    style={{color: num === 7 ? '#000' : '#f47600', opacity: num === 7 ? 0.25 : 1}} 
                    disabled={num === 7} 
                    onClick={next}
                    >
                        Siguiente
                    </button>
                </div>
            </section>
        )
    } else if(num === 5){
        result = (
            <section>
                <DashboardTitle title='Datos de registro' />
                <div style={{padding: '40px 0 26px 0'}}><DashboardSubtitle title='Sobre el proyecto' /></div>
                <AboutProject 
                    info={[
                        {label: '¿En qué idioma trabajaremos?', answer: brief.language},
                        {label: 'Cuéntanos situación de la industria en la que está tu marca:', answer: brief.sectorSituation, isText: true},
                        {label: '¿Cómo llamarás a este proyecto?', answer: brief.projectName},
                        {label: '¿Cuáles son las características del mercado objetivo?', answer: brief.target, isText: true},
                        {label: 'Objetivo del proyecto y alcances', answer: brief.projectObjectives, isText: true},
                    ]}
                />
                <div className='BriefDashboard-btn-wrapper'>
                    <button 
                    className='BriefDashboard-other-btns' 
                    style={{color: num === 1 ? '#000' : '#f47600', opacity: num === 1 ? 0.25 : 1}} 
                    disabled={num === 1} 
                    onClick={prev}
                    >
                        Anterior
                    </button>
                    {buttons}
                    <button 
                    className='BriefDashboard-other-btns' 
                    style={{color: num === 7 ? '#000' : '#f47600', opacity: num === 7 ? 0.25 : 1}} 
                    disabled={num === 7} 
                    onClick={next}
                    >
                        Siguiente
                    </button>
                </div>
            </section>
        )
    } else if(num === 6){
        result = (
            <section>
                <DashboardTitle title='Datos de registro' />
                <div style={{padding: '40px 0 26px 0'}}><DashboardSubtitle title='Hablemos de números' /></div>
                <Numbers 
                    info={[
                        {label: '¿Cuáles son los objetivos de negocio para este proyecto?', answer: brief.mainObjective,  isText: true},
                        {label: '¿Anteriormente trabajaste con alguna agencia?', answer: brief.hasWorkedWAgency},
                        {label: '¿Cual?', answer: brief.agencyWorked},
                    ]}
                />
                <div className='BriefDashboard-btn-wrapper'>
                    <button 
                    className='BriefDashboard-other-btns' 
                    style={{color: num === 1 ? '#000' : '#f47600', opacity: num === 1 ? 0.25 : 1}} 
                    disabled={num === 1} 
                    onClick={prev}
                    >
                        Anterior
                    </button>
                    <span style={{color: '#f47600'}}>...</span>
                    {buttons}
                    <span style={{color: '#f47600'}}>...</span>
                    <button 
                    className='BriefDashboard-other-btns' 
                    style={{color: num === 7 ? '#000' : '#f47600', opacity: num === 7 ? 0.25 : 1}} 
                    disabled={num === 7} 
                    onClick={next}
                    >
                        Siguiente
                    </button>
                </div>
            </section>
        )
    } else if(num === 7){
        result = (
            <section>
                <DashboardTitle title='Datos de registro' />
                <div style={{padding: '40px 0 26px 0'}}><DashboardSubtitle title='Último sorbo de café' /></div>
                <Numbers 
                    info={[
                        {label: '¿Cómo conociste Rhino.lat?', answer: brief.knowUs},
                        {label: '¿Qué marca te inspira y sirve como referencia y por qué?', answer: brief.inspireBrand,  isText: true},
                        {label: '¿Con cuánta frecuencia, tú o tu negocio, tienen necesidades de diseño?', answer: brief.frequency},
                        {label: '¿Cuántos empleados tiene tu empresa incluido tú mismo?', answer: brief.employees},
                    ]}
                />
                <div className='BriefDashboard-btn-wrapper'>
                    <button 
                    className='BriefDashboard-other-btns' 
                    style={{color: num === 1 ? '#000' : '#f47600', opacity: num === 1 ? 0.25 : 1}} 
                    disabled={num === 1} 
                    onClick={prev}
                    >
                        Anterior
                    </button>
                    <span style={{color: '#f47600'}}>...</span>
                    {buttons}
                    <button 
                    className='BriefDashboard-other-btns' 
                    style={{color: num === 7 ? '#000' : '#f47600', opacity: num === 7 ? 0.25 : 1}} 
                    disabled={num === 7}
                    >
                        Siguiente
                    </button>
                </div>
            </section>
        )
    }

    return result
}

export default withRouter(BriefDashboard);