import React from 'react';
import { Link } from 'react-router-dom';
import RightArrow from '../../../../icons/right-arrow-grey.svg';
import '../../../../css/projects/project-components/RecurrentDeliverables.css'

const RecorrentDeliverables = (props) => {
    const { title, isPaid, pathUrl } = props;
    let result = undefined;

    if(isPaid) {
        result = (
            <Link to={pathUrl}>
                <div className='RecurrentDeliverables'>
                    <p className='RecurrentDeliverables-title' style={{color: isPaid ? '#f47600' : '#58595c'}}>{title}</p>
                    <img src={RightArrow} alt='click'/>
                </div>
            </Link>
        )
    } else {
        result = (
            <div className='RecurrentDeliverables'>
                <p className='RecurrentDeliverables-title' style={{color: isPaid ? '#f47600' : '#58595c'}}>{title}</p>
                <img src={RightArrow} alt='click'/>
            </div>
        )
    }

    return result;
}

export default RecorrentDeliverables;