import React, { Component, createRef } from 'react';
import { Link, NavLink, withRouter, Redirect } from 'react-router-dom';
import {
  TimelineLite,
  Power3,
  TweenMax,
  TimelineMax,
  Expo,
  Linear,
} from 'gsap';
import { firebase, database } from '../../firebase/firebase';
import DropDownAgency from './DropDownAgency';
import AgencyLoginModal from '../modal/AgencyLoginModal';
import Logo from '../../img/navbar/rhinoLogo.png';
import ResponsiveLogo from '../../img/dashboard/rhino_logo_dash.png';
import '../../css/navbar/Navbar.css';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      user: {},
      isLoaded: false,
      isRedirected: false,
      show: false,
      innerWidth: null,
    };
    this.navRef = createRef();
    this.animNav = new TimelineLite();
    this.navbar = null;
    this.itemOne = null;
    this.itemSeven = null;
    //Modal
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    //Mobile Btn
    this.btnToggle = null;
    this.iconOneBtn = null;
    this.iconTwoBtn = null;
    this.iconThreeBtn = null;
    this.bgMenu = null;
    this.tl = new TimelineMax({ paused: true });
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleProviderLogout = this.handleProviderLogout.bind(this);
    this.setWidth = this.setWidth.bind(this);
  }

  /*Modal*/
  handleOpenModal() {
    this.setState({ show: true });
  }

  handleCloseModal() {
    this.setState({ show: false });
  }

  /*Mobile Navbar*/

  handleOpen() {
    this.tl.to(this.bgMenu, 0.6, { left: '0%', ease: Expo.easeInOut }).play();
    this.setState({ isOpen: !this.state.isOpen });
    TweenMax.to(this.iconOneBtn, 0.3, { rotation: -45, margin: 0 });
    TweenMax.to(this.iconThreeBtn, 0.3, { rotation: 45 });
    this.iconTwoBtn.style.display = 'none';
    this.bgMenu.style.position = 'fixed';
  }

  handleClose() {
    this.tl
      .to(this.bgMenu, 1, { left: '-100%', ease: Expo.easeInOut })
      .reversed(true)
      .play();
    TweenMax.to(this.iconOneBtn, 0.3, {
      rotation: 0,
      marginBottom: '5px',
      delay: 0.3,
    });
    TweenMax.to(this.iconThreeBtn, 0.3, { rotation: 0, delay: 0.3 });
    TweenMax.to(this.iconTwoBtn, 0.3, { display: 'block', delay: 0.3 });
    this.setState({ isOpen: !this.state.isOpen });
  }

  handleProviderLogout() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        this.setState({ isRedirected: true });
      });
  }

  setWidth() {
    this.setState({ innerWidth: window.innerWidth });
  }

  componentDidMount() {
    if (firebase.auth().currentUser !== null) {
      database
        .ref(`users/${firebase.auth().currentUser.uid}/profile`)
        .once('value')
        .then((snap) => {
          this.setState({ user: { ...snap.val() }, isLoaded: true });
        });
    }

    this.setState({ innerWidth: window.innerWidth });
    window.addEventListener('resize', this.setWidth, false);

    //let previousScrolled = window.pageYOffset;
    window.addEventListener(
      'scroll',
      () => {
        if (window.pageYOffset > 25) {
          TweenMax.to(this.navbar, 0.25, {
            top: 0,
            backgroundColor:
              this.props.location.pathname.slice(1) !== 'agencias'
                ? !this.state.isOpen && this.state.innerWidth < 1025
                  ? '#fff'
                  : '#01355f'
                : '#01355f',
            ease: Linear.easeInOut,
          });
        }

        if (window.pageYOffset < 25) {
          TweenMax.to(this.navbar, 0.25, {
            top: 0,
            backgroundColor: 'transparent',
            ease: Linear.easeInOut,
          });
        }
        //previousScrolled = window.pageYOffset;
      },
      false
    );

    const items = [
        this.itemOne,
        this.itemSeven,
    ];

    setTimeout(() => {
        this.animNav.staggerTo(
          items,
          1,
          { opacity: 1, y: 0, ease: Power3.easeOut },
          0.1
        );
    }, 500);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setWidth, false);
    window.removeEventListener(
      'scroll',
      () => {
        if (window.pageYOffset > 25) {
          TweenMax.to(this.navbar, 0.25, {
            top: 0,
            backgroundColor:
              this.props.location.pathname.slice(1) !== 'agencias'
                ? !this.state.isOpen && this.state.innerWidth < 1025
                  ? '#fff'
                  : '#01355f'
                : '#01355f',
            ease: Linear.easeInOut,
          });
        }

        if (window.pageYOffset < 25) {
          TweenMax.to(this.navbar, 0.25, {
            top: 0,
            backgroundColor: 'transparent',
            ease: Linear.easeInOut,
          });
        }
        //previousScrolled = window.pageYOffset;
      },
      false
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isOpen !== this.state.isOpen) {
      TweenMax.to(this.navbar, 0.25, {
        top: 0,
        backgroundColor:
          this.props.location.pathname.slice(1) !== 'agencias' && !this.state.isOpen
            ? '#fff'
            : '#f47600',
        ease: Linear.easeInOut,
      });

      if (window.pageYOffset < 25) {
        TweenMax.to(this.navbar, 0.25, {
          top: 0,
          backgroundColor: 'transparent',
          ease: Linear.easeInOut,
        });
      }
    }
  }

  render() {
    const path = this.props.location.pathname.slice(1);
    if (this.state.isRedirected) return <Redirect to='/agencias' />;

    let userName;
    let id = undefined;

    if (this.state.isLoaded && firebase.auth().currentUser) {
      userName = this.state.user.userName;
      id = firebase.auth().currentUser.uid;
    }

    return (
      <div ref={this.navRef} className='Navbar-container'>
        <nav ref={(el) => (this.navbar = el)} className='Navbar' style={{height: 70}}>
          <div
            ref={(el) => (this.btnToggle = el)}
            onClick={!this.state.isOpen ? this.handleOpen : this.handleClose}
            className='toggle-btn-movil'
          >
            <div
              ref={(el) => (this.iconOneBtn = el)}
              className='icon-movil'
              style={{
                backgroundColor:
                  path !== 'agencias' && !this.state.isOpen ? '#f47600' : '#fff',
              }}
            >
              &nbsp;
            </div>
            <div
              ref={(el) => (this.iconTwoBtn = el)}
              className='icon-movil'
              style={{
                backgroundColor:
                  path !== 'agencias' && !this.state.isOpen ? '#f47600' : '#fff',
              }}
            >
              &nbsp;
            </div>
            <div
              ref={(el) => (this.iconThreeBtn = el)}
              className='icon-movil'
              style={{
                backgroundColor:
                  path !== 'agencias' && !this.state.isOpen ? '#f47600' : '#fff',
              }}
            >
              &nbsp;
            </div>
          </div>
          {path !== 'agencias' && !this.state.isOpen ? (
            <Link to='/agencias'>
              <div className='Navbar-movil-logo'>
                <img src={ResponsiveLogo} alt='Rhino' />
              </div>
            </Link>
          ) : (
            <Link to='/agencias'>
              <div className='Navbar-movil-logo'>
                <img src={Logo} alt='Rhino' />
              </div>
            </Link>
          )}
          <ul className='Navbar-list'>
            <li
              ref={(el) => (this.itemOne = el)}
              className='Navbar-item Navabar-item-logo'
              style={{marginTop: 10}}
            >
              <Link to='/agencias'>
                <img src={Logo} alt='Rhino' />
              </Link>
            </li>
            {this.state.isLoaded ? (
              <li
                ref={(el) => (this.itemSeven = el)}
                className='Navbar-item Navbar-item-ing'
                style={{marginTop: 10}}
              >
                <DropDownAgency
                  userName={userName.toUpperCase()}
                  id={id}
                  handleLogOut={this.handleProviderLogout}
                />
              </li>
            ) : (
              <li
                ref={(el) => (this.itemSeven = el)}
                className='Navbar-item Navbar-item-ing'
                style={{marginTop: 10}}
              >
                <button className='Navbar-btn' onClick={this.handleOpenModal}>
                  Ingresar
                </button>
              </li>
            )}
          </ul>
        </nav>
        <div ref={(el) => (this.bgMenu = el)} className='mobile-menu-bg' style={{backgroundColor: '#01355f'}}>
          <div className='mobile-menu-container'>
            <div className='mobile-menu'>
              <div className='Navbar-mobile-line'>&nbsp;</div>
              {id === undefined && <div className='mobile-item'>
                <NavLink
                  exact
                  activeClassName='active'
                  className='mobile-link'
                  to='/agencias/login'
                >
                  Ingresar
                </NavLink>
              </div>}
              {id !== undefined && 
                <React.Fragment>
                  <div className='mobile-item'>
                    <NavLink
                      exact
                      activeClassName='active'
                      className='mobile-link'
                      to='/agencias/proyectos'
                      onClick={this.handleClose}
                    >
                      Mis proyectos
                    </NavLink>
                  </div>
                  <div className='mobile-item'>
                    <NavLink
                      exact
                      activeClassName='active'
                      className='mobile-link'
                      to={`/agencias/perfil/${id}`}
                      onClick={this.handleClose}
                    >
                      Mi perfil
                    </NavLink>
                  </div>
                  <div className='mobile-item'>
                   <button 
                    className='mobile-button' 
                    onClick={this.handleProviderLogout}>
                      Cerrar Sesión 
                    </button>
                  </div>
                </React.Fragment>
              }
            </div>
          </div>
        </div>
        <AgencyLoginModal
          show={this.state.show}
          handleClose={this.handleCloseModal}
        />
      </div>
    );
  }
}

export default withRouter(Navbar);
