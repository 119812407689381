import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import Info from '../../../icons/info-icon.png';
import { numberWithCommas } from '../../../helpers/utils';
import '../../../css/packageUserInterface/Payments.css';

const Payment = (props) => {
  const { title, payments, stages } = props;

  const stageIds = [];
  const indexOfStages = [];
  let stagesNum = '';

  for (let i = 3; i < payments.length; i++) {
    for (const [key, value] of Object.entries(payments[i])) {
      if (key !== 'packageId' && key !== 'amount' && key !== 'indexOf') {
        const isLocated = stageIds.indexOf(value);
        if (isLocated === -1) stageIds.push(value);
      }
    }
  }

  stageIds.forEach((id) => {
    stages.forEach((stage) => {
      if (stage.id === id) indexOfStages.push(stage.indexOf);
    });
  });

  if (indexOfStages.length === 1) {
    stagesNum = 'Etapa';
    for (let j = 0; j < indexOfStages.length; j++) {
      stagesNum += ` ${indexOfStages[j] + 1}`;
    }
  } else if (indexOfStages.length === 2) {
    stagesNum = 'Etapas ';
    for (let i = 0; i < indexOfStages.length; i++) {
      if (i === indexOfStages.length - 1) stagesNum += ` ${indexOfStages[i] + 1}`;
      else stagesNum += ` ${indexOfStages[i] + 1} y`;
    }
  } else {
    stagesNum = 'Etapas ';
    for (let k = 0; k < indexOfStages.length; k++) {
      if (k === indexOfStages.length - 1) {
        stagesNum += ` ${indexOfStages[k] + 1}`;
      } else if (k === indexOfStages.length - 2) {
        stagesNum += ` ${indexOfStages[k] + 1} y`;
      } else stagesNum += ` ${indexOfStages[k] + 1}, `;
    }
  }

  return (
    <div className="Payments">
      <p className="Payment-title">
        {title}
        <OverlayTrigger
          key="right"
          placement="right"
          overlay={(
            <Tooltip id="tooltip-right" className="tooltip-stage">
              {stagesNum}
            </Tooltip>
                    )}
        >
          <span style={{ marginLeft: 12 }}><img src={Info} alt="Info" /></span>
        </OverlayTrigger>
      </p>
      {payments[3]
      && (
      <p className="Payment-amount">
        {numberWithCommas(payments[3].amount)}
        .00 MXN
      </p>
      )}
    </div>
  );
};

export default Payment;
