import React, { Component, createRef } from 'react';
import { TimelineLite, TimelineMax, Elastic, Power3 } from 'gsap';
import { typingBenefits } from '../../helpers/utils';
import '../../css/homepage/Benefits.css';

class Benefits extends Component {
  constructor(props) {
    super(props);
    //Ref to DOMNodes
    this.sectionRef = createRef();
    this.title = null;
    this.paragraph = null;
    this.benefitOne = null;
    this.benefitTwo = null;
    this.benefitThree = null;
    this.benefitFour = null;
    this.innerTextOne = null;
    this.innerTextTwo = null;
    this.innerTextThree = null;
    this.innerTextFour = null;
    this.anim = new TimelineLite();
    this.animText = new TimelineMax();
  }
  componentDidMount() {
    const benefits = [
      this.benefitOne,
      this.benefitTwo,
      this.benefitThree,
      this.benefitFour,
    ];
    const innerText = [
      this.innerTextOne,
      this.innerTextTwo,
      this.innerTextThree,
      this.innerTextFour,
    ];

    //Triggers the animation when the section is in the viewport by using Intersection Observer
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.4,
    };

    const observer = new IntersectionObserver((entries) => {
      if (
        entries[0].isIntersecting &&
        entries[0].intersectionRatio >= options.threshold
      ) {
        this.title.style.visibility = 'visible';
        typingBenefits(this.animText, this.title, '100%');
        for (let i = 0; i < benefits.length; i++) {
          this.anim.staggerTo(
            benefits[i].children,
            0.6,
            { scale: 1, ease: Elastic.easeOut.config(0.85, 0.5) },
            0.1
          );
        }
        this.anim
          .to(
            this.paragraph,
            1.3,
            { opacity: 1, y: 0, ease: Power3.easeOut },
            '-=2.5'
          )
          .staggerTo(
            innerText,
            0.5,
            { opacity: 1, y: 0, ease: Power3.easeOut },
            0.5,
            '-=2'
          );
      }
    }, options);

    observer.observe(this.sectionRef.current);
  }
  render() {
    return (
      <section ref={this.sectionRef} className='Benefits'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='Benefits-title'>
                <h2 ref={(el) => (this.title = el)}>
                  <strong>BENEFICIOS</strong>
                </h2>
              </div>
            </div>
            <div ref={(el) => (this.paragraph = el)} className='Benefits-text'>
              <p>
                Creemos que lo que realmente importa en tu empresa es lo que
                proyecta y no el tamaño.
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-6 col-sm-6 col-md-6 col-lg-3'>
              <div className='Benefit-container'>
                <div
                  ref={(el) => (this.benefitOne = el)}
                  className='Benefit-img'
                >
                  <div className='Benefit-img-one Benefits-one'>&nbsp;</div>
                  <div className='Benefit-img-two Benefits-two'>&nbsp;</div>
                </div>
                <div
                  ref={(el) => (this.innerTextOne = el)}
                  className='Benefit-text'
                >
                  <p>Soluciones para tu proyecto de mejor calidad.</p>
                </div>
              </div>
            </div>
            <div className='col-6 col-sm-6 col-md-6 col-lg-3'>
              <div className='Benefit-container'>
                <div
                  ref={(el) => (this.benefitTwo = el)}
                  className='Benefit-img'
                >
                  <div className='Benefit-img-one Benefits-three'>&nbsp;</div>
                  <div className='Benefit-img-two Benefits-four'>&nbsp;</div>
                </div>
                <div
                  ref={(el) => (this.innerTextTwo = el)}
                  className='Benefit-text'
                >
                  <p>Invierte a un precio justo en cada proyecto.</p>
                </div>
              </div>
            </div>
            <div className='col-6 col-sm-6 col-md-6 col-lg-3'>
              <div className='Benefit-container'>
                <div
                  ref={(el) => (this.benefitThree = el)}
                  className='Benefit-img'
                >
                  <div className='Benefit-img-one Benefits-five'>&nbsp;</div>
                  <div className='Benefit-img-two Benefits-six'>&nbsp;</div>
                </div>
                <div
                  ref={(el) => (this.innerTextThree = el)}
                  className='Benefit-text'
                >
                  <p>Llega a tus metas a tiempo y en forma.</p>
                </div>
              </div>
            </div>
            <div className='col-6 col-sm-6 col-md-6 col-lg-3'>
              <div className='Benefit-container'>
                <div
                  ref={(el) => (this.benefitFour = el)}
                  className='Benefit-img'
                >
                  <div className='Benefit-img-one Benefits-seven'>&nbsp;</div>
                  <div className='Benefit-img-two Benefits-eight'>&nbsp;</div>
                </div>
                <div
                  ref={(el) => (this.innerTextFour = el)}
                  className='Benefit-text'
                >
                  <p>Obtén servicios especializados en un mismo lugar.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Benefits;
