import React from 'react';
import BlueTitle from './titles/BlueTitle';
import QuestionAccordion from './accordion/QuestionAccordion';
import '../../css/home-agency/FAQS.css';

const FAQS = (props) => (
  <section className="FAQS">
    <div className="container">
      <div className="FAQS-title-wrapper">
        <BlueTitle text="FAQ´s" />
      </div>
      <div className="FAQS-accordion-wrapper">
        <QuestionAccordion />
      </div>
    </div>
  </section>
);

export default FAQS;
