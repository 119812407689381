import React from 'react';
import '../../../css/utils/progress/Line.css';

const Line = (props) => {
    return(
        <div className='ProgressBar-line'>
            <div className='ProgressBar-line-one'>&nbsp;</div>
            <div className='ProgressBar-line-two' ref={props.line}>&nbsp;</div>
        </div>
    );
}

export default Line;