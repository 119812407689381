import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withFormik } from 'formik';
import * as yup from 'yup';
import Checked from '../../../img/navbar/checked.png';
import { firebase } from '../../../firebase/firebase';
import '../../../css/forms/FormInicioSesion.css';

const FormInicioSesion = ({
  values,
  errors,
  touched,
  handleChange,
  handleSubmit,
  isSubmitting,
  isValid,
}) => {
  return (
    <div className='FormRegistrase-form'>
      <form onSubmit={handleSubmit}>
        <div className='form-group FormRegistrarse-wrapper'>
          <input
            type='email'
            className='form-control FormRegistrarseInput'
            onChange={handleChange}
            placeholder='Correo electrónico'
            name='email'
            value={values.email}
            style={{
              border:
                errors.email !== undefined && touched.email
                  ? '1px solid #ff0000'
                  : '',
              boxShadow:
                errors.email !== undefined && touched.email
                  ? '0 0 0 0.2rem rgba(255, 0, 0, 0.25)'
                  : '',
            }}
          />
        </div>
        <div className='form-group FormRegistrarse-wrapper'>
          <input
            type='password'
            className='form-control FormRegistrarseInput'
            onChange={handleChange}
            placeholder='Contraseña'
            name='password'
            value={values.password}
            style={{
              border:
                errors.password !== undefined && touched.password
                  ? '1px solid #ff0000'
                  : '',
              boxShadow:
                errors.password !== undefined && touched.password
                  ? '0 0 0 0.2rem rgba(255, 0, 0, 0.25)'
                  : '',
            }}
          />
        </div>
        <div className='error-message'>
          {(errors.password && touched.password) ||
          (errors.email && touched.email) ? (
            <p style={{ color: '#ff0000', fontSize: 16, textAlign: 'center' }}>
              Usuario y/o contraseña incorrectos
            </p>
          ) : null}
        </div>
        <div className='FormInicioSesion-details'>
          <div className='form-group aligning-items FormInicioSesion-recordarme-container'>
            <input
              id='terms'
              type='checkbox'
              style={{ display: 'none' }}
              className='check-input-form'
              name='remember'
              checked={values.remember}
              onChange={handleChange}
            />
            <label htmlFor='terms' className='FormInicioSesion-recordarme'>
              <span className='FormRegistrarse-check' style={{ width: '16px' }}>
                {values.remember && <img src={Checked} alt='checked' />}
              </span>
              Recordarme.
            </label>
          </div>
          <div className='FormInicioSesion-link-container'>
            <Link className='FormInicioSesion-restablecer' to='/password_reset'>
              Olvidé mi contraseña
            </Link>
          </div>
        </div>
        <div className='FormInicioSesion-btn-container'>
          <button
            disabled={isSubmitting || !isValid}
            style={{
              backgroundColor: isValid && '#f47600',
              color: isValid && '#fff',
            }}
            type='submit'
          >
            INICIAR
          </button>
        </div>
      </form>
    </div>
  );
};

const FormikFormInicioSesion = withFormik({
  mapPropsToValues() {
    return {
      email: '',
      password: '',
      remember: false,
    };
  },
  validationSchema: yup.object().shape({
    email: yup.string().email('Correo invalido').required('Campo obligatorio'),
    password: yup.string().required('Campo obligatorio'),
  }),
  handleSubmit(values, { resetForm, setErrors, setSubmitting, props }) {
    firebase
      .auth()
      .signInWithEmailAndPassword(values.email, values.password)
      .then(() => {
        props.handleClose();
        window.location.reload(true);
      })
      .catch((error) => {
        let errorCode = error.code;
        if (errorCode === 'auth/wrong-password') {
          setErrors({ password: 'Contraseña invalida' });
        } else {
          setErrors({ email: 'Correo invalido' });
        }
      });
    setSubmitting(false);
  },
})(FormInicioSesion);

export default withRouter(FormikFormInicioSesion);
