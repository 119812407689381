import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { database } from '../../firebase/firebase';

class ModalMsg extends Component {
  constructor(props) {
    super(props);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleDelete() {
    if (this.props.state.arr.length > 0) {
      this.props.state.arr.forEach((arr) => {
        database.ref(`${this.props.state.collection}/${arr.id}`).remove().then(() => {
          console.log('Success!');
        }).catch((e) => {
          console.log(`Problems removing data ${e}`);
        });
      });
    }
    this.props.delete(this.props.state.id);
  }

  render() {
    return (
      <Modal centered show={this.props.show} onHide={this.props.handleClose}>
        <Modal.Body>
          {this.props.message}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="ark" onClick={this.props.handleClose}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={this.handleDelete}>
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withRouter(ModalMsg);
