import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import ButtonOnClick from '../utils/buttons/ButtonOnClick';
import '../../css/modal/StageModal.css';

class StageModal extends Component {
    constructor(props) {
        super(props);
        this.handleFinishStage = this.handleFinishStage.bind(this);
    }

    handleFinishStage() {
        this.props.finishStage()
    }

    render() {
        return(
            <Modal centered show={this.props.show} onHide={this.props.handleClose} className='StageModal'>
                <Modal.Body className='StageModal-body'>
                    <div className='StageModal-paragraphs-wrapper'>
                        <p style={{color: '#58595c', textAlign: 'center', margin: 0}} className='StageModal-message'>¿Estás seguro de que quieres dar por terminado este entregable?</p>
                        <p style={{color: '#58595c', textAlign: 'center', margin: 0}} className='StageModal-message'>Una vez finalizado no habrá más cambios</p>
                    </div>
                    <div className='StageModal-btns-wrapper'>
                        <div className='StageModal-finished'><ButtonOnClick text='Aceptar' click={this.handleFinishStage} /></div>
                        <div className='StageModal-cancel-wrapper'><button onClick={this.props.handleClose} className='StageModal-cancel'>Cancelar</button></div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default StageModal;