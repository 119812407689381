import React, {Component} from 'react';
import { Accordion, Card } from 'react-bootstrap';
import {database} from '../../../firebase/firebase';
import Deliverable from './Deliverable';
import DownArrow from '../../../icons/down-arrow-orange.svg';
import UpArrow from '../../../icons/up-arrow-orange.svg';
import '../../../css/packageUserInterface/Stage.css';

class Stage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deliverables: [],
      duration: '',
      isOpened: false
    }
    this.handleOpen = this.handleOpen.bind(this);
  }

  componentDidMount() {
    let deliverablesArray = [];
    let totalDuration = 0;

    database.ref('deliverables').orderByChild('stageId').equalTo(`${this.props.stageId}`).once('value').then(snapshot => {
      snapshot.forEach(snap => {
        deliverablesArray.push({
          id: snap.key,
          ...snap.val()
        });
        totalDuration += parseInt(snap.val().deliverableDuration);
      })
      this.setState({deliverables: [...deliverablesArray], duration: totalDuration});
    });
  }

  handleOpen() {
    this.setState({isOpened: !this.state.isOpened});
  }

  render() {
    let deliverables = undefined;
    if(this.state.deliverables.length > 0) {
      deliverables = this.state.deliverables.map(deliverable => (
        <Deliverable 
        key={deliverable.id}
        deliverableName={deliverable.deliverableName} 
        deliverableDesc={deliverable.deliverableDesc}
        deliverableList={deliverable.deliverableList}
        />
      ));
    }
    return (
      <div>
        <Card className='Stage-card'>
          <Accordion.Toggle
            as={Card.Header}
            eventKey={this.props.eventKey}
            className='Stage-card-header'
            onClick={this.handleOpen}
          >
            <p style={{ margin: 0 }} className='Stage-card-title'>
              <span style={{ display: 'block', color: '#01355f' }}>
                Etapa {this.props.index}: {this.props.name}
              </span>
              <span style={{ display: 'block', color: '#b3b3b3' }}>
                Días {this.state.duration}
              </span>
            </p>
            <img src={!this.state.isOpened ? DownArrow : UpArrow} alt='Flecha'/>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={this.props.eventKey}>
            <Card.Body>
              <div>
                {(this.state.deliverables.length > 0) && deliverables}
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </div>
    );
  }
};

export default Stage;
