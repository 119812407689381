import React, { Component } from 'react';
import { database } from '../../../firebase/firebase';
import PackageDetails from './PackageDetails';
import '../../../css/categories/userInterface/PackagesPerCategory.css';

class PackagesPerCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: {},
      packages: [],
      isCategoryLoaded: false,
      isPackegesLoaded: false,
      counter: 4,
    };
    this.counter = this.counter.bind(this);
  }

  counter() {
    this.setState((prevState) => ({
      counter: prevState.counter + 4,
    }));
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let packages = [];
    database
      .ref(`categories/${this.props.id}`)
      .once('value')
      .then((snapshot) => {
        this.setState({
          category: { id: snapshot.key, ...snapshot.val() },
          isCategoryLoaded: true,
        });
      });

    database
      .ref('packages')
      .orderByChild('categoryId')
      .equalTo(`${this.props.id}`)
      .once('value')
      .then((snapshot) => {
        snapshot.forEach((childSnapshot) => {
          packages.push({
            id: childSnapshot.key,
            ...childSnapshot.val(),
          });
        });
        this.setState({ packages: [...packages], isPackegesLoaded: true });
      });
  }

  render() {
    let packagesList = undefined;
    if(this.state.isPackegesLoaded && this.state.isCategoryLoaded) {
      const newArray = this.state.packages.slice(0, this.state.counter)

    packagesList = newArray.map((p) => (
      <div
        key={p.id}
        className='col-12 col-sm-12 col-md-6 col-lg-3'
        style={{ paddingLeft: 14, paddingRight: 14 }}
      >
        <PackageDetails
          id={p.id}
          name={p.packageName}
          categoryId={p.categoryId}
          categories={this.props.categories}
          img={p.imgPath}
        />
      </div>
    ));
    }

    return (
      <div className='row'>
        <div className='col-12'>
          <div className='PackagesPerCategory-title'>
            <h3>{this.state.category.categoryName}</h3>
          </div>
        </div>
        {packagesList}
        {this.state.counter < this.state.packages.length ? <div className='col-12'>
          <div className='PackagesPerCategory-btn-container'>
            <button className='PackagesPerCategory-btn' onClick={this.counter}>Ver más</button>
          </div>
        </div> : null}
      </div>
    );
  }
}

export default PackagesPerCategory;
