import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import '../../css/navbar/DropDownUser.css';

const DropDownUser = (props) => {
  return (
    <Dropdown>
      <Dropdown.Toggle className='Dropdown' style={{ fontWeight: 600 }}>
        {props.userName}
      </Dropdown.Toggle>
      <Dropdown.Menu className='Dropdown-menu'>
        <div className='Dropdown-list'>
          <div className='Dropdown-item-container'>
            <Link to='/proyectos' className='Dropdown-link'>
              Mis proyectos
            </Link>
          </div>
          <div className='Dropdown-item-container'>
            <Link
              to={{ pathname: `/perfil/${props.id}` }}
              className='Dropdown-link'
            >
              Mi perfil
            </Link>
          </div>
          <div className='Dropdown-item-container'>
            <button className='Dropdown-btn' onClick={props.handleLogOut}>
              Cerrar Sesión
            </button>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropDownUser;
