import React from 'react';

const PaymentMethod = (props) => {
  const { isTransferred, transFunc, cardFunc } = props;
  return (
    <div className='PaymentDetails-setBtns'>
      <button
        className='PaymentDetails-card'
        style={{
          color: !isTransferred ? '#f47600' : '#58595c',
          border: !isTransferred ? '2px solid #f47600' : '2px solid #58595c',
          backgroundColor: !isTransferred ? '#F2BF90' : 'transparent',
          borderRight: !isTransferred ? '2px solid #f47600' : 'transparent',
        }}
        onClick={cardFunc}
      >
        Pago con tarjeta
      </button>
      <button
        className='PaymentDetails-transferred'
        style={{
          color: isTransferred ? '#f47600' : '#58595c',
          border: isTransferred ? '2px solid #f47600' : '2px solid #58595c',
          backgroundColor: isTransferred ? '#F2BF90' : 'transparent',
          borderLeft: isTransferred ? '2px solid #f47600' : 'transparent',
        }}
        onClick={transFunc}
      >
        Transferencia
      </button>
    </div>
  );
};

export default PaymentMethod;
