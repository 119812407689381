import React from 'react';
import Input from '../../../utils/forms/input/Input';
import Select from '../../../utils/forms/select/Select';
import Textarea from '../../../utils/forms/textarea/Textarea';
import '../../../../css/forms/admin/create-package/CreatePackage.css';

const CreatePackage = (props) => {
  const {
    categories,
    packageName,
    packageDesc,
    categoryName,
    errorPackageName,
    errorPackageDesc,
    handleChange,
    fileHandleChange,
  } = props;
  return (
    <div className="CreatePackage">
      <div className="CreatePackage-title-container">
        <p className="CreatePackage-title">Datos del plan</p>
      </div>
      <form>
        <div className="CreatePackage-form-wrapper">
          <div className="CreatePackage-input-wrapper">
            <Input
              label="Nombre"
              type="text"
              placeholder="Negocio Integral"
              value={packageName}
              name="packageName"
              handleChange={handleChange}
              customError={errorPackageName}
            />
          </div>
          <div className="CreatePackage-select-wrapper">
            <Select
              label="Categoría"
              options={categories}
              value={categoryName}
              name="categoryName"
              handleChange={handleChange}
            />
          </div>
        </div>
        <div className="CreatePackage-textarea-wrapper">
          <Textarea
            label="Descripción"
            placeholder="Descripción"
            value={packageDesc}
            name="packageDesc"
            handleChange={handleChange}
            customError={errorPackageDesc}
          />
        </div>
        <div className="CreatePackage-file-wrapper">
          <input type="file" onChange={fileHandleChange} />
        </div>
      </form>
    </div>
  );
};

export default CreatePackage;
