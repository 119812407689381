import React, { Component } from 'react';
import { database } from '../../firebase/firebase';
import Title from '../utils/Title';
import Add from '../utils/Add';
import TableHeaders from '../utils/TableHeaders';
import ModalMsg from '../modal/ModalMsg';
import Details from './Details';
import DashboardTitle from '../dashboard/titles/DashboardTitle';

class CategoryList extends Component {
  constructor(props) {
    super(props);
    this.state = { categories: [], show: false };
    this.delete = this.delete.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  componentDidMount() {
    const categories = [];
    database.ref().child('categories').once('value').then((snapshot) => {
      snapshot.forEach((childSnapshot) => {
        console.log(childSnapshot.val());
        categories.push({
          id: childSnapshot.key,
          ...childSnapshot.val(),
        });
      });
      this.setState({ categories: [...categories] });
    })
      .catch((e) => {
        console.log(`Error fetching the data ${e}`);
      });
  }

  delete(id) {
    console.log('id:', id);
    database.ref(`categories/${id}`).remove().then(() => {
      this.setState((prevState) => ({
        categories: prevState.categories.filter((category) => category.id !== id),
      }));
    }).catch((e) => {
      console.log(`Could not remove document ${e}`);
    });
    this.setState({ show: false });
  }

  handleShow(state) {
    this.setState({ show: true, ...state });
  }

  handleClose() {
    this.setState({ show: false });
  }

  render() {
    const headers = [
      'Id',
      'Categoría',
      'Descripción',
      'Eliminar',
      'Editar',
    ];

    const {
      props,
      delete: deleteCategory,
      handleShow: handleShow1,
      handleClose: handleClose1,
      state,
    } = this;
    const { title } = props;

    const categories = state.categories.map((category) => (
      <Details
        key={category.id}
        id={category.id}
        name={category.categoryName}
        description={category.description}
        disabled={category.disabled}
        path="/rh-admin/dashboard/categories"
        handleShow={handleShow1}
      />
    ));
    return (
      <>
        <div className="UsersList">
          <DashboardTitle title="Categorias" />
          <div className="container">
            <Add path="/rh-admin/dashboard/create-category" />
            <Title title={title} />
            <TableHeaders headers={headers} content={categories} />
            <ModalMsg
              state={state}
              show={state.show}
              handleClose={handleClose1}
              delete={deleteCategory}
              message="¿Esta seguro que desea borrar esta categoría?"
            />
          </div>
        </div>

      </>
    );
  }
}

export default CategoryList;
