import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import Input from '../../../utils/forms/input/Input';
import Checked from '../../../../img/navbar/checked.png';
import ButtonOnClick from '../../../utils/buttons/ButtonOnClick';
import PaymentMethod from '../../../paymentsForms/PaymentMethod';
import '../../../../css/brief/BriefForms.css';

const StepEight = forwardRef((props, ref) => {
  // Elements from stripe
  const elements = useElements();
  const stripe = useStripe();

  const {
    handleChange,
    handleSubmit,
    cardName,
    isRemembered,
    hasPackage,
    isTransferred,
    payCard,
    payTransfer,
    isProcessing,
    handleChangeCheck,
    error,
    disabled,
    handleChangeStripe,
  } = props;

  useImperativeHandle(ref, () => ({
    async checkoutStripe(clientSecret, name) {
      if (!stripe || !elements) return;
      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name,
          },
        },
      });
      if (payload.error) {
        return payload.error;
      }
      return payload;
    },
  }));

  // styles for Stripe components
  const cardNumStyle = {
    style: {
      base: {
        color: '#58595c',
        fontFamily: 'Ubuntu, sans-serif',
        fontSize: '19px',
        '::placeholder': {
          color: '#C2C2C2',
        },
      },
      invalid: {
        color: 'red',
      },
    },
  };

  const cardExpStyle = {
    style: {
      base: {
        color: '#58595c',
        fontFamily: 'Ubuntu, sans-serif',
        fontSize: '19px',
        '::placeholder': {
          color: '#C2C2C2',
        },
      },
      invalid: {
        color: 'red',
      },
    },
  };

  const cardCvcStyle = {
    style: {
      base: {
        color: '#58595c',
        fontFamily: 'Ubuntu, sans-serif',
        fontSize: '19px',
        '::placeholder': {
          color: '#C2C2C2',
        },
      },
      invalid: {
        color: 'red',
        iconColor: '#fa755a',
      },
    },
  };

  return (
    <div className="Payment-container">
      <form>
        <PaymentMethod
          isTransferred={isTransferred}
          transFunc={payTransfer}
          cardFunc={payCard}
        />
        <div className="Brief-form-container-card">
          <Input
            type="text"
            label="Nombre en la tarjeta"
            placeholder="Joe Doe"
            value={cardName}
            name="cardName"
            handleChange={handleChange}
          />
        </div>
        <div className="Payment-imgs">
          <div className="Payment-img Payment-img-1">&nbsp;</div>
          <div className="Payment-img Payment-img-2">&nbsp;</div>
          <div className="Payment-img Payment-img-3">&nbsp;</div>
        </div>
        <div className="Brief-form-container-card">
          <div className="Brief-form-cardStripe">
            <CardNumberElement
              options={cardNumStyle}
              onChange={handleChangeStripe}
            />
          </div>
        </div>
        <div className="Brief-form-container-card">
          <p className="date-card-paragraph">Fecha de vencimiento</p>
          <div className="Brief-form-container-card-inner">
            <div className="Brief-form-cardStripe">
              <CardExpiryElement
                options={cardExpStyle}
                onChange={handleChangeStripe}
              />
            </div>
            <div className="Brief-form-cardStripe">
              <CardCvcElement
                options={cardCvcStyle}
                onChange={handleChangeStripe}
              />
            </div>
          </div>
        </div>
        {error && <p>{error}</p>}
        <div className="form-group">
          <input
            type="checkbox"
            className="form-check-input"
            id="isOnline"
            name="isRemembered"
            onChange={handleChangeCheck}
            checked={isRemembered}
            style={{ display: 'none' }}
          />
          <label className="form-check-label-remember" htmlFor="isOnline">
            <span className="check-container">
              {isRemembered && <img src={Checked} alt="Checked" />}
            </span>
            Recordar esta tarjeta
          </label>
        </div>
      </form>
      <div className="Step-button-container special-btn-bg">
        <ButtonOnClick
          text={isProcessing ? 'PROCESANDO...' : 'REALIZAR PAGO'}
          click={handleSubmit}
          isDisabled={!hasPackage || isProcessing || disabled}
          submit="submit"
        />
      </div>
    </div>
  );
});

export default StepEight;
