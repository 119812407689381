import React, { Component, createRef } from 'react';
import {
  TimelineLite, TimelineMax, Power3, Linear,
} from 'gsap';
import { typingSteps, scrollParallax } from '../../helpers/utils';
import IslaDos from '../../img/steps/isla_2.gif';
import '../../css/homepage/Steps.css';

class Steps extends Component {
  constructor(props) {
    super(props);
    // Ref to DOMNodes
    this.sectionRef = createRef();
    this.title = null;
    this.footprintOne = null;
    this.footprintTwo = null;
    this.footprintThree = null;
    this.footprintFour = null;
    this.footprintFive = null;
    this.innerTextOne = null;
    this.innerTextTwo = null;
    this.innerTextThree = null;
    this.innerTextFour = null;
    this.innerTextFive = null;
    this.islandTwo = null;
    this.bgAnimation = null;
    this.anim = new TimelineLite();
    this.animTitle = new TimelineMax();
    this.handleScroll = this.handleScroll.bind(this);
  }

  handleScroll() {
    scrollParallax(this.islandTwo, 0.2);
  }

  componentDidMount() {
    const footprints = [
      this.footprintOne,
      this.footprintTwo,
      this.footprintThree,
      this.footprintFour,
      this.footprintFive,
    ];
    const innerText = [
      this.innerTextOne,
      this.innerTextTwo,
      this.innerTextThree,
      this.innerTextFour,
      this.innerTextFive,
    ];

    // Trigger animation when the current section is in the viewport by using Intersection Observer
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.35,
    };

    const observer = new IntersectionObserver((entries) => {
      if (
        entries[0].isIntersecting
        && entries[0].intersectionRatio >= options.threshold
      ) {
        this.title.style.visibility = 'visible';
        typingSteps(this.animTitle, this.title, '100%');
        this.anim
          .to(
            this.bgAnimation,
            1.5,
            { opacity: 1, ease: Linear.easeInOut },
            '-=2',
          )
          .staggerTo(
            footprints,
            0.5,
            { opacity: 1, ease: Power3.easeOut, delay: 0.3 },
            0.8,
            '-=2',
          )
          .staggerTo(
            innerText,
            0.6,
            {
              opacity: 1, y: 0, ease: Power3.easeOut, delay: 0.3,
            },
            1,
            '-=4',
          );
      }
    }, options);

    observer.observe(this.sectionRef.current);

    // Parallax in Island
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    return (
      <section ref={this.sectionRef} className="Steps">
        <div ref={(el) => (this.bgAnimation = el)} className="Steps-bg">
          &nbsp;
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="Steps-title">
                <h2 ref={(el) => (this.title = el)}>
                  <strong>¿CÓMO FUNCIONA?</strong>
                </h2>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <div className="Steps-container">
                <div className="Steps-text">
                  <div
                    ref={(el) => (this.footprintOne = el)}
                    className="Steps-text-img"
                  >
                    <h2>1</h2>
                  </div>
                  <div
                    ref={(el) => (this.innerTextOne = el)}
                    className="Steps-text-info"
                  >
                    <h3>ELIGE EL PLAN A TU MEDIDA</h3>
                    <p>
                      En Rhino existe un proyecto para cada una de tus
                      necesidades. Y si no está, solicita ayuda a un EJECUTIVO RHINO.

                    </p>
                  </div>
                </div>
                <div className="Steps-text">
                  <div
                    ref={(el) => (this.footprintTwo = el)}
                    className="Steps-text-img Steps-text-img-m"
                  >
                    <h2>2</h2>
                  </div>
                  <div
                    ref={(el) => (this.innerTextTwo = el)}
                    className="Steps-text-info"
                  >
                    <h3>COMPLETA UN BRIEF PROFESIONAL</h3>
                    <p>
                      Queremos conocer a fondo tu negocio o marca,
                      para ponernos “a la carga” de tus objetivos y darte
                      resultados tangibles.
                    </p>
                  </div>
                </div>
                <div className="Steps-text">
                  <div
                    ref={(el) => (this.footprintThree = el)}
                    className="Steps-text-img"
                  >
                    <h2>3</h2>
                  </div>
                  <div
                    ref={(el) => (this.innerTextThree = el)}
                    className="Steps-text-info"
                  >
                    <h3>PAGA SÓLO LA ETAPA POR INICIAR </h3>
                    <p>
                      Invierte en tu proyecto y tu empresa,
                      con tus pagos divididos por cada etapa.
                    </p>
                  </div>
                </div>
                <div className="Steps-text">
                  <div
                    ref={(el) => (this.footprintFour = el)}
                    className="Steps-text-img Steps-text-img-m"
                  >
                    <h2>4</h2>
                  </div>
                  <div
                    ref={(el) => (this.innerTextFour = el)}
                    className="Steps-text-info"
                  >
                    <h3>UNA AGENCIA A TU MEDIDA </h3>
                    <p>
                      Rhino te GARANTIZA que tu proyecto será desarrollado por
                      una de nuestras agencias mejor calificadas,
                      bajo estrictas medidas de evaluación previa.
                    </p>
                  </div>
                </div>
                <div className="Steps-text">
                  <div
                    ref={(el) => (this.footprintFive = el)}
                    className="Steps-text-img"
                  >
                    <h2>5</h2>
                  </div>
                  <div
                    ref={(el) => (this.innerTextFive = el)}
                    className="Steps-text-info"
                  >
                    <h3>TU PROYECTO A TIEMPO </h3>
                    <p>
                      Cada proyecto esta divido por etapas y cada etapa en
                      entregables, a los cuales podrás dar seguimiento siempre
                      en comunicación directa.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ref={(el) => (this.islandTwo = el)} className="Steps-animation">
          <img src={IslaDos} alt="Isla" />
        </div>
      </section>
    );
  }
}

export default Steps;
