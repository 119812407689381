import React, { Component } from 'react';
import {firebase, database} from '../../../firebase/firebase';
import '../../../firebase/firebase';
import '../../../css/forms/FormRegistrarse.css';

class FormRegistroAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {name: '', email: '', password: '', confirmPass: ''}
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        const email = this.state.email.trim();
        const password = this.state.password.trim();
        firebase.auth().createUserWithEmailAndPassword(email, password)
        .then(() => {
            database.ref(`users/${firebase.auth().currentUser.uid}/profile`).set({
                userName: this.state.name.trim(),
                userEmail: this.state.email.trim(),
                typeOfUser: 'admin'
            })
            console.log(firebase.auth().currentUser);
        }).catch(error => {
            console.log(`Error: ${error}`);
        });
    }

    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    render() {
        return (
            <div className='FormRegistrase-form'>
                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <input type='text' className="form-control" onChange={this.handleChange} placeholder='Nombre' name='name' value={this.state.name} />
                    </div>
                    <div className="form-group">
                        <input type='email' className="form-control" onChange={this.handleChange} placeholder='Correo electrónico' name='email' value={this.state.email} />
                    </div>
                    <div className="form-group">
                        <input type='password' className="form-control" onChange={this.handleChange} placeholder='Contraseña' name='password' value={this.state.password} />
                    </div>
                    <div className="form-group">
                        <input type='password' className="form-control" onChange={this.handleChange} placeholder='Confirmar contraseña' name='confirmPass' value={this.state.confirmPass} />
                    </div>
                    <div className="form-group aligning-items">
                        <input id='terms' type='checkbox' />
                        <label htmlFor='terms' className='FormRegistrarse-terms'>He leido y estoy de acuerdo con los términos y condiciones.</label>
                    </div>
                    <div className='FormRegistrarse-btn-container'>
                        <button>REGISTRARSE</button>
                    </div>
                </form>
            </div>
        );
    }
}
 
export default FormRegistroAdmin;
