import React from 'react';
import { withRouter } from 'react-router-dom';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { firebase, database } from '../../../firebase/firebase';
import Checked from '../../../img/navbar/checked.png';
import '../../../firebase/firebase';
import '../../../css/forms/FormRegistrarse.css';

const FormRegistrarse = ({
  values,
  errors,
  touched,
  handleChange,
  handleSubmit,
  isSubmitting,
  isValid,
}) => {
  return (
    <div className='FormRegistrase-form'>
      <form onSubmit={handleSubmit}>
        <div className='form-group FormRegistrarse-wrapper'>
          <input
            type='text'
            className='form-control FormRegistrarseInput'
            onChange={handleChange}
            placeholder='Nombre'
            name='name'
            value={values.name}
            style={{
              border:
                errors.name !== undefined && touched.name
                  ? '1px solid #ff0000'
                  : '',
              boxShadow:
                errors.name !== undefined && touched.name
                  ? '0 0 0 0.2rem rgba(255, 0, 0, 0.25)'
                  : '',
            }}
          />
        </div>
        <div className='form-group FormRegistrarse-wrapper'>
          <input
            type='email'
            className='form-control FormRegistrarseInput'
            onChange={handleChange}
            placeholder='Correo electrónico'
            name='email'
            value={values.email}
            style={{
              border:
                errors.email !== undefined && touched.email
                  ? '1px solid #ff0000'
                  : '',
              boxShadow:
                errors.email !== undefined && touched.email
                  ? '0 0 0 0.2rem rgba(255, 0, 0, 0.25)'
                  : '',
            }}
          />
        </div>
        <div className='form-group FormRegistrarse-wrapper'>
          <input
            type='password'
            className='form-control FormRegistrarseInput'
            onChange={handleChange}
            placeholder='Contraseña'
            name='password'
            value={values.password}
            style={{
              border:
                errors.password !== undefined && touched.password
                  ? '1px solid #ff0000'
                  : '',
              boxShadow:
                errors.password !== undefined && touched.password
                  ? '0 0 0 0.2rem rgba(255, 0, 0, 0.25)'
                  : '',
            }}
          />
        </div>
        <div className='form-group FormRegistrarse-wrapper'>
          <input
            type='password'
            className='form-control FormRegistrarseInput'
            onChange={handleChange}
            placeholder='Confirmar contraseña'
            name='confirmPass'
            value={values.confirmPass}
            style={{
              border:
                errors.confirmPass !== undefined && touched.confirmPass
                  ? '1px solid #ff0000'
                  : '',
              boxShadow:
                errors.confirmPass !== undefined && touched.confirmPass
                  ? '0 0 0 0.2rem rgba(255, 0, 0, 0.25)'
                  : '',
            }}
          />
        </div>
        {(errors.email && touched.email) ||
        (errors.password && touched.password) ||
        (errors.confirmPass && touched.confirmPass) ? (
          <div className='error-message'>
            <p style={{ color: '#ff0000', textAlign: 'center', fontSize: 16 }}>
              Correo y/o contraseña inválidos
            </p>
          </div>
        ) : null}
        <div className='form-group aligning-items'>
          <input
            id='terms'
            type='checkbox'
            style={{ display: 'none' }}
            className='check-input-form'
            name='isDifferent'
            checked={values.isDifferent}
            onChange={handleChange}
          />
          <label
            htmlFor='terms'
            className='FormRegistrarse-terms'
            style={{ color: '#58595c' }}
          >
            <span className='FormRegistrarse-check' style={{ width: '16px' }}>
              {values.isDifferent && <img src={Checked} alt='checked' />}
            </span>
            He leido y estoy de acuerdo con los términos y condiciones.
          </label>
        </div>
        <div className='FormRegistrarse-btn-container'>
          <button
            type='submit'
            disabled={isSubmitting || !isValid}
            style={{
              backgroundColor: isValid && '#f47600',
              color: isValid && '#fff',
            }}
          >
            REGISTRARSE
          </button>
        </div>
      </form>
    </div>
  );
};

const FormikFormRegistrarse = withFormik({
  mapPropsToValues() {
    return {
      name: '',
      email: '',
      password: '',
      confirmPass: '',
      isDifferent: false,
    };
  },
  validationSchema: yup.object().shape({
    name: yup.string().max(50).required('Campo obligatorio'),
    email: yup.string().email('Correo invalido').required('Campo obligatorio'),
    password: yup
      .string()
      .min(6, 'La contraseña debe contener al menos 6 caracteres')
      .required('Campo obligatorio'),
    confirmPass: yup
      .string()
      .oneOf([yup.ref('password'), null], 'La contraseña es diferente')
      .required('Confirmar contraseña'),
    isDifferent: yup.boolean().oneOf([true], 'Aceptar Términos y Condiciones'),
  }),
  handleSubmit(values, { resetForm, setErrors, setSubmitting, props }) {
    firebase
      .auth()
      .createUserWithEmailAndPassword(values.email, values.password)
      .then((result) => {
        database
          .ref(`users/${firebase.auth().currentUser.uid}/profile`)
          .set({
            userName: values.name.trim(),
            userEmail: values.email.trim(),
            typeOfUser: 'usuario',
          })
          .then(() => {
            //sendgrid start
            fetch('https://devapi.rhino.lat/send-email', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                email: values.email.trim(),
                subject: 'Bienvenido a la familia',
                templateId: 'd-57ef848ed46643739ece6bf8eae5412d',
                data: { name: values.name.trim() },
              }),
            });
            props.handleClose();
          });
        result.user
          .updateProfile({
            displayName: values.name.trim(),
          })
          .then(() => {
            window.location.reload(true);
          });
      })
      .catch((error) => {
        console.log(`Error: ${error.code}`);
        console.log(`Error: ${error}`);
        if (error.code === 'auth/weak-password') {
          setErrors({
            password: 'La contrasela debe ser de al menos 6 caracteres',
          });
        } else if (error.code === 'auth/invalid-email') {
          setErrors({ email: 'Correo invalido' });
        } else if (error.code === 'auth/email-already-in-use') {
          setErrors({ email: 'Correo existente' });
        }
      });
    setSubmitting(false);
  },
})(FormRegistrarse);

export default withRouter(FormikFormRegistrarse);
