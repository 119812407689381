import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { database } from '../../../../firebase/firebase';
import DashboardTitle from '../../titles/DashboardTitle';
import AddIcon from '../../../../icons/add-agency-dashboard.svg';
import AgencyDetails from './agency-details/AgencyDetails';
import Search from './search/Search';
import PagesDetails from '../../pages-details/PagesDetails';
import ShowDocs from '../../show-docs/ShowDocs';
import PrevNextButtons from '../../buttons/PrevNextButtons';
import '../../../../css/dashboard/agencies/agencies-list/AgenciesList.css';

const AgenciesList = (props) => {
  const [agencies, setAgencies] = useState([]);
  const [search, setSearch] = useState('');
  const [totalDocs, setTotalDocs] = useState(null);
  const [lastId, setLastId] = useState(null);
  const [firstId, setFirstId] = useState(null);
  const [count, setCount] = useState(1);
  const [seen, setSeen] = useState(0);
  const [selectedOption, setSelectedOption] = useState(10);
  const [searchedAgencies, setSearchedAgencies] = useState([]);
  const inputSearch = useRef(null);

  let searchedList;

  useEffect(() => {
    let agencies = [];
    database
      .ref('agencies')
      .limitToLast(parseInt(selectedOption) + 1)
      .once('value')
      .then((snapshot) => {
        snapshot.forEach((childSnap) => {
          agencies.push({
            id: childSnap.key,
          });
        });
        setAgencies([...agencies]);
        setLastId(agencies[0].id);
        setFirstId(agencies[agencies.length - 1].id);
        setSeen(agencies.length - 1);
      });
  }, [selectedOption]);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const prevPage = (last) => {
    let prevData = [];
    database
      .ref('agencies')
      .orderByKey()
      .startAt(firstId)
      .limitToFirst(last)
      .once('value')
      .then((snapshot) => {
        console.log(snapshot.val());
        snapshot.forEach((childSnap) => {
          prevData.push({
            id: childSnap.key,
            ...childSnap.val(),
          });
        });
        setAgencies([...prevData]);
        setLastId(prevData[0].id);
        setFirstId(prevData[prevData.length - 1].id);
        setCount(count - 1);
        console.log(prevData);
        if (prevData.length !== 1) setSeen(seen - (prevData.length - 1));
        else setSeen(seen - 1);
      });
  };

  const nextPage = (last) => {
    let nextData = [];
    database
      .ref('agencies')
      .orderByKey()
      .limitToLast(last)
      .endAt(lastId)
      .once('value')
      .then((snapshot) => {
        console.log(snapshot.val());
        snapshot.forEach((childSnap) => {
          nextData.push({
            id: childSnap.key,
            ...childSnap.val(),
          });
        });
        setLastId(nextData[0].id);
        setAgencies([...nextData]);
        setFirstId(nextData[nextData.length - 1].id);
        setCount(count + 1);
        if (nextData.length !== 1) setSeen(seen + (nextData.length - 1));
        else setSeen(seen + 1);
      });
  };

  const searchAgency = (start) => {
    let search = [];
    database
      .ref('agencies')
      .orderByChild('userName')
      .limitToFirst(10)
      .startAt(start)
      .endAt(start + '\uf8ff')
      .once('value', (snapshot) => {
        snapshot.forEach((snap) => {
          search.push({
            id: snap.key,
          });
        });
        setSearchedAgencies([...search]);
      });
  };

  useEffect(() => {
    if (search === '') {
      setSearchedAgencies([]);
    } else {
      searchAgency(search);
    }
  }, [search]);

  if (searchedAgencies.length > 0) {
    searchedList = searchedAgencies.map((item) => (
      <AgencyDetails id={item.id} key={item.id} />
    ));
  }

  let list = [];

  if (agencies.length > 1) {
    for (let i = agencies.length - 1; i >= 1; i--) {
      list.push(<AgencyDetails id={agencies[i].id} key={agencies[i].id} />);
    }
  } else {
    for (let i = agencies.length - 1; i >= 0; i--) {
      list.push(<AgencyDetails id={agencies[i].id} key={agencies[i].id} />);
    }
  }

  const getTotalDocs = (total) => {
    setTotalDocs(total);
  };

  const handleChangeSelect = (e) => {
    setSelectedOption(e.target.value);
  };

  return (
    <section className='Dashboard-agencies-list'>
      <div className='Dashboard-agencies-header'>
        <DashboardTitle title='Agencias' />
        <div className='Dashboard-agencies-link-wrapper'>
          <Link to='/rh-admin/dashboard/registrar-agencia'>
            <img src={AddIcon} alt='Agregar agencia' />
          </Link>
        </div>
      </div>
      <div className='Dashboard-agencies-search-wrapper'>
        <Search
          search={search}
          handleChange={handleChange}
          inputSearch={inputSearch}
        />
        <ShowDocs value={selectedOption} handleChange={handleChangeSelect} />
      </div>
      <div>
        <div className='Dashboard-titles-wrapper'>
          <div className='Dashboard-table-title'>Nombre</div>
          <div className='Dashboard-table-title'>Agencia</div>
          <div className='Dashboard-table-title'>Correo electrónico</div>
          <div className='Dashboard-table-title'>Estado</div>
        </div>
        <div>{searchedAgencies.length > 0 ? searchedList : list}</div>
        {search === '' && (
          <div className='Dashboard-pagination'>
            <PagesDetails
              count={seen}
              collection='agencies'
              getTotalDocs={getTotalDocs}
            />
            <PrevNextButtons
              disabledPrev={count === 1}
              disabledNext={seen === totalDocs || totalDocs < selectedOption}
              prevFunc={prevPage}
              nextFunc={nextPage}
              limit={parseInt(selectedOption) + 1}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default AgenciesList;
