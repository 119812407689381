import { SteppedEase } from 'gsap';

//Text animation 'Typing effect'
const typing = (anim, elem, widthProp) => {
  //Letter animation
  anim.fromTo(
    elem,
    2,
    {
      width: '0%',
    },
    {
      width: widthProp,
      ease: SteppedEase.config(37),
      onComplete: function () {
        elem.style.borderRightColor = 'transparent';
      },
    },
    0
  );

  elem.style.width = '100%';
};

//Befenits
const typingBenefits = (anim, elem, widthProp) => {
  //Letter animation
  anim.fromTo(
    elem,
    1.3,
    {
      width: '0%',
    },
    {
      width: widthProp,
      ease: SteppedEase.config(37),
      onComplete: function () {
        elem.style.borderRightColor = 'transparent';
      },
    },
    0
  );

  elem.style.width = '100%';
};

//Steps
const typingSteps = (anim, elem, widthProp) => {
  //Letter animation
  anim.fromTo(
    elem,
    1.6,
    {
      width: '0%',
    },
    {
      width: widthProp,
      ease: SteppedEase.config(37),
      onComplete: function () {
        elem.style.borderRightColor = 'transparent';
      },
    },
    0
  );

  elem.style.width = '100%';
};

//Testimonios
const typingTestimonios = (anim, elem, widthProp) => {
  //Letter animation
  anim.fromTo(
    elem,
    1.5,
    {
      width: '0%',
    },
    {
      width: widthProp,
      ease: SteppedEase.config(37),
      onComplete: function () {
        elem.style.borderRightColor = 'transparent';
      },
    },
    0
  );

  elem.style.width = '100%';
};

//Mensaje
const typingMgs = (anim, elem, widthProp) => {
  //Letter animation
  anim.fromTo(
    elem,
    1.5,
    {
      width: '0%',
    },
    {
      width: widthProp,
      ease: SteppedEase.config(37),
      onComplete: function () {
        elem.style.borderRightColor = 'transparent';
      },
    },
    0
  );

  elem.style.width = '100%';
};

//Get date
const getDate = (milliseconds) => {
  let date = new Date(milliseconds);
  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  return (
    date.getDate() + '/' + months[date.getMonth()] + '/' + date.getFullYear()
  );
};

//Parallax function
const scrollParallax = (el, speed) => {
  el.style.transform = `translate3d(0px, -${
    window.pageYOffset * speed
  }px, 0px)`;
};

//Divide numbers with comas
const numberWithCommas = (price) => {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

//Validate Form
const formValid = (formErrors) => {
  let valid = true;
  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  return valid;
};

//Gets extention of file
const getFileExtension = (filename) => {
  return /[.]/.exec(filename) ? /[^.]+$/.exec(filename)[0] : undefined;
};

//Validates that all fields are filled otherwise it prevents to continuo
const validateFields = (arr) => {
  let isEmpty = false;
  arr.forEach((elm) => {
    if (elm === '') isEmpty = true;
  });
  return isEmpty;
};

export {
  typing,
  typingBenefits,
  typingSteps,
  typingTestimonios,
  typingMgs,
  scrollParallax,
  getDate,
  numberWithCommas,
  formValid,
  getFileExtension,
  validateFields,
};
