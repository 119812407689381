import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Switch from '../../../switch/Switch';
import { database } from '../../../../../firebase/firebase';

class AgencyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { item: {} };
  }

  componentDidMount() {
    database.ref(`agencies/${this.props.id}`).on('value', (snapshot) => {
      this.setState({ item: { id: snapshot.key, ...snapshot.val() } });
    });
  }

  componentWillUnmount() {
    database.ref(`agencies/${this.props.id}`).off('value');
  }

  render() {
    return (
      <div className='Dashboard-agency-option'>
        <Link
          to={`/rh-admin/dashboard/visualizar-agencia/${this.state.item.id}`}
          className='Dashboard-AgencyList-link'
        >
          <div>
            <p className='Dashboard-agency-option-data'>
              {this.state.item.userName}
            </p>
          </div>
        </Link>
        <Link
          to={`/rh-admin/dashboard/visualizar-agencia/${this.state.item.id}`}
          className='Dashboard-AgencyList-link'
        >
          <div>
            <p className='Dashboard-agency-option-data'>
              {this.state.item.agency}
            </p>
          </div>
        </Link>
        <Link
          to={`/rh-admin/dashboard/visualizar-agencia/${this.state.item.id}`}
          className='Dashboard-AgencyList-link'
        >
          <div>
            <p className='Dashboard-agency-option-data'>
              {this.state.item.userEmail}
            </p>
          </div>
        </Link>
        <div className='Dashboard-agency-list-switch'>
          <Switch
            status={this.state.item.status}
            id={this.state.item.id}
            mainId={this.state.item.mainAgencyId}
          />
          <p className='Dashboard-agency-option-data'>
            {this.state.item.status}
          </p>
        </div>
      </div>
    );
  }
}

export default AgencyDetails;
