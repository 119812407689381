import React from 'react';
import {Accordion} from 'react-bootstrap';
import Stage from './Stage';

const Stages = (props) => {
    const stages = props.stages.map((stage, index) => (
        <Stage 
            key={stage.id} 
            eventKey={index} 
            name={stage.stageName} 
            stageId={stage.id}
            index={index + 1}
        />
    ));
    return(
        <div>
            <Accordion defaultActiveKey="1">
                {stages}
            </Accordion>
        </div>
    );
}

export default Stages;