import React from 'react';
import { Link } from 'react-router-dom';
import '../../../css/utils/Collapsable/PackagesList.css';

const PackagesList = (props) => (
  <li className="PackagesList">
    <div className="PackagesList-Link-container">
      <Link to={`/categorias/paquete/${props.id}`} className="PackagesList-link">{props.name}</Link>
    </div>
  </li>
);

export default PackagesList;
