import React, {useState} from 'react';
import uuid from 'uuid/v4';
import {database} from '../../../../firebase/firebase';
import {Link} from 'react-router-dom';
import { Card, Accordion } from 'react-bootstrap';
import { getDate } from '../../../../helpers/utils';
import DownArrow from '../../../../icons/down-arrow-grey.svg';
import UpArrow from '../../../../icons/up-arrow-grey.svg';
import RightArrow from '../../../../icons/right-arrow-grey.svg'
import '../../../../css/projects/project-components/CardMobile.css';

const CardMobile = (props) => {
    const { name, index, options, linkTwo, status, refUrl, resetProp } = props;
    const [ isOpened, setIsOpened ] = useState(false);

    const handleMgsViews = () => {
        database.ref(refUrl).update({
            [resetProp]: 0
        })
      }

    const items = options.map((option, index) => {
        if(option.title === 'Mensajes') {
            return (
                <Link to={option.mgsLink} key={uuid()}>
                    <div className='CardMobile-body-wrapper CardMobile-body-wicon' onClick={handleMgsViews}>
                        <p className='CardMobile-body-title'>{option.title}</p>
                        <p className='CardMobile-body-subtitle'>{option.subtitle}</p>
                        {parseInt(option.mgs) > 0 && <div className='CardMobile-mgs-num'><p>{option.mgs}</p></div>}
                        <div className='CardMobile-body-icon'><img src={RightArrow} alt='click'/></div>
                    </div>
                </Link>
            )
        } else if(index !== 0 && index !== 1) {
            return (
                <div key={uuid()} className='CardMobile-body-wrapper'>
                    <p className='CardMobile-body-title'>{option.title}</p>
                    <p className='CardMobile-body-subtitle'>{option.subtitle}</p>
                </div>
            )
        } else {
            return (
                <div key={uuid()} className='CardMobile-body-wrapper'>
                    <p className='CardMobile-body-title'>{option.title}</p>
                    <p className='CardMobile-body-subtitle'>{getDate(option.subtitle)}</p>
                </div>
            )
        }
    })

    const handleOpen = () => {
        setIsOpened(!isOpened);
    }

    return (
        <Card className='CardMobile'>
            <Accordion.Toggle 
                as={Card.Header} 
                eventKey={index} 
                className='CardMobile-header'
                onClick={handleOpen}
            >
                <p className='CardMobile-header-title'>{name}</p>
                <img src={!isOpened ? DownArrow : UpArrow} alt='Flecha'/>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={index}>
                <Card.Body className='CardMobile-body'>
                    <div>
                        {items}
                        <Link to={linkTwo}>
                            <div key={uuid()} className='CardMobile-body-wrapper CardMobile-body-wicon'>
                                <p className='CardMobile-body-title'>Estatus</p>
                                <p className='CardMobile-body-subtitle' style={{
                                    color: (status !== 'Por finalizar' && status !== 'Finalizado') ? '#01355f': '#f47600',
                                    fontWeight: status !== 'Por iniciar' ? 700 : 400
                                  }}>{status}</p>
                                <div className='CardMobile-body-icon'><img src={RightArrow} alt='click'/></div>
                            </div>
                        </Link>
                    </div>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}

export default CardMobile;