import React, { Component } from 'react';
import uuid from 'uuid/v4';
import { database } from '../../../firebase/firebase';
import { Link, withRouter } from 'react-router-dom';
import ArrowExit from '../../../icons/exit_icon.svg';
import Message from '../../../icons/message_icon.svg';
import MgsNotification from '../../../icons/notificacion.svg';
import ProjectAccordion from '../project-components/project-accordion/ProjectAccordion';
import TableHeaders from '../../utils/TableHeaders';
import AccordionMobile from '../project-components/project-accordion-mobile/AccordionMobile';
import CardMobile from '../project-components/project-accordion-mobile/CardMobile';
import RecurrentDeliverables from '../project-components/project-recurrent-deliverables/RecurrentDeliverables';
import Project from '../projectsAdmin/Project';
import { getDate } from '../../../helpers/utils';
import '../../../css/projects/projectsUser/EntregableList.css';
import '../../../css/projects/projectsAdmin/EntregableDashList.css';

class EntregableDashList extends Component {
  constructor(props) {
    super(props);
    this.state = { deliverables: [], purchaseDate: undefined, isLoaded: false };
    this.back = this.back.bind(this);
    this.handleMgsViews = this.handleMgsViews.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let deliverables = [];
    database
      .ref(
        `usersDeliverables/${this.props.match.params.userId}/${
          this.props.match.params.packageId
        }/${this.props.match.params.stageId}`
      )
      .once('value')
      .then((snapshot) => {
        snapshot.forEach((childSnap) => {
          let arr = [];
          database
            .ref(
              `usersPayments/${this.props.match.params.userId}/${
                this.props.match.params.packageId
              }/${childSnap.key}`
            )
            .once('value')
            .then((snapshot) => {
              const obj = {
                id: snapshot.key,
                isPaid: snapshot.val().isPaid,
                date: snapshot.val().date,
                indexOf: snapshot.val().indexOf,
              };
              for (let [key, value] of Object.entries(childSnap.val())) {
                arr.push({
                  id: key,
                  ...value,
                });
              }
              deliverables[obj.indexOf] = { payment: { ...obj }, deliverables: arr };
              const newArr = deliverables.filter(el => el !== undefined)
              this.setState({
                deliverables: [...newArr],
                isLoaded: true,
              });
            });
        });
      });
  }

  back() {
    this.props.history.goBack();
  }

  handleMgsViews(refUrl, resetProp) {
    database.ref(refUrl).update({
        [resetProp]: 0
    })
  }

  render() {
    let stageName = atob(this.props.match.params.stageName);
    let stageNum = atob(this.props.match.params.stageNum);
    let isRecurrent = false;
    const headers = [
      'Entregable',
      'Inicio',
      'Termino',
      'Margen',
      'Mensaje',
      'Estatus',
    ];
   
    let deliverablesItems = [];
    let deliverablesItemsMobile = [];
    let numList = 1;

    this.state.deliverables.forEach((items) => {
      let started = items.payment.date;
      let index = 0;
      let deliverables = [];
      let deliverablesMobile = [];
      items.deliverables.forEach((item) => {
        let count = 0;
        let start = started;
        let finished;
        let totalDays;
        let totalMgs;

        while (count !== parseInt(item.deliverableDuration)) {
          start += 86400000;
          let date = new Date(start);
          if (date.getDay() === 0 || date.getDay() === 6) {
            count--;
          }
          count++;
        }

        finished = start;

        if (item.deliveryDate !== undefined && item.deliveryDate !== '') {
          const milliseconds = finished - item.deliveryDate;
          if (Math.sign(Math.ceil(milliseconds / 86400000)) === 1) {
              totalDays = `+${Math.ceil(milliseconds / 86400000)}`;
          } else if (Math.sign(Math.ceil(milliseconds / 86400000)) === -1) {
            totalDays = `-${Math.ceil(milliseconds / 86400000)}`;
          }
        } else {
          totalDays = '-';
        }

        if(item.userCountMgs)  {
          totalMgs = item.userCountMgs;
        } else {
          totalMgs = 0;
        }

        deliverables.push(
          <Project
            key={item.id}
            items={[
              item.deliverableName,
              getDate(started),
              getDate(finished),
              totalDays,
              <Link to={`/rh-admin/dashboard/msg/${this.props.match.params.id}/${this.props.match.params.packageId}/${this.props.match.params.userId}/etapas/${this.props.match.params.stageId}/${items.payment.id}/entregable/${item.id}/messages`}>
                <div onClick={() => this.handleMgsViews(`usersDeliverables/${this.props.match.params.userId}/${this.props.match.params.packageId}/${this.props.match.params.stageId}/${items.payment.id}/${item.id}`, 'userCountMgs')}>
                  <img src={totalMgs > 0 ? MgsNotification : Message} alt='icono' />
                </div>
              </Link>,
              <Link
                to={`/rh-admin/dashboard/entregable-detalles/${this.props.match.params.id}/${this.props.match.params.packageId}/${this.props.match.params.userId}/etapas/${this.props.match.params.stageId}/${items.payment.id}/entregable/${item.id}`}
                style={{
                  color: (item.status !== 'Por finalizar' && item.status !== 'Finalizado') ? '#01355f': '#f47600',
                  textDecoration: 'none',
                  fontWeight: item.status !== 'Por iniciar' ? 700 : 400
                }}
                className='EntregableList-status-link'
              >
                {item.status}
              </Link>,
            ]}
          />
        );
        deliverablesMobile.push(
          <CardMobile 
            key={item.id} 
            name={item.deliverableName} 
            index={index}
            options={[
              {title: 'Inicio', subtitle: started}, 
              {title: 'Termino', subtitle: finished},
              {title: 'Margen', subtitle: totalDays},
              {title: 'Mensajes', subtitle: `${totalMgs } mensajes`,
              mgsLink: `/rh-admin/dashboard/msg/${this.props.match.params.id}/${this.props.match.params.packageId}/${this.props.match.params.userId}/etapas/${this.props.match.params.stageId}/${items.payment.id}/entregable/${item.id}/messages`,
              mgs: totalMgs
              },
            ]}
            status={item.status}
            linkTwo={`/rh-admin/dashboard/entregable-detalles/${this.props.match.params.id}/${this.props.match.params.packageId}/${this.props.match.params.userId}/etapas/${this.props.match.params.stageId}/${items.payment.id}/entregable/${item.id}`}
            refUrl={`usersDeliverables/${this.props.match.params.userId}/${this.props.match.params.packageId}/${this.props.match.params.stageId}/${items.payment.id}/${item.id}`}
            resetProp='userCountMgs'
          />
        );
        started = finished;
        index++;
      });
      if(this.state.deliverables.length > 1) {
        deliverablesItems.push(
          <ProjectAccordion
            key={uuid()}
            title={`Entregable ${numList}/${this.state.deliverables.length}`}
            isPaid={items.payment.isPaid}

            body={
              <TableHeaders
                key={items.payment.id}
                headers={headers}
                content={deliverables}
              />
            }
          />
        );
        deliverablesItemsMobile.push(
          <RecurrentDeliverables
            key={uuid()}
            title={`Entregable ${numList}/${this.state.deliverables.length}`}
            isPaid={items.payment.isPaid}
            pathUrl={`/rh-admin/dashboard/entregable-detalles/${this.props.match.params.id}/${this.props.match.params.packageId}/${this.props.match.params.userId}/etapas/${this.props.match.params.stageId}/${this.props.match.params.stageNum}/${this.props.match.params.stageName}/entregables/${items.payment.id}/recurrentes`}
          />
        )
        isRecurrent = true
      } else {
        deliverablesItems.push(
          <TableHeaders
            key={items.payment.id}
            headers={headers}
            content={deliverables}
          />
        );
        deliverablesItemsMobile.push(
          <AccordionMobile
            key={items.payment.id}
            items={deliverablesMobile}
          />
        )
      }
      numList+= 1;
    });

    return (
        <section className='EntregableDashList-sec' 
            style={{
                backgroundColor: !isRecurrent ? '#fff' : 'transparent',
                padding: !isRecurrent ? '40px 20px' : 0
            }}
        >
          <div className='container'>
            <div className='row' 
                style={{
                    backgroundColor: isRecurrent ? '#fff' : 'transparent',
                    padding: isRecurrent ? '40px 20px 0 20px' : 0
                }}
            >
              <div
                className='ProjectDetails-exit-icon'
                onClick={this.back}
                style={{ cursor: 'pointer' }}
              >
                <div className='ArrowExit'>
                  <img src={ArrowExit} alt='icono' />
                </div>
                <p>Salir</p>
              </div>
            </div>
            <div className='row' 
                style={{
                    backgroundColor: isRecurrent ? '#fff' : 'transparent',
                    padding: isRecurrent ? '0 20px 40px 20px' : 0
                }}
            >
              <div className='col-12'>
                <div className='EntregableDashList-title'>
                  <h3>
                    {stageNum.split('_').join(' ')}
                    <span style={{ display: 'block' }}>
                      {stageName.split('_').join(' ')}
                    </span>
                  </h3>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12' style={{padding: isRecurrent ? 0 : '0 15px'}}>
                <div 
                    className='EntregableList-wrapper EntregableDashList-wrapper'
                    style={{marginTop: isRecurrent ? 24 : 0}}
                >
                  {deliverablesItems}
                </div>
              </div>
            </div>
          </div>
        </section>
    );
  }
}

export default withRouter(EntregableDashList);