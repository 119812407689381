import React from 'react';
import Select from '../../../utils/forms/select/Select';
import '../../../../css/dashboard/payments/stage-payment/StagePayment.css';
const StagePayment = (props) => {
  const {name, value, handleChange, data} = props;
  return (
    <div className='StagePayment'>
      <Select 
      options={data}
      name={name}
      value={value}
      handleChange={handleChange}
      />
    </div>
  )
};

export default StagePayment;
