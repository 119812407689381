import React from 'react';
import '../../css/utils/tables/TableHeaders.css';

const TableHeaders = (props) => {
    const th = props.headers.map((header, index)=> (
        <th key={index} scope="col">{header}</th>
    ));
    return (
        <table className="table table-striped table-borderless">
            <thead>
                <tr>
                    {th}
                </tr>
            </thead>
            <tbody>
                {props.content}
            </tbody>
        </table>
    );
}

export default TableHeaders;