import React, { Component } from 'react';
import { database } from '../../../firebase/firebase';
import { Link } from 'react-router-dom';
import '../../../css/projects/project-components/StagesDetails.css';

class StageDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { payments: [], ids: [], deliverables: [] };
  }

  componentDidMount() {
    let payments = [];
    let deliverables = [];
    let ids = [];
    database
      .ref(
        `usersDeliverables/${this.props.userId}/${
          this.props.packageId
        }/${this.props.stageId}`
      )
      .once('value')
      .then((snapshot) => {
        snapshot.forEach((childSnap) => {
          ids.push(childSnap.key);
        });
        this.setState({ ids: [...ids] });
        this.state.ids.forEach((id) => {
          database
            .ref(
              `usersPayments/${this.props.userId}/${
                this.props.packageId
              }/${id}`
            )
            .once('value')
            .then((childSnap) => {
              payments.push({
                id: childSnap.key,
                isPaid: childSnap.val().isPaid,
              });
              this.setState({ payments: [...payments] });
            });
        });
        database
          .ref(
            `usersDeliverables/${this.props.userId}/${
              this.props.packageId
            }/${this.props.stageId}/${this.state.ids[0]}`
          )
          .once('value')
          .then((snapshot) => {
            snapshot.forEach((snapChild) => {
              deliverables.push({
                duration: snapChild.val().deliverableDuration,
              });
            });
            this.setState({ deliverables: [...deliverables] });
          });
      });
  }

  render() {
    let count = 0;
    let duration = 0;
    const { stage, stageName, pathUrl } = this.props;
    const name = stageName.split(' ').join('_');
    const num = stage.split(' ').join('_');

    if (this.state.payments.length > 0) {
      this.state.payments.forEach((payment) => {
        if (payment.isPaid) count += 1;
      });
    }

    if (this.state.deliverables.length > 0) {
      this.state.deliverables.forEach((deliverable) => {
        duration += parseInt(deliverable.duration);
      });
    }

    return (
      <div className='StageDetails'>
        <div className='StageDetails-container'>
          <p className='StageDetails-num'>{stage}</p>
          <p className='StageDetails-name'>{stageName}</p>
        </div>
        <div className='StageDetails-container'>
          <p className='StageDetails-days'>Días</p>
          <p className='StageDetails-duration'>{duration}</p>
          <p className='StageDetails-duration-movil'><span>Días:</span> {duration}</p>
        </div>
        <div className='StageDetails-container'>
          <p className='StageDetails-deliverables'>Entregables</p>
          <p className='StageDetails-deliverables-count'>
          {this.state.payments.length > 1 ? 'Recurrentes' : 'Único'} {count}/{this.state.payments.length}
          </p>
          <p className='StageDetails-deliverables-movil'>
           <span>Entregables:</span> {this.state.payments.length > 1 ? 'Recurrentes' : 'Único'}  {count}/{this.state.payments.length}
          </p>
        </div>
        <div className='StageDetails-container'>
          <div className='StageDetails-details-container'>
            <p className='StageDetails-deliverables'>Estatus</p>
            <div className='StageDetails-link-container'>
              {count > 0 ? (
                <Link
                  to={`${pathUrl}/${btoa(name)}/${btoa(num)}/entregables`}
                  className='StageDetails-link'
                >
                  Disponible
                </Link>
              ) : (
                <p className='StageDetails-unavailable'>No disponible</p>
              )}
            </div>
            <p className='StageDetails-status-movil'><span>Estatus:</span> {count > 0 ? (
              <Link
                to={`${pathUrl}/${btoa(name)}/${btoa(num)}/entregables`}
                className='StageDetails-link-movil'
              >
                Disponible
              </Link>
            ) : (
              'No disponible'
            )}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default StageDetails;
