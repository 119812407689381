import React from 'react';

const ProjectInfo = (props) => {
  const { user, packageName, status } = props;
  return (
    <div className='AgencyProfile-project-details-container'>
      <div>
        <p className='AgencyProfile-proyect-details-subtitle'>{user}</p>
      </div>
      <div>
        <p className='AgencyProfile-proyect-details-subtitle'>{packageName}</p>
      </div>
      <div>
        <p className='AgencyProfile-proyect-details-subtitle'>{status}</p>
      </div>
    </div>
  );
};

export default ProjectInfo;
