import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { database } from '../../firebase/firebase';
import Title from '../utils/Title';
import Button from '../utils/Button';

class CategoryEdit extends Component {
  constructor(props) {
    super(props);
    const { categoryName, description, disabled } = this.props.location.state;
    this.state = { categoryName, description, disabled };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();

    const { description, categoryName, disabled } = this.state;
    const { match, history } = this.props;

    database.ref(`categories/${match.params.id}`).update({
      categoryName: categoryName.trim(),
      description: description.trim(),
      disabled,
    });

    this.setState({ categoryName: '', description: '' });
    history.push('/rh-admin/dashboard/categories');
  }

  render() {
    const { categoryName, description, disabled } = this.state;
    const { title } = this.props;

    return (
      <div className="UserForm">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <div className="UserForm-container">
                <Title title={title} />
                <form onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="categoryName">Nombre de categoría:</label>
                    <input
                      id="categoryName"
                      name="categoryName"
                      type="text"
                      placeholder="Nombre de la categoría"
                      className="form-control"
                      onChange={this.handleChange}
                      value={categoryName}
                    />
                    <label htmlFor="categoryName">Descripción de la categoría:</label>
                    <input
                      id="description"
                      name="description"
                      type="text"
                      placeholder="Descripción de la categoría"
                      className="form-control"
                      onChange={this.handleChange}
                      value={description}
                    />
                  </div>
                  <Form.Check
                    label="Deshabilitar"
                    type="checkbox"
                    id="disabled"
                    name="disabled"
                    onChange={() => this.handleChange({ target: { name: 'disabled', value: !disabled } })}
                    value={disabled}
                    checked={disabled}

                  />
                  <Button className="btn btn-primary" label="Editar categoría" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CategoryEdit);
