import React from 'react';
import { database } from '../../../firebase/firebase';
import CreateStage from '../stages/create-stage/CreateStage';
import CreatePayment from '../payments/create-payment/CreatePayment';

export function initPayments(stagesPerPayment) {
  stagesPerPayment.forEach((stages, index) => {
    this.payments.refs[index].current.setStageObject(stages);
  });
}

export async function getPaymentsForPlan(planId) {
  const parent = this;
  return database
    .ref('payments')
    .orderByChild('packageId')
    .equalTo(planId)
    .once('value')
    .then((snapshot) => {
      const payments = [];
      snapshot.forEach((packageSnapshot) => {
        payments.push({ ...packageSnapshot.val(), key: packageSnapshot.key });
        const refName = `${packageSnapshot.key}REF`;
        parent[refName] = React.createRef();
        parent.payments.refs.push(parent[refName]);
      });
      payments.sort((a, b) => a.indexOf - b.indexOf);
      const newPaymentKeys = payments.map((stage) => stage.key);
      const values = payments.reduce((acc, cur) => ({ ...acc, [cur.key]: cur.amount }), {});
      const stagesPerPayment = payments.map(({
        key, amount, indexOf, packageId, ...rest
      }) => rest);

      this.setState({
        paymentkeyNames: newPaymentKeys,
        ...values,
        numberOfPayments: values.length,
        hasData: true,
        updatePayments: newPaymentKeys,
        stagesPerPayment,
      });
    });
}
export async function getDeliverablesForStages(stages) {
  const promises = stages.map((stageId) => database.ref('deliverables')
    .orderByChild('stageId')
    .equalTo(stageId)
    .once('value')
    .then((snap) => snap.val()));

  const resolved = await Promise.all(promises);
  resolved.forEach((deliverables, index) => {
    this.stages.refs[index].current.initDeliverables(deliverables);
  });
}

/**
 * Recupera del backend la información de los stages
 * @param planId
 */
export function getStagesForPlan(planId) {
  const parent = this;
  return database.ref('stages')
    .orderByChild('packageId')
    .equalTo(planId)
    .once('value')
    .then((snapshot) => {
      const stages = [];
      snapshot.forEach((packageSnapshot) => {
        stages.push({ ...packageSnapshot.val(), key: packageSnapshot.key });
        const refName = `${packageSnapshot.key}REF`;
        parent[refName] = React.createRef();
        parent.stages.refs.push(parent[refName]);
      });
      stages.sort((a, b) => a.indexOf - b.indexOf);
      const newStagesKeyNames = stages.map((stage) => stage.key);
      const values = stages.reduce((acc, cur) => ({ ...acc, [cur.key]: cur.stageName }), {});

      this.setState({
        stageskeyNames: newStagesKeyNames,
        ...values,
        numberOfStages: values.length,
        hasData: true,
        updateStages: newStagesKeyNames,
      });
    });
}
/**
 * Recupera del backend la información del plan:
 * Nombre, descripción, y categoría
 * @param planId es el id en la BD del plan
 */
export async function recoverPlanData(planId) {
  await database.ref(`packages/${planId}`).once('value').then((snapshot) => {
    const plan = snapshot.val();
    console.log(plan);
    const newCat = this.state.categories.find(
      (elem) => elem.id === plan.categoryId,
    );

    console.log(newCat);
    this.setState({
      packageId: planId,
      packageName: plan.packageName,
      packageDesc: plan.packageDetails,
      categoryName: newCat.categoryName,
    });
  });
}

export function getCategoriesList() {
  const list = [];
  database
    .ref('categories')
    .once('value')
    .then((snapshot) => {
      snapshot.forEach((snapChild) => {
        list.push({
          id: snapChild.key,
          ...snapChild.val(),
        });
      });
      this.setState({
        categories: [...list],
        categoryName: list[0].categoryName,
      });
    });
}

export function validateRequiredFields(name, value) {
  const { formErrors } = this.state;

  if (name === 'packageName') {
    formErrors.packageName = value.length === 0 ? 'Campo obligatorio' : '';
    formErrors.packageName = value === '' ? 'Campo obligatorio' : '';
  }
  if (name === 'packageDesc') {
    formErrors.packageDesc = value.length === 0 ? 'Campo obligatorio' : '';
  }

  this.state.stageskeyNames.forEach((stage) => {
    if (name === stage) {
      formErrors[stage] = value.length === 0 ? 'Campo obligatorio' : '';
    }
  });

  this.state.paymentkeyNames.forEach((payment) => {
    if (name === payment) {
      formErrors[payment] = value.length === 0 ? 'Campo obligatorio' : '';
    }
  });
  return formErrors;
}

export function getStages(stageskeyNames, formErrors, onDelete) {
  return stageskeyNames.map((stage, index) => (
    <CreateStage
      key={index}
      stageNum={index + 1}
      stageName={stage}
      stageValue={this.state[stage]}
      customError={formErrors[stage]}
      handleChange={this.handleChange}
      ref={this.stages.refs[index]}
      onDelete={() => onDelete(index)}
    />
  ));
}

export function getPayments(paymentkeyNames, packageId, formErrors, onDelete, onCopy) {
  return paymentkeyNames.map((payment, index) => (
    <CreatePayment
      key={payment}
      paymentNum={index + 1}
      paymentAmount={payment}
      paymentValue={this.state[payment]}
      packageId={packageId}
      handleChange={this.handleChange}
      customError={formErrors[payment]}
      ref={this.payments.refs[index]}
      onDelete={() => onDelete(index)}
      onCopy={() => onCopy(index)}
    />
  ));
}

export function validateForm() {
  const {
    formErrors,
    packageName,
    packageDesc,
    stageskeyNames,
  } = this.state;

  if (packageName === '') {
    formErrors.packageName = 'Campo obligatorio';
  }

  if (packageDesc === '') {
    formErrors.packageDesc = 'Campo obligatorio';
  }

  stageskeyNames.forEach((stage) => {
    if (this.state[stage] === '') {
      formErrors[stage] = 'Campo obligatorio';
    }
  });

  this.setState((prevState) => ({
    formErrors: { ...prevState.formErrors, ...formErrors },
  }));
}
