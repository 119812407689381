import React from 'react';
import {Link} from 'react-router-dom';
import {Plus} from 'react-feather';

const Add = (props) => {
    return(
        <div style={{textAlign: 'right'}}>
            <Link to={props.path}><Plus size={20}/></Link>
        </div>
    );
}

export default Add;