import React from 'react';
import '../../../css/dashboard/show-docs/ShowDocs.css';

const ShowDocs = (props) => {
  const { handleChange, value } = props;
  return (
    <div className='ShowDocs-wrapper'>
      <p className='ShowDocs-title'>Mostrar</p>
      <select className='ShowDocs-select' value={value} onChange={handleChange}>
        <option value={15}>15</option>
        <option value={10}>10</option>
        <option value={8}>8</option>
        <option value={5}>5</option>
      </select>
      <p className='ShowDocs-title'>resultados</p>
    </div>
  );
};

export default ShowDocs;
