import React, { Component } from 'react';
import uuid from 'uuid/v4';
import { database } from '../../../../firebase/firebase';
import WhiteContainer from '../../containers/whit-container/WhiteContainer';
import DashboardAccordion from '../../dashboard-accordion/DashboardAccordion';
import StagePayment from '../stage-payment/StagePayment';
import '../../../../css/dashboard/payments/create-payment/CreatePayment.css';

class CreatePayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stages: [], isOpened: false, data: [], stagesObject: null,
    };
    this.addStage = this.addStage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.isOpened = this.isOpened.bind(this);
  }

  componentDidMount() {
    const stages = [];
    database.ref('stages')
      .orderByChild('packageId')
      .equalTo(this.props.packageId)
      .once('value')
      .then((snapshot) => {
        snapshot.forEach((snapChild) => {
          stages.push({
            id: snapChild.key,
            ...snapChild.val(),
          });
        });
        this.setState({ data: [...stages] }, () => this.initStages());
      })
      .catch((e) => {
        console.error(`Could not fetch data ${e}`);
      });
  }

  setStageObject(stagesObject) {
    this.setState({ stagesObject });
  }

  initStages() {
    if (!this.state.stagesObject) { return; }
    Object.entries(this.state.stagesObject).forEach(([key, value]) => {
      this.addStage(key, value);
    });
  }

  addStage(key = uuid().toString(), stageId = '') {
    let defaultValue;
    if (this.state.data.length > 0) defaultValue = this.state.data[0].stageName;
    const stageName = `stageName${key}`;
    if (stageId) {
      const realStage = this.state.data.find((elem) => elem.id === stageId);
      defaultValue = realStage.stageName;
    }
    this.setState((prevState) => ({
      stages: [...prevState.stages, stageName],
      [stageName]: defaultValue !== undefined ? defaultValue : '',
    }));
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  sendDataToDatabase(id) {
    if (this.state.stages.length > 0) {
      for (let i = 0; i < this.state.stages.length; i++) {
        const stage = this.state.data.filter((stage) => stage.stageName === this.state[`${this.state.stages[i]}`]);
        const stageId = stage[0].id;
        database.ref(`payments/${id}`).update({
          [`stageId${i}`]: stageId,
        });
      }
    }
  }

  isOpened() {
    this.setState({ isOpened: !this.state.isOpened });
  }

  render() {
    const {
      paymentAmount,
      paymentValue,
      paymentNum,
      handleChange,
      customError,
      onDelete,
      onCopy,
    } = this.props;

    const stagesName = [];

    if (this.state.data.length > 0) {
      this.state.data.forEach((stage) => {
        stagesName.push(stage.stageName);
      });
    }

    const stages = this.state.stages.map((stage, index) => (
      <StagePayment
        key={index}
        handleChange={this.handleChange}
        name={stage}
        data={stagesName}
        value={this.state[`${stage}`]}
      />
    ));

    return (
      <div className="CreatePayment">
        <WhiteContainer>
          <DashboardAccordion
            num={paymentNum}
            name={paymentAmount}
            type="text"
            value={paymentValue}
            handleChange={handleChange}
            click={this.addStage}
            data={stages}
            isOpened={this.state.isOpened}
            handleOpen={this.isOpened}
            label="Monto"
            placeholder="100000"
            title="Pago"
            innerTitle="Etapas"
            addText="Agregar etapa"
            customError={customError}
            onDeletePressed={onDelete}
            onCopy={onCopy}
          />
        </WhiteContainer>
      </div>
    );
  }
}

export default CreatePayment;
