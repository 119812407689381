import React, { Component } from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { firebase, googleAuthProvider } from '../../../firebase/firebase';
import FormInicioSesion from '../../forms/user/FormInicioSesion';
import Title from '../../utils/Title';
import Google from '../../../img/navbar/google-logo.png';
import GoogleWhite from '../../../img/navbar/google-white.png';
import '../../../css/login/users/SignUp.css';

class Login extends Component {
  constructor(props) {
    super(props);
    this.handleProviderLogin = this.handleProviderLogin.bind(this);
  }

  handleProviderLogin() {
    firebase
      .auth()
      .signInWithPopup(googleAuthProvider)
      .then((result) => {
        this.props.handleClose();
        window.location.reload(true);
      });
  }

  render() {
    if (
      this.props.isAuthenticated === true &&
      this.props.typeOfUser === 'usuario'
    ) {
      return <Redirect to='/proyectos' />;
    }
    return (
      <section className='SignUp'>
        <div className='SignUp-container'>
          <Title title='Iniciar Sesión' />
          <div className='google-signup-container google-desk'>
            <button
              className='google-signup-btn'
              onClick={this.handleProviderLogin}
            >
              <div className='google-icon-container'>
                <img src={Google} alt='Google' />
              </div>
              Continuar con Google
            </button>
          </div>
          <div className='google-signup-container google-movil'>
            <button className='google-signup-btn'>
              <div className='google-icon-container'>
                <img
                  src={GoogleWhite}
                  alt='Google'
                  style={{ width: '25px', height: '25px' }}
                />
              </div>
              Continuar con Google
            </button>
          </div>
          <div className='line-container'>&nbsp;</div>
          <FormInicioSesion
            isAuthenticated={this.isAuthenticated}
            typeOfUser={this.typeOfUser}
            handleClose={this.props.handleClose}
          />
          <div
            style={{ marginTop: 16 }}
            className='SignUp-paragraph Login-paragraph'
          >
            <p style={{ textAlign: 'center', fontSize: 16, color: '#58595c' }}>
              ¿Ya tienes una cuenta?
              <button
                className='btn-con-cuenta'
                onClick={this.props.handleRegistro}
              >
                Regístrate
              </button>
              <Link to='/signup' className='link-con-cuenta'>
                Regístrate
              </Link>
            </p>
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(Login);
