import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { firebase, database } from '../../../firebase/firebase';
import Input from '../../utils/forms/input/Input';
import ButtonOnSubmit from '../../utils/buttons/ButtonOnSubmit';
import Navbar from '../../navbar/Navbar';
import NavbarBg from '../../navbar/NavbarBg';
import Footer from '../../footer/Footer';
import '../../../css/profile/users/EditUserProfile.css';

const EditSchema = yup.object().shape({
  name: yup.string().required('Campo obligatorio'),
  email: yup.string().email('Correo invalido').required('Campo obligatorio'),
});

const EditUserProfile = (props) => {
  const [isAuth, setIsAuth] = useState(false);
  const { history } = props;

  const user = firebase.auth().currentUser;
  let name, email, id;

  if (user != null) {
    name = user.displayName;
    email = user.email;
    id = user.uid;
  }

  return (
    <React.Fragment>
      <Navbar />
      <NavbarBg />
      <section className='EditUserProfile'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='UserProfile-container'>
                <div className='UserProfile-title-wrapper'>
                  <h3 className='UserProfile-title'>Mi Perfil</h3>
                </div>
                <div className='UserProfile-isEdited'>
                  <p className='UserProfile-isEdited-paragraph'>
                    Editar usuario
                  </p>
                  <Formik
                    initialValues={{
                      name: name,
                      email: email,
                      currentEmail: email,
                      currentPassword: '',
                    }}
                    validationSchema={EditSchema}
                    onSubmit={(
                      values,
                      { resetForm, setSubmitting, setErrors }
                    ) => {
                      const user = firebase.auth().currentUser;
                      user
                        .updateProfile({
                          displayName: values.name.trim(),
                        })
                        .then(() => {
                          database.ref(`users/${id}/profile`).update({
                            userName: values.name.trim(),
                          });
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                      user
                        .updateEmail(values.email.trim())
                        .then(() => {
                          database.ref(`users/${id}/profile`).update({
                            userEmail: values.email,
                          });
                          history.push(`/perfil/${id}`);
                        })
                        .catch((error) => {
                          if (error.code === 'auth/requires-recent-login') {
                            setIsAuth(true);
                            if (isAuth && values.currentPassword !== '') {
                              const credential = firebase.auth.EmailAuthProvider.credential(
                                values.currentEmail,
                                values.currentPassword
                              );
                              // Now you can use that to reauthenticate
                              user
                                .reauthenticateWithCredential(credential)
                                .then(() => {
                                  user
                                    .updateEmail(values.email.trim())
                                    .then(() => {
                                      database
                                        .ref(`users/${id}/profile`)
                                        .update({
                                          userEmail: values.email,
                                          userName: values.name,
                                        });
                                      history.push(`/perfil/${id}`);
                                    })
                                    .catch((error) => {
                                      console.log(error);
                                    });
                                })
                                .catch((error) => {
                                  console.log(error);
                                });
                            }
                          }
                        });
                    }}
                  >
                    {({
                      errors,
                      touched,
                      values,
                      handleChange,
                      isSubmitting,
                    }) => (
                      <Form>
                        <div className='UserProfile-input-wrapper'>
                          <Input
                            type='text'
                            label='Nombre'
                            name='name'
                            value={values.name}
                            error={errors.name}
                            isTouched={touched.name}
                            handleChange={handleChange}
                          />
                        </div>
                        <div className='UserProfile-input-wrapper'>
                          <Input
                            type='email'
                            label='Correo electrónico'
                            name='email'
                            value={values.email}
                            error={errors.email}
                            isTouched={touched.email}
                            handleChange={handleChange}
                          />
                        </div>
                        {isAuth && (
                          <React.Fragment>
                            <div className='UserProfile-input-wrapper'>
                              <Input
                                type='email'
                                label='Correo actual'
                                name='currentEmail'
                                value={values.currentEmail}
                                error={errors.currentEmail}
                                isTouched={touched.currentEmail}
                                handleChange={handleChange}
                              />
                            </div>
                            <div className='UserProfile-input-wrapper'>
                              <Input
                                type='password'
                                label='Contraseña actual'
                                name='currentPassword'
                                value={values.currentPassword}
                                error={errors.currentPassword}
                                isTouched={touched.currentPassword}
                                handleChange={handleChange}
                              />
                            </div>
                          </React.Fragment>
                        )}
                        <div className='UserProfile-btns'>
                          <div className='UserProfile-link-cancel-wrapper'>
                            <Link
                              to={`/perfil/${id}`}
                              className='UserProfile-link-cancel'
                            >
                              Cancelar
                            </Link>
                          </div>
                          <div className='UserProfile-btn-save-wrapper'>
                            <ButtonOnSubmit text='GUARDAR' />
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default EditUserProfile;
