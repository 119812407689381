import React, { useState, useEffect, useRef } from 'react';
import DashboardTitle from '../titles/DashboardTitle';
import { database } from '../../../firebase/firebase';
import PaymentAccordion from './payment-accordion/PaymentAccordion';
import CusmonCard from './payment-accordion/CustomCard';
import Search from '../agencies/agencies-list/search/Search';
import PrevNextButtons from '../buttons/PrevNextButtons';
import PagesDetails from '../pages-details/PagesDetails';
import ShowDocs from '../show-docs/ShowDocs';
import '../../../css/dashboard/payments-details/PaymentDetails.css';

const PaymentDetails = (props) => {
  const [payments, setPayments] = useState([]);
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [lastId, setLastId] = useState(null);
  const [firstId, setFirstId] = useState(null);
  const [count, setCount] = useState(1);
  const [totalDocs, setTotalDocs] = useState(null);
  const [seen, setSeen] = useState(0);
  const [selectedOption, setSelectedOption] = useState(5);
  const inputSearch = useRef(null);
  let cards = [];
  let searchedList;

  useEffect(() => {
    let payments = [];
    database
      .ref('paymentsMade')
      .orderByChild('userName')
      .limitToLast(parseInt(selectedOption) + 1)
      .once('value')
      .then((snapshot) => {
        snapshot.forEach((childSnap) => {
          payments.push({
            id: childSnap.key,
            ...childSnap.val(),
          });
        });
        setLastId(payments[0].id);
        setFirstId(payments[payments.length - 1].id);
        setPayments([...payments]);
        setSeen(payments.length - 1);
      });
  }, [selectedOption]);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const prevPage = (last) => {
    let prevData = [];
    database
      .ref('paymentsMade')
      .orderByKey()
      .startAt(firstId)
      .limitToFirst(last)
      .once('value')
      .then((snapshot) => {
        console.log(snapshot.val());
        snapshot.forEach((childSnap) => {
          prevData.push({
            id: childSnap.key,
            ...childSnap.val(),
          });
        });
        setPayments([...prevData]);
        setLastId(prevData[0].id);
        setFirstId(prevData[prevData.length - 1].id);
        setCount(count - 1);
        console.log(prevData);
        if (prevData.length !== 1) setSeen(seen - (prevData.length - 1));
        else setSeen(seen - 1);
      });
  };

  const nextPage = (last) => {
    let nextData = [];
    database
      .ref('paymentsMade')
      .orderByKey()
      .limitToLast(last)
      .endAt(lastId)
      .once('value')
      .then((snapshot) => {
        snapshot.forEach((childSnap) => {
          nextData.push({
            id: childSnap.key,
            ...childSnap.val(),
          });
        });
        setLastId(nextData[0].id);
        setPayments([...nextData]);
        setFirstId(nextData[nextData.length - 1].id);
        setCount(count + 1);
        if (nextData.length !== 1) setSeen(seen + (nextData.length - 1));
        else setSeen(seen + 1);
      });
  };

  const getTotalDocs = (total) => {
    setTotalDocs(total);
  };

  const handleChangeSelect = (e) => {
    setSelectedOption(e.target.value);
  };

  const searchUser = (start) => {
    let search = [];
    database
      .ref('paymentsMade')
      .orderByChild('userName')
      .limitToFirst(10)
      .startAt(start)
      .endAt(start + '\uf8ff')
      .once('value', (snapshot) => {
        snapshot.forEach((snap) => {
          search.push({
            id: snap.key,
            ...snap.val(),
          });
        });
        setSearchedUsers([...search]);
      });
  };

  useEffect(() => {
    if (search === '') {
      setSearchedUsers([]);
    } else {
      searchUser(search);
    }
  }, [search]);

  if (searchedUsers.length > 0) {
    searchedList = searchedUsers.map((item, index) => (
      <CusmonCard
        key={item.id}
        index={index}
        id={item.id}
        user={item.userName}
        userEmail={item.userEmail}
        date={item.date}
        typeOfPayment={item.paidWith}
        amount={item.amount}
        packageName={item.packageName}
        payNum={item.paymentNum}
        briefId={item.briefId}
        isPending={item.isPending}
        paymentId={item.paymentId}
        userId={item.userId}
        packageId={item.packageId}
        details={item}
      />
    ));
  }

  if (payments.length > 1) {
    for (let i = payments.length - 1; i >= 1; i--) {
      cards.push(
        <CusmonCard
          key={payments[i].id}
          index={i}
          id={payments[i].id}
          user={payments[i].userName}
          userEmail={payments[i].userEmail}
          date={payments[i].date}
          typeOfPayment={payments[i].paidWith}
          amount={payments[i].amount}
          packageName={payments[i].packageName}
          payNum={payments[i].paymentNum}
          briefId={payments[i].briefId}
          isPending={payments[i].isPending}
          paymentId={payments[i].paymentId}
          userId={payments[i].userId}
          packageId={payments[i].packageId}
          details={payments[i]}
        />
      );
    }
  } else {
    for (let i = payments.length - 1; i >= 0; i--) {
      cards.push(
        <CusmonCard
          key={payments[i].id}
          index={i}
          id={payments[i].id}
          user={payments[i].userName}
          userEmail={payments[i].userEmail}
          date={payments[i].date}
          typeOfPayment={payments[i].paidWith}
          amount={payments[i].amount}
          packageName={payments[i].packageName}
          payNum={payments[i].paymentNum}
          briefId={payments[i].briefId}
          isPending={payments[i].isPending}
          paymentId={payments[i].paymentId}
          userId={payments[i].userId}
          packageId={payments[i].packageId}
          details={payments[i]}
        />
      );
    }
  }

  return (
    <section>
      <DashboardTitle title='Pagos' />
      <div className='PaymentDetails-search'>
        <ShowDocs value={selectedOption} handleChange={handleChangeSelect} />
        <Search
          search={search}
          handleChange={handleChange}
          inputSearch={inputSearch}
        />
      </div>
      <div className='PaymentDetails'>
        <PaymentAccordion>
          {searchedUsers.length > 0 ? searchedList : cards}
        </PaymentAccordion>
      </div>
      {search === '' && (
        <div className='PaymentDetails-btns-dash'>
          <div>
            <PagesDetails
              count={seen}
              collection='paymentsMade'
              getTotalDocs={getTotalDocs}
            />
          </div>
          <PrevNextButtons
            disabledPrev={count === 1}
            disabledNext={seen === totalDocs}
            prevFunc={prevPage}
            nextFunc={nextPage}
            limit={parseInt(selectedOption) + 1}
          />
        </div>
      )}
    </section>
  );
};

export default PaymentDetails;
