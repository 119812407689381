import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Edit2, Trash2 } from 'react-feather';
import { database } from '../../firebase/firebase';
import Button from '../utils/Button';

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = { packages: [] };
  }

  componentDidMount() {
    const packages = [];
    database.ref('packages').orderByChild('categoryId').equalTo(this.props.id).once('value')
      .then((snapshot) => {
        snapshot.forEach((childSnapshot) => {
          packages.push({
            id: childSnapshot.key,
            ...childSnapshot.val(),
          });
        });
        this.setState({ packages: [...packages] });
      })
      .catch((e) => {
        console.log(`Error fetching data ${e}`);
      });
  }

  render() {
    const {
      path,
      handleShow,
      id,
      name,
      description,
      disabled,
    } = this.props;
    const { packages } = this.state;
    return (
      <tr>
        <td>{id}</td>
        <td>{name}</td>
        <td>{description}</td>
        <td>
          <Button
            handleClick={() => handleShow({ id, collection: 'packages', arr: packages })}
            link={(<Trash2 size="20" />)}
          />
        </td>
        <td>
          <Link to={
            {
              pathname: `/rh-admin/dashboard/edit-category/${id}`,
              state: {
                categoryName: name,
                description,
                disabled,
              },
            }
          }
          >
            <Edit2 size="20" />
          </Link>
        </td>
      </tr>
    );
  }
}

export default Details;
