import React, { Component } from 'react';
import Lottie from 'react-lottie';
import { withRouter } from 'react-router-dom';
import { database } from '../.././../firebase/firebase';
import * as animationData from '../../../lottie/lottie_loading.json';
import EntregableOptions from '../projectsAgency/EntregableOptions';
import ArrowExit from '../../../icons/exit_icon.svg';
import '../../../css/projects/projectsAgency/EntregableDetails.css';
import '../../../css/projects/projectsUser/EntregableDetailsUser.css';
import '../../../css/projects/projectsAdmin/EntregableDashDetails.css';


class EntregableDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { deliverable: {}, isLoaded: false, fileName: null };
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    database
      .ref(
        `usersDeliverables/${this.props.match.params.userId}/${this.props.match.params.packageId}/${this.props.match.params.stageId}/${this.props.match.params.payment}/${this.props.match.params.deliverableId}`
      )
      .on('value', (snapshot) => {
        this.setState({
          deliverable: { id: snapshot.key, ...snapshot.val() },
          isLoaded: true,
        });
      });
  }

  componentWillUnmount() {
    database
      .ref(
        `usersDeliverables/${this.props.match.params.userId}/${this.props.match.params.packageId}/${this.props.match.params.stageId}/${this.props.match.params.payment}/${this.props.match.params.deliverableId}`
      )
      .off();
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    if(this.state.file !== null) console.log(this.state.fileName, this.state.file)
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData.default,
    };
    let deliveryDate = null;
    let url = null;
    let fileName = null;

    if (this.state.deliverable.fileName !== undefined && this.state.deliverable.deliveryDate !== '') {
      deliveryDate = this.state.deliverable.deliveryDate;
    } else {
      deliveryDate = null;
    }

    if (this.state.deliverable.fileName !== undefined && this.state.deliverable.pathUrl !== '') {
      url = this.state.deliverable.pathUrl;
    } else {
      url = null;
    }

    if (this.state.deliverable.fileName !== undefined && this.state.deliverable.fileName !== '') {
      fileName = this.state.deliverable.fileName;
    } else {
      fileName = null;
    }

    return (
        <section>
          <div className='container'>
            <div className='row'>
              <div
                className='ProjectDetails-exit-icon'
                onClick={this.goBack}
                style={{ cursor: 'pointer' }}
              >
                <div className='ArrowExit'>
                  <img src={ArrowExit} alt='icono' />
                </div>
                <p>Salir</p>
              </div>
            </div>
            {this.state.isLoaded ? (
              <React.Fragment>
                <div className='row'>
                  <div className='col-12'>
                    <div className='EntregableDashDetails-title'>
                      <h3>{this.state.deliverable.deliverableName}</h3>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='EntregableDashDetails-options'>
                      <EntregableOptions
                        desc={this.state.deliverable.deliverableDesc}
                        options={this.state.deliverable.deliverableList}
                        status={this.state.deliverable.status}
                        deliveryDate={deliveryDate}
                        file={url}
                        fileName={fileName}
                      />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <div className='row'>
                <div className='col-12'>
                  <Lottie options={defaultOptions} height={400} width={320} />
                </div>
              </div>
            )}
          </div>
        </section>
    );
  }
}

export default withRouter(EntregableDetails);
