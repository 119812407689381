import React from 'react';
import Input from '../../../utils/forms/input/Input';
import Textarea from '../../../utils/forms/textarea/Textarea';
import { validateFields } from '../../../../helpers/utils';
import ButtonOnClick from '../../../utils/buttons/ButtonOnClick';
import '../../../../css/brief/BriefForms.css';

const StepOne = (props) => {
  const {
    companyName,
    companyInfo,
    slogan,
    strengths,
    weaknesses,
    next,
    handleChange,
    errorCompanyName,
    errorCompanyInfo,
    errorSlogan,
    errorStrengths,
    errorWeaknesses,
  } = props;

  const fieldValues = [companyName, companyInfo, slogan, strengths, weaknesses];

  return (
    <div>
      <form>
        <div className='Brief-form-container'>
          <Input
            type='text'
            label='¿Cómo se llama tu marca/empresa?'
            placeholder='Textiles John Doe'
            value={companyName}
            name='companyName'
            handleChange={handleChange}
            customError={errorCompanyName}
          />
        </div>
        <div className='Brief-form-container'>
          <Input
            type='text'
            label='¿Qué slogan o frase de venta tiene?'
            placeholder='Slogan'
            value={slogan}
            name='slogan'
            handleChange={handleChange}
            customError={errorSlogan}
          />
        </div>
        <div className='Brief-form-container'>
          <Textarea
            type='text'
            label='Describe a qué se didica tu marca:'
            placeholder='Venta de ropa'
            value={companyInfo}
            name='companyInfo'
            handleChange={handleChange}
            customError={errorCompanyInfo}
            message='Cuéntanos un poco sobre el giro de tu negocio, que hacen, etc.'
          />
        </div>
        <div className='Brief-form-container'>
          <Textarea
            type='text'
            label='Haz una lista de las fortalezas de la marca:'
            placeholder='Responsable, puntual, proactiva…'
            value={strengths}
            name='strengths'
            handleChange={handleChange}
            customError={errorStrengths}
          />
        </div>
        <div className='Brief-form-container'>
          <Textarea
            type='text'
            label='Haz una lista de las debilidades de la marca:'
            placeholder=''
            value={weaknesses}
            name='weaknesses'
            handleChange={handleChange}
            customError={errorWeaknesses}
          />
        </div>
      </form>
      <div className='Step-button-container'>
        <ButtonOnClick
          text='CONTINUAR'
          click={next}
          isDisabled={validateFields(fieldValues)}
        />
      </div>
    </div>
  );
};

export default StepOne;
