import React from 'react';
import Input from '../../../utils/forms/input/Input';
import Textarea from '../../../utils/forms/textarea/Textarea';
import { validateFields } from '../../../../helpers/utils';
import ButtonOnClick from '../../../utils/buttons/ButtonOnClick';
import '../../../../css/brief/BriefForms.css';

const StepThree = (props) => {
  const {
    next,
    back,
    handleChange,
    competitors,
    directCompetitor,
    errorCompetitors,
    errorDirectCompetitor,
  } = props;

  const fieldsValues = [competitors, directCompetitor];

  return (
    <div>
      <form>
        <div className='Brief-form-container'>
          <Textarea
            type='text'
            label='¿Tienes identificadas otras propuestas similares a la tuya?'
            placeholder='Textiles 1, Textiles 2…'
            value={competitors}
            name='competitors'
            handleChange={handleChange}
            customError={errorCompetitors}
            message='Haz una pequeña lista de soluciones que ofrecen algo similar a lo que tu brindas.'
          />
        </div>
        <div className='Brief-form-container'>
          <Input
            type='text'
            label='¿Cuál es el nombre de tu competencia?'
            placeholder='Textiles T…'
            value={directCompetitor}
            name='directCompetitor'
            handleChange={handleChange}
            customError={errorDirectCompetitor}
            message='¿Qué marcas hacen exactamente lo mismo que tú?'
          />
        </div>
      </form>
      <div className='Step-button-container two-btns'>
        <ButtonOnClick text='ATRÁS' click={back} />
        <ButtonOnClick
          text='CONTINUAR'
          click={next}
          isDisabled={validateFields(fieldsValues)}
        />
      </div>
    </div>
  );
};

export default StepThree;
