import React from 'react';
import List from '../../utils/list/List';
import '../../../css/packageUserInterface/Deliverable.css';

const Deliverable = (props) => {
    const {deliverableName, deliverableDesc, deliverableList} = props;
    return(
        <div className='Deliverable-container'>
            <p className='Deliverable-title'>{deliverableName}</p>
            <p className='Deliverable-desc'>{deliverableDesc}</p>
           {deliverableList !== undefined && <List list={deliverableList} />}
        </div>
    )
}

export default Deliverable;