import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getDate } from '../../../helpers/utils';
import { database } from '../../../firebase/firebase';
import OrangeRhino from '../../../img/projects/projectAgencies/rhino_agency_img.png';
import '../../../css/projects/project-components/Project.css';

const Project = (props) => {
  const { briefId, pathUrl, deliverables, isAgency } = props;
  const [status, setStatus] = useState(null);
  let totalMgs = 0;
  let newMgs = 0;

  deliverables.forEach((deliverable) => {
    if (deliverable.mgsCount) {
      totalMgs += deliverable.mgsCount;
    }
    if (deliverable.msg !== undefined) {
      newMgs += deliverable.msg;
    }
  });

  const isFinished = (deliverables) => {
    for (let i = 0; i < deliverables.length; i++) {
      if (deliverables[i].status !== 'Finalizado') {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (isFinished(deliverables)) {
      database
        .ref(`briefs/${briefId}`)
        .update({
          status: 'Finalizado',
        })
        .then(() => {
          setStatus('Finalizado');
        });
    } else {
      setStatus(props.status);
    }
  }, [deliverables, briefId, props.status]);

  return (
    <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
      <div className='Project-wrapper'>
        <Link to={pathUrl} className='Project-link'>
          <div
            className='card card-project'
            style={{
              border:
                props.status === 'Por iniciar' && isAgency
                  ? 'solid 4px #f47600'
                  : null,
            }}
          >
            {props.status === 'Por iniciar' && isAgency ? (
              <div
                className='card-project-img'
                style={{
                  backgroundImage:
                    props.status === 'Por iniciar' && isAgency
                      ? `url(${OrangeRhino})`
                      : null,
                }}
              >
                &nbsp;
              </div>
            ) : (
              <div className='card-project-img'>&nbsp;</div>
            )}
            <div className='card-body'>
              <h5 className='card-title card-title-project'>
                Paquete Rhino <span>{props.name}</span>
              </h5>
              <p
                className='card-subtitle mb-2 text-muted card-subtitle-project'
                style={{ color: '#58595c' }}
              >
                Estatus:{' '}
                <span
                  style={{ color: status === 'Pendiente' ? 'red' : '#58595c' }}
                >
                  {status === 'Pendiente' ? (
                    <strong>{`${status} (Pago)`}</strong>
                  ) : (
                    <strong>{status}</strong>
                  )}
                </span>
              </p>
              <p
                className='card-text card-text-project'
                style={{ color: '#58595c' }}
              >
                Fecha de compra: {getDate(props.date)}
              </p>
              <p
                className='card-subtitle mb-2 text-muted card-subtitle-project'
                style={{ margin: 0, color: '#58595c' }}
              >
                {totalMgs} mensajes (
                {newMgs > 1 ? (
                  <strong>{`${newMgs} nuevos`}</strong>
                ) : (
                  <strong>{`${newMgs} nuevo`}</strong>
                )}
                )
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Project;
