import React from 'react';
import HornImg from '../../../img/progress/horn.svg';
import '../../../css/utils/progress/Horn.css';

const Horn = (props) => {
    return (
        <div className='ProgressBar-horn-container' ref={props.horn}>
            <div className='ProgressBar-horn' style={{display: 'inline-block'}}>
                <img src={HornImg} alt='Horn'/>
            </div>
        </div>
    );
}

export default Horn;