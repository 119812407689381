import React from 'react';
import { Modal } from 'react-bootstrap';
import ButtonOnClick from '../utils/buttons/ButtonOnClick';
import '../../css/modal/ResetModal.css';

const StageModal = (props) => {
  return (
    <Modal centered show={props.show} onHide={props.handleClose}>
      <Modal.Body className='ResetModal-body'>
        <div className='ResetModal'>
          <div className='ResetModal-paragraph-container'>
            <p
              style={{ color: '#58595c', textAlign: 'center' }}
              className='ResetModal-paragraph'
            >
              {props.message}
            </p>
          </div>
          <div className='ResetModal-btn-container'>
            <ButtonOnClick text='ACEPTAR' click={props.handleClose} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default StageModal;
