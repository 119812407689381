import React, { Component } from 'react';
import uuid from 'uuid/v4';
import { withRouter } from 'react-router-dom';
import { database, firebase } from '../../../firebase/firebase';
import { formValid, getFileExtension } from '../../../helpers/utils';
import Add from '../../utils/buttons/Add';
import WhiteContainer from '../containers/whit-container/WhiteContainer';
import CreatePackage from '../../forms/admin/create-package/CreatePackage';
import MenuButton from '../../utils/buttons/MenuButton';
import '../../../css/dashboard/DashboardPackages.css';
import {
  getCategoriesList,
  getDeliverablesForStages,
  getPayments,
  getPaymentsForPlan,
  getStages,
  getStagesForPlan,
  initPayments,
  recoverPlanData,
  validateForm,
  validateRequiredFields,
} from './utils';

class DashboardPackageForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      packageName: '',
      packageDesc: '',
      categoryName: '',
      file: null,
      isUploaded: false,
      // Nombre en el estado de los stages
      stageskeyNames: [],
      // Nombre en el estado de los pagos
      paymentkeyNames: [],
      formErrors: { packageName: '', packageDesc: '' },
      stagesSent: false,
      hasData: false,
      numberOfStages: 0,
      numberOfPayments: 0,
      packageId: uuid(),
      stagesToEdit: [],
      updateMode: false,
      updateStages: [],
      deleteStages: [],
      uploadFile: false,
      createStages: [],
      updatePayments: [],
      stagesPerPayment: [],
      createPayments: [],
    };
    this.history = props.history;
    this.stages = { refs: [] };
    this.payments = { refs: [] };
    this.handleChange = this.handleChange.bind(this);
    this.fileHandleChange = this.fileHandleChange.bind(this);
    this.submitStages = this.submitStages.bind(this);
    this.addStage = this.addStage.bind(this);
    this.addPayment = this.addPayment.bind(this);
    this.sendPayments = this.sendPayments.bind(this);
  }

  componentDidMount() {
    getCategoriesList.call(this);
    const { params } = this.props.match;
    const planId = params.id;
    if (planId) {
      this.onUpdateInit(planId);
    }
  }

  async onUpdateInit(planId) {
    this.setState({
      updateMode: true,
    });

    await recoverPlanData.call(this, planId);
    await getStagesForPlan.call(this, planId);
    await getDeliverablesForStages.call(this, this.state.stageskeyNames);
    await getPaymentsForPlan.call(this, planId);
  }

  addRefToStages(name, index) {
    this[`${name}${index}`] = React.createRef();
    this.stages.refs.push(this[`${name}${index}`]);
  }

  addRefToPayments(name, index) {
    this[`${name}${index}`] = React.createRef();
    this.payments.refs.push(this[`${name}${index}`]);
  }

  generateStagesIds(stagesArray, newArray) {
    stagesArray.forEach(() => {
      const id = uuid();
      newArray.push(id);
    });
  }

  addStage() {
    const key = uuid();
    const stageName = `stageName${key}`;
    this.setState((prevState) => ({
      stageskeyNames: [...prevState.stageskeyNames, stageName],
      [stageName]: '',
      createStages: [...prevState.createStages, stageName],
      formErrors: { ...prevState.formErrors, [stageName]: '' },
    }));
    this.addRefToStages('stage', this.state.numberOfStages);
    this.setState({ addedStages: this.state.numberOfStages + 1, hasData: true });
  }

  removeStage(index) {
    const stagesLeft = this.state.numberOfStages - 1;
    const { stageskeyNames } = this.state;
    stageskeyNames.splice(index, 1);
    this.stages.refs.splice(index, 1);

    this.setState({ stageskeyNames, addedStages: stagesLeft, hasData: stagesLeft > 0 });
  }

  addPayment() {
    const paymentAmount = uuid().toString();
    this.setState((prevState) => ({
      paymentkeyNames: [...prevState.paymentkeyNames, paymentAmount],
      [paymentAmount]: '',
      formErrors: { ...prevState.formErrors, [paymentAmount]: '' },
      createPayments: [...prevState.createPayments, paymentAmount],
    }));
    this.addRefToPayments('payment', this.state.numberOfPayments);
    this.setState({
      addedPayments: this.state.numberOfPayments + 1,
      hasData: true,
    });
  }

  async copyPayment(index) {
    const paymentKey = uuid().toString();
    const amount = this.state[this.state.paymentkeyNames[index]];
    console.log('index:', index);
    const otherArray = [...this.state.paymentkeyNames];
    otherArray.splice(index + 1, 0, paymentKey);
    this.setState((prevState) => ({
      paymentkeyNames: otherArray,
      [paymentKey]: amount,
      formErrors: { ...prevState.formErrors, [paymentKey]: '' },
      createPayments: [...prevState.createPayments, paymentKey],
    }));
    const refName = `${paymentKey}REF`;
    this[refName] = React.createRef();
    this.payments.refs.splice(index + 1, 0, this[refName]);
    setTimeout(() => {
      console.log('index:', index);
      const { stagesObject } = this.payments.refs[index].current.state;
      console.log(stagesObject);
      this.payments.refs[index + 1].current.setStageObject(stagesObject);
      this.payments.refs[index + 1].current.initStages();
    }, 1000);

    this.setState({
      addedPayments: this.state.numberOfPayments + 1,
    });
  }

  removePayment(index) {
    const paymentsLeft = this.state.numberOfPayments - 1;
    const { paymentkeyNames } = this.state;
    paymentkeyNames.splice(index, 1);
    this.payments.refs.splice(index, 1);

    this.setState({ paymentkeyNames, addedPayments: paymentsLeft, hasData: paymentsLeft > 0 });
  }

  validatePayments() {
    const { formErrors, paymentkeyNames } = this.state;

    paymentkeyNames.forEach((payment) => {
      if (this.state[payment] === '') {
        formErrors[payment] = 'Campo obligatorio';
      }
    });

    this.setState((prevState) => ({
      formErrors: { ...prevState.formErrors, ...formErrors },
    }));
  }

  handleChange(e) {
    const { name, value } = e.target;
    const formErrors = validateRequiredFields.call(this, name, value);

    this.setState({ formErrors, [name]: value });
  }

  fileHandleChange(e) {
    const file = e.target.files[0];
    this.setState({ file, isUploaded: true });
  }

  submitStages(e) {
    e.preventDefault();

    // Validates if fields are not empty
    validateForm.call(this);

    // Validates if there are not any empty fields inside the deliverable form
    if (this.state.stageskeyNames.length > 0) {
      for (let i = 0; i < this.state.stageskeyNames.length; i += 1) {
        this.stages.refs[i].current.validateDeliverables();
      }
    }

    if (!formValid(this.state.formErrors)) {
      return;
    }
    // Validates if there is no error message inside deliverables form
    if (this.state.stageskeyNames.length > 0) {
      for (let i = 0; i < this.state.stageskeyNames.length; i += 1) {
        if (!this.stages.refs[i].current.isAValidForm()) return;
      }
    }

    // Filters only the category that matches the categoryName
    const category = this.state.categories.filter(
      (element) => element.categoryName === this.state.categoryName,
    );
    const categoryId = category[0].id;

    if (this.state.updateMode) {
      database.ref(`packages/${this.state.packageId}`).update({
        packageName: this.state.packageName.trim(),
        packageDetails: this.state.packageDesc.trim(),
        categoryId,
      });
    } else {
      // Checks if the file is a img (.jpg, .png)
      const fileXt = getFileExtension(this.state.file.name);
      if (fileXt !== 'jpg' && fileXt !== 'png' && fileXt !== 'jpeg') {
        alert('Extensión de archivo no válida. Solo se acepta jpg, png y jpeg');
        return;
      }

      this.createPlanWithImage(categoryId);
    }

    const stageIds = [];
    this.generateStagesIds(this.state.createStages, stageIds);
    if (this.state.createStages.length > 0) {
      for (let i = 0; i < this.state.createStages.length; i++) {
        database.ref(`stages/${stageIds[i]}`).set({
          packageId: this.state.packageId,
          stageName: this.state[`${this.state.stageskeyNames[i]}`].trim(),
          indexOf: i,
        });
        this.stages.refs[i].current.sendDataToDatabase(`${stageIds[i]}`);
      }
    }

    if (this.state.updateStages.length > 0) {
      this.state.updateStages.forEach((stageId, index) => {
        console.log(stageId);
        database.ref(`stages/${stageId}`).update({
          packageId: this.state.packageId,
          stageName: this.state[stageId].trim(),
          indexOf: index,
        });
        this.stages.refs[index].current.sendDataToDatabase(stageId);
      });
    }

    this.setState({ stagesSent: true }, () => {
      initPayments.call(this, this.state.stagesPerPayment);
    });
  }

  createPlanWithImage(categoryId) {
    // Change spaces on file name for _ and adds timestamp
    const fileName = this.state.file.name;
    const fileNameWSpaces = fileName.replace(/\s/g, '_');
    // Get extension of file
    const ext = getFileExtension(fileNameWSpaces);
    const fileNameAndExt = fileNameWSpaces.split(`.${ext}`);
    const newFileName = `${fileNameAndExt[0]}${uuid()}.${ext}`;

    // Sends img to firebase storage and if everything goes well, saves packageData to database
    const storageRef = firebase.storage().ref(`imgPackages/${newFileName}`);
    const task = storageRef.put(this.state.file);
    task.on(
      'state_changed',
      (snapshot) => {
        console.log(snapshot);
      },
      (error) => {
        console.error(error.message);
      },
      () => {
        firebase
          .storage()
          .ref()
          .child(`imgPackages/${newFileName}`)
          .getDownloadURL()
          .then((url) => {
            if (url) {
              database.ref(`packages/${this.state.packageId}`).set({
                packageName: this.state.packageName.trim(),
                packageDetails: this.state.packageDesc.trim(),
                imgPath: url,
                categoryId,
              });
            }
          })
          .catch((e) => {
            console.log(`Error: ${e}`);
          });
      },
    );
  }

  sendPayments(e) {
    this.validatePayments();
    if (formValid(this.state.formErrors)) {
      if (this.state.createPayments.length > 0) {
        // Generates Id por payment
        this.state.createPayments.forEach((paymentId, index) => {
          database.ref(`payments/${paymentId}`).set({
            amount: this.state[paymentId].trim(),
            packageId: this.state.packageId,
            indexOf: index,
          });
          this.payments.refs[index].current.sendDataToDatabase(paymentId);
        });
      }

      if (this.state.updatePayments.length > 0) {
        this.state.updatePayments.forEach((paymentId, index) => {
          database.ref(`payments/${paymentId}`).update({
            amount: this.state[paymentId].trim(),
            packageId: this.state.packageId,
            indexOf: index,
          });
          this.payments.refs[index].current.sendDataToDatabase(paymentId);
        });
      }
    }
    this.history.push('/rh-admin/dashboard/packages/');
  }

  render() {
    const categoriesName = [];
    const {
      stageskeyNames,
      hasData,
      paymentkeyNames,
      packageId,
      categories,
      stagesSent,
      packageName,
      categoryName,
      packageDesc,
      formErrors,
    } = this.state;

    categories.forEach((category) => {
      categoriesName.push(category.categoryName);
    });

    return (
      <div className="DashboardPackages-main-container">
        <div className="DashboardPackages-package">
          <WhiteContainer>
            <CreatePackage
              categories={categoriesName}
              packageName={packageName}
              packageDesc={packageDesc}
              categoryName={categoryName}
              handleChange={this.handleChange}
              fileHandleChange={this.fileHandleChange}
              errorPackageName={formErrors.packageName}
              errorPackageDesc={formErrors.packageDesc}
            />
          </WhiteContainer>
        </div>
        {hasData && (
        <div className="DashboardPackages-inner-menu">
          <div className="DashboardPackages-btn-stages">
            <MenuButton text="Etapas" disabled={stagesSent} />
          </div>
          <div className="DashboardPackages-btn-payment">
            <MenuButton text="Pagos" disabled={!stagesSent} />
          </div>
        </div>
        )}
        {
          !stagesSent
          && getStages.call(this, stageskeyNames, formErrors, (index) => this.removeStage(index))
        }
        {
          stagesSent
          && getPayments.call(
            this, paymentkeyNames, packageId, formErrors, (index) => this.removePayment(index),
            (index) => this.copyPayment(index),
          )
        }
        <div className="DashboardPackages-btns">
          <Add
            text={!stagesSent ? 'Agregar etapa' : 'Agregar pago'}
            click={!stagesSent ? this.addStage : this.addPayment}
          />
          {hasData && (
          <button
            className="Continue-btn"
            onClick={
              stagesSent ? this.sendPayments : this.submitStages
            }
          >
            {stagesSent ? 'Finalizar' : 'Continuar a pagos'}
          </button>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(DashboardPackageForm);
