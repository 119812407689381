import React, { useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import DownArrow from '../../../../icons/down-arrow-orange.svg'
import UpArrow from '../../../../icons/up-arrow-orange.svg'
import '../../../../css/projects/project-components/ProjectAccordion.css'

const ProjectAccordion = (props) => {
    const {body, title, isPaid} = props;
    const [isOpened, setIsOpened] = useState(false);

    const handleOpen = () => {
        setIsOpened(!isOpened);
    }

    return (
        <Accordion className='ProjectAccordion'>
            <Card className='ProjectAccordion-card' onClick={isPaid ? handleOpen : null}>
                <Accordion.Toggle as={Card.Header} eventKey="0" className='ProjectAccordion-card-header'>
                    <div className='ProjectAccordion-title-wrapper'>
                        <p className='ProjectAccordion-title' style={{color: isPaid ? '#f47600' : '#58595c'}}>{title}</p>
                        <img src={!isOpened ? DownArrow : UpArrow} alt='Flecha'/>
                    </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                    <Card.Body className='ProjectAccordion-card-body'>
                        {isPaid ? body : <p className='NoPaid'>Entregable temporalmenete inactivo</p>}
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    )
}

export default ProjectAccordion;