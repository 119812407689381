import React, { useContext, useEffect, useState } from 'react';
import { Accordion, Card, useAccordionToggle } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AccordionContext from 'react-bootstrap/AccordionContext';
import ArrowDown from '../../../../icons/dash-grey-downArrow.svg';
import ArrowUp from '../../../../icons/dash-orange-upArrow.svg';
import { numberWithCommas, getDate } from '../../../../helpers/utils';
import ConfirmButton from '../button/ConfirmButton';
import { database } from '../../../../firebase/firebase';
import '../../../../css/dashboard/payments-details/payment-accordion/CustomCard.css';

function CustomToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div onClick={decoratedOnClick}>
      {children}
      <div className='CustomCard-arrow'>
        <img src={isCurrentEventKey ? ArrowUp : ArrowDown} alt='Flecha' />
      </div>
    </div>
  );
}

const CusmonCard = (props) => {
  const {
    index,
    id,
    user,
    date,
    typeOfPayment,
    amount,
    packageName,
    payNum,
    briefId,
    paymentId,
    userId,
    packageId,
    isPending,
    details,
    userEmail,
  } = props;

  const [status, setStatus] = useState(isPending);
  const [positions, setPositions] = useState([]);
  let stagesPositions = '';
  let stagesIds = [];
  let ids = [];

  for (let [key, value] of Object.entries(details)) {
    if (
      key !== 'packageId' &&
      key !== 'amount' &&
      key !== 'indexOf' &&
      key !== 'isPaid' &&
      key !== 'date' &&
      key !== 'id' &&
      key !== 'briefId' &&
      key !== 'isPending' &&
      key !== 'packageName' &&
      key !== 'paidWith' &&
      key !== 'paymentId' &&
      key !== 'paymentNum' &&
      key !== 'userEmail' &&
      key !== 'userId' &&
      key !== 'userName' &&
      key !== 'capture' &&
      key !== 'currency' &&
      key !== 'type' &&
      key !== 'status'
    ) {
      stagesIds.push(value);
    }
  }

  useEffect(() => {
    const unsubscribe = database
      .ref(`paymentsMade/${id}`)
      .on('child_changed', (snapshot) => {
        setStatus(snapshot.val().isPending);
      });
    return () => database.ref(`paymentsMade/${id}`).off('value', unsubscribe);
  }, []);

  useEffect(() => {
    let positions = [];
    database
      .ref(`usersStages/${userId}/${packageId}`)
      .once('value')
      .then((snapshot) => {
        snapshot.forEach((childSnap) => {
          ids.push(childSnap.key);
        });
        for (let j = 0; j < stagesIds.length; j++) {
          for (let i = 0; i < ids.length; i++) {
            if (stagesIds[j] === ids[i]) {
              positions.push(i + 1);
            }
          }
        }
        setPositions([...positions]);
      });
  }, []);

  if (positions.length > 0) {
    for (let i = 0; i < positions.length; i++) {
      if (i === positions.length - 1) {
        stagesPositions += `${positions[i]}`;
      } else {
        stagesPositions += `${positions[i]}, `;
      }
    }
  }

  const approvedPayment = () => {
    database
      .ref(`usersPayments/${userId}/${packageId}/${paymentId}`)
      .update({
        isPaid: true,
        isPending: false,
      })
      .then(() => {
        database.ref(`paymentsMade/${id}`).update({
          isPaid: true,
          isPending: false,
        });
      });
  };

  return (
    <Card className='CustomCard'>
      <div className='CustomCard-main-content'>
        <CustomToggle eventKey={index} className='BriefDetails-header'>
          <div className='CustomCard-wrapper'>
            <div>
              {status && (
                <p className='CustomCard-body-title'>Datos del cliente</p>
              )}
              <p className='CustomCard-details'>{user}</p>
              {status && (
                <p className='CustomCard-details-email'>{userEmail}</p>
              )}
              {!status && <p className='CustomCard-details'>{getDate(date)}</p>}
              {!status && <p className='CustomCard-details'>{typeOfPayment}</p>}
            </div>
            {status && (
              <div>
                <p className='CustomCard-body-title'>Detalles de pago</p>
                <p className='CustomCard-body-subtitle'>
                  {payNum}: Etapas {stagesPositions}
                </p>
                <ConfirmButton text='Confirmar pago' click={approvedPayment} />
              </div>
            )}
            <div>
              <p className='CustomCard-details CustomCard-amount'>
                ${numberWithCommas(amount)}.00
              </p>
              <p className='CustomCard-details'>{`${packageName} (${payNum})`}</p>
            </div>
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey={index}>
          <Card.Body className='CustomCard-body'>
            <div>
              <p className='CustomCard-body-title'>Datos del paquete</p>
              <p className='CustomCard-body-subtitle'>{packageName}</p>
              <Link
                to={`/rh-admin/dashboard/proyectos/${briefId}/etapas`}
                className='CustomCar-link'
              >
                Ir a paquete
              </Link>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </div>
    </Card>
  );
};

export default CusmonCard;
