import React from 'react';
import '../../../../css/dashboard/payments-details/button/ConfirmButton.css';

const ConfirmButton = (props) => {
  const { text, click } = props;
  return (
    <button type='button' className='ConfirmButton' onClick={click}>
      {text}
    </button>
  );
};

export default ConfirmButton;
