import React, { useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import data from '../../../helpers/data';
import ArrowDown from '../../../img/home-agency/arrow-down-faq.svg';
import ArrowUp from '../../../img/home-agency/arrow-up-faq.svg';
import '../../../css/home-agency/accordion/QuestionAccordion.css';

const QuestionAccordion = (props) => {
  const [isOpened, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(!isOpened);
  };

  const questions = data.map((item, index) => (
    <Card key={index} className="QuestionAccordion-card">
      <Accordion.Toggle as={Card.Header} eventKey={index} className="QuestionAccordion-header">
        <div>
          <img src={ArrowDown} alt="Flecha" />
          <p className="QuestionAccordion-title">{item.question}</p>
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={index}>
        <Card.Body className="QuestionAccordion-body">
          <p className="QuestionAccordion-answer">{item.answer}</p>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  ));

  return (
    <Accordion defaultActiveKey="0">
      {questions}
    </Accordion>
  );
};

export default QuestionAccordion;
