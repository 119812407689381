import React  from 'react';
import '../../../css/categories/userInterface/PackageName.css';

const PackageName = (props) => {
    return (
        <div className='PackageName'>
            <h1>{props.package}</h1>
            <p>{props.details}</p>
        </div>
    );
}

export default PackageName;