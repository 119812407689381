import React, { useEffect, useState } from 'react';
import { database, firebase } from '../../../../firebase/firebase';
import '../../../../css/dashboard/projects/assign-project/AssignProject.css';
import 'firebase/functions';

const functions = firebase.functions();

const AssingProject = (props) => {
  const {
    agency, user, id, briefId, email,
  } = props;
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const projects = [];
    database
      .ref('briefs')
      .orderByChild('agency')
      .equalTo(id)
      .once('value')
      .then((snapshot) => {
        snapshot.forEach((childSnap) => {
          projects.push({
            status: childSnap.val().status,
          });
        });
        setProjects([...projects]);
      });
  }, [id]);

  const assign = () => {
    database.ref(`briefs/${briefId}`).update({
      agency: id,
    });
    // sendgrid start
    const sendEmail = functions.httpsCallable('sendAgencyAssignedEmail');
    sendEmail({
      email,
      agencyName: agency,
    });
  };

  let isAvailable = true;

  if (projects.length > 0) {
    projects.forEach((project) => {
      if (project.status === 'En proceso') {
        isAvailable = false;
      }
    });
  }

  return (
    <div className="AssignProject">
      <div>
        <p className="AssignProject-title">{agency}</p>
      </div>
      <div>
        <p className="AssignProject-title">{user}</p>
      </div>
      <div>
        <p className="AssignProject-title">
          {isAvailable ? 'Disponible' : 'En proyecto'}
        </p>
      </div>
      <div>
        <button className="AssignProject-btn" onClick={assign}>
          Asignar
        </button>
      </div>
    </div>
  );
};

export default AssingProject;
