import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import FormLoginAgency from '../../forms/agencies/FormLoginAgency';
import Title from '../../utils/Title';
import '../../../css/login/users/SignUp.css';

class LoginAgency extends Component {
  render() {
    if (
      this.props.isAuthenticated === true &&
      this.props.typeOfUser === 'agency'
    ) {
      return <Redirect to='/agencias/proyectos' />;
    }
    return (
      <section className='SignUp'>
        <div className='SignUp-container'>
          <Title title='Iniciar Sesión' />
          <FormLoginAgency
            isAuthenticated={this.isAuthenticated}
            typeOfUser={this.typeOfUser}
            handleClose={this.props.handleClose}
          />
        </div>
      </section>
    );
  }
}

export default LoginAgency;

