import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { database, firebase } from '../../../firebase/firebase';
import { getDate, numberWithCommas } from '../../../helpers/utils';
import '../../../css/projects/projectsUser/Payment.css';

const AgencyPayment = (props) => {
  const { num, date, amount, isPaid, obj, userId, id, packageId } = props;
  const [stages, setStages] = useState([]);
  const [packageName, setPackageName] = useState(null);

  let stagesIds = [];
  let stagesPositions = [];
  let stagesNum;

  for (let [key, value] of Object.entries(obj)) {
    if (
      key !== 'packageId' &&
      key !== 'amount' &&
      key !== 'indexOf' &&
      key !== 'isPaid' &&
      key !== 'date' &&
      key !== 'id'
    ) {
      stagesIds.push(value);
    }
  }

  useEffect(() => {
    let stages = [];
    database
      .ref(`usersStages/${userId}/${obj.packageId}`)
      .once('value')
      .then((snapshot) => {
        snapshot.forEach((childSnap) => {
          stages.push({
            id: childSnap.key,
          });
        });
        setStages([...stages]);
      });
      database
        .ref(`usersPackages/${userId}/${packageId}`)
        .once('value').then(snapshot => {
          setPackageName(snapshot.val().packageName)
        }).catch(e => {
          console.log(e)
        })
  }, [obj.packageId, packageId, userId]);

  if (stages.length > 0) {
    for (let j = 0; j < stagesIds.length; j++) {
      for (let i = 0; i < stages.length; i++) {
        if (stagesIds[j] === stages[i].id) stagesPositions.push(i);
      }
    }

    if (stagesPositions.length === 1) {
      stagesNum = 'Etapa';
      for (let j = 0; j < stagesPositions.length; j++) {
        stagesNum += ` ${stagesPositions[j] + 1}`;
      }
    } else if (stagesPositions.length === 2) {
      stagesNum = 'Etapas ';
      for (let i = 0; i < stagesPositions.length; i++) {
        if (i === stagesPositions.length - 1)
          stagesNum += ` ${stagesPositions[i] + 1}`;
        else stagesNum += ` ${stagesPositions[i] + 1} y`;
      }
    } else {
      stagesNum = 'Etapas ';
      for (let k = 0; k < stagesPositions.length; k++) {
        if (k === stagesPositions.length - 1) {
          stagesNum += ` ${stagesPositions[k] + 1}`;
        } else if (k === stagesPositions.length - 2) {
          stagesNum += ` ${stagesPositions[k] + 1} y`;
        } else stagesNum += ` ${stagesPositions[k] + 1}, `;
      }
    }
  }

  let encodedAmount = btoa(amount);
  let encodedPackageName = btoa(packageName);
  let encodedPaymentNum = btoa(`Pago ${num}`);

  let ganancia = amount * 0.75;

  return (
    <div className='Payment-project'>
      <div>
        <div>
          <p className='Payment-title-project Payment-align-project'>
            Pago {num}
          </p>
          <p className='Payment-title-proyect-movil font-movil'><span>Pago {num}</span></p>
        </div>
      </div>
      <div>
        <div>
          <p className='Payment-title-project Payment-align-project'>Monto</p>
        </div>
        <div>
          <p className='Payment-subtitle-project'>
            ${numberWithCommas(amount)}.00
          </p>
        </div>
        <p className='Payment-title-proyect-movil'><span>Monto:</span> ${numberWithCommas(amount)}.00</p>
      </div>
      <div>
        <div>
          <p className='Payment-title-project Payment-align-project'>Etapas</p>
        </div>
        <div>
          <p className='Payment-subtitle-project'>{stagesNum}</p>
        </div>
        <p className='Payment-title-proyect-movil'><span>Etapas:</span> {stagesNum}</p>
      </div>
      <div>
        <p className='Payment-title-project Payment-align-project'>Ganancia</p>
        <p className='Payment-subtitle-project'>${numberWithCommas(ganancia)}.00</p>
        <p className='Payment-title-proyect-movil'><span>Ganancia:</span> ${numberWithCommas(ganancia)}.00</p>
      </div>
    </div>
  );
};

export default AgencyPayment;
