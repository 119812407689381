import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import Lottie from 'react-lottie';
import { database, firebase } from '../.././../firebase/firebase';
import * as animationData from '../../../lottie/lottie_loading.json';
import EntregableOptions from '../projectsAgency/EntregableOptions';
import ArrowExit from '../../../icons/exit_icon.svg';
import NavbarBgAgency from '../../navbar/NavbarBgAgency';
import NavbarAgency from '../../navbar/NavbarAgency';
import FooterAgency from '../../footer/FooterAgency';
import ButtonOnClick from '../../utils/buttons/ButtonOnClick';
import AddFileIcon from '../../../icons/add_file.svg';
import DeleteFile from '../../../icons/delete_file.svg';
import '../../../css/projects/projectsAgency/EntregableDetails.css';
import '../../../css/projects/projectsUser/EntregableDetailsUser.css';

class EntregableDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deliverable: {},
      file: null,
      show: false,
      isLoaded: false,
      fileSrc: null,
      fileName: null,
      user: {},
      isUploading: false,
    };
    this.goBack = this.goBack.bind(this);
    this.handleOnDrop = this.handleOnDrop.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    database
      .ref(
        `usersDeliverables/${this.props.match.params.userId}/${this.props.match.params.packageId}/${this.props.match.params.stageId}/${this.props.match.params.payment}/${this.props.match.params.deliverableId}`
      )
      .on('value', (snapshot) => {
        this.setState({
          deliverable: { id: snapshot.key, ...snapshot.val() },
          isLoaded: true,
        });
      });
    database
      .ref(`users/${this.props.match.params.userId}/profile`)
      .once('value')
      .then((snapshot) => {
        this.setState({
          user: {
            userName: snapshot.val().userName,
            userEmail: snapshot.val().userEmail,
          },
        });
      });
  }

  componentWillUnmount() {
    database
      .ref(
        `usersDeliverables/${this.props.match.params.userId}/${this.props.match.params.packageId}/${this.props.match.params.stageId}/${this.props.match.params.payment}/${this.props.match.params.deliverableId}`
      )
      .off();
  }

  goBack() {
    this.props.history.goBack();
  }

  handleOnDrop(files, rejectedFiles) {
    if (files && files.length > 0) {
      const file = files[0];
      const name = files[0].name.split(' ').join('_');
      this.setState({ file: file, fileName: name });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ isUploading: true });
    const name = this.state.file.name.split(' ').join('_');
    const storageRef = firebase
      .storage()
      .ref(
        `files/${this.props.match.params.userId}/${this.props.match.params.deliverableId}/${name}`
      );
    const task = storageRef.put(this.state.file);
    task.on(
      'state_changed',
      (snapshot) => {
        //success
      },
      (error) => {
        console.error(error.message);
      },
      () => {
        firebase
          .storage()
          .ref()
          .child(
            `files/${this.props.match.params.userId}/${this.props.match.params.deliverableId}/${name}`
          )
          .getDownloadURL()
          .then((url) => {
            if (this.state.isLoaded) {
              database
                .ref(
                  `usersDeliverables/${this.props.match.params.userId}/${this.props.match.params.packageId}/${this.props.match.params.stageId}/${this.props.match.params.payment}/${this.props.match.params.deliverableId}`
                )
                .update({
                  pathUrl: url,
                  deliveryDate: firebase.database.ServerValue.TIMESTAMP,
                  fileName: this.state.file.name.split(' ').join('_'),
                  status: 'Por finalizar',
                });
              //sendgrid start
              fetch('https://devapi.rhino.lat/send-email', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  email: this.state.user.userEmail,
                  subject: '',
                  templateId: 'd-6535e96306f04d639b59f8070efc2472',
                  data: {
                    name: this.state.user.userName,
                  },
                }),
              });
            }
            this.setState({ isUploading: false });
          })
          .catch((e) => {
            console.log(`Error: ${e}`);
            this.setState({ isUploading: false });
          });
      }
    );
  }

  handleDelete() {
    var storageRef = firebase.storage().ref();
    // Create a reference to the file to delete
    var desertRef = storageRef.child(
      `files/${this.props.match.params.userId}/${this.props.match.params.deliverableId}/${this.state.deliverable.fileName}`
    );
    // Delete the file
    desertRef
      .delete()
      .then(() => {
        if (this.state.isLoaded) {
          database
            .ref(
              `usersDeliverables/${this.props.match.params.userId}/${this.props.match.params.packageId}/${this.props.match.params.stageId}/${this.props.match.params.payment}/${this.props.match.params.deliverableId}`
            )
            .update({
              pathUrl: '',
              deliveryDate: '',
              fileName: '',
              status: 'En proceso',
            })
            .then(() => {
              this.setState({ show: false, file: null, fileName: null });
            });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleClose() {
    this.setState({ show: false });
  }

  render() {
    if (this.state.file !== null)
      console.log(this.state.fileName, this.state.file);
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData.default,
    };
    let deliveryDate = null;
    let url = null;
    let fileName = null;

    if (
      this.state.deliverable.fileName !== undefined &&
      this.state.deliverable.deliveryDate !== ''
    ) {
      deliveryDate = this.state.deliverable.deliveryDate;
    } else {
      deliveryDate = null;
    }

    if (
      this.state.deliverable.fileName !== undefined &&
      this.state.deliverable.pathUrl !== ''
    ) {
      url = this.state.deliverable.pathUrl;
    } else {
      url = null;
    }

    if (
      this.state.deliverable.fileName !== undefined &&
      this.state.deliverable.fileName !== ''
    ) {
      fileName = this.state.deliverable.fileName;
    } else {
      fileName = null;
    }

    return (
      <div>
        <NavbarAgency />
        <NavbarBgAgency />
        <section className='EntregableDetails EntregableDetailsUser'>
          <div className='container'>
            <div className='row'>
              <div
                className='ProjectDetails-exit-icon'
                onClick={this.goBack}
                style={{ cursor: 'pointer' }}
              >
                <div className='ArrowExit'>
                  <img src={ArrowExit} alt='icono' />
                </div>
                <p>Salir</p>
              </div>
            </div>
            {this.state.isLoaded ? (
              <React.Fragment>
                <div className='row'>
                  <div className='col-12'>
                    <div className='ProjectDetails-title'>
                      <h3>{this.state.deliverable.deliverableName}</h3>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div>
                      <EntregableOptions
                        desc={this.state.deliverable.deliverableDesc}
                        options={this.state.deliverable.deliverableList}
                        status={this.state.deliverable.status}
                        deliveryDate={deliveryDate} //deliverableDate
                        file={url} //url
                        fileName={fileName} //filename
                        delete={this.handleDelete}
                        iconDelete={DeleteFile}
                        show={this.state.show}
                        handleClose={this.handleClose}
                        handleShow={this.handleShow}
                      />
                    </div>
                  </div>
                  <div className='col-12'>
                    {deliveryDate === null && (
                      <p className='Dropzone-title'>Archivo final</p>
                    )}
                    {deliveryDate === null && (
                      <div>
                        <p className='Dropzone-fileName'>
                          {this.state.fileName}
                        </p>
                      </div>
                    )}
                  </div>
                  {deliveryDate === null && (
                    <React.Fragment>
                      <div className='col-12'>
                        <Dropzone onDrop={this.handleOnDrop}>
                          {({ getRootProps, getInputProps }) => (
                            <section className='Dropzone-sec'>
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <p className='Dropzone-text-oragen'>
                                  <img
                                    src={AddFileIcon}
                                    alt='Agregar'
                                    style={{ marginRight: 6 }}
                                    className='DropzoneIcon'
                                  />
                                  Adjunta un archivo
                                </p>
                                <p className='Dropzone-text-grey'>
                                  o arrástralo aquí
                                </p>
                              </div>
                            </section>
                          )}
                        </Dropzone>
                      </div>
                    </React.Fragment>
                  )}
                  {deliveryDate === null && (
                    <div className='UploadBtnWrapper'>
                      <ButtonOnClick
                        text={
                          !this.state.isUploading ? 'Cargar' : 'Cargando...'
                        }
                        click={this.handleSubmit}
                        isDisabled={
                          this.state.file === null ||
                          this.state.deliverable.status === 'Por iniciar' ||
                          this.state.isUploading
                        }
                      />
                    </div>
                  )}
                </div>
              </React.Fragment>
            ) : (
              <div className='row'>
                <div className='col-12'>
                  <Lottie options={defaultOptions} height={400} width={320} />
                </div>
              </div>
            )}
          </div>
        </section>
        <FooterAgency />
      </div>
    );
  }
}

export default EntregableDetails;
