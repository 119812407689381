import React, { Component, createRef } from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'react-feather';
import { TimelineLite, TimelineMax, Power3, Elastic } from 'gsap';
import { firebase, database } from '../../firebase/firebase';
import { typing, scrollParallax } from '../../helpers/utils';
import Isla from '../../img/jungle/isla.gif';
import '../../css/homepage/Jungle.css';

class Jungle extends Component {
  constructor(props) {
    super(props);
    this.state = { isTouched: false, isLoaded: false, user: {} };
    //Ref to DOMNodes
    this.sectionRef = createRef();
    this.paragraph = null;
    this.title = null;
    this.packageOne = null;
    this.packageTwo = null;
    this.packageThree = null;
    this.packageFour = null;
    this.packageFive = null;
    this.animParagraph = null;
    //Instans of Gsap
    this.anim = new TimelineLite();
    this.onEnterAnim = new TimelineLite({ paused: true });
    this.onLeaveAnim = new TimelineLite({ paused: true });
    this.animTitle = new TimelineMax();
    //Ref to Graph
    this.island = null;
    this.animIsland = new TimelineMax();
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleScrollSec = this.handleScrollSec.bind(this);
  }

  handleMouseEnter(e) {
    this.onEnterAnim.staggerTo(
      e.currentTarget.children,
      0.4,
      { scale: 1.1, ease: Elastic.easeOut.config(1, 0.5) },
      0.1
    );
    this.setState({ isTouched: true });
    this.onEnterAnim.play();
  }

  handleMouseLeave(e) {
    this.onLeaveAnim.staggerTo(
      e.currentTarget.children,
      0.4,
      { scale: 1, ease: Elastic.easeOut.config(0.75, 0.5) },
      0.1
    );
    this.setState({ isTouched: false });
    if (this.state.isTouched) {
      this.onLeaveAnim.play();
    } else {
      this.onLeaveAnim.kill();
    }
  }

  handleScrollSec() {
    scrollParallax(this.detailsSec, 0.3);
  }

  handleScroll() {
    scrollParallax(this.island, 0.2);
  }

  componentDidMount() {
    //user info
    if (firebase.auth().currentUser !== null) {
      database
        .ref(`users/${firebase.auth().currentUser.uid}/profile`)
        .once('value')
        .then((snap) => {
          this.setState({ user: { ...snap.val() }, isLoaded: true });
        });
    }

    const packages = [
      this.packageOne,
      this.packageTwo,
      this.packageThree,
      this.packageFour,
      this.packageFive,
    ];

    //Trigger the animation when the section is in the viewport using Intersection observer
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && entries[0].intersectionRatio >= 0.1) {
        this.title.style.visibility = 'visible';
        typing(this.animTitle, this.title, '100%');
        this.anim.to(
          this.paragraph,
          1.3,
          { opacity: 1, y: 0, ease: Power3.easeOut },
          '-=1.5'
        );
        for (let i = 0; i < packages.length; i++) {
          this.anim.staggerTo(
            packages[i].children,
            1.5,
            { scale: 1, ease: Elastic.easeOut.config(0.85, 0.5) },
            0.25,
            '-=1'
          );
        }
      }
    }, options);

    observer.observe(this.sectionRef.current);

    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    let userName = undefined;
    let firtName = undefined;
    if (this.state.isLoaded && firebase.auth().currentUser) {
      userName = this.state.user.userName;
      userName = userName.split(' ');
      firtName = userName[0];
    }
    return (
      <section ref={this.sectionRef} className='Jungle'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='Jungle-title-container'>
                <h2 ref={(el) => (this.title = el)} className='Jungle-title'>
                  <strong>
                    ¡BIENVENIDO A LA JUNGLA{' '}
                    {userName !== undefined ? (
                      <span style={{ color: '#f47600' }}>
                        {firtName.toUpperCase()}
                      </span>
                    ) : null}
                    !
                  </strong>
                </h2>
              </div>
              <div
                ref={(el) => (this.paragraph = el)}
                className='Jungle-paragraph-container'
              >
                <p>
                  Este es el hogar de tu empresa y te ofrecemos que lo hagas más
                  poderoso con estos servicios. Elige tu proyecto.
                </p>
              </div>
            </div>
          </div>
          <div className='row mg movil-none'>
            <div className=' col-12 col-sm-12 col-md-4 col-lg-4'>
              <div
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                ref={(el) => (this.packageOne = el)}
                className='Jungle-items-container'
              >
                <div className='Jungle-leave-one'>&nbsp;</div>
                <div className='Jungle-leave-two'>&nbsp;</div>
                <div className='Jungle-item Jungle-item-one'>
                  <div className='Jungle-item-bg'>&nbsp;</div>
                  <div className='Jungle-item-text'>
                    <p>Paquete Rhino</p>
                    <h5>
                      <strong>Negocio integral</strong>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className=' col-12 col-sm-12 col-md-4 col-lg-4'>
              <div
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                ref={(el) => (this.packageTwo = el)}
                className='Jungle-items-container'
              >
                <div className='Jungle-leave-one'>&nbsp;</div>
                <div className='Jungle-leave-two'>&nbsp;</div>
                <div className='Jungle-item Jungle-item-two'>
                  <div className='Jungle-item-bg'>&nbsp;</div>
                  <div className='Jungle-item-text'>
                    <p>Paquete Rhino</p>
                    <h5>
                      <strong>Negocio medio</strong>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className=' col-12 col-sm-12 col-md-4 col-lg-4'>
              <div
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                ref={(el) => (this.packageThree = el)}
                className='Jungle-items-container'
              >
                <div className='Jungle-leave-one'>&nbsp;</div>
                <div className='Jungle-leave-two'>&nbsp;</div>
                <div className='Jungle-item Jungle-item-three'>
                  <div className='Jungle-item-bg'>&nbsp;</div>
                  <div className='Jungle-item-text'>
                    <p>Paquete Rhino</p>
                    <h5>
                      <strong>Negocio básico</strong>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row justify-content-end'>
            <div className='col-12 col-sm-12 col-md-4 col-lg-4'>
              <div
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                ref={(el) => (this.packageFour = el)}
                className='Jungle-items-container'
              >
                <div className='Jungle-leave-one'>&nbsp;</div>
                <div className='Jungle-leave-two'>&nbsp;</div>
                <div className='Jungle-item Jungle-item-four'>
                  <div className='Jungle-item-bg'>&nbsp;</div>
                  <div className='Jungle-item-text'>
                    <p>Paquete Rhino</p>
                    <h5>
                      <strong>Negocio micro</strong>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-sm-12 col-md-4 col-lg-4'>
              <div
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                ref={(el) => (this.packageFive = el)}
                className='Jungle-items-container'
              >
                <div className='Jungle-leave-one'>&nbsp;</div>
                <div className='Jungle-leave-two'>&nbsp;</div>
                <div className='Jungle-item Jungle-item-five'>
                  <div className='Jungle-item-bg'>&nbsp;</div>
                  <div className='Jungle-item-text'>
                    <p>Paquete Rhino</p>
                    <h5>
                      <strong>RS posicionamiento</strong>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row justify-content-end mg movil-none'>
            <div className='col-12 col-sm-12 col-md-3 col-lg-6'>
              <div className='Jungle-btn'>
                <Link
                  to='/categorias'
                  className='Jungle-btn-link hvr-shutter-out-horizontal'
                >
                  VER CATEGORIAS{' '}
                  <ArrowRight className='arrow-icon' color='white' size={20} />
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* Inicia sección movil */}
        <div className='Jungle-packages-movil'>
          <div className='Jungle-package-movil-container'>
            <div className='Jungle-package-img-container package-one'>
              &nbsp;
            </div>
            <div className='Jungle-package-details'>
              <p>Paquete Rhino</p>
              <h4>Negocio integral</h4>
            </div>
            <div className='Jungle-list-container'>
              <ul>
                <li>Servicios: Inbound Marketing</li>
                <li>
                  Categorias: Branding, publicidad, estrategia digital, redes
                  sociales.
                </li>
              </ul>
            </div>
            <div className='Jungle-package-link-container'>
              <Link
                to='categorias/paquete/97b19555-c739-4d41-836b-cbec78634c6f'
                className='Jungle-package-link'
              >
                ¡A la carga!
              </Link>
            </div>
          </div>
          <div className='Jungle-package-movil-container'>
            <div className='Jungle-package-img-container package-two'>
              &nbsp;
            </div>
            <div className='Jungle-package-details'>
              <p>Paquete Rhino</p>
              <h4>Negocio medio</h4>
            </div>
            <div className='Jungle-list-container'>
              <ul>
                <li>Servicios: Inbound Marketing</li>
                <li>
                  Categorias: Branding, publicidad, estrategia digital, redes
                  sociales.
                </li>
              </ul>
            </div>
            <div className='Jungle-package-link-container'>
              <Link
                to='/categorias/paquete/d033afff-a2dc-478e-aa8b-bf06920cf191'
                className='Jungle-package-link'
              >
                ¡A la carga!
              </Link>
            </div>
          </div>
          <div className='Jungle-package-movil-container'>
            <div className='Jungle-package-img-container package-three'>
              &nbsp;
            </div>
            <div className='Jungle-package-details'>
              <p>Paquete Rhino</p>
              <h4>Negocio básico</h4>
            </div>
            <div className='Jungle-list-container'>
              <ul>
                <li>Servicios: Inbound Marketing</li>
                <li>
                  Categorias: Branding, publicidad, estrategia digital, redes
                  sociales.
                </li>
              </ul>
            </div>
            <div className='Jungle-package-link-container'>
              <Link
                to='/categorias/paquete/aba12f7a-fc8c-4b5a-8609-029abead51bb'
                className='Jungle-package-link'
              >
                ¡A la carga!
              </Link>
            </div>
          </div>
          <div className='Jungle-package-movil-container'>
            <div className='Jungle-package-img-container package-four'>
              &nbsp;
            </div>
            <div className='Jungle-package-details'>
              <p>Paquete Rhino</p>
              <h4>Negocio micro</h4>
            </div>
            <div className='Jungle-list-container'>
              <ul>
                <li>Servicios: Inbound Marketing</li>
                <li>
                  Categorias: Branding, publicidad, estrategia digital, redes
                  sociales.
                </li>
              </ul>
            </div>
            <div className='Jungle-package-link-container'>
              <Link
                to='/categorias/paquete/bc103efc-e97f-4fc0-98d2-8642d7c8b84c'
                className='Jungle-package-link'
              >
                ¡A la carga!
              </Link>
            </div>
          </div>
          <div className='Jungle-package-movil-container'>
            <div className='Jungle-package-img-container package-five'>
              &nbsp;
            </div>
            <div className='Jungle-package-details'>
              <p>Paquete Rhino</p>
              <h4>Negocio RS posicionamiento</h4>
            </div>
            <div className='Jungle-list-container'>
              <ul>
                <li>Servicios: Inbound Marketing</li>
                <li>
                  Categorias: Branding, publicidad, estrategia digital, redes
                  sociales.
                </li>
              </ul>
            </div>
            <div className='Jungle-package-link-container'>
              <Link to='/' className='Jungle-package-link'>
                ¡A la carga!
              </Link>
            </div>
          </div>
        </div>
        <div className='Jungle-btn Jungle-movil-btn'>
          <Link
            to='/categorias'
            className='Jungle-btn-link Jungle-btn-link-movil hvr-shutter-out-horizontal'
          >
            VER CATEGORIA
          </Link>
        </div>
        <div ref={(el) => (this.island = el)} className='Jungle-animation'>
          <img src={Isla} alt='Isla' />
        </div>
      </section>
    );
  }
}

export default Jungle;
