import React, { useRef } from 'react';
import { TweenMax } from 'gsap';
import { database } from '../../../firebase/firebase';
import '../../../css/dashboard/switch/Switch.css';

const Switch = (props) => {
    const icon = useRef(null)
    const { status, id, mainId } = props;

    const desactive = () => {
       TweenMax.to(icon.current, .5, {justifyContent: 'flex-start'})
       database.ref(`agencies/${id}`).update({
           status: 'Inactivo'
       }).then(() => {
            database.ref(`users/${mainId}/profile`).update({
                status: 'Inactivo'
            })
       })
    }    

    const active = () => {
        TweenMax.to(icon.current, .5, {justifyContent: 'flex-end'})
        database.ref(`agencies/${id}`).update({
            status: 'Activo'
        }).then(() => {
            database.ref(`users/${mainId}/profile`).update({
                status: 'Activo'
            })
        })
     }

    return (
        <div 
            className='Switch-container' 
            style={{
                backgroundColor: status === 'Activo' ? '#00c914' : '#C6C6C6',
                justifyContent: status === 'Activo' ? 'flex-end' : 'flex-start',
                border: status === 'Activo' ? '#00c914' : '#C6C6C6'
            }}
            ref={icon}
            onClick={status === 'Activo' ? desactive : active}
        >
            <div className='Switch-icon'>&nbsp;</div>
        </div>
    )
}

export default Switch;