import React from 'react';
import '../../../../css/dashboard/brief-dash/brief-dash-components/DashInput.css';

const DashInput = (props) => {
    const { label, value } = props;
    return (
        <div className='DashInput-wrapper'>
            <label className='DashboardLabel'>{label}</label>
            <input className='Dashboard-input' type='text' value={value} disabled={true}/>
        </div>
    )
}

export default DashInput;