import React, {useContext} from 'react';
import {Accordion, Card, useAccordionToggle} from 'react-bootstrap';
import {ChevronDown} from 'react-feather';
import AccordionContext from 'react-bootstrap/AccordionContext';
import ArrowDown from '../../../icons/down-arrow-orange.svg';
import ArrowUp from '../../../icons/up-arrow-orange.svg';
import '../../../css/projects/projectsAgency/BriefDetails.css';

function CustomToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);
  
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );
  
    const isCurrentEventKey = currentEventKey === eventKey;
  
    return (
      <div
        className='BriefDetails-header'
        onClick={decoratedOnClick}
      >
        {children}
        <img src={isCurrentEventKey ? ArrowUp : ArrowDown} alt='Flecha' />
      </div>
    );
}

const BriefDetails = (props) => {
    const {brief} = props;
    console.log(brief);
    return(
        <div>
            <Accordion className='BriefDetails-accordion'>
                <Card className='BriefDetails-card'>
                    <CustomToggle eventKey="0" className='BriefDetails-header'>
                        <p style={{margin: 0}} className='BriefDetails-main-title'>Sobre tu marca/empresa</p>
                    </CustomToggle>
                    <Accordion.Collapse eventKey='0'>
                        <Card.Body className='BriefDetails-body'>
                            <div>
                                <p className='BriefDetails-question'>¿Cómo se llama tu marca/empresa?</p>
                                <p className='BriefDetails-answer'>{brief.companyName}</p>
                            </div>
                            <div>
                                <p className='BriefDetails-question'>¿Qué slogan o frase de ventas tiene?</p>
                                <p className='BriefDetails-answer'>{brief.slogan}</p>
                            </div>
                            <div>
                                <p className='BriefDetails-question'>Describe a qué se dedica tu marca:</p>
                                <p className='BriefDetails-answer'>{brief.companyInfo}</p>
                            </div>
                            <div>
                                <p className='BriefDetails-question'>Haz una lista de las fortalizas y debilidades de la marca</p>
                                <p className='BriefDetails-answer' style={{margin: 0}}>{brief.weaknesses}</p>
                                <p className='BriefDetails-answer' style={{margin: 0}}>{brief.strengths}</p>
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='BriefDetails-card'>
                    <CustomToggle eventKey="1">
                        <p style={{margin: 0}} className='BriefDetails-main-title'>Percepción de la marca</p>
                    </CustomToggle>
                    <Accordion.Collapse eventKey='1'>
                        <Card.Body className='BriefDetails-body'>
                            <div>
                                <p className='BriefDetails-question'>¿Cómo ven tus clientes a tu marca y como quieres que la vean?</p>
                                <p className='BriefDetails-answer'>{brief.clientsPOV}</p>
                            </div>
                            <div>
                                <p className='BriefDetails-question'>¿A qué sector pertenece tu marca?</p>
                                <p className='BriefDetails-answer'>{brief.companySector}</p>
                            </div>
                            <div>
                                <p className='BriefDetails-question'>¿Qué problemas solucionan los productos/servicios que ofrecen tu marca?</p>
                                <p className='BriefDetails-answer'>{brief.solutions}</p>
                            </div>
                            <div>
                                <p className='BriefDetails-question'>¿Qué hace diferente a tu marca?</p>
                                <p className='BriefDetails-answer'>{brief.different}</p>
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='BriefDetails-card'>
                    <CustomToggle eventKey="2">
                        <p style={{margin: 0}} className='BriefDetails-main-title'>Competencia</p>
                    </CustomToggle>
                    <Accordion.Collapse eventKey='2'>
                        <Card.Body className='BriefDetails-body'>
                            <div>
                                <p className='BriefDetails-question'>¿Tienes identificadas otras propuestas similares a la tuya?</p>
                                <p className='BriefDetails-answer'>{brief.competitors}</p>
                            </div>
                            <div>
                                <p className='BriefDetails-question'>¿Cuál es el nombre de tu competencia?</p>
                                <p className='BriefDetails-answer'>{brief.directCompetitor}</p>
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='BriefDetails-card'>
                    <CustomToggle eventKey="3" className='BriefDetails-header'>
                        <p style={{margin: 0}} className='BriefDetails-main-title'>Presencia Online</p>
                    </CustomToggle>
                    <Accordion.Collapse eventKey='3'>
                        <Card.Body className='BriefDetails-body'>
                            <div>
                                <p className='BriefDetails-question'>Facebook:</p>
                                <p className='BriefDetails-answer'>{brief.facebookURL}</p>
                            </div>
                            <div>
                                <p className='BriefDetails-question'>Twitter:</p>
                                <p className='BriefDetails-answer'>{brief.twitterURL}</p>
                            </div>
                            <div>
                                <p className='BriefDetails-question'>Instagram:</p>
                                <p className='BriefDetails-answer'>{brief.instagramURL}</p>
                            </div>
                            <div>
                                <p className='BriefDetails-question'>Youtube:</p>
                                <p className='BriefDetails-answer'>{brief.youtubeURL}</p>
                            </div>
                            <div>
                                <p className='BriefDetails-question'>Indeed:</p>
                                <p className='BriefDetails-answer'>{brief.indeedURL}</p>
                            </div>
                            <div>
                                <p className='BriefDetails-question'>Otra:</p>
                                <p className='BriefDetails-answer'>{brief.anotherURL}</p>
                            </div>
                            <div>
                                <p className='BriefDetails-question'>¿Qué palabras usarías para encontrar a tu marca en Google?</p>
                                <p className='BriefDetails-answer'>{brief.googleKeywords}</p>
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='BriefDetails-card'>
                    <CustomToggle eventKey="4" className='BriefDetails-header'>
                        <p style={{margin: 0}} className='BriefDetails-main-title'>Sobre el proyecto</p>
                    </CustomToggle>
                    <Accordion.Collapse eventKey='4'>
                        <Card.Body className='BriefDetails-body'>
                            <div>
                                <p className='BriefDetails-question'>¿En qué idioma trabajaremos?</p>
                                <p className='BriefDetails-answer'>{brief.language}</p>
                            </div>
                            <div>
                                <p className='BriefDetails-question'>Situación de la industria en la que está tu marca:</p>
                                <p className='BriefDetails-answer'>{brief.sectorSituation}</p>
                            </div>
                            <div>
                                <p className='BriefDetails-question'>¿Cómo llamarás a este proyecto?</p>
                                <p className='BriefDetails-answer'>{brief.projectName}</p>
                            </div>
                            <div>
                                <p className='BriefDetails-question'>¿Cuáles son las características del mercado objetivo?</p>
                                <p>{brief.target}</p>
                            </div>
                            <div>
                                <p className='BriefDetails-question'>Objetivo del proyecto y alcances</p>
                                <p className='BriefDetails-answer'>{brief.mainObjective}</p>
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='BriefDetails-card'>
                    <CustomToggle eventKey="5" className='BriefDetails-header'>
                        <p style={{margin: 0}} className='BriefDetails-main-title'>Hablemos de números</p>
                    </CustomToggle>
                    <Accordion.Collapse eventKey='5'>
                        <Card.Body>
                            <div>
                                <p className='BriefDetails-question'>¿Cuáles son los objetivos de negocio para este proyecto?</p>
                                <p className='BriefDetails-answer'>{brief.projectObjectives}</p>
                            </div>
                            <div>
                                <p className='BriefDetails-question'>¿Anteriormente trabajaste con alguna agencia?</p>
                                <p className='BriefDetails-answer'>{brief.agencyWorked}</p>
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='BriefDetails-card'>
                    <Accordion.Toggle as={Card.Header} eventKey="6" className='BriefDetails-header'>
                        <p style={{margin: 0}} className='BriefDetails-main-title'>Último sorbo de café</p>
                        <ChevronDown size='25' color='#f47600'/>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey='6'>
                        <Card.Body className='BriefDetails-body'>
                            <div>
                                <p className='BriefDetails-question'>¿Cómo conociste Rhino.lat?</p>
                                <p className='BriefDetails-answer'>{brief.knowUs}</p>
                            </div>
                            <div>
                                <p className='BriefDetails-question'>¿Qué marca te inspira y sirve como referencia y por qué?</p>
                                <p className='BriefDetails-answer'>{brief.inspireBrand}</p>
                            </div>
                            <div>
                                <p className='BriefDetails-question'>¿Con cuánta frecuencia, tú o tu negocio, tienen necesidades de diseño?</p>
                                <p className='BriefDetails-answer'>{brief.frequency}</p>
                            </div>
                            <div>
                                <p className='BriefDetails-question'>¿Cuántos empleados tiene tu empresa incluido tú mismo?</p>
                                <p className='BriefDetails-answer'>{brief.employees}</p>
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    );
}

export default BriefDetails;