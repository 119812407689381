import React from 'react';
import {Accordion} from 'react-bootstrap';
import CollapsableItems from './CollapsableItems';

const Collapsable = (props) => {
    const items = props.items.map( (item, index) => (
        <CollapsableItems 
            key={item.id} id={item.id}  
            options={props.options} 
            evtKey={index} 
            item={item.name} 
            body={props.body}
        />
    ));
    return (
        <div className='Collapsable'>
            <Accordion defaultActiveKey="1">
                {items}
            </Accordion>
        </div>
    );
}
 
export default Collapsable;