import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { database } from '../../../../firebase/firebase';
import { getDate } from '../../../../helpers/utils';
import DashboardTitle from '../../titles/DashboardTitle';
import AgencyDetailsDash from '../assign-project/AgencyDetailsDash';
import '../../../../css/dashboard/projects/project-details/ProjectDetails.css';

class ProjectDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { data: {}, isLoaded: false };
  }

  componentDidMount() {
    database
      .ref(`briefs/${this.props.match.params.id}`)
      .on('value', (snapshot) => {
        database
          .ref(`users/${snapshot.val().userId}/profile/userName`)
          .once('value')
          .then((snapUser) => {
            database
              .ref(
                `usersPackages/${snapshot.val().userId}/${
                  snapshot.val().packageId
                }/packageName`
              )
              .once('value')
              .then((snap) => {
                if (snapshot.val().agency !== 'Sin asignar') {
                  database
                    .ref(`users/${snapshot.val().agency}/profile/agency`)
                    .once('value')
                    .then((snapAgency) => {
                      this.setState({
                        data: {
                          userName: snapUser.val(),
                          agency: snapAgency.val(),
                          date: snapshot.val().purchaseDate,
                          packageName: snap.val(),
                          status: snapshot.val().status,
                        },
                        isLoaded: true,
                      });
                    });
                } else {
                  this.setState({
                    data: {
                      userName: snapUser.val(),
                      agency: snapshot.val().agency,
                      date: snapshot.val().purchaseDate,
                      packageName: snap.val(),
                      status: snapshot.val().status,
                    },
                    isLoaded: true,
                  });
                }
              });
          });
      });
  }

  componentWillUnmount() {
    database.ref(`briefs/${this.props.match.params.id}`).off();
  }

  render() {
    let date = getDate(this.state.data.date);
    return (
      <section>
        {this.state.isLoaded && (
          <React.Fragment>
            <div className='ProjectDetails-data'>
              <DashboardTitle
                title={`Paquete Rhino ${this.state.data.packageName}`}
              />
              <div className='ProjectDetails-dash-wrapper'>
                <div className='ProjectDetails-dash-item'>
                  <div className='ProjectDetails-dash-item-title'>Cliente</div>
                  <div className='ProjectDetails-dash-item-subtitle'>
                    {this.state.data.userName}
                  </div>
                </div>
                <div className='ProjectDetails-dash-item'>
                  <div className='ProjectDetails-dash-item-title'>Agencia</div>
                  <div className='ProjectDetails-dash-item-subtitle'>
                    {this.state.data.agency}
                  </div>
                </div>
                <div className='AgencyProfile-item'>
                  <div className='ProjectDetails-dash-item-title'>
                    Fecha de compra
                  </div>
                  <div className='ProjectDetails-dash-item-subtitle'>
                    {date}
                  </div>
                </div>
              </div>
            </div>
            {this.state.data.agency === 'Sin asignar' ||
            (this.state.data.agency !== 'Sin asignar' &&
              this.state.data.status === 'Por iniciar') ? (
              <div className='ProjectDetails-agency-list-container'>
                <DashboardTitle title='Agencias sugeridas' />
                <AgencyDetailsDash briefId={this.props.match.params.id} />
              </div>
            ) : null}
          </React.Fragment>
        )}
      </section>
    );
  }
}

export default withRouter(ProjectDetails);
