import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import FormInicioAdmin from '../../forms/admin/FormInicioAdmin';
import Logo from '../../../icons/rhino_logo_dash.svg';
import '../../../css/login//admin/LoginAdmin.css';

class LoginAdmin extends Component {
  render() {
    if (
      this.props.isAuthenticated === true &&
      this.props.typeOfUser === 'admin'
    ) {
      return <Redirect to='/rh-admin/dashboard/proyectos' />;
    }
    return (
      <section className='LoginAdmin'>
        <div className='LoginAdmin-container'>
          <div className='LoginAdim-center'>
            <div className='Login-rhino-logo-dash'><img src={Logo} alt='Rhino'/></div>
            <div className='LoginAdmin-wrapper-form'>
              <FormInicioAdmin />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default LoginAdmin;
