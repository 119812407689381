import React from 'react';
import searchIcon from '../../../../../icons/search.svg';

const Search = (props) => {
  const { search, inputSearch, handleChange } = props;
  return (
    <div style={{ position: 'relative' }}>
      <i
        style={{
          position: 'absolute',
          right: '10px',
          top: '10%',
        }}
      >
        <img src={searchIcon} alt='search' />
      </i>
      <input
        type='text'
        placeholder='Buscar'
        className='Dashboard-agencies-search'
        value={search}
        onChange={handleChange}
        ref={inputSearch}
        style={{ width: 300, height: 35 }}
      />
    </div>
  );
};

export default Search;
