import React from 'react';
import BlueTitle from './titles/BlueTitle';
import AgencyParagraph from './paragraph/AgencyParagraph';
import AgencyLink from '../utils/links/AgencyLink';
import '../../css/home-agency/Rhino.css';
import AgencyButton from './buttons/AgencyButton';

const Rhino = (props) => (
  <section className="Rhino">
    <div className="Rhino-container">
      <div className="Rhino-title">
        <BlueTitle text="¿Por qué Rhino ha decidido aportar para esta causa?" />
      </div>
      <div className="Rhino-paragraph">
        <AgencyParagraph
          text="En Rhino.lat estamos comprometidos con ayudar con un granito a conservar el lo más preciado que tenemos los seres humanos que es el planeta; salvar a los rinocerontes de la crueldad y de su extinción es un motivador nos hace ponernos literal “a la carga”, sin embargo nuestra visión no se queda ahí, hay miles de especies en diferentes regiones que necesitan ser rescatados."
          color="#58595c"
          fontWeight={300}
        />
        <div className="Rhino-firm"><p>-Rhino</p></div>
      </div>
      <div className="Rhino-join">
        <AgencyParagraph
          text="¡Únete a la jungla!"
          color="#58595c"
          fontWeight="500"
        />
      </div>
      <div className="Rhino-link">
        <AgencyButton text="¡A LA CARGA!" click={() => window.open('https://api.whatsapp.com/send?phone=5215570910612&text=Quiero%20poner%20%22a%20la%20carga%22%20mi%20agencia%20y%20hacerla%20m%C3%A1s%20rentable', '_blank')} />
      </div>
    </div>
  </section>
);

export default Rhino;
