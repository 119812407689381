import React from 'react';
import BlueTitle from './titles/BlueTitle'
import AgencyParagraph from './paragraph/AgencyParagraph';
import Huella from '../../img/steps/huella.png';
import '../../css/home-agency/HowItWorks.css';

const HowItWorks = (props) => {
    return(
        <section className='HowItWorks'>
            <div className='HowItWorks-container'>
                <BlueTitle text='¿Cómo funciona Rhino?' />
                <div className='cloud-up'>&nbsp;</div>
                <div className='clouds-group'>
                    <div className='cloud-1'>
                        <div className='cloud-text-container'>
                            <h4>1</h4>
                            <p>Regístrate <span>(¡A la Carga!)</span></p>
                        </div>
                    </div>
                    <div className='cloud-2'>
                        <div className='cloud-text-container'>
                            <h4>2</h4>
                            <p>Ayudanos <span>respondiendo</span> <span>unas preguntas</span></p>
                        </div>
                    </div>
                </div>
                <div className='clouds-group-2'>
                    <div className='circle-1'>&nbsp;</div>
                    <div className='cloud-1'>
                        <div className='cloud-text-container'>
                            <h4>3</h4>
                            <p>Agenda tu <span>cita para</span> <span>entrevista</span></p>
                        </div>
                    </div>
                    <div className='circle-2'>&nbsp;</div>
                    <div className='cloud-2 cloud-2-up'>
                        <div className='cloud-text-container'>
                            <h4>4</h4>
                            <p>Cerramos <span>el trato</span></p>
                        </div>
                    </div>
                    <div className='circle-1 circle-1-end'>&nbsp;</div>
                </div>
                <div className='clouds-group-3'>
                    <div className='cloud-1 cloud-1-group-3'>
                        <div className='cloud-text-container'>
                            <h4>5</h4>
                            <p>¡Bienvenido <span>a la jungla!</span></p>
                        </div>
                    </div>
                    <div className='cloud-2 cloud-2-group-3'>
                        <div className='cloud-text-container'>
                            <h4>6</h4>
                            <p>Ponte a "La Carga" <span>y Recibe tu primer</span> <span>proyecto</span></p>
                        </div>
                    </div>
                </div>
                <div className='clouds-group-4'>
                    <div className='circle-3'>&nbsp;</div>
                    <div className='cloud-1-group-4'>
                        <div className='cloud-text-container'>
                            <h4>7</h4>
                            <p>Recibe una <span>evaluación total</span></p>
                        </div>
                    </div>
                    <div className='circle-4'>&nbsp;</div>
                    <div className='cloud-2-group-4'>
                        <div className='cloud-text-container'>
                            <h4>8</h4>
                            <p>Sigue <span>construyendo y</span> <span>aumentando tu</span> <span>rentabilidad</span></p>
                        </div>
                    </div>
                </div>
                <div className='HowItWorks-paragraph-container'>
                    <AgencyParagraph 
                        text='No olvides checar los Términos y condiciones y el Aviso de Privacidad'
                        color='#01355f'
                        fontWeight='normal'
                    />
                </div>
            </div>
            <div className='HowItWorks-container-movil'>
                <div>
                    <BlueTitle text='¿Cómo funciona Rhino?' />
                </div>
                <div className='HowItWorks-movil-container-perks'>
                    <div className='HowItWorks-perks-wrapper'>
                        <div className='HowItWorks-perks-img'>
                            <img src={Huella} alt='Huella'/>
                            <p className='HowItWorks-perks-number'>1</p>
                        </div>
                        <div className='HowItWorks-perks-paragraph'>
                            <p>Regístrate 
                            <span>(¡A la Carga!)</span></p>
                        </div>
                    </div>
                    <div className='HowItWorks-perks-wrapper'>
                        <div className='HowItWorks-perks-img'>
                            <img src={Huella} alt='Huella'/>
                            <p className='HowItWorks-perks-number'>2</p>
                        </div>
                        <div className='HowItWorks-perks-paragraph'>
                            <p>
                                <span>Ayúdanos</span>
                                <span>respondiendo </span>
                                <span>unas preguntas</span>
                            </p>
                        </div>
                    </div>
                    <div className='HowItWorks-perks-wrapper'>
                        <div className='HowItWorks-perks-img'>
                            <img src={Huella} alt='Huella'/>
                            <p className='HowItWorks-perks-number'>3</p>
                        </div>
                        <div className='HowItWorks-perks-paragraph'>
                            <p>
                                <span>Agenda tu cita para</span>
                                <span>entrevista</span>
                            </p>
                        </div>
                    </div>
                    <div className='HowItWorks-perks-wrapper'>
                        <div className='HowItWorks-perks-img'>
                            <img src={Huella} alt='Huella'/>
                            <p className='HowItWorks-perks-number'>4</p>
                        </div>
                        <div className='HowItWorks-perks-paragraph'>
                            <p>
                                <span>Cerremos</span>
                                <span>el trato</span>
                            </p>
                        </div>
                    </div>
                    <div className='HowItWorks-perks-wrapper'>
                        <div className='HowItWorks-perks-img'>
                            <img src={Huella} alt='Huella'/>
                            <p className='HowItWorks-perks-number'>5</p>
                        </div>
                        <div className='HowItWorks-perks-paragraph'>
                            <p>
                                <span>¡Bienvenido</span>
                                <span>a la jungla!</span>
                            </p>
                        </div>
                    </div>
                    <div className='HowItWorks-perks-wrapper'>
                        <div className='HowItWorks-perks-img'>
                            <img src={Huella} alt='Huella'/>
                            <p className='HowItWorks-perks-number'>6</p>
                        </div>
                        <div className='HowItWorks-perks-paragraph'>
                            <p> 
                                <span>Ponte a “La Carga”</span>
                                <span>y Recibe tu primer</span>
                                <span>proyecto</span>
                            </p>
                        </div>
                    </div>
                    <div className='HowItWorks-perks-wrapper'>
                        <div className='HowItWorks-perks-img'>
                            <img src={Huella} alt='Huella'/>
                            <p className='HowItWorks-perks-number'>7</p>
                        </div>
                        <div className='HowItWorks-perks-paragraph'>
                            <p>
                                <span>Recibe una</span>
                                <span>evaluación total</span>
                            </p>
                        </div>
                    </div>
                    <div className='HowItWorks-perks-wrapper'>
                        <div className='HowItWorks-perks-img'>
                            <img src={Huella} alt='Huella'/>
                            <p className='HowItWorks-perks-number'>8</p>
                        </div>
                        <div className='HowItWorks-perks-paragraph'>
                            <p>
                                <span>Sigue construyendo</span>
                                <span>y aumentando tu</span>
                                <span>rentabilidad</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className='HowItWorks-paragraph-movil'>
                    <AgencyParagraph 
                    text='No olvides checar los Términos y condiciones y el Aviso de Privacidad'
                    color='#01355f'
                    fontWeight='normal'
                    />
                </div>
            </div>
        </section>
    )
}

export default HowItWorks;