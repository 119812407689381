import React from 'react';

const Button = (props) => {
  const {
    className, handleClick, icon, link, label,
  } = props;
  return (
    <button className={className} onClick={handleClick && handleClick}>
      {icon && icon}
      {' '}
      {link || label}
    </button>
  );
};

export default Button;
