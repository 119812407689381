import React, { useState, useEffect } from 'react';
import { database } from '../../../../firebase/firebase';
import DashboardTitle from '../../titles/DashboardTitle';
import SingleProject from '../single-project/SingleProject';
import PrevNextButtons from '../../buttons/PrevNextButtons';
import ShowDocs from '../../show-docs/ShowDocs';
import '../../../../css/dashboard/projects/project-list/ProjectsList.css';

const ProjectsList = (props) => {
  const [choice, setChoice] = useState('Por iniciar');
  const [projects, setProjects] = useState([]);
  const [count, setCount] = useState(1);
  const [index, setIndex] = useState(null);
  const [totalDocuments, setTotalDocuments] = useState(null);
  const [seen, setSeen] = useState(null);
  const [quotient, setQuotient] = useState(0);
  const [remainder, setRemainder] = useState(0);
  const [selectedOption, setSelectedOption] = useState(10);
  const [projectsList, setProjectsList] = useState([]);

  let tempArray = [];

  useEffect(() => {
    let projects = [];
    setCount(1);
    setProjects([]);
    setProjectsList([]);
    setRemainder(null);
    setQuotient(null);
    setIndex(null);
    setTotalDocuments(0);
    setSeen(0);

    database
      .ref('briefs')
      .orderByChild('status')
      .equalTo(`${choice}`)
      .once('value')
      .then((snapshot) => {
        snapshot.forEach((childSnap) => {
          let userName;
          database
            .ref(`users/${childSnap.val().userId}/profile/userName`)
            .once('value')
            .then((snap) => {
              userName = snap.val();
              database
                .ref(
                  `usersPackages/${childSnap.val().userId}/${
                    childSnap.val().packageId
                  }/packageName`
                )
                .once('value')
                .then((snap) => {
                  projects.push({
                    id: childSnap.key,
                    status: childSnap.val().status,
                    userName: userName,
                    packageName: snap.val(),
                    agency: childSnap.val().agency,
                  });
                  setProjects([...projects]);
                  setTotalDocuments(projects.length);

                  if (projects.length >= parseInt(selectedOption)) {
                    setRemainder(projects.length % parseInt(selectedOption));
                    setQuotient(
                      Math.floor(projects.length / parseInt(selectedOption)) - 1
                    );
                    setIndex(parseInt(selectedOption));
                    projects.length > parseInt(selectedOption)
                      ? setSeen(parseInt(selectedOption))
                      : setSeen(projects.length);
                    setProjectsList([]);
                  } else {
                    setIndex(projects.length);
                    setSeen(projects.length);
                    setProjectsList([]);
                  }
                });
            });
        });
      })
      .catch((e) => {
        console.log('No hay nada');
      });
  }, [choice, selectedOption]);

  const setOption = (str) => {
    setChoice(str);
  };

  const prevPage = (last) => {
    let newArr = [];
    const lastItem = index;

    if (index % parseInt(selectedOption) === 0) {
      for (
        let i = lastItem - parseInt(selectedOption) - 1;
        i >=
        lastItem -
          parseInt(selectedOption) -
          1 -
          (parseInt(selectedOption) - 1);
        i--
      ) {
        newArr.push(
          <SingleProject
            key={projects[i].id}
            client={projects[i].userName}
            packageName={projects[i].packageName}
            isAsign={
              projects[i].agency !== 'Sin asignar' ? 'Asignada' : 'Sin asignar'
            }
            id={projects[i].id}
            status={projects[i].status}
          />
        );
      }
      setIndex(index - newArr.length);
      setSeen(seen - newArr.length);
    } else {
      for (
        let i = lastItem - remainder - 1;
        i >= lastItem - remainder - 1 - (parseInt(selectedOption) - 1);
        i--
      ) {
        newArr.push(
          <SingleProject
            key={projects[i].id}
            client={projects[i].userName}
            packageName={projects[i].packageName}
            isAsign={
              projects[i].agency !== 'Sin asignar' ? 'Asignada' : 'Sin asignar'
            }
            id={projects[i].id}
            status={projects[i].status}
          />
        );
      }
      setIndex(index - remainder);
      setSeen(seen - remainder);
    }

    setProjectsList([...newArr]);
    setCount(count - 1);
    setQuotient(quotient + 1);
  };

  const nextPage = (last) => {
    let newArr = [];
    const lastItem = index;
    if (quotient === 0 && remainder > 0) {
      for (let i = lastItem; i < index + remainder; i++) {
        newArr.push(
          <SingleProject
            key={projects[i].id}
            client={projects[i].userName}
            packageName={projects[i].packageName}
            isAsign={
              projects[i].agency !== 'Sin asignar' ? 'Asignada' : 'Sin asignar'
            }
            id={projects[i].id}
            status={projects[i].status}
          />
        );
      }
    } else if (quotient > 0) {
      for (let i = lastItem; i < index + parseInt(selectedOption); i++) {
        newArr.push(
          <SingleProject
            key={projects[i].id}
            client={projects[i].userName}
            packageName={projects[i].packageName}
            isAsign={
              projects[i].agency !== 'Sin asignar' ? 'Asignada' : 'Sin asignar'
            }
            id={projects[i].id}
            status={projects[i].status}
          />
        );
      }
    }

    setIndex(index + newArr.length);
    setCount(count + 1);
    setSeen(seen + newArr.length);
    setQuotient(quotient - 1);
    setProjectsList([...newArr]);
  };

  const handleChangeSelect = (e) => {
    setSelectedOption(e.target.value);
  };

  if (
    projectsList.length > 0 &&
    projectsList.length >= parseInt(selectedOption)
  ) {
    for (let i = 0; i < parseInt(selectedOption); i++) {
      tempArray.push(
        <SingleProject
          key={projects[i].id}
          client={projects[i].userName}
          packageName={projects[i].packageName}
          isAsign={
            projects[i].agency !== 'Sin asignar' ? 'Asignada' : 'Sin asignar'
          }
          id={projects[i].id}
          status={projects[i].status}
        />
      );
    }
  } else if (projects.length > parseInt(selectedOption)) {
    for (let i = 0; i <= parseInt(selectedOption) - 1; i++) {
      tempArray.push(
        <SingleProject
          key={projects[i].id}
          client={projects[i].userName}
          packageName={projects[i].packageName}
          isAsign={
            projects[i].agency !== 'Sin asignar' ? 'Asignada' : 'Sin asignar'
          }
          id={projects[i].id}
          status={projects[i].status}
        />
      );
    }
  } else if (projects.length < parseInt(selectedOption)) {
    for (let i = 0; i < projects.length; i++) {
      tempArray.push(
        <SingleProject
          key={projects[i].id}
          client={projects[i].userName}
          packageName={projects[i].packageName}
          isAsign={
            projects[i].agency !== 'Sin asignar' ? 'Asignada' : 'Sin asignar'
          }
          id={projects[i].id}
          status={projects[i].status}
        />
      );
    }
  }

  return (
    <section>
      <DashboardTitle title='Proyectos' />
      <div className='ProjectsList-container-innerNav'>
        <div
          className='ProjectsList-wrapper'
          style={{
            borderBottom:
              choice === 'Por iniciar'
                ? '4px solid rgb(244, 118, 0)'
                : '1px solid rgb(191, 191, 191)',
          }}
          onClick={() => setOption('Por iniciar')}
        >
          <p
            className='ProjectsList-innerNav'
            style={{
              color: choice === 'Por iniciar' ? '#f47600' : '#000',
              opacity: choice === 'Por iniciar' ? 1 : 0.25,
            }}
          >
            Por iniciar
          </p>
        </div>
        <div
          className='ProjectsList-wrapper'
          style={{
            borderBottom:
              choice === 'En proceso'
                ? '4px solid rgb(244, 118, 0)'
                : '1px solid rgb(191, 191, 191)',
          }}
          onClick={() => setOption('En proceso')}
        >
          <p
            className='ProjectsList-innerNav'
            style={{
              color: choice === 'En proceso' ? '#f47600' : '#000',
              opacity: choice === 'En proceso' ? 1 : 0.25,
            }}
          >
            En proceso
          </p>
        </div>
        <div
          className='ProjectsList-wrapper'
          style={{
            borderBottom:
              choice === 'Finalizado'
                ? '4px solid rgb(244, 118, 0)'
                : '1px solid rgb(191, 191, 191)',
          }}
          onClick={() => setOption('Finalizado')}
        >
          <p
            className='ProjectsList-innerNav'
            style={{
              color: choice === 'Finalizado' ? '#f47600' : '#000',
              opacity: choice === 'Finalizado' ? 1 : 0.25,
            }}
          >
            Finalizado
          </p>
        </div>
      </div>
      <div className='ProjectList-dash-showDocs'>
        <ShowDocs value={selectedOption} handleChange={handleChangeSelect} />
      </div>
      <div>
        {projects.length > 0 ? (
          <React.Fragment>
            <div className='ProjectsList-headers'>
              <div className='ProjectsList-headers-wrapper'>
                <p className='ProjectsList-header-title'>Cliente</p>
              </div>
              <div className='ProjectsList-headers-wrapper'>
                <p className='ProjectsList-header-title'>Paquete</p>
              </div>
              <div className='ProjectsList-headers-wrapper'>
                <p className='ProjectsList-header-title'>Agencia</p>
              </div>
            </div>
            <div>{projectsList.length !== 0 ? projectsList : tempArray}</div>
          </React.Fragment>
        ) : (
          <div className='ProjectsList-alter-msg-wrapper'>
            <p className='ProjectsList-alter-msg'>Aún no hay proyectos</p>
          </div>
        )}
      </div>
      <div className='ProjectList-dash-pag-wrapper'>
        <p className='PagesDetails'>
          Mostrando {seen} de {totalDocuments} resultados
        </p>
        <PrevNextButtons
          disabledPrev={count === 1}
          disabledNext={index === projects.length}
          prevFunc={prevPage}
          nextFunc={nextPage}
          limit={parseInt(selectedOption) + 1}
        />
      </div>
    </section>
  );
};

export default ProjectsList;
