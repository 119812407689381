import React from 'react';
import '../../../css/dashboard/buttons/PrevNextButtons.css';

const PrevNextButton = (props) => {
  const { disabledPrev, disabledNext, prevFunc, nextFunc, limit } = props;
  return (
    <div>
      <button
        type='button'
        disabled={disabledPrev}
        onClick={() => prevFunc(limit)}
        className='PrevNextButtons'
        style={{
          color: disabledPrev ? '#000' : '#f47600',
          opacity: disabledPrev ? 0.25 : 1,
        }}
      >
        Anterior
      </button>
      <button
        type='button'
        disabled={disabledNext}
        onClick={() => nextFunc(limit)}
        className='PrevNextButtons'
        style={{
          color: disabledNext ? '#000' : '#f47600',
          opacity: disabledNext ? 0.25 : 1,
        }}
      >
        Siguiente
      </button>
    </div>
  );
};

export default PrevNextButton;
