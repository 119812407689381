import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { firebase } from '../../../firebase/firebase';
import ProfileInfo from './ProfileInfo';
import Navbar from '../../navbar/Navbar';
import NavbarBg from '../../navbar/NavbarBg';
import Footer from '../../footer/Footer';
import '../../../css/profile/users/UserProfile.css';

const UserProfile = (props) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    const user = firebase.auth().currentUser;

    if (user != null) {
      setName(user.displayName);
      setEmail(user.email);
    }
  }, [name, email]);

  return (
    <React.Fragment>
      <Navbar />
      <NavbarBg />
      <section className='UserProfile'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='UserProfile-container'>
                <div className='UserProfile-title-wrapper'>
                  <h3 className='UserProfile-title'>Mi Perfil</h3>
                </div>
                <div className='UserProfile-info-link-edit-mobile-wrapper'>
                  <Link
                    className='UserProfile-info-link-edit-mobile'
                    to={`/perfil/${props.match.params.userId}/editar`}
                  >
                    Editar
                  </Link>
                </div>
                <div className='UserProfile-info'>
                  <div className='UserProfile-info-title-container'>
                    <p className='UserProfile-info-title'>Datos</p>
                    <Link
                      className='UserProfile-info-link-edit'
                      to={`/perfil/${props.match.params.userId}/editar`}
                    >
                      Editar
                    </Link>
                  </div>
                  <div className='UserProfile-info-container'>
                    <div>
                      <ProfileInfo field='Nombre' data={name} />
                    </div>
                    <div className='UserProfile-wrapper-desk'>
                      <ProfileInfo field='Correo electrónico' data={email} />
                    </div>
                    <div className='UserProfile-wrapper-mobile'>
                      <ProfileInfo field='Correo' data={email} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default UserProfile;
