import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRouteDash = ({
  isAuthenticated,
  typeOfUser,
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      component={(props) =>
        isAuthenticated === true && typeOfUser === 'admin' ? (
          <Component {...props} />
        ) : (
          <Redirect to='/rh-admin/login' />
        )
      }
    />
  );
};

export default PrivateRouteDash;
