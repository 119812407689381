import React from 'react';
import '../../../css/utils/buttons/ButtonOnSubmit.css';

const ButtonOnSubmit = (props) => {
  const { text, isDisabled } = props;
  return (
    <div className='Button-anim-container'>
      <button
        type='submit'
        disabled={isDisabled}
        className='Button-anim Button-anim-hvr-shutter-out-horizontal'
      >
        {text}
      </button>
    </div>
  );
};

export default ButtonOnSubmit;
