import React from 'react';
import Input from '../../../utils/forms/input/Input';
import Textarea from '../../../utils/forms/textarea/Textarea';
import '../../../../css/forms/admin/create-deliverable/CreateDeliverable.css';
import { Button } from 'react-bootstrap';
import { Trash } from 'react-feather';

const CreateDeliverable = (props) => {
  const {
    num,
    deliverableName,
    valueName,
    deliverableDuration,
    valueDuration,
    deliverableDesc,
    valueDesc,
    handleChange,
    errorDeliverableName,
    errorDeliverableDesc,
    errorDeliverableDuration,
    onDeletePressed,
  } = props;

  return (
    <div className="CreateDeliverable">
      <div className="CreateDeliverable-title-container">
        <span className="CreateDeliverable-title">
          Entregable
          {num}
        </span>
        {onDeletePressed && <Button variant="outline-danger" onClick={onDeletePressed}><Trash size="15" /></Button>}
      </div>
      <form>
        <div className="CreateDeliverable-form-wrapper">
          <div className="CreateDeliverable-input-wrapper">
            <Input
              type="text"
              placeholder="Viabiliad de nombre"
              name={deliverableName}
              value={valueName}
              customError={errorDeliverableName}
              handleChange={handleChange}
            />
          </div>
          <div className="CreateDeliverable-input-wrapper">
            <Input
              type="text"
              placeholder="Duracion (número)"
              name={deliverableDuration}
              value={valueDuration}
              customError={errorDeliverableDuration}
              handleChange={handleChange}
            />
          </div>
        </div>
        <div>
          <div className="CreateDeliverable-textarea-wrapper">
            <Textarea
              type="text"
              placeholder="Descripción"
              name={deliverableDesc}
              value={valueDesc}
              customError={errorDeliverableDesc}
              handleChange={handleChange}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateDeliverable;
