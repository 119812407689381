import React, { useState, useEffect } from 'react';
import uuid from 'uuid/v4';
import { Link } from 'react-router-dom';
import { database } from '../../../../firebase/firebase';
import { withRouter } from 'react-router-dom';
import DashboardTitle from '../../titles/DashboardTitle';
import Edit from '../../../../icons/editar.svg';
import Delete from '../../../../icons/eliminar.svg';
import PrevNextButtons from '../../buttons/PrevNextButtons';
import PagesAgenciesDetails from './PagesAgenciesDetails';
import ProjectInfo from './ProjectInfo';
import '../../../../css/dashboard/agencies/profile/AgencyProfile.css';

const AgencyProfile = (props) => {
  const [agency, setAgency] = useState({});
  const [projects, setProjects] = useState([]);
  const [isInfoClicked, setIsInfoClicked] = useState(true);
  const [count, setCount] = useState(1);
  const [index, setIndex] = useState(5);
  const [totalDocs, setTotalDocs] = useState(null);
  const [seen, setSeen] = useState(1);
  const [counter, setCounter] = useState(0);
  const [quotient, setQuotient] = useState(0);
  const [remainder, setRemainder] = useState(0);
  const [selectedOption, setSelectedOption] = useState(2);
  const [listProjects, setListProjects] = useState([]);

  let tempArray = [];

  useEffect(() => {
    database
      .ref(`agencies/${props.match.params.id}`)
      .once('value')
      .then((snapshot) => {
        setAgency({ id: snapshot.key, ...snapshot.val() });
      });
  }, [props.match.params.id]);

  useEffect(() => {
    let projects = [];
    database
      .ref('briefs')
      .orderByChild('agency')
      .equalTo(`${agency.mainAgencyId}`)
      .once('value')
      .then((snapshot) => {
        snapshot.forEach((childSnap) => {
          let packageName;
          database
            .ref(
              `usersPackages/${childSnap.val().userId}/${
                childSnap.val().packageId
              }/packageName`
            )
            .once('value')
            .then((snap) => {
              packageName = snap.val();

              database
                .ref(`users/${childSnap.val().userId}/profile/userName`)
                .once('value')
                .then((snap) => {
                  projects.push({
                    userName: snap.val(),
                    packageName: packageName,
                    status: childSnap.val().status,
                  });
                  setProjects([...projects]);
                  setRemainder(projects.length % 5);
                  setQuotient(Math.floor(projects.length / 5) - 1);
                  projects.length > 5 ? setSeen(5) : setSeen(projects.length);
                });
            });
        });
      });
  }, [agency.mainAgencyId, props.match.params.id]);

  const handleClickInfo = () => {
    setIsInfoClicked(true);
  };

  const handleClickProjects = () => {
    setIsInfoClicked(false);
  };

  const prevPage = (last) => {
    let newArr = [];
    const lastItem = index;

    if (index % 5 === 0) {
      for (let i = lastItem - 5 - 1; i >= lastItem - 5 - 1 - (5 - 1); i--) {
        newArr.push(
          <ProjectInfo
            key={uuid()}
            user={projects[i].userName}
            packageName={projects[i].packageName}
            status={projects[i].status}
          />
        );
      }
      setIndex(index - newArr.length);
      setSeen(seen - newArr.length);
    } else {
      for (
        let i = lastItem - remainder - 1;
        i >= lastItem - remainder - 1 - 4;
        i--
      ) {
        newArr.push(
          <ProjectInfo
            key={uuid()}
            user={projects[i].userName}
            packageName={projects[i].packageName}
            status={projects[i].status}
          />
        );
      }
      setIndex(index - remainder);
      setSeen(seen - remainder);
    }

    setListProjects([...newArr]);
    setCount(count - 1);
    setQuotient(quotient + 1);
  };

  const nextPage = (last) => {
    let newArr = [];
    const lastItem = index;
    if (quotient === 0 && remainder > 0) {
      for (let i = lastItem; i < index + remainder; i++) {
        newArr.push(
          <ProjectInfo
            key={uuid()}
            user={projects[i].userName}
            packageName={projects[i].packageName}
            status={projects[i].status}
          />
        );
      }
    } else if (quotient > 0) {
      for (let i = lastItem; i < index + 5; i++) {
        newArr.push(
          <ProjectInfo
            key={uuid()}
            user={projects[i].userName}
            packageName={projects[i].packageName}
            status={projects[i].status}
          />
        );
      }
    }

    setIndex(index + newArr.length);
    setCount(count + 1);
    setSeen(seen + newArr.length);
    setQuotient(quotient - 1);
    setListProjects([...newArr]);
  };

  const getTotalDocs = (total) => {
    setTotalDocs(total);
  };

  if (projects.length > 0 && projects.length <= 5) {
    for (let i = 0; i < projects.length; i++) {
      tempArray.push(
        <ProjectInfo
          key={uuid()}
          user={projects[i].userName}
          packageName={projects[i].packageName}
          status={projects[i].status}
        />
      );
    }
  } else if (projects.length > 5) {
    for (let i = 0; i <= 4; i++) {
      tempArray.push(
        <ProjectInfo
          key={uuid()}
          user={projects[i].userName}
          packageName={projects[i].packageName}
          status={projects[i].status}
        />
      );
    }
  }

  return (
    <section>
      <div className='AgencyProfile-header-container'>
        <DashboardTitle title={agency.agency} />
        <div className='AgencyProfile-icons-wrapper'>
          <Link
            to={`/rh-admin/dashboard/editar-agencia/${agency.id}`}
            className='AgencyProfile-icon-link'
          >
            <img src={Edit} alt='Editar' />
          </Link>
          <button className='AgencyProfile-icon-btn'>
            <img src={Delete} alt='Borrar' />
          </button>
        </div>
      </div>
      <div className='AgencyProfile-innerLinks'>
        <ul className='AgencyProfile-innerLinks-list'>
          <li
            className='AgencyProfile-innerLinks-item'
            onClick={handleClickInfo}
            style={{
              color: isInfoClicked ? '#f47600' : '#BFBFBF',
              borderBottom: isInfoClicked
                ? '4px solid #f47600'
                : '1px solid #BFBFBF',
            }}
          >
            Datos generales
          </li>
          <li
            className='AgencyProfile-innerLinks-item'
            onClick={handleClickProjects}
            style={{
              color: !isInfoClicked ? '#f47600' : '#BFBFBF',
              borderBottom: !isInfoClicked
                ? '4px solid #f47600'
                : '1px solid #BFBFBF',
            }}
          >
            Proyectos
          </li>
        </ul>
      </div>
      {isInfoClicked ? (
        <div className='AgencyProfile-wrapper'>
          <div className='AgencyProfile-item'>
            <div className='AgencyProfile-item-title'>Nombre</div>
            <div className='AgencyProfile-item-subtitle'>{agency.userName}</div>
          </div>
          <div className='AgencyProfile-item'>
            <div className='AgencyProfile-item-title'>Agencia</div>
            <div className='AgencyProfile-item-subtitle'>{agency.agency}</div>
          </div>
          <div className='AgencyProfile-item'>
            <div className='AgencyProfile-item-title'>Correo electrónico</div>
            <div className='AgencyProfile-item-subtitle'>
              {agency.userEmail}
            </div>
          </div>
          <div className='AgencyProfile-item'>
            <div className='AgencyProfile-item-title'>Especialidad</div>
            <div className='AgencyProfile-item-subtitle'>
              {agency.specialty}
            </div>
          </div>
          <div className='AgencyProfile-item'>
            <div className='AgencyProfile-item-title'>Estado</div>
            <div className='AgencyProfile-item-subtitle'>{agency.status}</div>
          </div>
        </div>
      ) : (
        <div>
          {projects.length > 0 ? (
            <React.Fragment>
              <div className='AgencyProfile-projects-container'>
                <div>
                  <p className='AgencyProfile-project-title'>Cliente</p>
                </div>
                <div>
                  <p className='AgencyProfile-project-title'>Paquete</p>
                </div>
                <div>
                  <p className='AgencyProfile-project-title'>Estado</p>
                </div>
              </div>
              {listProjects.length !== 0 ? listProjects : tempArray}
            </React.Fragment>
          ) : (
            <p className='AgencyProfile-noProjects'>Aún no hay proyectos</p>
          )}
        </div>
      )}
      {!isInfoClicked && (
        <div className='AgencyProfile-pagination'>
          <PagesAgenciesDetails
            count={projects.length <= 5 ? projects.length : seen}
            collection='briefs'
            getTotalDocs={getTotalDocs}
            child='agency'
            id={agency.mainAgencyId}
          />
          <PrevNextButtons
            disabledPrev={count === 1}
            disabledNext={index === projects.length || projects.length <= 5}
            prevFunc={prevPage}
            nextFunc={nextPage}
            limit={parseInt(selectedOption) + 1}
          />
        </div>
      )}
    </section>
  );
};

export default withRouter(AgencyProfile);
