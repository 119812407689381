import React, { useState, useEffect } from 'react';
import { database } from '../../../../firebase/firebase';
import AssignProject from './AssignProject';
import '../../../../css/dashboard/projects/assign-project/AgencyDetailsDash.css';

const AgencyDetailsDash = (props) => {
  const [agencies, setAgencies] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  let agenciesList = [];

  useEffect(() => {
    let agencies = [];
    database
      .ref('agencies')
      .orderByChild('status')
      .equalTo('Activo')
      .once('value')
      .then((snapshot) => {
        snapshot.forEach((childSnap) => {
          agencies.push({
            id: childSnap.key,
            ...childSnap.val(),
          });
        });
        setAgencies([...agencies]);
        setIsLoaded(true);
      });
  }, []);

  if (isLoaded) {
    agenciesList = agencies.map((agency) => (
      <AssignProject
        key={agency.id}
        agency={agency.agency}
        user={agency.userName}
        id={agency.mainAgencyId}
        briefId={props.briefId}
        email={agency.userEmail}
      />
    ));
  }

  return (
    <div className='AgencyDetailsDash-project'>
      <div className='AgencyDetailsDash-headers'>
        <div className='AgencyDetailsDash-title-wrapper'>
          <p className='AgencyDetailsDash-title'>Agencia</p>
        </div>
        <div className='AgencyDetailsDash-title-wrapper'>
          <p className='AgencyDetailsDash-title'>Nombre de usuario</p>
        </div>
        <div className='AgencyDetailsDash-title-wrapper'>
          <p className='AgencyDetailsDash-title'>Estado</p>
        </div>
        <div className='AgencyDetailsDash-title-wrapper'>
          <p className='AgencyDetailsDash-title'>Asignar</p>
        </div>
      </div>
      {agenciesList}
    </div>
  );
};

export default AgencyDetailsDash;
