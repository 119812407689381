import React, { Component, createRef } from 'react';
import { ChevronLeft } from 'react-feather';
import { database, firebase } from '../../../firebase/firebase';
import Message from './Message';
import NavbarBg from '../../navbar/NavbarBg';
import Navbar from '../../navbar/Navbar';
import Clip from '../../../img/chat/clip.png';
import ExitIcon from '../../../icons/exit_icon.svg';
import Uploadfile from '../../../icons/add_file_mgs.svg';
import SendMsg from '../../../icons/sendMsg.svg';
import '../../../css/chat/userChat/UserChat.css';

class UserChat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: {},
      message: '',
      messages: [],
      users: [],
      isLoaded: false,
      isUploaded: false,
      fileUpload: '',
      userCount: 0,
      deliverableName: '',
      agency: {},
    };
    this.inputFile = new createRef();
    this.mgsContainer = createRef();
    this.mgsContainerMobile = createRef();
    this.goBack = this.goBack.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeFile = this.handleChangeFile.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.scrollToBottomMobile = this.scrollToBottomMobile.bind(this);
  }

  scrollToBottom() {
    const scrollHeight = this.mgsContainer.current.scrollHeight;
    const height = this.mgsContainer.current.clientHeight;
    const maxScrollTop = scrollHeight - height;
    this.mgsContainer.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  }

  scrollToBottomMobile() {
    const scrollHeight = this.mgsContainerMobile.current.scrollHeight;
    const height = this.mgsContainerMobile.current.clientHeight;
    const maxScrollTop = scrollHeight - height;
    this.mgsContainerMobile.current.scrollTop =
      maxScrollTop > 0 ? maxScrollTop : 0;
  }

  componentDidMount() {
    let messages = [];
    let users = [];

    database
      .ref(`briefs/${this.props.match.params.id}`)
      .once('value')
      .then((snapshot) => {
        if (snapshot.val().agency !== 'Sin asignar') {
          database
            .ref(`users/${snapshot.val().agency}/profile`)
            .once('value')
            .then((snap) => {
              this.setState({
                agency: {
                  agencyName: snap.val().userName,
                  agencyEmail: snap.val().userEmail,
                },
              });
            });
        }
      });

    database
      .ref(`stages/${this.props.match.params.stage}`)
      .once('value')
      .then((snap) => {
        this.setState({ stage: { ...snap.val() } });
      });

    database
      .ref(
        `usersDeliverables/${this.props.match.params.userId}/${this.props.match.params.packageId}/${this.props.match.params.stageId}/${this.props.match.params.payment}/${this.props.match.params.deliverableId}/deliverableName`
      )
      .once('value')
      .then((snapshot) => this.setState({ deliverableName: snapshot.val() }));

    database
      .ref(
        `usersDeliverables/${this.props.match.params.userId}/${this.props.match.params.packageId}/${this.props.match.params.stageId}/${this.props.match.params.payment}/${this.props.match.params.deliverableId}/mgs`
      )
      .on('child_added', (snap) => {
        this.setState((prevState) => ({
          messages: [...prevState.messages, { id: snap.key, ...snap.val() }],
        }));
      });

    database
      .ref('users')
      .once('value')
      .then((snap) => {
        snap.forEach((childSnap) => {
          users.push({
            id: childSnap.key,
            userName: childSnap.val().profile.userName,
          });
        });
        this.setState({ users: [...users], isLoaded: true });
        this.scrollToBottom();
        this.scrollToBottomMobile();
      });
  }

  componentWillUnmount() {
    database
      .ref(
        `usersDeliverables/${this.props.match.params.userId}/${this.props.match.params.packageId}/${this.props.match.params.stageId}/${this.props.match.params.payment}/${this.props.match.params.deliverableId}/mgs`
      )
      .off();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.userCount !== this.state.userCount) {
      let count = this.state.userCount;
      this.handleCount(count);
      this.scrollToBottom();
      this.scrollToBottomMobile();
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChangeFile(e) {
    const file = e.target.files[0];
    this.setState({ fileUpload: file, isUploaded: true });
  }

  handleCount(count) {
    database
      .ref(
        `usersDeliverables/${this.props.match.params.userId}/${this.props.match.params.packageId}/${this.props.match.params.stageId}/${this.props.match.params.payment}/${this.props.match.params.deliverableId}`
      )
      .update({
        userCountMgs: count,
      });
  }

  handleSubmit(e) {
    e.preventDefault();
    //Uploading a file
    if (this.state.isUploaded) {
      const name = this.state.fileUpload.name.split(' ').join('_');
      const storageRef = firebase
        .storage()
        .ref(
          `files/${this.props.match.params.userId}/${this.props.match.params.deliverableId}/${name}`
        );
      const task = storageRef.put(this.state.fileUpload);
      task.on(
        'state_changed',
        (snapshot) => {
          console.log('File has been uploaded');
        },
        (error) => {
          console.error(error.message);
        },
        () => {
          firebase
            .storage()
            .ref()
            .child(
              `files/${this.props.match.params.userId}/${this.props.match.params.deliverableId}/${name}`
            )
            .getDownloadURL()
            .then((url) => {
              database
                .ref(
                  `usersDeliverables/${this.props.match.params.userId}/${this.props.match.params.packageId}/${this.props.match.params.stageId}/${this.props.match.params.payment}/${this.props.match.params.deliverableId}/mgs`
                )
                .push({
                  userId: firebase.auth().currentUser.uid,
                  time: firebase.database.ServerValue.TIMESTAMP,
                  message: this.state.message.trim(),
                  pathUrl: url,
                  fileName: this.state.fileUpload.name,
                });
              if (this.state.agency.hasOwnProperty('agencyEmail')) {
                //sendgrid start
                fetch('https://devapi.rhino.lat/send-email', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    email: this.state.agency.agencyEmail,
                    subject: '',
                    templateId: 'd-63d9e4d62f0f4d2cb6d7f494cd3f3539',
                    data: {
                      name: this.state.agency.agencyName,
                    },
                  }),
                });
              }
              this.setState({
                message: '',
                fileUpload: '',
                isUploaded: false,
                userCount: this.state.userCount + 1,
              });
              this.inputFile.current.value = '';
            })
            .catch((e) => {
              console.log(`Error: ${e}`);
            });
        }
      );
    } else {
      database
        .ref(
          `usersDeliverables/${this.props.match.params.userId}/${this.props.match.params.packageId}/${this.props.match.params.stageId}/${this.props.match.params.payment}/${this.props.match.params.deliverableId}/mgs`
        )
        .push({
          userId: firebase.auth().currentUser.uid,
          time: firebase.database.ServerValue.TIMESTAMP,
          message: this.state.message.trim(),
        });
      if (this.state.agency.hasOwnProperty('agencyEmail')) {
        //sendgrid start
        fetch('https://devapi.rhino.lat/send-email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: this.state.agency.agencyEmail,
            subject: '',
            templateId: 'd-63d9e4d62f0f4d2cb6d7f494cd3f3539',
            data: {
              name: this.state.agency.agencyName,
            },
          }),
        });
      }
      this.setState({ message: '', userCount: this.state.userCount + 1 });
    }
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    let bgColor;
    let bgColorMobile;
    let messages = [];
    let mobileMgs = [];
    let alignItem;
    let userName;
    let url;
    let fileName;
    let color;

    if (this.state.isLoaded) {
      for (let i = 0; i < this.state.messages.length; i++) {
        if (this.state.messages[i].pathUrl !== undefined) {
          url = this.state.messages[i].pathUrl;
          fileName = this.state.messages[i].fileName;
        } else {
          url = null;
          fileName = null;
        }
        if (this.state.messages[i].userId === firebase.auth().currentUser.uid) {
          bgColor = '#f3f3f3';
          bgColorMobile = '#31A4FC';
          alignItem = 'flex-end';
          userName = 'Tú';
          color = '#fff';
        } else if (
          this.state.messages[i].userId !== firebase.auth().currentUser.uid
        ) {
          const user = this.state.users.filter(
            (user) => user.id === this.state.messages[i].userId
          );
          bgColor = '#ffffff';
          bgColorMobile = '#E5E6EA';
          alignItem = 'flex-start';
          userName = user[0].userName;
          color = '#000';
        }
        messages.push(
          <Message
            key={this.state.messages[i].id}
            message={this.state.messages[i].message}
            time={this.state.messages[i].time}
            bgColor={bgColor}
            align={alignItem}
            user={userName}
            url={url}
            fileName={fileName}
          />
        );

        mobileMgs.push(
          <Message
            key={this.state.messages[i].id}
            message={this.state.messages[i].message}
            time={this.state.messages[i].time}
            bgColor={bgColorMobile}
            align={alignItem}
            user={userName}
            url={url}
            fileName={fileName}
            color={color}
          />
        );
      }
    }

    return (
      <React.Fragment>
        <div className='UserChat-desk'>
          <Navbar />
          <NavbarBg />
          <section className='UserChat'>
            <div className='container'>
              <div className='row'>
                <button
                  onClick={this.goBack}
                  className='ProjectDetails-exit-link buttonGoBack'
                >
                  <div
                    className='ProjectDetails-exit-icon'
                    style={{ marginBottom: 0 }}
                  >
                    <ChevronLeft size={40} color={'#58595c'} />
                    <p>Salir</p>
                  </div>
                </button>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <h3 className='UserChat-title'>
                    {this.state.stage.deliverableName}
                  </h3>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  {this.state.isLoaded ? (
                    <div
                      className='UserChat-msg-container'
                      ref={this.mgsContainer}
                    >
                      {this.state.messages.length > 0 ? (
                        messages
                      ) : (
                        <p className='UserChat-msg-container-paragraph'>
                          Este es el principio de tu historial de mensajes
                          directos con la agencia.
                        </p>
                      )}
                    </div>
                  ) : (
                    <div
                      className='UserChat-msg-container'
                      ref={this.mgsContainer}
                    >
                      &nbsp;
                    </div>
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='UserChat-container'>
                    <form onSubmit={this.handleSubmit}>
                      <div className='form-group UserChat-form-group'>
                        <textarea
                          className='Message-input'
                          name='message'
                          onChange={this.handleChange}
                          value={this.state.message}
                          rows='4'
                          placeholder='Ingresa tu mensaje'
                        ></textarea>
                      </div>
                      <div className='UserChat-upload-btn-container'>
                        <div className='UserChat-upload'>
                          <label
                            htmlFor='fileUpload'
                            className='fileUploadLabel'
                          >
                            <img
                              src={Clip}
                              alt='Clip'
                              className='fileUploadLabelClip'
                            />{' '}
                            {this.state.fileUpload !== ''
                              ? this.state.fileUpload.name
                              : 'Adjuntar archivo'}
                          </label>
                          <input
                            className='fileUpload'
                            type='file'
                            ref={this.inputFile}
                            id='fileUpload'
                            name='fileUpload'
                            onChange={this.handleChangeFile}
                          />
                        </div>
                        <div className='UserChat-btn-container'>
                          <button
                            disabled={this.state.message === ''}
                            className='UserChat-btn'
                          >
                            ENVIAR
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className='UserChat-mobile'>
          <div className='UserChat-header-mobile'>
            <div>
              <div onClick={this.goBack}>
                <img src={ExitIcon} alt='Salir' />
              </div>
              <p style={{ margin: 0 }}>{this.state.deliverableName}</p>
            </div>
          </div>
          <div className='UserChat-mgs-container-mobile'>
            <p className='UserChat-mgs-title-mobile'>Rhino</p>
            {this.state.isLoaded ? (
              <div
                className='UserChat-msg-container'
                ref={this.mgsContainerMobile}
              >
                {this.state.messages.length > 0 ? (
                  mobileMgs
                ) : (
                  <p className='UserChat-msg-container-paragraph'>
                    Este es el principio de tu historial de mensajes directos
                    con el usuario
                  </p>
                )}
              </div>
            ) : (
              <div
                className='UserChat-msg-container'
                ref={this.mgsContainerMobile}
              >
                &nbsp;
              </div>
            )}
            <div>
              {this.state.fileUpload !== '' && (
                <p className='UserChat-mgs-mobile-fileName'>
                  {this.state.fileUpload.name}
                </p>
              )}
              <form onSubmit={this.handleSubmit}>
                <div className='UserChat-wrapper-mobile'>
                  <div>
                    <label htmlFor='fileUpload' className='fileUploadLabel'>
                      <img src={Uploadfile} alt='subir' />
                    </label>
                    <input
                      className='fileUpload'
                      type='file'
                      ref={this.inputFile}
                      id='fileUpload'
                      name='fileUpload'
                      onChange={this.handleChangeFile}
                    />
                  </div>
                  <div className='form-group UserChat-form-group'>
                    <textarea
                      className='Message-input'
                      name='message'
                      onChange={this.handleChange}
                      value={this.state.message}
                      rows='4'
                      placeholder='Ingresa tu mensaje'
                    ></textarea>
                  </div>
                  <div className='UserChat-mgs-send'>
                    <button
                      disabled={this.state.message === ''}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img src={SendMsg} alt='msg' />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UserChat;
