import React, { useState, useEffect } from 'react';
import { firebase, database } from '../../../firebase/firebase';
import Arrow from '../../../icons/arrow-dashboard.svg';
import '../../../css/dashboard/dropDownAdmin/DropDownAdmin.css';
import { snap } from 'gsap/gsap-core';

const DropDownAdmin = (props) => {
  const { handleLogOut } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [userName, setUserName] = useState('');

  useEffect(() => {
    let user = firebase.auth().currentUser;
    if (user != null) {
      database
        .ref(`users/${user.uid}/profile/userName`)
        .once('value')
        .then((snapshot) => {
          setUserName(snapshot.val());
        });
    }
  }, []);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className='DropDownAdmin-main'>
      <div className='DropDownAdmin-inner' onClick={handleOpen}>
        <div className='DropDownAdmin-icon'>
          <p className='DropDownAdmin-icon-initial'>
            {userName !== '' ? userName[0] : 'R'}
          </p>
        </div>
        <div className='DropDownAdmin-arrow'>
          <img src={Arrow} alt='Flecha' />
        </div>
      </div>
      {isOpen && (
        <div className='DropDownAdmin-options'>
          <ul>
            <li>
              <button onClick={handleLogOut}>Cerrar Sesión</button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropDownAdmin;
