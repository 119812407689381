import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { database } from '../../firebase/firebase';
import Title from '../utils/Title';
import Button from '../utils/Button';

class CategoryForm extends Component {
  constructor(props) {
    super(props);
    this.state = { categoryName: '', description: '' };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    database.ref('categories').push({
      categoryName: this.state.categoryName.trim(),
      description: this.state.description.trim(),
    });
    this.setState({ categoryName: '', description: '' });
    this.props.history.push('/rh-admin/dashboard/categories');
  }

  render() {
    return (
      <div className="UserForm">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <div className="UserForm-container">
                <Title title={this.props.title} />
                <form onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="categoryName">Nombre de Categoria:</label>
                    <input id="categoryName" name="categoryName" type="text" placeholder="Nombre de la categoria" className="form-control" onChange={this.handleChange} value={this.state.categoryName} />
                    <label htmlFor="categoryName">Descripción de Categoría:</label>
                    <input id="description" name="description" type="text" placeholder="Descripción de la categoria" className="form-control" onChange={this.handleChange} value={this.state.description} />
                  </div>
                  <Button className="btn btn-primary" label="Crear categoría" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CategoryForm);
