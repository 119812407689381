import React from 'react';
import Input from '../../../utils/forms/input/Input';
import Textarea from '../../../utils/forms/textarea/Textarea';
import { validateFields } from '../../../../helpers/utils';
import ButtonOnClick from '../../../utils/buttons/ButtonOnClick';
import '../../../../css/brief/BriefForms.css';

const StepTwo = (props) => {
  const {
    next,
    back,
    handleChange,
    clientsPOV,
    companySector,
    solutions,
    different,
    errorClientsPOV,
    errorCompanySector,
    errorSolutions,
    errorDifferent,
  } = props;

  const fieldsValues = [clientsPOV, companySector, solutions, different];

  return (
    <div>
      <form>
        <div className='Brief-form-container'>
          <Input
            type='text'
            label='¿Cómo ven tus clientes a tu marca y como quieres que la vean?'
            placeholder='Mis clientes nos ven como…'
            value={clientsPOV}
            name='clientsPOV'
            handleChange={handleChange}
            customError={errorClientsPOV}
          />
        </div>
        <div className='Brief-form-container'>
          <Input
            type='text'
            label='¿A qué sector pertenece tu marca?'
            placeholder='Textil'
            value={companySector}
            name='companySector'
            handleChange={handleChange}
            customError={errorCompanySector}
          />
        </div>
        <div className='Brief-form-container'>
          <Textarea
            type='text'
            label='¿Qué problemas solucionan los productos/servicios que ofrecen tu marca?'
            placeholder='Ofrecemos soluciones en…'
            value={solutions}
            name='solutions'
            handleChange={handleChange}
            customError={errorSolutions}
          />
        </div>
        <div className='Brief-form-container'>
          <Textarea
            type='text'
            label='¿Qué hace diferente a tu marca?'
            placeholder='Nos caracterizamos por…'
            value={different}
            name='different'
            handleChange={handleChange}
            customError={errorDifferent}
            message='Cuéntanos qué te distingue de la competencia, no olvides ningún detalle. '
          />
        </div>
      </form>
      <div className='Step-button-container two-btns'>
        <ButtonOnClick text='ATRÁS' click={back} />
        <ButtonOnClick
          text='CONTINUAR'
          click={next}
          isDisabled={validateFields(fieldsValues)}
        />
      </div>
    </div>
  );
};

export default StepTwo;
