import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import FormRegistroAgencia from '../../forms/agencies/FormRegistroAgencia';
import Title from '../../utils/Title';
import '../../../css/login/users/SignUp.css';

class SignUpAdmin extends Component {
  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to='/agencias/proyectos' />;
    }
    return (
      <section className='SignUp'>
        <div className='row justify-content-center align-items-center SignUpRow'>
          <div className='col-12 col-sm-12 col-md-5 col-lg-5'>
            <div className='SignUp-container'>
              <Title title='Regístrate' />
              <FormRegistroAgencia />
              <div style={{ marginTop: 20 }}>
                <p style={{ textAlign: 'center', fontSize: 13 }}>
                  ¿Ya tienes una cuenta?{' '}
                  <Link to='/rh-admin/login'>Iniciar sesión</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default SignUpAdmin;
