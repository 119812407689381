import React, { Component, createRef } from 'react';
import {
  Link, NavLink, withRouter, Redirect,
} from 'react-router-dom';
import {
  TimelineLite,
  Power3,
  TweenMax,
  TimelineMax,
  Expo,
  Linear,
} from 'gsap';
import { firebase, database } from '../../firebase/firebase';
import DropDownUser from './DropDownUser';
import SignUpModal from '../modal/SignUpModal';
import Logo from '../../img/navbar/rhinoLogo.png';
import ResponsiveLogo from '../../img/dashboard/rhino_logo_dash.png';
import '../../css/navbar/Navbar.css';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      user: {},
      isLoaded: false,
      isRedirected: false,
      show: false,
      innerWidth: null,
      categories: [],
      isCategoriesLoaded: false,
    };
    this.navRef = createRef();
    this.animNav = new TimelineLite();
    this.navbar = null;
    this.itemOne = null;
    this.itemTwo = null;
    this.itemSeven = null;
    this.liRefs = { refs: [] };
    // Modal
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    // Mobile Btn
    this.btnToggle = null;
    this.iconOneBtn = null;
    this.iconTwoBtn = null;
    this.iconThreeBtn = null;
    this.bgMenu = null;
    this.tl = new TimelineMax({ paused: true });
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleProviderLogout = this.handleProviderLogout.bind(this);
    this.setWidth = this.setWidth.bind(this);
  }

  /* Modal */
  handleOpenModal() {
    this.setState({ show: true });
  }

  handleCloseModal() {
    this.setState({ show: false });
  }

  /* Mobile Navbar */

  handleOpen() {
    this.tl.to(this.bgMenu, 0.6, { left: '0%', ease: Expo.easeInOut }).play();
    this.setState({ isOpen: !this.state.isOpen });
    TweenMax.to(this.iconOneBtn, 0.3, { rotation: -45, margin: 0 });
    TweenMax.to(this.iconThreeBtn, 0.3, { rotation: 45 });
    this.iconTwoBtn.style.display = 'none';
    this.bgMenu.style.position = 'fixed';
  }

  handleClose() {
    this.tl
      .to(this.bgMenu, 1, { left: '-100%', ease: Expo.easeInOut })
      .reversed(true)
      .play();
    TweenMax.to(this.iconOneBtn, 0.3, {
      rotation: 0,
      marginBottom: '5px',
      delay: 0.3,
    });
    TweenMax.to(this.iconThreeBtn, 0.3, { rotation: 0, delay: 0.3 });
    TweenMax.to(this.iconTwoBtn, 0.3, { display: 'block', delay: 0.3 });
    this.setState({ isOpen: !this.state.isOpen });
  }

  handleProviderLogout() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        this.setState({ isRedirected: true });
      });
  }

  setWidth() {
    this.setState({ innerWidth: window.innerWidth });
  }

  componentDidMount() {
    const categories = [];

    if (firebase.auth().currentUser !== null) {
      database
        .ref(`users/${firebase.auth().currentUser.uid}/profile`)
        .once('value')
        .then((snap) => {
          this.setState({ user: { ...snap.val() }, isLoaded: true });
        });
    }

    database
      .ref('categories')
      .limitToFirst(5)
      .orderByChild('disabled')
      .equalTo(false)
      .once('value')
      .then((snapshot) => {
        snapshot.forEach((childSnap) => {
          categories.push({
            id: childSnap.key,
            ...childSnap.val(),
          });
          this[`${childSnap.key}`] = React.createRef();
          this.liRefs.refs.push(this[`${childSnap.key}`]);
        });
        this.setState({
          categories: [...categories],
          isCategoriesLoaded: true,
        });
        const items = [
          this.itemOne,
          ...this.liRefs.refs,
          this.itemTwo,
          this.itemSeven,
        ];
        setTimeout(() => {
          this.animNav.staggerTo(
            items,
            1,
            { opacity: 1, y: 0, ease: Power3.easeOut },
            0.1,
          );
        }, 500);
      });

    this.setState({ innerWidth: window.innerWidth });
    window.addEventListener('resize', this.setWidth, false);

    // let previousScrolled = window.pageYOffset;
    window.addEventListener(
      'scroll',
      () => {
        if (window.pageYOffset > 25) {
          TweenMax.to(this.navbar, 0.25, {
            top: 0,
            backgroundColor:
              this.props.location.pathname.slice(1) !== ''
                ? !this.state.isOpen && this.state.innerWidth < 1025
                  ? '#fff'
                  : '#f47600'
                : '#f47600',
            ease: Linear.easeInOut,
          });
        }

        if (window.pageYOffset < 25) {
          TweenMax.to(this.navbar, 0.25, {
            top: 0,
            backgroundColor: 'transparent',
            ease: Linear.easeInOut,
          });
        }
        // previousScrolled = window.pageYOffset;
      },
      false,
    );
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setWidth, false);
    window.removeEventListener(
      'scroll',
      () => {
        if (window.pageYOffset > 25) {
          TweenMax.to(this.navbar, 0.25, {
            top: 0,
            backgroundColor:
              this.props.location.pathname.slice(1) !== ''
                ? !this.state.isOpen && this.state.innerWidth < 1025
                  ? '#fff'
                  : '#f47600'
                : '#f47600',
            ease: Linear.easeInOut,
          });
        }

        if (window.pageYOffset < 25) {
          TweenMax.to(this.navbar, 0.25, {
            top: 0,
            backgroundColor: 'transparent',
            ease: Linear.easeInOut,
          });
        }
        // previousScrolled = window.pageYOffset;
      },
      false,
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isOpen !== this.state.isOpen) {
      TweenMax.to(this.navbar, 0.25, {
        top: 0,
        backgroundColor:
          this.props.location.pathname.slice(1) !== '' && !this.state.isOpen
            ? '#fff'
            : '#f47600',
        ease: Linear.easeInOut,
      });

      if (window.pageYOffset < 25) {
        TweenMax.to(this.navbar, 0.25, {
          top: 0,
          backgroundColor: 'transparent',
          ease: Linear.easeInOut,
        });
      }
    }
  }

  render() {
    const path = this.props.location.pathname.slice(1);
    if (this.state.isRedirected) return <Redirect to="/" />;

    let categories;
    let mobileCategories;
    let userName;
    let id;

    if (this.state.isLoaded && firebase.auth().currentUser) {
      userName = this.state.user.userName;
      id = firebase.auth().currentUser.uid;
    }

    if (this.state.isCategoriesLoaded) {
      categories = this.state.categories.map((category, index) => (
        <li
          key={category.id}
          ref={(el) => this.liRefs.refs[index] = el}
          className="Navbar-item"
        >
          <NavLink
            exact
            activeClassName="active"
            className="Navbar-link"
            to={`/categorias/${category.id}`}
          >
            {category.categoryName}
          </NavLink>
        </li>
      ));

      mobileCategories = this.state.categories.map((category, index) => (
        <div key={index} className="mobile-item">
          <NavLink
            exact
            activeClassName="active"
            className="mobile-link"
            to={`/categorias/${category.id}`}
            onClick={this.handleClose}
          >
            {category.categoryName}
          </NavLink>
        </div>
      ));
    }

    return (
      <div ref={this.navRef} className="Navbar-container">
        <nav ref={(el) => (this.navbar = el)} className="Navbar">
          <div
            ref={(el) => (this.btnToggle = el)}
            onClick={!this.state.isOpen ? this.handleOpen : this.handleClose}
            className="toggle-btn-movil"
          >
            <div
              ref={(el) => (this.iconOneBtn = el)}
              className="icon-movil"
              style={{
                backgroundColor:
                  path !== '' && !this.state.isOpen ? '#f47600' : '#fff',
              }}
            >
              &nbsp;
            </div>
            <div
              ref={(el) => (this.iconTwoBtn = el)}
              className="icon-movil"
              style={{
                backgroundColor:
                  path !== '' && !this.state.isOpen ? '#f47600' : '#fff',
              }}
            >
              &nbsp;
            </div>
            <div
              ref={(el) => (this.iconThreeBtn = el)}
              className="icon-movil"
              style={{
                backgroundColor:
                  path !== '' && !this.state.isOpen ? '#f47600' : '#fff',
              }}
            >
              &nbsp;
            </div>
          </div>
          {path !== '' && !this.state.isOpen ? (
            <Link to="/">
              <div className="Navbar-movil-logo">
                <img src={ResponsiveLogo} alt="Rhino" />
              </div>
            </Link>
          ) : (
            <Link to="/">
              <div className="Navbar-movil-logo">
                <img src={Logo} alt="Rhino" />
              </div>
            </Link>
          )}
          <ul className="Navbar-list">
            <li
              ref={(el) => (this.itemOne = el)}
              className="Navbar-item Navabar-item-logo"
            >
              <Link to="/">
                <img src={Logo} alt="Rhino" />
              </Link>
            </li>
            {categories}
            <li
              ref={(el) => this.itemTwo = el}
              className="Navbar-item"
            >
              <NavLink
                exact
                activeClassName="active"
                className="Navbar-link"
                to="/categorias"
              >
                CATEGORIAS
              </NavLink>
            </li>
            {this.state.isLoaded ? (
              <li
                ref={(el) => (this.itemSeven = el)}
                className="Navbar-item Navbar-item-ing"
              >
                <DropDownUser
                  userName={userName.toUpperCase()}
                  id={id}
                  handleLogOut={this.handleProviderLogout}
                />
              </li>
            ) : (
              <li
                ref={(el) => (this.itemSeven = el)}
                className="Navbar-item Navbar-item-ing"
              >
                <button className="Navbar-btn" onClick={this.handleOpenModal}>
                  Ingresar
                </button>
              </li>
            )}
          </ul>
        </nav>
        <div ref={(el) => (this.bgMenu = el)} className="mobile-menu-bg">
          <div className="mobile-menu-container">
            <div className="mobile-menu">
              {mobileCategories}
              <div className="mobile-item">
                <NavLink
                  exact
                  activeClassName="active"
                  className="mobile-link"
                  to="/categorias"
                  onClick={this.handleClose}
                >
                  Categorías
                </NavLink>
              </div>
              <div className="Navbar-mobile-line">&nbsp;</div>
              {id === undefined && (
              <div className="mobile-item">
                <NavLink
                  exact
                  activeClassName="active"
                  className="mobile-link"
                  to="/login"
                >
                  Ingresar
                </NavLink>
              </div>
              )}
              {id !== undefined
                && (
                <>
                  <div className="mobile-item">
                    <NavLink
                      exact
                      activeClassName="active"
                      className="mobile-link"
                      to="/proyectos"
                      onClick={this.handleClose}
                    >
                      Mis proyectos
                    </NavLink>
                  </div>
                  <div className="mobile-item">
                    <NavLink
                      exact
                      activeClassName="active"
                      className="mobile-link"
                      to={`/perfil/${id}`}
                      onClick={this.handleClose}
                    >
                      Mi perfil
                    </NavLink>
                  </div>
                  <div className="mobile-item">
                    <button
                      className="mobile-button"
                      onClick={this.handleProviderLogout}
                    >
                      Cerrar Sesión
                    </button>
                  </div>
                </>
                )}
            </div>
          </div>
        </div>
        <SignUpModal
          show={this.state.show}
          handleClose={this.handleCloseModal}
        />
      </div>
    );
  }
}

export default withRouter(Navbar);
