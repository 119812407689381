import React from 'react';
import '../../../css/dashboard/buttons/AgencyButton.css';

const AgencyButton = (props) => {
    const { text, disabled, isValid } = props;
    return <button type='submit' className='Dashboard-AgencyButton' disabled={disabled} style={{
        backgroundColor: isValid && '#f47600',
        color: isValid && '#fff',
      }}>{text}</button>
}

export default AgencyButton;