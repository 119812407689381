import React from 'react';
import { format } from 'date-fns'
import Clip from '../../../img/chat/clip.png';
import '../../../css/chat/userChat/Message.css';

const Message = (props) => {
    const getDate = milliseconds => {
        let date = new Date(milliseconds);
        const months = ["01", "02", "03","04", "05", "06", "07", "08", "09", "10", "11", "12"];
        return date.getDate() + "-" + months[date.getMonth()] + "-" + date.getFullYear();
    }

    const date = getDate(props.time);
    const time = format(props.time, "hh:mmaaaaa'm'");

    return(
        <div className='MessageChat' style={{alignSelf: props.align}}>
            <div className='MessageChat-container' style={{backgroundColor: props.bgColor}}>
                <p className='MessageChat-paragraph' style={{color: props.color ? props.color : '#000'}}>{props.message}</p>
            </div>
            <div className='MessageChat-details-container' style={{alignItems: props.align, justifyContent: props.align}}>
                <p className='MessageChat-time'><span style={{fontWeight: 500}}>{props.user}</span> - {date} {time}</p>
                {props.url !== null && <a href={props.url} className='download-btn' target='_blank' rel="noopener noreferrer"><img src={Clip} alt='Uploaded' className='Message-clip-icon'/> {props.fileName}</a>}
            </div>
        </div>
    )
}

export default Message;