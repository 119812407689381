import React, { useState, useEffect } from 'react';
import { database } from '../../../firebase/firebase';
import '../../../css/dashboard/pages-details/PagesDetails.css';

const PagesDetails = (props) => {
  const { count, collection, getTotalDocs } = props;
  const [totalDocuments, setTotalDocuments] = useState('');

  useEffect(() => {
    let totalRecords = [];
    database
      .ref(collection)
      .once('value')
      .then((snapshot) => {
        snapshot.forEach((childSnap) => {
          totalRecords.push(childSnap.key);
        });
        setTotalDocuments(totalRecords.length);
        getTotalDocs(totalRecords.length);
      });
  }, []);

  return (
    <p className='PagesDetails'>
      Mostrando {count} de {totalDocuments} resultados
    </p>
  );
};

export default PagesDetails;
