import React from 'react';

const TransferDetails = (props) => {
  return (
    <div className='Transfer'>
      <div>
        <p className='Transfer-title'>Datos de tranferencia:</p>
      </div>
      <div className='Transfer-wrapper'>
        <div>
          <p className='Transfer-subtitle'>
            Titular de la cuenta: RHINO LAT SAS DE CV
          </p>
        </div>
        <div>
          <p className='Transfer-subtitle'>RFC: RLA171006HW3</p>
        </div>
        <div>
          <p className='Transfer-subtitle'>Banco: HSBC</p>
        </div>
        <div>
          <p className='Transfer-subtitle'>Cuenta: 4060396652</p>
        </div>
        <div>
          <p className='Transfer-subtitle'>Cuenta CLABE: 021180040603966523</p>
        </div>
      </div>
      <div className='Transfer-email-details'>
        <p className='Transfer-subtitle'>
          Recuerda poner en el concepto de pago el paquete y la etapa que se
          esta pagando y enviar el comprobante a{' '}
          <span className='Trasfer-email'>pagos@rhino.lat</span> para
          confirmarlo o comenzar con tu proyecto.
        </p>
      </div>
    </div>
  );
};

export default TransferDetails;
