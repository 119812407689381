import React from 'react';
import uuid from 'uuid/v4';
import DashInput from '../brief-dash-components/DashInput';
import DashTextarea from '../brief-dash-components/DashTextarea';

const Perception = (props) => {
    const { info } = props;

    const answers = info.map(data => {
        if(data.isText) {
            return (
                <div key={uuid()} style={{marginBottom: '26px'}}>
                    <DashTextarea label={data.label} value={data.answer} />
                </div>
            )
        } else {
            return (
                <div key={uuid()} style={{marginBottom: '26px'}}>
                    <DashInput label={data.label} value={data.answer} />
                </div>
            )
        }
    })
     
    return(
        <div>
            {answers}
        </div>
    )
}

export default Perception;