import React, { Component, createRef } from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { TweenMax, Expo } from 'gsap';
import { firebase } from '../../firebase/firebase';
import DropDownAdmin from './dropDownAdmin/DropDownAdmin';
import AgenciesList from './agencies/agencies-list/AgenciesList';
import AddAgency from './agencies/add-agency/AddAgency';
import AgencyProfile from './agencies/profile/AgencyProfile';
import EditAgency from './agencies/edit-agency/EditAgency';
import LogoDashboard from '../../img/dashboard/rhino_logo_dash.png';
import ProjectsList from './projects/project-list/ProjectsList';
import ProjectDetails from './projects/project-details/ProjectDetails';
import ProjectDashboardDetails from '../projects/projectsAdmin/ProjectDashboardDetails';
import EntregableDashList from '../projects/projectsAdmin/EntregableDashList';
import EntregableDashDetails from '../projects/projectsAdmin/EntregableDashDetails';
import AdminChat from '../chat/adminChat/AdminChat';
import UserDashboardList from './users/UserDashboardList';
import UserDashDetails from './users/user-dash-details/UserDashDetails';
import BriefDashboard from './brief-dash/BriefDashboard';
import PaymentDetails from './payments-details/PaymentDetails';
import '../../css/dashboard/Dashboard.css';
import CategoryList from '../categories/CategoryList';
import CategoryEdit from '../categories/CategoryEdit';
import CategoryForm from '../categories/CategoryForm';
import DashboardPackageList from './DashboardPackageList';
import DashboardPackages from "./packages/DashboardPackageForm";

const EnlacesNavegacion = [
  {
    name: 'Agencias',
    enlace: '/rh-admin/dashboard/agencias',
    routes: [
      'agencias',
      'registrar-agencia',
      'visualizar-agencia',
      'editar-agencia',
    ],
  },
  {
    name: 'Proyectos',
    enlace: '/rh-admin/dashboard/proyectos',
    routes: [
      'proyectos',
      'proyecto-detalles',
      'etapas',
      'pagos',
      'proyectos-detalles',
      'entregable-detalles',
    ],
  },
  {
    name: 'Usuarios',
    enlace: '/rh-admin/dashboard/usuarios',
    routes: [
      'usuarios',
      'usuario-detalles',
      'brief',
    ],
  },
  {
    name: 'Pagos',
    enlace: '/rh-admin/dashboard/pagos-detalles',
    routes: [
      'pagos-detalles',
    ],
  },
  {
    name: 'Categorías',
    enlace: '/rh-admin/dashboard/categories',
    routes: [
      'categories',
      'edit-category',
      'create-category',
    ],
  },
  {
    name: 'Planes',
    enlace: '/rh-admin/dashboard/packages',
    routes: [
      'packages',
      'create-package',
    ],
  },
];

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpened: false, isRedirected: false };
    this.panel = createRef();
    this.mainContainer = createRef();
    this.leftContent = createRef();
    this.handleIsOpened = this.handleIsOpened.bind(this);
    this.handleIsClosed = this.handleIsClosed.bind(this);
    this.handleLogOut = this.handleLogOut.bind(this);
  }

  handleIsOpened() {
    TweenMax.to(this.panel.current, 0.8, {
      x: '-200px',
      width: 0,
      display: 'none',
      ease: Expo.easeInOut,
    });
    TweenMax.to(this.mainContainer.current, 0.4, {
      width: '100%',
      ease: Expo.easeInOut,
    });
    this.setState({ isOpened: true });
  }

  handleIsClosed() {
    TweenMax.to(this.panel.current, 0.8, {
      x: '0',
      width: '200px',
      display: 'block',
      ease: Expo.easeInOut,
    });
    TweenMax.to(this.mainContainer.current, 0.4, {
      width: '100%',
      ease: Expo.easeInOut,
    });
    this.setState({ isOpened: false });
  }

  handleLogOut() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        this.setState({ isRedirected: true });
      });
  }

  render() {
    if (this.state.isRedirected) return <Redirect to="/rh-admin/login" />;

    let section;

    switch (this.props.match.params.sec) {
      case 'agencias':
        section = <AgenciesList />;
        break;
      case 'registrar-agencia':
        section = <AddAgency />;
        break;
      case 'visualizar-agencia':
        section = <AgencyProfile />;
        break;
      case 'editar-agencia':
        section = <EditAgency />;
        break;
      case 'proyectos':
        section = <ProjectsList />;
        break;
      case 'proyecto-detalles':
        section = <ProjectDetails />;
        break;
      case 'etapas':
        section = <ProjectDashboardDetails />;
        break;
      case 'pagos':
        section = <ProjectDashboardDetails />;
        break;
      case 'proyectos-detalles':
        section = <EntregableDashList />;
        break;
      case 'entregable-detalles':
        section = <EntregableDashDetails />;
        break;
      case 'msg':
        section = <AdminChat />;
        break;
      case 'usuarios':
        section = <UserDashboardList />;
        break;
      case 'usuario-detalles':
        section = <UserDashDetails />;
        break;
      case 'brief':
        section = <BriefDashboard />;
        break;
      case 'pagos-detalles':
        section = <PaymentDetails />;
        break;
      case 'categories':
        section = <CategoryList />;
        break;
      case 'edit-category':
        section = <CategoryEdit title="Editar categoría" />;
        break;
      case 'create-category':
        section = <CategoryForm title="Crear nueva categoría" />;
        break;
      case 'packages':
        section = <DashboardPackageList />;
        break;
      case 'create-package':
        section = <DashboardPackages />;
        break;
      case 'edit-package':
        section = <DashboardPackages />;
        break;
      default:
        section = <h4>Sección no encontrada</h4>;
        break;
    }

    return (
      <section className="Dashboard">
        <div className="Dashboard-left-panel" ref={this.panel}>
          <div className="Dashboard-left-paner-img">
            <img src={LogoDashboard} alt="Rhino" />
          </div>
          <div className="Dashboard-left-pannel-options" ref={this.leftContent}>
            <ul className="Dashboard-left-pannel-list">
              {
                EnlacesNavegacion.map((elemento) => {
                  const active = elemento.routes.includes(this.props.match.params.sec);
                  return (
                    <li className="Dashboard-left-pannel-item">
                      <NavLink
                        to={elemento.enlace}
                        className="Dashboard-left-pannel-link"
                        activeClassName="Dashboard-active-link"
                        style={{
                          color: active ? '#f47600' : '#bfbfbf',
                          borderLeft: active ? '4px solid #f47600' : '4px solid transparent',

                        }}
                      >

                        <div>
                          <span style={{ backgroundColor: active ? '#f47600' : '#bfbfbf' }}>
                      &nbsp;
                          </span>
                          {elemento.name}
                        </div>
                      </NavLink>
                    </li>
                  );
                })
              }
            </ul>
          </div>
        </div>
        <div className="Dashboard-right-panel" ref={this.mainContainer}>
          <div className="Dashboard-navbar">
            <div
              className="Dashboar-navbar-hamburguer"
              onClick={
                this.state.isOpened ? this.handleIsClosed : this.handleIsOpened
              }
            >
              <div className="Dashboard-navbar-icon-line">&nbsp;</div>
              <div className="Dashboard-navbar-icon-line">&nbsp;</div>
              <div className="Dashboard-navbar-icon-line">&nbsp;</div>
            </div>
            <DropDownAdmin handleLogOut={this.handleLogOut} />
          </div>
          <div
            className="Dashboar-right-panel-detail-container"
            style={{
              backgroundColor:
                this.props.match.params.sec === 'proyecto-detalles'
                || this.props.match.params.sec === 'proyectos-detalles'
                  ? 'transparent'
                  : '#fff',
              padding:
                this.props.match.params.sec === 'proyecto-detalles'
                || this.props.match.params.sec === 'proyectos-detalles'
                  ? 'transparent'
                  : '40px 20px',
              boxShadow:
                this.props.match.params.sec === 'proyecto-detalles'
                || this.props.match.params.sec === 'proyectos-detalles'
                  ? 'none'
                  : '0 8px 16px 0 rgba(0, 0, 0, 0.1)',
            }}
          >
            {section}
          </div>
        </div>
      </section>
    );
  }
}

export default Dashboard;
