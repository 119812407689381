import React from 'react';
import { Accordion } from 'react-bootstrap';
import '../../../../css/projects/project-components/AccordionMobile.css';

const AccordionMobile = (props) => {
    const { items } = props;
    return(
        <Accordion>
            {items}
        </Accordion>
    )
}

export default AccordionMobile;